import React from 'react';

import { Select } from '@rocketseat/unform';

import { Div } from './styles';

export default function InputSelect({ ...props }) {
  return (
    <Div>
      <Select {...props} />
    </Div>
  );
}
