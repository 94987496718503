/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import {
  Aside,
  Container,
  Main,
  NavTwo,
  Section,
} from '~/components/Layout/Body';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError } from '~/utils/toast';

import Menu from '../../Menu';
import { ChartMonth, Content } from './styles';

export default function Month() {
  const { tenant } = useSelector(state => state.user.profile);

  const [loadingChart, setLoadingChart] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [presences, setPresences] = useState([]);

  const [dataPieChart, setDataPieChart] = useState([]);

  const [adultsData, setAdultsData] = useState([]);
  const [teenagersData, setTeenagersData] = useState([]);
  const [visitorsData, setVisitorsData] = useState([]);
  const [new_convertsData, setNew_convertsData] = useState([]);

  const [adultsTotal, setAdultsTotal] = useState(0);
  const [teenagersTotal, setTeenagersTotal] = useState(0);
  const [visitorsTotal, setVisitorsTotal] = useState(0);
  const [new_convertsTotal, setNew_convertsTotal] = useState(0);

  const currentDate = format(new Date(), 'yyyy-MM');
  const [month, setMonth] = useState(currentDate);

  try {
    useEffect(() => {
      async function loadPresences() {
        if (month.length !== currentDate.length) {
          return;
        }

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const monthResult = utcToZonedTime(month, timezone);

        const response = await api
          .get('/dashboard-public/month', {
            params: {
              month: monthResult,
            },
          })
          .catch(async res => {
            try {
              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoadingChart(false);
            }
          });

        if (response === undefined) {
          setLoadingChart(false);

          return;
        }

        setPresences(response.data);

        setLoadingChart(false);
      }

      loadPresences();
    }, [currentDate.length, month]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  useEffect(() => {
    const timeCourse =
      presences.length === 0
        ? [0]
        : presences.map(presence =>
            format(new Date(presence.available), 'dd/MM')
          );

    const adultsResult =
      presences.length === 0 ? [0] : presences.map(presence => presence.adults);

    setAdultsTotal(
      adultsResult.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
    );

    const teenagersResult =
      presences.length === 0
        ? [0]
        : presences.map(presence => presence.teenagers);

    setTeenagersTotal(
      teenagersResult.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
    );

    const visitorsResult =
      presences.length === 0
        ? [0]
        : presences.map(presence => presence.visitors);

    setVisitorsTotal(
      visitorsResult.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
    );

    const new_convertsResult =
      presences.length === 0
        ? [0]
        : presences.map(presence => presence.new_converts);

    setNew_convertsTotal(
      new_convertsResult.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      )
    );

    setDataPieChart([
      ['Member', 'Number of members'],
      ['Adultos', adultsTotal],
      ['Adolescentes', teenagersTotal],
      ['Visitantes', visitorsTotal],
      ['Novos convertidos', new_convertsTotal],
    ]);

    const countAdultsMax = Math.max.apply(
      null,
      adultsResult.map(adult => adult)
    );

    const countAdultsMin = Math.min.apply(
      null,
      adultsResult.map(adult => adult)
    );

    const colorAdult = adultsResult.map(
      adult =>
        (adult <= countAdultsMin && '#EB5545') ||
        (adult < countAdultsMax - Math.round(countAdultsMax * 0.1) &&
          '#F4F482') ||
        (adult >= countAdultsMax - Math.round(countAdultsMax * 0.1) &&
          '#0195F6')
    );

    setAdultsData([
      ['Month', 'Adultos', { role: 'style' }, { role: 'annotation' }],
      [timeCourse[0], adultsResult[0], colorAdult[0], adultsResult[0]],
      [timeCourse[1], adultsResult[1], colorAdult[1], adultsResult[1]],
      [timeCourse[2], adultsResult[2], colorAdult[2], adultsResult[2]],
      [timeCourse[3], adultsResult[3], colorAdult[3], adultsResult[3]],
      [timeCourse[4], adultsResult[4], colorAdult[4], adultsResult[4]],
      [timeCourse[5], adultsResult[5], colorAdult[5], adultsResult[5]],
      [timeCourse[6], adultsResult[6], colorAdult[6], adultsResult[6]],
      [timeCourse[7], adultsResult[7], colorAdult[7], adultsResult[7]],
      [timeCourse[8], adultsResult[8], colorAdult[8], adultsResult[8]],
      [timeCourse[9], adultsResult[9], colorAdult[9], adultsResult[9]],
      [timeCourse[10], adultsResult[10], colorAdult[10], adultsResult[10]],
      [timeCourse[11], adultsResult[11], colorAdult[11], adultsResult[11]],
      [timeCourse[12], adultsResult[12], colorAdult[12], adultsResult[12]],
      [timeCourse[13], adultsResult[13], colorAdult[13], adultsResult[13]],
      [timeCourse[14], adultsResult[14], colorAdult[14], adultsResult[14]],
      [timeCourse[15], adultsResult[15], colorAdult[15], adultsResult[15]],
      [timeCourse[16], adultsResult[16], colorAdult[16], adultsResult[16]],
      [timeCourse[17], adultsResult[17], colorAdult[17], adultsResult[17]],
      [timeCourse[18], adultsResult[18], colorAdult[18], adultsResult[18]],
      [timeCourse[19], adultsResult[19], colorAdult[19], adultsResult[19]],
      [timeCourse[20], adultsResult[20], colorAdult[20], adultsResult[20]],
      [timeCourse[21], adultsResult[21], colorAdult[21], adultsResult[21]],
      [timeCourse[22], adultsResult[22], colorAdult[22], adultsResult[22]],
      [timeCourse[23], adultsResult[23], colorAdult[23], adultsResult[23]],
      [timeCourse[24], adultsResult[24], colorAdult[24], adultsResult[24]],
      [timeCourse[25], adultsResult[25], colorAdult[25], adultsResult[25]],
      [timeCourse[26], adultsResult[26], colorAdult[26], adultsResult[26]],
      [timeCourse[27], adultsResult[27], colorAdult[27], adultsResult[27]],
      [timeCourse[28], adultsResult[28], colorAdult[28], adultsResult[28]],
      [timeCourse[29], adultsResult[29], colorAdult[29], adultsResult[29]],
      [timeCourse[30], adultsResult[30], colorAdult[30], adultsResult[30]],
    ]);

    const countTeenagersMax = Math.max.apply(
      null,
      teenagersResult.map(teenager => teenager)
    );

    const countTeenagersMin = Math.min.apply(
      null,
      teenagersResult.map(teenager => teenager)
    );

    const colorTeenager = teenagersResult.map(
      teenager =>
        (teenager <= countTeenagersMin && '#EB5545') ||
        (teenager < countTeenagersMax - Math.round(countTeenagersMax * 0.1) &&
          '#F4F482') ||
        (teenager >= countTeenagersMax - Math.round(countTeenagersMax * 0.1) &&
          '#0195F6')
    );

    setTeenagersData([
      ['Month', 'Adolecentes', { role: 'style' }, { role: 'annotation' }],
      [timeCourse[0], teenagersResult[0], colorTeenager[0], teenagersResult[0]],
      [timeCourse[1], teenagersResult[1], colorTeenager[1], teenagersResult[1]],
      [timeCourse[2], teenagersResult[2], colorTeenager[2], teenagersResult[2]],
      [timeCourse[3], teenagersResult[3], colorTeenager[3], teenagersResult[3]],
      [timeCourse[4], teenagersResult[4], colorTeenager[4], teenagersResult[4]],
      [timeCourse[5], teenagersResult[5], colorTeenager[5], teenagersResult[5]],
      [timeCourse[6], teenagersResult[6], colorTeenager[6], teenagersResult[6]],
      [timeCourse[7], teenagersResult[7], colorTeenager[7], teenagersResult[7]],
      [timeCourse[8], teenagersResult[8], colorTeenager[8], teenagersResult[8]],
      [timeCourse[9], teenagersResult[9], colorTeenager[9], teenagersResult[9]],
      [
        timeCourse[10],
        teenagersResult[10],
        colorTeenager[10],
        teenagersResult[10],
      ],
      [
        timeCourse[11],
        teenagersResult[11],
        colorTeenager[11],
        teenagersResult[11],
      ],
      [
        timeCourse[12],
        teenagersResult[12],
        colorTeenager[12],
        teenagersResult[12],
      ],
      [
        timeCourse[13],
        teenagersResult[13],
        colorTeenager[13],
        teenagersResult[13],
      ],
      [
        timeCourse[14],
        teenagersResult[14],
        colorTeenager[14],
        teenagersResult[14],
      ],
      [
        timeCourse[15],
        teenagersResult[15],
        colorTeenager[15],
        teenagersResult[15],
      ],
      [
        timeCourse[16],
        teenagersResult[16],
        colorTeenager[16],
        teenagersResult[16],
      ],
      [
        timeCourse[17],
        teenagersResult[17],
        colorTeenager[17],
        teenagersResult[17],
      ],
      [
        timeCourse[18],
        teenagersResult[18],
        colorTeenager[18],
        teenagersResult[18],
      ],
      [
        timeCourse[19],
        teenagersResult[19],
        colorTeenager[19],
        teenagersResult[19],
      ],
      [
        timeCourse[20],
        teenagersResult[20],
        colorTeenager[20],
        teenagersResult[20],
      ],
      [
        timeCourse[21],
        teenagersResult[21],
        colorTeenager[21],
        teenagersResult[21],
      ],
      [
        timeCourse[22],
        teenagersResult[22],
        colorTeenager[22],
        teenagersResult[22],
      ],
      [
        timeCourse[23],
        teenagersResult[23],
        colorTeenager[23],
        teenagersResult[23],
      ],
      [
        timeCourse[24],
        teenagersResult[24],
        colorTeenager[24],
        teenagersResult[24],
      ],
      [
        timeCourse[25],
        teenagersResult[25],
        colorTeenager[25],
        teenagersResult[25],
      ],
      [
        timeCourse[26],
        teenagersResult[26],
        colorTeenager[26],
        teenagersResult[26],
      ],
      [
        timeCourse[27],
        teenagersResult[27],
        colorTeenager[27],
        teenagersResult[27],
      ],
      [
        timeCourse[28],
        teenagersResult[28],
        colorTeenager[28],
        teenagersResult[28],
      ],
      [
        timeCourse[29],
        teenagersResult[29],
        colorTeenager[29],
        teenagersResult[29],
      ],
      [
        timeCourse[30],
        teenagersResult[30],
        colorTeenager[30],
        teenagersResult[30],
      ],
    ]);

    const countVisitorsMax = Math.max.apply(
      null,
      visitorsResult.map(visitor => visitor)
    );

    const countVisitorsMin = Math.min.apply(
      null,
      visitorsResult.map(visitor => visitor)
    );

    const colorVisitor = visitorsResult.map(
      visitor =>
        (visitor <= countVisitorsMin && '#EB5545') ||
        (visitor < countVisitorsMax - Math.round(countVisitorsMax * 0.1) &&
          '#F4F482') ||
        (visitor >= countVisitorsMax - Math.round(countVisitorsMax * 0.1) &&
          '#0195F6')
    );

    setVisitorsData([
      ['Month', 'Visitantes', { role: 'style' }, { role: 'annotation' }],
      [timeCourse[0], visitorsResult[0], colorVisitor[0], visitorsResult[0]],
      [timeCourse[1], visitorsResult[1], colorVisitor[1], visitorsResult[1]],
      [timeCourse[2], visitorsResult[2], colorVisitor[2], visitorsResult[2]],
      [timeCourse[3], visitorsResult[3], colorVisitor[3], visitorsResult[3]],
      [timeCourse[4], visitorsResult[4], colorVisitor[4], visitorsResult[4]],
      [timeCourse[5], visitorsResult[5], colorVisitor[5], visitorsResult[5]],
      [timeCourse[6], visitorsResult[6], colorVisitor[6], visitorsResult[6]],
      [timeCourse[7], visitorsResult[7], colorVisitor[7], visitorsResult[7]],
      [timeCourse[8], visitorsResult[8], colorVisitor[8], visitorsResult[8]],
      [timeCourse[9], visitorsResult[9], colorVisitor[9], visitorsResult[9]],
      [
        timeCourse[10],
        visitorsResult[10],
        colorVisitor[10],
        visitorsResult[10],
      ],
      [
        timeCourse[11],
        visitorsResult[11],
        colorVisitor[11],
        visitorsResult[11],
      ],
      [
        timeCourse[12],
        visitorsResult[12],
        colorVisitor[12],
        visitorsResult[12],
      ],
      [
        timeCourse[13],
        visitorsResult[13],
        colorVisitor[13],
        visitorsResult[13],
      ],
      [
        timeCourse[14],
        visitorsResult[14],
        colorVisitor[14],
        visitorsResult[14],
      ],
      [
        timeCourse[15],
        visitorsResult[15],
        colorVisitor[15],
        visitorsResult[15],
      ],
      [
        timeCourse[16],
        visitorsResult[16],
        colorVisitor[16],
        visitorsResult[16],
      ],
      [
        timeCourse[17],
        visitorsResult[17],
        colorVisitor[17],
        visitorsResult[17],
      ],
      [
        timeCourse[18],
        visitorsResult[18],
        colorVisitor[18],
        visitorsResult[18],
      ],
      [
        timeCourse[19],
        visitorsResult[19],
        colorVisitor[19],
        visitorsResult[19],
      ],
      [
        timeCourse[20],
        visitorsResult[20],
        colorVisitor[20],
        visitorsResult[20],
      ],
      [
        timeCourse[21],
        visitorsResult[21],
        colorVisitor[21],
        visitorsResult[21],
      ],
      [
        timeCourse[22],
        visitorsResult[22],
        colorVisitor[22],
        visitorsResult[22],
      ],
      [
        timeCourse[23],
        visitorsResult[23],
        colorVisitor[23],
        visitorsResult[23],
      ],
      [
        timeCourse[24],
        visitorsResult[24],
        colorVisitor[24],
        visitorsResult[24],
      ],
      [
        timeCourse[25],
        visitorsResult[25],
        colorVisitor[25],
        visitorsResult[25],
      ],
      [
        timeCourse[26],
        visitorsResult[26],
        colorVisitor[26],
        visitorsResult[26],
      ],
      [
        timeCourse[27],
        visitorsResult[27],
        colorVisitor[27],
        visitorsResult[27],
      ],
      [
        timeCourse[28],
        visitorsResult[28],
        colorVisitor[28],
        visitorsResult[28],
      ],
      [
        timeCourse[29],
        visitorsResult[29],
        colorVisitor[29],
        visitorsResult[29],
      ],
      [
        timeCourse[30],
        visitorsResult[30],
        colorVisitor[30],
        visitorsResult[30],
      ],
    ]);

    const countNew_convertsMax = Math.max.apply(
      null,
      new_convertsResult.map(new_convert => new_convert)
    );

    const countNew_convertsMin = Math.min.apply(
      null,
      new_convertsResult.map(new_convert => new_convert)
    );

    const colorNew_convert = new_convertsResult.map(
      new_convert =>
        (new_convert <= countNew_convertsMin && '#EB5545') ||
        (new_convert <
          countNew_convertsMax - Math.round(countNew_convertsMax * 0.1) &&
          '#F4F482') ||
        (new_convert >=
          countNew_convertsMax - Math.round(countNew_convertsMax * 0.1) &&
          '#0195F6')
    );

    setNew_convertsData([
      ['Month', 'Novos convertidos', { role: 'style' }, { role: 'annotation' }],
      [
        timeCourse[0],
        new_convertsResult[0],
        colorNew_convert[0],
        new_convertsResult[0],
      ],
      [
        timeCourse[1],
        new_convertsResult[1],
        colorNew_convert[1],
        new_convertsResult[1],
      ],
      [
        timeCourse[2],
        new_convertsResult[2],
        colorNew_convert[2],
        new_convertsResult[2],
      ],
      [
        timeCourse[3],
        new_convertsResult[3],
        colorNew_convert[3],
        new_convertsResult[3],
      ],
      [
        timeCourse[4],
        new_convertsResult[4],
        colorNew_convert[4],
        new_convertsResult[4],
      ],
      [
        timeCourse[5],
        new_convertsResult[5],
        colorNew_convert[5],
        new_convertsResult[5],
      ],
      [
        timeCourse[6],
        new_convertsResult[6],
        colorNew_convert[6],
        new_convertsResult[6],
      ],
      [
        timeCourse[7],
        new_convertsResult[7],
        colorNew_convert[7],
        new_convertsResult[7],
      ],
      [
        timeCourse[8],
        new_convertsResult[8],
        colorNew_convert[8],
        new_convertsResult[8],
      ],
      [
        timeCourse[9],
        new_convertsResult[9],
        colorNew_convert[9],
        new_convertsResult[9],
      ],
      [
        timeCourse[10],
        new_convertsResult[10],
        colorNew_convert[10],
        new_convertsResult[10],
      ],
      [
        timeCourse[11],
        new_convertsResult[11],
        colorNew_convert[11],
        new_convertsResult[11],
      ],
      [
        timeCourse[12],
        new_convertsResult[12],
        colorNew_convert[12],
        new_convertsResult[12],
      ],
      [
        timeCourse[13],
        new_convertsResult[13],
        colorNew_convert[13],
        new_convertsResult[13],
      ],
      [
        timeCourse[14],
        new_convertsResult[14],
        colorNew_convert[14],
        new_convertsResult[14],
      ],
      [
        timeCourse[15],
        new_convertsResult[15],
        colorNew_convert[15],
        new_convertsResult[15],
      ],
      [
        timeCourse[16],
        new_convertsResult[16],
        colorNew_convert[16],
        new_convertsResult[16],
      ],
      [
        timeCourse[17],
        new_convertsResult[17],
        colorNew_convert[17],
        new_convertsResult[17],
      ],
      [
        timeCourse[18],
        new_convertsResult[18],
        colorNew_convert[18],
        new_convertsResult[18],
      ],
      [
        timeCourse[19],
        new_convertsResult[19],
        colorNew_convert[19],
        new_convertsResult[19],
      ],
      [
        timeCourse[20],
        new_convertsResult[20],
        colorNew_convert[20],
        new_convertsResult[20],
      ],
      [
        timeCourse[21],
        new_convertsResult[21],
        colorNew_convert[21],
        new_convertsResult[21],
      ],
      [
        timeCourse[22],
        new_convertsResult[22],
        colorNew_convert[22],
        new_convertsResult[22],
      ],
      [
        timeCourse[23],
        new_convertsResult[23],
        colorNew_convert[23],
        new_convertsResult[23],
      ],
      [
        timeCourse[24],
        new_convertsResult[24],
        colorNew_convert[24],
        new_convertsResult[24],
      ],
      [
        timeCourse[25],
        new_convertsResult[25],
        colorNew_convert[25],
        new_convertsResult[25],
      ],
      [
        timeCourse[26],
        new_convertsResult[26],
        colorNew_convert[26],
        new_convertsResult[26],
      ],
      [
        timeCourse[27],
        new_convertsResult[27],
        colorNew_convert[27],
        new_convertsResult[27],
      ],
      [
        timeCourse[28],
        new_convertsResult[28],
        colorNew_convert[28],
        new_convertsResult[28],
      ],
      [
        timeCourse[29],
        new_convertsResult[29],
        colorNew_convert[29],
        new_convertsResult[29],
      ],
      [
        timeCourse[30],
        new_convertsResult[30],
        colorNew_convert[30],
        new_convertsResult[30],
      ],
    ]);
  }, [
    adultsTotal,
    new_convertsTotal,
    presences,
    teenagersTotal,
    visitorsTotal,
  ]);

  const groupWidth =
    presences.length === 1
      ? '10%'
      : presences.length === 2
      ? '20%'
      : presences.length === 3
      ? '30%'
      : presences.length === 4
      ? '40$'
      : presences.length === 5
      ? '50%'
      : presences.length === 6
      ? '60%'
      : presences.length === 7
      ? '70%'
      : '75%';

  return (
    <Container>
      <Aside>
        <Menu month="month" />
      </Aside>

      <Main>
        <Title title="Um mês" count={`${presences.length}`} />

        <Section>
          <NavTwo>
            <Form>
              <div>
                <InputText
                  name="month"
                  type="month"
                  value={month}
                  onChange={event => setMonth(event.target.value)}
                  lang="pt-BR"
                  required
                />
              </div>
            </Form>
          </NavTwo>
        </Section>

        <ChartMonth>
          {loadingChart ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <>
              <Content>
                <Chart
                  chartType="PieChart"
                  data={dataPieChart}
                  options={{
                    title: tenant,
                    titleTextStyle: {
                      color: '#FFFFFF',
                      fontSize: 16,
                      bold: true,
                      italic: false,
                    },
                    height: '232px',
                    curveType: 'function',
                    backgroundColor: '#202024',
                    chartArea: { right: 0, left: 0, bottom: 27 },
                    legend: {
                      textStyle: { color: '#FFFFFF', fontSize: 12 },
                    },
                    colors: ['#0195F6', '#F4F482', '#7159C1', '#06BC0A'],
                    is3D: true,
                    // pieHole: 0.4,
                    pieSliceTextStyle: {
                      color: '#121214',
                    },
                    animation: {
                      duration: 1000,
                      easing: 'in',
                      startup: true,
                    },
                  }}
                />

                <div className="legent">
                  <div>
                    <span style={{ color: 'var(--color-blue)' }}>
                      Adultos:{' '}
                    </span>

                    <p>{adultsTotal}</p>
                  </div>

                  <hr />

                  <div>
                    <span style={{ color: 'var(--color-yellow)' }}>
                      Adolescentes:{' '}
                    </span>

                    <p>{teenagersTotal}</p>
                  </div>

                  <hr />

                  <div>
                    <span style={{ color: 'var(--color-purple)' }}>
                      Visitantes:{' '}
                    </span>

                    <p>{visitorsTotal}</p>
                  </div>

                  <hr />

                  <div>
                    <span style={{ color: 'var(--color-green)' }}>
                      Novos convertidos:{' '}
                    </span>

                    <p>{new_convertsTotal}</p>
                  </div>
                </div>
              </Content>

              <div className="chart">
                {adultsData !== 0 && (
                  <Chart
                    chartType="ComboChart"
                    data={adultsData}
                    options={{
                      title: tenant,
                      titleTextStyle: {
                        color: '#FFFFFF',
                        fontSize: 16,
                        bold: true,
                        italic: false,
                      },
                      height: '350px',
                      curveType: 'function',
                      backgroundColor: '#202024',
                      chartArea: { right: 50, left: 50 },
                      legend: {
                        position: 'bottom',
                        textStyle: { color: '#FFFFFF', fontSize: 12 },
                      },
                      colors: ['#0195F6'],
                      reverseCategories: false,
                      fontSize: 12,
                      fontName: 'Roboto',
                      hAxis: {
                        viewWindow: {
                          max: presences.length,
                        },
                        textStyle: {
                          fontSize: 10,
                          color: '#FFFFFF',
                          opacity: 0.5,
                        },
                      },
                      vAxis: {
                        viewWindow: {
                          min: 0,
                        },
                        baselineColor: '#FFFFFF',
                      },
                      seriesType: 'bars',
                      bar: { groupWidth },
                      animation: {
                        duration: 1000,
                        easing: 'in',
                        startup: true,
                      },
                    }}
                  />
                )}

                {teenagersData !== 0 && (
                  <Chart
                    chartType="ComboChart"
                    data={teenagersData}
                    style={{ paddingTop: 0.5 }}
                    options={{
                      title: tenant,
                      titleTextStyle: {
                        color: '#FFFFFF',
                        fontSize: 16,
                        bold: true,
                        italic: false,
                      },
                      height: '350px',
                      curveType: 'function',
                      backgroundColor: '#202024',
                      chartArea: { right: 50, left: 50 },
                      legend: {
                        position: 'bottom',
                        textStyle: { color: '#FFFFFF', fontSize: 12 },
                      },
                      colors: ['#0195F6'],
                      reverseCategories: false,
                      fontSize: 12,
                      fontName: 'Roboto',
                      hAxis: {
                        viewWindow: {
                          max: presences.length,
                        },
                        textStyle: {
                          fontSize: 10,
                          color: '#FFFFFF',
                          opacity: 0.5,
                        },
                      },
                      vAxis: {
                        viewWindow: {
                          min: 0,
                        },
                        baselineColor: '#FFFFFF',
                      },
                      seriesType: 'bars',
                      bar: { groupWidth },
                      animation: {
                        duration: 1000,
                        easing: 'in',
                        startup: true,
                      },
                    }}
                  />
                )}

                {visitorsData !== 0 && (
                  <Chart
                    chartType="ComboChart"
                    data={visitorsData}
                    style={{ paddingTop: 1 }}
                    options={{
                      title: tenant,
                      titleTextStyle: {
                        color: '#FFFFFF',
                        fontSize: 16,
                        bold: true,
                        italic: false,
                      },
                      height: '350px',
                      curveType: 'function',
                      backgroundColor: '#202024',
                      chartArea: { right: 50, left: 50 },
                      legend: {
                        position: 'bottom',
                        textStyle: { color: '#FFFFFF', fontSize: 12 },
                      },
                      colors: ['#0195F6'],
                      reverseCategories: false,
                      fontSize: 12,
                      fontName: 'Roboto',
                      hAxis: {
                        viewWindow: {
                          max: presences.length,
                        },
                        textStyle: {
                          fontSize: 10,
                          color: '#FFFFFF',
                          opacity: 0.5,
                        },
                      },
                      vAxis: {
                        viewWindow: {
                          min: 0,
                        },
                        baselineColor: '#FFFFFF',
                      },
                      seriesType: 'bars',
                      bar: { groupWidth },
                      animation: {
                        duration: 1000,
                        easing: 'in',
                        startup: true,
                      },
                    }}
                  />
                )}

                {new_convertsData !== 0 && (
                  <Chart
                    chartType="ComboChart"
                    data={new_convertsData}
                    style={{ paddingTop: 1 }}
                    options={{
                      title: tenant,
                      titleTextStyle: {
                        color: '#FFFFFF',
                        fontSize: 16,
                        bold: true,
                        italic: false,
                      },
                      height: '350px',
                      curveType: 'function',
                      backgroundColor: '#202024',
                      chartArea: { right: 50, left: 50 },
                      legend: {
                        position: 'bottom',
                        textStyle: { color: '#FFFFFF', fontSize: 12 },
                      },
                      colors: ['#0195F6'],
                      reverseCategories: false,
                      fontSize: 12,
                      fontName: 'Roboto',
                      hAxis: {
                        viewWindow: {
                          max: presences.length,
                        },
                        textStyle: {
                          fontSize: 10,
                          color: '#FFFFFF',
                          opacity: 0.5,
                        },
                      },
                      vAxis: {
                        viewWindow: {
                          min: 0,
                        },
                        baselineColor: '#FFFFFF',
                      },
                      seriesType: 'bars',
                      bar: { groupWidth },
                      animation: {
                        duration: 1000,
                        easing: 'in',
                        startup: true,
                      },
                    }}
                  />
                )}
              </div>
            </>
          )}

          {logout && <UserLogout message={message} />}
        </ChartMonth>
      </Main>
    </Container>
  );
}
