import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';

import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import Dialog from '~/components/Layout/Dialog';
import InputText from '~/components/Layout/Inputs/InputText';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content } from '../styles';

const schema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Descrição deve ter no mínimo 3 caracteres')
    .max(100, 'Descrição deve ter no máximo 100 caracteres'),
  description: Yup.string()
    .min(0, 'Descrição deve ter no mínimo 0 caracteres')
    .max(10000, 'Descrição deve ter no máximo 10000 caracteres'),
  link: Yup.string().max(254, 'URL YouTube deve ter no máximo 254 caracteres'),
});

export default function TrainingLessonNew({
  open,
  handleClose,
  handleDataNew,
}) {
  const idLesson = useSelector(state => state.trainingLesson.trainingLessonId);

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [link, setLink] = useState('');

  async function handleSubmit() {
    setLoading(true);

    try {
      const data = await api
        .post(`/church/course/trainings-lesson-new/${idLesson}`, {
          title,
          description,
          link,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              setMessage('Autenticação falhou. Entre em contato com seu líder');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Pro disabled') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant disabled') {
              setMessage('Conta expirou. Entre em contato com o administrador');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant expired') {
              setMessage(
                'Plano Pro expirou. Entre em contato com o administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Title already exists') {
              ToastError({
                title: 'Título já existe ',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) return;

      const { id, createdAt, updatedAt } = data.data;

      handleDataNew({
        id,
        title,
        description,
        link,
        created_at: createdAt,
        updated_at: updatedAt,
        lesson: [],
      });
      setTitle('');
      setDescription('');
      setLink('');
      handleClose();

      setLoading(false);

      ToastSuccess({ title: 'Aula adicionada' });
    } catch (error) {
      ToastError({ title: 'Erro ao adicionar, verifique os dados' });

      setLoading(false);
    }
  }

  return (
    <Dialog
      open={open}
      width={50}
      title="Adicionar aula"
      handleClose={handleClose}
    >
      <Content>
        <Form schema={schema} onSubmit={handleSubmit}>
          <InputText
            label="Título"
            name="title"
            value={title}
            onChange={event => setTitle(event.target.value)}
          />

          <InputText
            label="URL YouTube"
            name="link"
            value={link}
            onChange={event => setLink(event.target.value)}
          />

          <InputText
            label="Descrição"
            name="description"
            value={description}
            onChange={event => setDescription(event.target.value)}
            multiline
          />

          <hr />

          <div className="buttons">
            <div>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress
                    size={24}
                    style={{ color: 'var(--color-white)' }}
                  />
                ) : (
                  'Salvar'
                )}
              </SubmitButton>
            </div>

            <div className="cancel">
              <CancelButton type="button" onClick={handleClose}>
                Cancelar
              </CancelButton>
            </div>
          </div>
        </Form>
      </Content>

      {logout && <UserLogout message={message} />}
    </Dialog>
  );
}
