import React from 'react';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import photoDefault from '~/assets/photo-default.svg';

import { Container, Content, Header } from './styles';

export default function History({ history }) {
  const formattedDate = format(
    parseISO(history.created_at),
    "'Dia' dd 'às' HH:mm",
    {
      locale: pt,
    }
  );

  return (
    <Container>
      <Content>
        <Header>
          <div>
            <img
              src={history.user.photo ? history.user.url : photoDefault}
              alt=""
            />
          </div>

          <div className="group">
            <strong>{history.user.nameuser}</strong>

            <div>
              <span className="type">{history.type} - </span>

              <span>{history.register}</span>
            </div>
          </div>
        </Header>

        <div className="date">
          <span>{formattedDate}</span>
        </div>
      </Content>
    </Container>
  );
}
