import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import { Form } from '@rocketseat/unform';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import Dialog from '~/components/Layout/Dialog';
import InputText from '~/components/Layout/Inputs/InputText';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content } from '../styles';

const schema = Yup.object().shape({
  title: Yup.string()
    .min(3, 'Descrição deve ter no mínimo 3 caracteres')
    .max(100, 'Descrição deve ter no máximo 100 caracteres'),
});

export default function TrainingUpdate({
  open,
  handleClose,
  handleDataUpdate,
  trainingUpdate,
  handleDataDelete,
}) {
  const {
    churchPermissions: { training_delete },
  } = useSelector(state => state.user.profile);

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  async function handleSubmit({ title }) {
    setLoading(true);

    try {
      const data = await api
        .put(`/church/course/trainings-update/${trainingUpdate.id}`, {
          title,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Title already exists') {
              ToastError({
                title: 'Título já existe ',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) return;

      handleDataUpdate({
        id: trainingUpdate.id,
        title,
        created_at: trainingUpdate.created_at,
      });
      handleClose();

      setLoading(false);

      ToastSuccess({ title: 'Treinamento alterado' });
    } catch (error) {
      ToastError({ title: 'Erro ao adicionar, verifique os dados' });

      setLoading(false);
    }
  }

  async function handleDelete() {
    handleClose();

    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          await api.delete(
            `/church/course/trainings-delete/${trainingUpdate.id}`
          );

          Swal.fire('Excluída!', 'Treinamento excluído', 'success');

          handleDataDelete({ id: trainingUpdate.id });
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      width={40}
      title="Alterar treinamento"
      handleClose={handleClose}
    >
      <Content>
        <Form
          schema={schema}
          initialData={trainingUpdate}
          onSubmit={handleSubmit}
        >
          <InputText label="Título" name="title" />

          {training_delete && (
            <button
              type="button"
              className="delete"
              onClick={() => handleDelete()}
            >
              Excluir treinamento
            </button>
          )}

          <hr />

          <div className="buttons">
            <div>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress
                    size={24}
                    style={{ color: 'var(--color-white)' }}
                  />
                ) : (
                  'Salvar'
                )}
              </SubmitButton>
            </div>

            <div className="cancel">
              <CancelButton type="button" onClick={handleClose}>
                Cancelar
              </CancelButton>
            </div>
          </div>
        </Form>
      </Content>

      {logout && <UserLogout message={message} />}
    </Dialog>
  );
}
