import produce from 'immer';

export default function post(state = {}, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@post/UPDATE_POST_REQUEST': {
        draft.postId = action.payload.id;
        break;
      }
      case '@post/PAGE_POST_REQUEST': {
        draft.postPage = action.payload.page;
        break;
      }
      default:
    }
  });
}
