import React, { useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import * as Yup from 'yup';

import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import Dialog from '~/components/Layout/Dialog';
import InputText from '~/components/Layout/Inputs/InputText';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content } from './styles';

const schema = Yup.object().shape({
  description: Yup.string()
    .required('Descrição é obrigatória')
    .max(254, 'Motivo deve ter no máximo 254 caracteres'),
  date: Yup.date().required(),
  hour: Yup.string().required('Selecione o horário'),
  adults: Yup.number()
    .typeError('Adultos é obrigatório')
    .min(0, 'Valor mínimo de adultos 0'),
  teenagers: Yup.number()
    .typeError('Adolecentes é obrigatório')
    .min(0, 'Valor mínimo de adolecentes 0'),
  visitors: Yup.number()
    .typeError('Visitantes é obrigatório')
    .min(0, 'Valor mínimo de visitantes 0'),
  new_converts: Yup.number()
    .typeError('Convertidos é obrigatório')
    .min(0, 'Valor mínimo de convertidos 0'),
  cars: Yup.number()
    .typeError('Carros é obrigatório')
    .min(0, 'Valor mínimo de carros 0'),
  motorcycles: Yup.number()
    .typeError('Motos é obrigatório')
    .min(0, 'Valor mínimo de motos 0'),
});

export default function PublicsNew({ open, handleClose, handleDataNew }) {
  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const dateNew = { date: format(new Date(), 'yyyy-MM-dd') };

  const [description, setDescription] = useState('');
  const [adults, setAdults] = useState('');
  const [teenagers, setTeenagers] = useState('');
  const [visitors, setVisitors] = useState('');
  const [new_converts, setNew_converts] = useState('');
  const [cars, setCars] = useState('');
  const [motorcycles, setMotorcycles] = useState('');

  async function handleSubmit({ date, hour }) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = utcToZonedTime(date, timezone);

    try {
      setLoading(true);

      const data = await api
        .post('/church/admin/presences/publics-new', {
          description,
          available: timezoneDate,
          hour,
          adults,
          teenagers,
          visitors,
          new_converts,
          cars,
          motorcycles,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              setMessage('Autenticação falhou. Entre em contato com seu líder');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant disabled') {
              setMessage('Conta expirou. Entre em contato com o administrador');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant expired') {
              setMessage(
                'Plano Pro expirou. Entre em contato com o administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) return;

      const { id, available, createdAt } = data.data;

      handleDataNew({
        id,
        description,
        available,
        hour,
        adults,
        teenagers,
        visitors,
        new_converts,
        cars,
        motorcycles,
        created_at: createdAt,
      });

      setDescription('');
      setAdults('');
      setTeenagers('');
      setVisitors('');
      setNew_converts('');
      setCars('');
      setMotorcycles('');

      setLoading(false);
      handleClose();

      ToastSuccess({ title: 'Presença adicionada' });
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  return (
    <Dialog
      open={open}
      width={60}
      title="Adicionar presença"
      handleClose={handleClose}
    >
      <Content>
        <Form schema={schema} initialData={dateNew} onSubmit={handleSubmit}>
          <div className="group">
            <div className="description">
              <InputText
                label="Descrição"
                name="description"
                value={description}
                onChange={event => setDescription(event.target.value)}
              />
            </div>

            <div className="available">
              <InputText
                label="Data"
                name="date"
                type="date"
                min="2021-01-01"
                max="9999-12-31"
                lang="pt-BR"
                required
              />
            </div>

            <div className="hour">
              <InputText label="Hora" name="hour" type="time" />
            </div>
          </div>

          <div className="group">
            <div className="adults">
              <InputText
                label="Adultos"
                name="adults"
                type="number"
                value={adults}
                onChange={event => setAdults(event.target.value)}
              />
            </div>

            <div className="teenagers">
              <InputText
                label="Adolecentes"
                name="teenagers"
                type="number"
                value={teenagers}
                onChange={event => setTeenagers(event.target.value)}
              />
            </div>

            <div className="visitors">
              <InputText
                label="Visitantes"
                name="visitors"
                type="number"
                value={visitors}
                onChange={event => setVisitors(event.target.value)}
              />
            </div>

            <div className="new_converts">
              <InputText
                label="Convertidos"
                name="new_converts"
                type="number"
                value={new_converts}
                onChange={event => setNew_converts(event.target.value)}
              />
            </div>
          </div>

          <div className="group">
            <div className="cars">
              <InputText
                label="Carros"
                name="cars"
                type="number"
                value={cars}
                onChange={event => setCars(event.target.value)}
              />
            </div>

            <div className="motorcycles">
              <InputText
                label="Motos"
                name="motorcycles"
                type="number"
                value={motorcycles}
                onChange={event => setMotorcycles(event.target.value)}
              />
            </div>
          </div>

          <hr />

          <div className="buttons">
            <div>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress
                    size={24}
                    style={{ color: 'var(--color-white)' }}
                  />
                ) : (
                  'Salvar'
                )}
              </SubmitButton>
            </div>

            <div className="cancel">
              <CancelButton type="button" onClick={handleClose}>
                Cancelar
              </CancelButton>
            </div>
          </div>
        </Form>
      </Content>

      {logout && <UserLogout message={message} />}
    </Dialog>
  );
}
