import React from 'react';

import Admin from './Admin';
import Auth from './Auth';
import Course from './Course';
import Dashboard from './Dashboard';
import Media from './Media';
import Minister from './Minister';
import Praise from './Praise';
import Profile from './Profile';

export default function Routes() {
  return (
    <>
      <Auth />
      <Profile />
      <Dashboard />
      <Admin />
      <Praise />
      <Media />
      <Course />
      <Minister />
    </>
  );
}
