import produce from 'immer';

export default function scale(state = {}, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@scale/UPDATE_SCALE_REQUEST': {
        draft.scaleId = action.payload.id;
        break;
      }

      case '@scale/DUPLICATE_SCALE_REQUEST': {
        draft.scaleId = action.payload.id;
        break;
      }
      default:
    }
  });
}
