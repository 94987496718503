import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border-left: 0.1rem solid var(--color-white);
  background: var(--color-primary);
`;

export const Header = styled.div`
  margin-bottom: 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: block;
    align-items: center;

    strong {
      font-size: var(--fontSizes-4xl);
    }
  }

  div.edit {
    button {
      border: none;
      background: transparent;
      color: var(--color-white);

      &:hover {
        opacity: var(--opacity-medium);
      }
    }
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 0.5rem;

    span {
      font-size: var(--fontSizes-2xl);
      opacity: var(--opacity-default);
    }

    p {
      padding-left: 1rem;
      font-size: var(--fontSizes-xl) !important;
    }
  }

  hr {
    border: 0;
    height: 0.1rem;
    background-color: var(--color-tertiary);
  }
`;
