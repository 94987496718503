import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  background: var(--color-primary);
  border-left: ${props =>
    props.today
      ? '0.1rem solid var(--color-green)'
      : '0.1rem solid var(--color-blue)'};
  opacity: ${props =>
    props.past ? 'var(--opacity-semiOpaque)' : 'var(--opacity-medium)'};

  hr {
    border: 0;
    height: 0.1rem;
    background-color: var(--color-tertiary);

    margin: 0.5rem 0;
  }

  div.note {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    strong {
      color: var(--color-mandatory);
    }

    span {
      margin-left: 0.5rem;
      font-size: var(--fontSizes-2xl);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: 4rem;

  div.description {
    display: flex;
    align-items: center;
    justify-content: space-between;

    strong {
      color: ${props =>
        props.today ? 'var(--color-green)' : 'var(--color-blue)'};
      font-size: var(--fontSizes-6xl);

      margin-bottom: 1rem;
    }

    a {
      margin: 0 0 0 1rem;
      color: var(--color-white);
      &:hover {
        opacity: var(--opacity-medium);
      }
    }
  }

  div.hour-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    div {
      display: flex;
      align-items: center;

      span {
        font-weight: bold;
      }

      strong {
        margin-bottom: 0;
        font-size: var(--fontSizes-4xl);
      }

      a {
        margin: 0 0 0 1rem;
        color: var(--color-white);
        &:hover {
          opacity: var(--opacity-medium);
        }
      }
    }
  }
`;

export const Minister = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  strong {
    margin-bottom: 1rem;
    font-size: var(--fontSizes-4xl);
    color: ${props =>
      props.today ? 'var(--color-green)' : 'var(--color-blue)'};
  }

  div {
    display: flex;
    flex-direction: column;
    width: 100%;

    span {
      display: flex;
      align-items: center;

      font-size: var(--fontSizes-2xl);
      color: var(--color-white);

      max-width: 25ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      p {
        margin-right: 1rem;
        font-weight: bold;
        font-size: var(--fontSizes-2xl);
        color: ${props =>
          props.today ? 'var(--color-green)' : 'var(--color-blue)'};
      }
    }

    span + span {
      margin-top: 1rem;
    }
  }
`;

export const Backing = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;

  strong {
    margin-bottom: 1rem;
    font-size: var(--fontSizes-4xl);
    color: ${props =>
      props.today ? 'var(--color-green)' : 'var(--color-blue)'};
  }

  div {
    display: flex;
    flex-direction: column;
    width: 100%;

    span {
      display: flex;
      align-items: center;

      font-size: var(--fontSizes-2xl);
      color: var(--color-white);

      max-width: 25ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      p {
        margin-right: 1rem;
        font-weight: bold;
        font-size: var(--fontSizes-2xl);
        color: ${props =>
          props.today ? 'var(--color-green)' : 'var(--color-blue)'};
      }
    }

    span + span {
      margin-top: 1rem;
    }
  }
`;

export const Instrument = styled.div`
  div {
    strong {
      padding-bottom: 0.2rem;
      font-size: var(--fontSizes-4xl);
      color: ${props =>
        props.today ? 'var(--color-green)' : 'var(--color-blue)'};

      margin-right: 1rem;
    }
  }

  div + div {
    margin-top: 1rem;
  }
`;

export const Block = styled.div`
  display: flex;
  flex-direction: column;

  div.description {
    display: flex;
    justify-content: center;

    margin-bottom: 1rem;
    width: 100%;

    strong.description {
      font-size: var(--fontSizes-4xl);
      color: ${props =>
        props.today ? 'var(--color-green)' : 'var(--color-blue)'};
    }
  }
`;

export const Song = styled.div`
  div {
    div.group {
      display: flex;

      p {
        font-weight: bold;
        color: ${props =>
          props.today ? 'var(--color-green)' : 'var(--color-blue)'};
      }

      a {
        margin: 0 1rem;
        font-size: var(--fontSizes-2xl);
        color: var(--color-white);
        &:hover {
          opacity: var(--opacity-medium);
        }
      }

      strong {
        margin-right: 1rem;
        color: var(--color-yellow);
      }
    }

    span {
      font-size: var(--fontSizes-lg);
      color: var(--color-white);
      opacity: var(--opacity-medium);
    }
  }
`;
