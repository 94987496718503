import styled from 'styled-components';

export const OneYear = styled.div`
  div.loading {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 1180px) {
    div.chart {
      max-width: 65.8rem;
    }
  }
`;

export const Content = styled.div`
  margin-bottom: 4rem;
  padding: 0.5rem 5rem;
  border-radius: 0.5rem;
  background: var(--color-primary);

  display: flex;
  flex-direction: column;

  div.legent {
    margin-bottom: 1.3rem;

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-top: 0.5rem;

      span {
        font-size: var(--fontSizes-2xl);
        opacity: var(--opacity-default);
      }

      p {
        padding-left: 1rem;
        font-size: var(--fontSizes-xl) !important;
      }
    }
  }

  hr {
    border: 0;
    height: 0.1rem;
    background-color: var(--color-tertiary);
  }

  @media (min-width: 1180px) {
    max-width: 65.8rem;
  }
`;
