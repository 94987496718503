export function openTrainingLessonRequest(id) {
  return {
    type: '@training-lesson/READ_LESSON_REQUEST',
    payload: { id },
  };
}

export function selectedTrainingLessonRequest(id) {
  return {
    type: '@training-lesson/SELECTED_LESSON_REQUEST',
    payload: { id },
  };
}
