import styled from 'styled-components';

export const Content = styled.div`
  form {
    div.group {
      display: block;

      div {
        width: 100%;

        p {
          margin: -1rem 0 1rem 0;
          color: var(--color-mandatory);
        }
      }
    }

    div.group-switch {
      display: flex;
      align-items: center;

      div {
        width: 100%;
      }

      div.permission {
        padding: 0.5rem 0 0 0;
      }
    }

    button.delete {
      border: none;
      background: none;
      color: var(--color-red);

      &:hover {
        opacity: var(--opacity-semiOpaque);
      }
    }

    div.group-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin: 1rem 0 0.5rem 0;

      button.duplicate {
        border: none;
        background: none;
        color: var(--color-blue);
        &:hover {
          opacity: var(--opacity-semiOpaque);
        }
      }
    }

    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);

      margin: 0 0 2rem 0;
    }
  }

  @media (min-width: 720px) {
    form {
      div.group {
        display: flex;
        padding-bottom: 0;

        div.author {
          width: 70%;
          margin-left: 1rem;
        }

        div.tone {
          width: 20%;
          margin-right: 1rem;
        }
      }

      div.group-switch {
        margin-top: 0.6rem;
        margin-bottom: 1.5rem;
      }

      div.buttons {
        display: flex;
        flex-direction: row-reverse;

        width: 100%;

        button {
          margin-top: 2rem;
          width: 15rem;
        }

        div.cancel {
          margin-right: 1rem;

          button {
            width: 12rem;
          }
        }
      }
    }
  }
`;

export const Label = styled.label`
  margin-right: 1rem;
`;
