import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Aside, Container, Main } from '~/components/Layout/Body';
import Markdown from '~/components/Layout/Markdown';
import Title from '~/components/Layout/Title';
import Menu from '~/pages/Praise/Menu';
import api from '~/services/api';
import { ToastError } from '~/utils/toast';

import { Content } from './styles';

export default function SongRead() {
  const id = useSelector(state => state.song.songId);

  const [loading, setLoading] = useState(true);

  const [songs, setSongs] = useState([]);

  try {
    useEffect(() => {
      async function loadSongData() {
        const response = await api.get(`/church/praise/songs-show/${id}`);

        setSongs(response.data);

        setLoading(false);
      }

      loadSongData();
    }, [id]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  return (
    <Container>
      <Aside>
        <Menu song="song" />
      </Aside>

      <Main>
        <Title title="Letra da música" />

        {loading ? (
          <div
            className="loading"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <CircularProgress style={{ color: 'var(--color-blue)' }} />
          </div>
        ) : (
          <Content>
            <div>
              <h1>{songs.name}</h1>
              <strong>{`( ${songs.tone} )`}</strong>
            </div>

            <div className="letter">
              <div>
                <pre>
                  <Markdown data={songs.letter} />
                </pre>
              </div>
            </div>

            {songs.link && (
              <div className="div-player">
                <ReactPlayer url={songs.link} playing controls light pip />
              </div>
            )}
          </Content>
        )}
      </Main>
    </Container>
  );
}
