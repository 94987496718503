import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border-left: 0.1rem solid var(--color-blue);
  background: var(--color-primary);
  opacity: ${props =>
    props.edit ? 'var(--opacity-semiOpaque)' : 'var(--opacity-medium)'};

  div.minister-name {
    display: flex;
    justify-content: center;
    align-items: center;

    strong {
      margin-bottom: 0.2rem;
      font-size: var(--fontSizes-4xl);
      color: var(--color-blue);
    }
  }

  strong {
    display: flex;
    justify-content: flex-end;

    color: var(--color-yellow);
    font-size: var(--fontSizes-4xl);
  }

  hr {
    border: 0;
    height: 0.1rem;
    background-color: var(--color-tertiary);
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    a {
      margin: 0 1rem;
      color: var(--color-white);
      &:hover {
        opacity: var(--opacity-medium);
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    border: 0.2rem solid var(--color-white);
    background: var(--color-secondary);

    object-fit: cover;
  }

  div {
    margin-left: 0.5rem;
    text-align: left;

    div {
      div.group {
        display: flex;
        margin-top: 1rem;

        p {
          color: var(--color-blue);
        }

        a {
          font-size: var(--fontSizes-2xl);
          color: var(--color-white);
          &:hover {
            opacity: var(--opacity-medium);
          }
        }

        strong {
          color: var(--color-yellow);
        }
      }

      span {
        margin-left: 1rem;
        font-size: var(--fontSizes-lg);
        text-decoration: underline;
        color: var(--color-white);
        opacity: var(--opacity-medium);
      }
    }
  }
`;
