import React from 'react';
import { MdModeEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import photoDefault from '~/assets/photo-default.svg';

import { Container, Content, Header } from './styles';

export default function User({ user, onUpdate }) {
  const {
    id,
    churchPermissions: { user_update },
  } = useSelector(state => state.user.profile);

  const location = useLocation();

  return (
    <Container>
      <Content>
        <Header supervisor={user.supervisor}>
          <img src={user.photo ? user.url : photoDefault} alt="" />

          <div>
            <strong>{user.name}</strong>
            <span>{user.email}</span>
          </div>
        </Header>

        <div>
          {user_update && id !== user.id && (
            <Link
              className="permission"
              title="Alterar cadastro"
              to={`/admin/${location.pathname.split('/', 3)[2]}/users-update`}
              onClick={onUpdate}
            >
              <MdModeEdit size={20} />
            </Link>
          )}
        </div>
      </Content>
    </Container>
  );
}
