import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.5rem;
  border: 0.1rem solid var(--color-secondary);
  background: var(--color-secondary);

  overflow: hidden;

  button.open {
    display: flex;

    padding: 1rem 0 1rem 1rem;
    background: transparent;

    width: 90%;

    p {
      font-size: var(--fontSizes-2xl);
      color: var(--color-white);
      opacity: var(--opacity-intense);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      opacity: var(--opacity-default);
      font-weight: bold;
    }
  }

  button {
    display: flex;

    padding: 1rem;
    border: none;
    width: 10%;

    &:hover {
      svg {
        color: var(--color-green);
      }
    }
  }

  button.check {
    justify-content: center;

    background: var(--color-tertiary);
  }

  svg {
    min-width: 1.5rem;
    max-width: 1.5rem;

    color: var(--color-white);
  }

  @media (min-width: 1180px) {
    button.open {
      width: 80%;
    }

    button {
      width: 20%;
    }
  }
`;
