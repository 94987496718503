import styled from 'styled-components';

export const Div = styled.div`
  margin-bottom: 1rem;

  a {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    font-size: var(--fontSizes-md);
    color: var(--color-white);
    background: var(--color-primary);

    width: 8rem;
    height: 6rem;
    margin: 0.5rem;
    border-radius: 0.5rem;

    -webkit-box-align: center;
    -webkit-box-pack: justify;
  }

  @media (min-width: 1180px) {
    margin-top: -0.5rem;
    margin-bottom: 0;

    & + div {
      margin-top: 0.5rem;
    }

    a {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;

      height: 6rem;
      padding: 2rem;

      font-size: var(--fontSizes-2xl);
      font-weight: bold;

      width: 23rem;
      margin-bottom: 0;
      border-top: none;
      &:hover {
        color: var(--color-blue);
        opacity: var(--opacity-intense);
      }
    }
  }
`;
