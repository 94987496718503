import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { formatDistance, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import Swal from 'sweetalert2/src/sweetalert2';

import photoDefault from '~/assets/photo-default.svg';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputText from '~/components/Layout/Inputs/InputText';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Comments } from './styles';

export default function Comment({ postComment, post, handleComment }) {
  const {
    id: idUser,
    churchPermissions: { post_praise_delete },
  } = useSelector(state => state.user.profile);

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [answer, setAnswer] = useState(false);

  const [comments, setComments] = useState('');
  const [borderInput, setBorderInput] = useState(false);

  const formattedDate = formatDistance(
    parseISO(postComment.created_at),
    new Date(),
    {
      addSuffix: true,
      locale: pt,
    }
  );

  function handleAnswer() {
    setAnswer(!answer);
  }

  async function handleSubmit() {
    try {
      setLoading(true);

      if (comments.length < 5) {
        setLoading(false);
        setBorderInput(true);

        setTimeout(() => setBorderInput(false), 5000);

        return;
      }

      const data = await api
        .post('/church/praise/post-comments-new', {
          post_id: post.id,
          title: post.title,
          comment: comments,
          answer: postComment.user_id,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              setMessage('Autenticação falhou. Entre em contato com seu líder');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Pro disabled') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant disabled') {
              setMessage('Conta expirou. Entre em contato com o administrador');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant expired') {
              setMessage(
                'Plano Pro expirou. Entre em contato com o administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) {
        setLoading(false);

        return;
      }

      ToastSuccess({ title: 'Resposta adicionada' });

      setComments('');
      setAnswer(false);
      handleComment(false);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  async function handleDelete(id) {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          await api.delete(`/church/praise/post-comments-delete/${id}`);

          Swal.fire('Excluída!', 'Mensagem excluída', 'success');

          handleComment(false);
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Comments key={postComment.id}>
      <div className="comments">
        <div className="title-comment">
          <img
            src={postComment.user.photo ? postComment.user.url : photoDefault}
            alt=""
          />
        </div>

        <div className="boby-comment">
          <p>
            <strong className="user-name">{postComment.user.nameuser}</strong>

            <strong className="user-name-answer">
              {postComment.answer_id &&
                `${'@'}${postComment.answer_id.nameuser}`}
            </strong>

            {postComment.comment}
          </p>
        </div>
      </div>

      <div className="button-comment">
        <div className="group-buttons">
          {idUser !== postComment.user_id && (
            <button type="button" onClick={() => handleAnswer()}>
              Responder
            </button>
          )}

          {!post_praise_delete ? (
            idUser === postComment.user_id && (
              <button
                className="delete"
                type="button"
                onClick={() => handleDelete(postComment.id)}
              >
                Excluir
              </button>
            )
          ) : (
            <button
              className="delete"
              type="button"
              onClick={() => handleDelete(postComment.id)}
            >
              Excluir
            </button>
          )}
        </div>

        <div>
          <span>{formattedDate}</span>
        </div>
      </div>

      {answer && (
        <div className="comment">
          <div className="input-button">
            <div className="div-input">
              <InputText
                name="post"
                placeholder="Resposta ..."
                onChange={event => setComments(event.target.value)}
                style={
                  borderInput ? { border: '0.1rem solid var(--color-red)' } : {}
                }
              />
            </div>

            <SubmitButton type="button" onClick={() => handleSubmit()}>
              {loading ? (
                <CircularProgress
                  size={24}
                  style={{ color: 'var(--color-white)' }}
                />
              ) : (
                'Enviar'
              )}
            </SubmitButton>
          </div>

          {borderInput && <span>Resposta deve ter no mínimo 5 caracteres</span>}
        </div>
      )}

      {logout && <UserLogout message={message} />}
    </Comments>
  );
}
