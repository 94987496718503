import React from 'react';
import {
  MdGroup,
  MdInput,
  MdNewReleases,
  MdPersonOutline,
  MdSettings,
} from 'react-icons/md';
import { useSelector } from 'react-redux';

import MenuButton from '~/components/Layout/Buttons/MenuButton';

import { Content } from './styles';

export default function Menu({
  user,
  presence,
  presenceLeader,
  church,
  changelogs,
  login,
}) {
  const {
    praise_department,
    churchPermissions: {
      user_update,
      user_list,
      song_update,
      presence_list,
      presence_leader_list,
      account_list,
      login_list,
      history_list,
    },
  } = useSelector(state => state.user.profile);

  return (
    <Content>
      <div>
        {user_list && (
          <MenuButton to="/admin/admin/users-active" focus={user}>
            <MdGroup size={18} /> Membros
          </MenuButton>
        )}

        {presence_list && (
          <MenuButton
            to="/admin/presences/publics/publics-list"
            focus={presence}
          >
            <MdGroup size={18} /> Presenças
          </MenuButton>
        )}

        {presence_leader_list && (
          <MenuButton
            to="/admin/presences/leaders/leaders-list"
            focus={presenceLeader}
          >
            <MdPersonOutline size={18} /> Faltas
          </MenuButton>
        )}

        {account_list || user_update || (song_update && praise_department) ? (
          <MenuButton
            to={
              // eslint-disable-next-line no-nested-ternary
              account_list
                ? '/admin/account/church/church-dice'
                : // eslint-disable-next-line no-nested-ternary
                user_update
                ? '/admin/account/archiveds/users-archived'
                : song_update && praise_department
                ? '/admin/account/archiveds/songs-archived'
                : '/'
            }
            className="permission"
            focus={church}
          >
            <MdSettings size={18} /> Conta
          </MenuButton>
        ) : (
          ''
        )}

        <MenuButton to="/admin/changelogs" focus={changelogs}>
          <MdNewReleases size={18} /> Changelog
        </MenuButton>

        {login_list || history_list ? (
          <MenuButton
            className="permission"
            to={
              // eslint-disable-next-line no-nested-ternary
              login_list
                ? '/admin/logins'
                : history_list
                ? '/admin/historys'
                : '/'
            }
            focus={login}
          >
            <MdInput size={18} /> Históricos
          </MenuButton>
        ) : (
          ''
        )}
      </div>
    </Content>
  );
}
