import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0 2rem;
  width: 100%;
  background: var(--color-primary);
  box-shadow: rgb(12 12 14 / 60%) 0 0.5rem 2rem;

  position: fixed;
  z-index: 2;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  height: 6.4rem;

  nav {
    display: flex;
    align-items: center;

    a {
      display: flex;
      align-items: center;

      img {
        height: 4rem;
        width: 4rem;

        padding-right: 1rem;
      }

      strong {
        display: none;
        padding-right: 1rem;
        color: var(--color-blue);
      }
    }

    button {
      display: flex;
      align-items: center;

      border-style: initial;
      border-color: initial;
      border-image: initial;
      padding-right: 2rem;

      background: none;
      color: var(--color-white);
      transition: color 0.2s ease 0s;

      svg {
        font-size: var(--fontSizes-5xl);
      }
    }

    button.button-home {
      svg {
        ${props =>
          props.home &&
          css`
            color: var(--color-blue);
            content: '';
          `};
      }
    }

    div.div-home,
    div.div-dashboard {
      display: none;
    }

    button.button-dashboard {
      svg {
        ${props =>
          props.dashboard &&
          css`
            color: var(--color-blue);
            content: '';
          `};
      }
    }
  }

  aside {
    display: flex;
    align-items: center;
  }

  @media (min-width: 1180px) {
    nav {
      a {
        img {
          height: 5rem;
          width: 5rem;
        }

        strong {
          display: unset;
          font-size: var(--fontSizes-5xl);
        }
      }

      button svg {
        display: none;
      }

      div.div-home {
        display: unset;
        padding: 0 1rem;

        opacity: var(--opacity-medium);
        ${props =>
          props.home &&
          css`
            border-bottom: 0.2rem solid var(--color-blue);
            opacity: var(--opacity-default);
            content: '';
          `}

        &:hover {
          border-bottom: 0.2rem solid var(--color-blue);
          opacity: var(--opacity-default);
        }

        button {
          padding: 2.165rem 0;
        }
      }

      div.div-dashboard {
        display: unset;
        padding: 0 1rem;

        opacity: var(--opacity-medium);
        ${props =>
          props.dashboard &&
          css`
            border-bottom: 0.2rem solid var(--color-blue);
            opacity: var(--opacity-default);
            content: '';
          `}

        &:hover {
          border-bottom: 0.2rem solid var(--color-blue);
          opacity: var(--opacity-default);
        }

        button {
          padding: 2.165rem 0;
        }
      }
    }
  }
`;
