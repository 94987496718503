import React from 'react';
import { MdArrowBack, MdHome, MdLeaderboard } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';

import logo from '~/assets/logo.png';
import Bill from '~/components/Layout/Bill';
import Notifications from '~/components/Layout/Notifications';

import { Container, Content } from './styles';

export default function Header() {
  const {
    churchPermissions: { presence_list },
  } = useSelector(state => state.user.profile);
  const history = useHistory();

  const location = useLocation();

  return (
    <Container>
      <Content
        home={location.pathname === '/dashboard'}
        dashboard={
          location.pathname === '/dashboard-public/month' ||
          location.pathname === '/dashboard-public/last-six-months' ||
          location.pathname === '/dashboard-public/one-year'
        }
      >
        <nav>
          <Link to="/">
            <img src={logo} alt="Praise" />
            <strong>PRAISE</strong>
          </Link>

          <button type="button" onClick={() => window.history.back()}>
            <MdArrowBack />
          </button>

          <button
            className="button-home"
            type="button"
            onClick={() => history.push('/')}
          >
            <MdHome />
          </button>

          <div className="div-home">
            <button type="button" onClick={() => history.push('/')}>
              Home
            </button>
          </div>

          {presence_list && (
            <>
              <button
                className="button-dashboard"
                type="button"
                onClick={() => history.push('/dashboard-public/month')}
              >
                <MdLeaderboard />
              </button>

              <div className="div-dashboard">
                <button
                  type="button"
                  onClick={() => history.push('/dashboard-public/month')}
                >
                  Dashboard
                </button>
              </div>
            </>
          )}
        </nav>

        <aside>
          <Notifications />

          <Bill />
        </aside>
      </Content>
    </Container>
  );
}
