import styled from 'styled-components';

export const Content = styled.div`
  form {
    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);

      margin: 0.5rem 0 2rem 0;
    }
  }

  @media (min-width: 720px) {
    div.buttons {
      display: flex;
      flex-direction: row-reverse;

      width: 100%;

      button {
        margin-top: 2rem;
        width: 15rem;
      }

      div.cancel {
        margin-right: 1rem;

        button {
          width: 12rem;
        }
      }
    }
  }
`;

export const Share = styled.div`
  div.share {
    margin-top: 0.5rem;
  }

  button.delete {
    margin-top: 2rem;

    border: none;
    background: none;
    color: var(--color-red);

    &:hover {
      opacity: var(--opacity-semiOpaque);
    }
  }
`;

export const Label = styled.label``;
