import React from 'react';

import { Aside, Container, Main } from '~/components/Layout/Body';
import Pro from '~/components/Pro';

import Menu from '../Menu';

export default function ScalesList() {
  return (
    <Container>
      <Aside>
        <Menu />
      </Aside>

      <Main>
        <Pro />
      </Main>
    </Container>
  );
}
