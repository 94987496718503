import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border-left: 0.1rem solid var(--color-blue);
  background: var(--color-primary);

  &:hover {
    opacity: var(--opacity-semiOpaque);
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    white-space: nowrap;

    a {
      color: var(--color-white);

      &:hover {
        opacity: var(--opacity-medium);
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: ${props =>
      props.supervisor
        ? '0.2rem solid var(--color-blue)'
        : '0.2rem solid var(--color-white)'};
    background: var(--color-secondary);

    object-fit: cover;
  }

  div {
    margin-left: 1rem;
    text-align: left;

    max-width: 18ch;
    overflow: hidden;
    text-overflow: ellipsis;

    strong {
      margin-bottom: 0.2rem;
      display: block;
      font-size: var(--fontSizes-2xl);
      color: var(--color-white);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      font-size: var(--fontSizes-2xl);
      opacity: var(--opacity-medium);
    }
  }

  @media (min-width: 720px) {
    div {
      max-width: 40ch;
    }
  }
`;
