import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

export default function BlockFour({
  today,
  scale,
  blockFourReadOne,
  blockFourReadTwo,
  blockFourReadThree,
  blockFourReadFour,
  blockFourReadFive,
  blockFourReadSix,
  blockFourReadSeven,
  blockFourReadEight,
}) {
  return (
    <Container today={today}>
      {scale.blockFour.firstSong && (
        <div>
          <div className="group">
            <p>1.</p>

            <Link to="/song/song-read" onClick={blockFourReadOne}>
              {scale.blockFour.firstSong ? scale.blockFour.firstSong.name : ''}
            </Link>

            <strong>
              {scale.blockFour.firstSong ? scale.blockFour.firstSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockFour.firstSong ? scale.blockFour.firstSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFour.secondSong && (
        <div>
          <div className="group">
            <p>2.</p>

            <Link to="/song/song-read" onClick={blockFourReadTwo}>
              {scale.blockFour.secondSong
                ? scale.blockFour.secondSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockFour.secondSong
                ? scale.blockFour.secondSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockFour.secondSong
              ? scale.blockFour.secondSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFour.thirdSong && (
        <div>
          <div className="group">
            <p>3.</p>

            <Link to="/song/song-read" onClick={blockFourReadThree}>
              {scale.blockFour.thirdSong ? scale.blockFour.thirdSong.name : ''}
            </Link>

            <strong>
              {scale.blockFour.thirdSong ? scale.blockFour.thirdSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockFour.thirdSong ? scale.blockFour.thirdSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFour.fourthtSong && (
        <div>
          <div className="group">
            <p>4.</p>

            <Link to="/song/song-read" onClick={blockFourReadFour}>
              {scale.blockFour.fourthtSong
                ? scale.blockFour.fourthtSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockFour.fourthtSong
                ? scale.blockFour.fourthtSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockFour.fourthtSong
              ? scale.blockFour.fourthtSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFour.fifthSong && (
        <div>
          <div className="group">
            <p>5.</p>

            <Link to="/song/song-read" onClick={blockFourReadFive}>
              {scale.blockFour.fifthSong ? scale.blockFour.fifthSong.name : ''}
            </Link>

            <strong>
              {scale.blockFour.fifthSong ? scale.blockFour.fifthSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockFour.fifthSong ? scale.blockFour.fifthSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFour.sixthSong && (
        <div>
          <div className="group">
            <p>6.</p>

            <Link to="/song/song-read" onClick={blockFourReadSix}>
              {scale.blockFour.sixthSong ? scale.blockFour.sixthSong.name : ''}
            </Link>

            <strong>
              {scale.blockFour.sixthSong ? scale.blockFour.sixthSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockFour.sixthSong ? scale.blockFour.sixthSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFour.seventhSong && (
        <div>
          <div className="group">
            <p>7.</p>

            <Link to="/song/song-read" onClick={blockFourReadSeven}>
              {scale.blockFour.seventhSong
                ? scale.blockFour.seventhSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockFour.seventhSong
                ? scale.blockFour.seventhSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockFour.seventhSong
              ? scale.blockFour.seventhSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFour.eighthSong && (
        <div>
          <div className="group">
            <p>8.</p>

            <Link to="/song/song-read" onClick={blockFourReadEight}>
              {scale.blockFour.eighthSong
                ? scale.blockFour.eighthSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockFour.eighthSong
                ? scale.blockFour.eighthSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockFour.eighthSong
              ? scale.blockFour.eighthSong.author
              : ''}
          </span>
        </div>
      )}
    </Container>
  );
}
