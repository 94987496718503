import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';

import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import Dialog from '~/components/Layout/Dialog';
import InputText from '~/components/Layout/Inputs/InputText';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content, Label } from '../styles';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Nome deve ter no mínimo 3 caracteres')
    .max(100, 'Nome deve ter no máximo 100 caracteres'),
  author: Yup.string()
    .min(3, 'Autor deve ter no mínimo 3 caracteres')
    .max(100, 'Autor deve ter no máximo 100 caracteres'),
  letter: Yup.string()
    .min(10, 'Letra deve ter no mínimo 10 caracteres')
    .max(5000, 'Letra deve ter no máximo 5000 caracteres'),
  tone: Yup.string()
    .min(1, 'Tom deve ter no mínimo 1 caracteres')
    .max(20, 'Tom deve ter no máximo 20 caracteres'),
  link: Yup.string().max(254, 'URL YouTube deve ter no máximo 254 caracteres'),
  active: Yup.boolean(),
  archived: Yup.boolean(),
});

export default function SongDuplicate({
  open,
  songUpdate,
  handleClose,
  handleSongDuplicate,
}) {
  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [songs, setSongs] = useState([]);
  const [letter, setLetter] = useState();

  const [shepherd, setShepherd] = useState(false);
  const [active, setActive] = useState(true);

  const [errorInput, setErrorInput] = useState(false);

  try {
    useEffect(() => {
      async function loadSongData() {
        const data = await songUpdate;

        setSongs(data);
        setLetter(data.letter);
        setShepherd(data.shepherd);
        setActive(data.active);
      }

      loadSongData();
    }, [songUpdate]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleSubmit({ name, author, tone, link }) {
    try {
      setLoading(true);

      const dataResponse = await api
        .post('/church/praise/songs-new', {
          name,
          author,
          letter,
          tone,
          link,
          shepherd,
          active,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              setMessage('Autenticação falhou. Entre em contato com seu líder');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Praise department disabled') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Pro disabled') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant disabled') {
              setMessage('Conta expirou. Entre em contato com o administrador');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant expired') {
              setMessage(
                'Plano Pro expirou. Entre em contato com o administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Name already exists') {
              ToastError({ title: 'Nome de musíca já existe' });

              setLoading(false);
              setErrorInput(true);

              setTimeout(() => setErrorInput(false), 5000);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      const { id } = dataResponse.data;

      handleSongDuplicate({
        id,
        name,
        author,
        letter,
        tone,
        link,
        shepherd,
        active,
      });
      handleClose();

      ToastSuccess({ title: 'Música adicionada' });
    } catch (error) {
      ToastError({ title: 'Erro ao adicionar, verifique os dados' });

      setLoading(false);
    }
  }

  return (
    <>
      <Dialog
        open={open}
        width={60}
        title="Duplicar música"
        handleClose={handleClose}
      >
        <Content>
          <Form schema={schema} initialData={songs} onSubmit={handleSubmit}>
            <div className="group">
              <div className="name">
                <InputText label="Nome" name="name" />

                {errorInput && <p>Nome já existe</p>}
              </div>

              <div className="author">
                <InputText label="Autor" name="author" />
              </div>
            </div>

            <div className="group">
              <div className="tone">
                <InputText label="Tom" name="tone" />
              </div>

              <div>
                <InputText label="URL YouTube" name="link" />
              </div>
            </div>

            <div className="group">
              <div>
                <InputText
                  label="Letra"
                  name="letter"
                  value={letter}
                  onChange={event => setLetter(event.target.value)}
                  multiline
                />
              </div>
            </div>

            <div className="group-switch">
              <div>
                <Label>Pastor</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={() => setShepherd(!shepherd)}
                    checked={shepherd}
                  />
                </div>
              </div>

              <div>
                <Label>Ativa</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={() => setActive(!active)}
                    checked={active}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="buttons">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <CancelButton type="button" onClick={handleClose}>
                  Cancelar
                </CancelButton>
              </div>
            </div>
          </Form>
        </Content>

        {logout && <UserLogout message={message} />}
      </Dialog>
    </>
  );
}
