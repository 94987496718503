import styled from 'styled-components';

export const Content = styled.div`
  form {
    button.delete {
      margin-bottom: 0.5rem;

      border: none;
      background: none;
      color: var(--color-red);

      &:hover {
        opacity: var(--opacity-semiOpaque);
      }
    }

    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);

      margin: 0 0 2rem 0;
    }
  }

  @media (min-width: 720px) {
    form {
      div.buttons {
        display: flex;
        flex-direction: row-reverse;

        width: 100%;

        button {
          margin-top: 2rem;
          width: 15rem;
        }

        div.cancel {
          margin-right: 1rem;

          button {
            width: 12rem;
          }
        }
      }
    }
  }
`;
