import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Layout/Body';
import ButtonSelectRemove from '~/components/Layout/Buttons/ButtonSelectRemove';
import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputSelect from '~/components/Layout/Inputs/InputSelect';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Praise/Menu';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content } from '../styles';

const schema = Yup.object().shape({
  minister_id: Yup.string().required('Selecione um ministro'),
  first_song: Yup.string().required('Selecione uma música'),
  second_song: Yup.string(),
  third_song: Yup.string(),
  fourth_song: Yup.string(),
  fifth_song: Yup.string(),
  sixth_song: Yup.string(),
  seventh_song: Yup.string(),
  eighth_song: Yup.string(),
  available: Yup.date().required(),
});

export default function BlockUpdateThree() {
  const {
    churchPermissions: { block_delete },
  } = useSelector(state => state.user.profile);

  const id = useSelector(state => state.block.blockId);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [availables, setAvailables] = useState([]);

  const [minister, setMinister] = useState();
  const [ministers, setMinisters] = useState([]);

  const [first, setFirst] = useState();
  const [second, setSecond] = useState();
  const [third, setThird] = useState();
  const [fourth, setFourth] = useState();
  const [fifth, setFifth] = useState();
  const [fixth, setFixth] = useState();
  const [seventh, setSeventh] = useState();
  const [eighth, setEighth] = useState();

  const [songs, setSongs] = useState([]);

  try {
    useEffect(() => {
      async function loadBlockData() {
        const response = await api.get(
          `/church/praise/blocks-three-show/${id}`
        );

        const {
          minister_id,
          first_song,
          second_song,
          third_song,
          fourth_song,
          fifth_song,
          sixth_song,
          seventh_song,
          eighth_song,
          available,
        } = response.data;

        const parsedDate = parseISO(available);
        const formattedDate = format(parsedDate, 'yyyy-MM-dd');

        setAvailables({ available: formattedDate });

        setMinister(minister_id);

        setFirst(first_song);
        setSecond(second_song || undefined);
        setThird(third_song || undefined);
        setFourth(fourth_song || undefined);
        setFifth(fifth_song || undefined);
        setFixth(sixth_song || undefined);
        setSeventh(seventh_song || undefined);
        setEighth(eighth_song || undefined);
      }

      loadBlockData();
    }, [id]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  try {
    useEffect(() => {
      async function loadMinisters() {
        const { data } = await api.get('/church/admin/users-minister');

        setMinisters(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadMinisters();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os ministros' });
  }

  try {
    useEffect(() => {
      async function loadSongs() {
        const { data } = await api.get('/church/praise/songs-active');

        setSongs(data.map(song => ({ id: song.id, title: song.name })));
      }

      loadSongs();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar as músicas' });
  }

  async function handleSubmit({
    minister_id,
    first_song,
    second_song,
    third_song,
    fourth_song,
    fifth_song,
    sixth_song,
    seventh_song,
    eighth_song,
    available,
  }) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = utcToZonedTime(available, timezone);

    try {
      setLoading(true);

      const data = {
        minister_id,
        first_song,
        second_song,
        third_song,
        fourth_song,
        fifth_song,
        sixth_song,
        seventh_song,
        eighth_song,
        available: timezoneDate,
      };

      const dataResponse = await api
        .put(`/church/praise/blocks-three-update/${id}`, data)
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Past dates are not permitted') {
              ToastError({
                title: 'Datas passadas não são permitidas',
              });

              setLoading(false);

              return;
            }

            if (
              res.response.data.error ===
              'There is already a block with a more recent date'
            ) {
              ToastError({
                title: 'Já existe um bloco com uma data mais recente',
              });

              setLoading(false);

              return;
            }

            if (
              res.response.data.error ===
              'Block one already registered in this in the selected period'
            ) {
              ToastError({
                title: 'Já existe um bloco cadastrado  no período selecionado',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      ToastSuccess({ title: 'Bloco alterado' });

      history.push('/block/block-list-three');
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao alterar, verifique os dados' });
    }
  }

  async function handleDelete() {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          await api.delete(`/church/praise/blocks-three-delete/${id}`);

          Swal.fire('Excluído!', 'Bloco 3 excluído', 'success');

          history.push('/block/block-list-three');
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Container>
      <Aside>
        <Menu block="block" />
      </Aside>

      <Main>
        <Title title="Alterar bloco 3" />

        <Content>
          <Form
            schema={schema}
            initialData={availables}
            onSubmit={handleSubmit}
          >
            <div className="group">
              <div className="minister-date">
                <div className="minister">
                  <InputSelect
                    label="Voz principal"
                    name="minister_id"
                    value={minister}
                    options={ministers}
                    onChange={event => setMinister(event.target.value)}
                  />
                </div>

                <div className="date">
                  <InputText
                    label="Data"
                    name="available"
                    type="date"
                    min="2021-01-01"
                    max="9999-12-31"
                    lang="pt-BR"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="group">
              <div className="first-song">
                <InputSelect
                  label="Música 1"
                  name="first_song"
                  value={first}
                  options={songs}
                  onChange={event => setFirst(event.target.value)}
                />
              </div>

              <div className="second-song">
                <InputSelect
                  label="Música 2"
                  name="second_song"
                  value={second}
                  options={songs}
                  onChange={event => setSecond(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setSecond('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="third-song">
                <InputSelect
                  label="Música 3"
                  name="third_song"
                  value={third}
                  options={songs}
                  onChange={event => setThird(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setThird('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="fourth-song">
                <InputSelect
                  label="Música 4"
                  name="fourth_song"
                  value={fourth}
                  options={songs}
                  onChange={event => setFourth(event.target.value)}
                />
                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setFourth('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="fifth-song">
                <InputSelect
                  label="Música 5"
                  name="fifth_song"
                  value={fifth}
                  options={songs}
                  onChange={event => setFifth(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setFifth('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="sixth_song">
                <InputSelect
                  label="Música 6"
                  name="sixth_song"
                  value={fixth}
                  options={songs}
                  onChange={event => setFixth(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setFixth('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="seventh_song">
                <InputSelect
                  label="Música 7"
                  name="seventh_song"
                  value={seventh}
                  options={songs}
                  onChange={event => setSeventh(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setSeventh('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="eighth_song">
                <InputSelect
                  label="Música 8"
                  name="eighth_song"
                  value={eighth}
                  options={songs}
                  onChange={event => setEighth(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setEighth('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            {block_delete && (
              <button
                type="button"
                className="delete"
                onClick={() => handleDelete()}
              >
                Excluir bloco 3
              </button>
            )}

            <hr />

            <div className="buttons">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <CancelButton
                  type="button"
                  onClick={() => history.push('/block/block-list-three')}
                >
                  Cancelar
                </CancelButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
