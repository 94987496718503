import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import UsersActive from '~/pages/Admin/User/UsersActive';
import UsersDisabled from '~/pages/Admin/User/UsersDisabled';
import UsersNew from '~/pages/Admin/User/UsersNew/UsersNewMinister';
import UsersPermission from '~/pages/Admin/User/UsersPermission/UsersPermissionMinister';
import UsersUpdate from '~/pages/Admin/User/UsersUpdate/UsersUpdateMinister';
import Pro from '~/pages/Minister/Pro';
import ScalesList from '~/pages/Minister/Scale/ScalesList';
import ScalesNew from '~/pages/Minister/Scale/ScalesNew';
import ScalesUpdate from '~/pages/Minister/Scale/ScalesUpdate';

import Route from './Route';

export default function Minister() {
  const version = useSelector(state => state.user.profile);

  const pro = version === null ? false : version.pro;

  return (
    <Switch>
      <Route
        path="/minister/scale/scales-new"
        component={pro ? ScalesNew : Pro}
        isPrivate
      />
      <Route
        path="/minister/scale/scales-list"
        component={pro ? ScalesList : Pro}
        isPrivate
      />
      <Route
        path="/minister/scale/scales-update"
        component={pro ? ScalesUpdate : Pro}
        isPrivate
      />

      <Route
        path="/admin/minister/users-active"
        component={pro ? UsersActive : Pro}
        isPrivate
      />
      <Route
        path="/admin/minister/users-disabled"
        component={pro ? UsersDisabled : Pro}
        isPrivate
      />
      <Route
        path="/admin/minister/users-new"
        component={pro ? UsersNew : Pro}
        isPrivate
      />
      <Route
        path="/admin/minister/users-update"
        component={pro ? UsersUpdate : Pro}
        isPrivate
      />
      <Route
        path="/admin/minister/users-permission"
        component={pro ? UsersPermission : Pro}
        isPrivate
      />
    </Switch>
  );
}
