import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import {
  differenceInYears,
  endOfMonth,
  format,
  isAfter,
  isValid,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import {
  Aside,
  Container,
  Main,
  Nav,
  NavTwo,
  Section,
} from '~/components/Layout/Body';
import SubMenuButton from '~/components/Layout/Buttons/SubMenuButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import Scale from '~/components/Pages/Media/Scale';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Media/Menu';
import api from '~/services/api';
import { updateScaleMediaRequest } from '~/store/modules/media/scale/actions';
import { ToastError } from '~/utils/toast';

export default function ScalesList() {
  const {
    churchPermissions: { scale_media_new },
  } = useSelector(state => state.user.profile);

  const dispatch = useDispatch();

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [scales, setScales] = useState([]);

  const currentDate = format(new Date(), 'yyyy-MM-dd');

  const [initialDate, setInitialDate] = useState(currentDate);

  const [finalDate, setFinalDate] = useState(
    format(endOfMonth(new Date()), 'yyyy-MM-dd')
  );

  const initial = {
    initialDateSelected: initialDate,
    finalDateSelected: finalDate,
  };

  try {
    useEffect(() => {
      async function loadScales() {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timezoneDateInitial = utcToZonedTime(initialDate, timezone);
        const timezoneDateFinal = utcToZonedTime(finalDate, timezone);

        setLoading(true);

        const response = await api
          .get('/church/media/scales-list', {
            params: {
              initialDate: timezoneDateInitial,
              finalDate: timezoneDateFinal,
            },
          })
          .catch(async res => {
            try {
              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Pro disabled') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setScales(response.data);

        setLoading(false);
      }
      loadScales();
    }, [finalDate, initialDate]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  function handleSubmit({ initialDateSelected, finalDateSelected }) {
    if (isAfter(new Date(initialDateSelected), new Date(finalDateSelected))) {
      ToastError({
        title: 'A data inicial não pode ser maior que a data final',
      });

      setLoading(false);

      return;
    }

    if (
      differenceInYears(
        new Date(initialDateSelected),
        new Date(finalDateSelected)
      ) !== 0
    ) {
      ToastError({
        title: 'A busca máxima é de um ano',
      });

      setLoading(false);

      return;
    }

    if (isValid(initialDateSelected)) {
      ToastError({
        title: 'A data inicial é inválida',
      });

      setLoading(false);

      return;
    }

    if (isValid(finalDateSelected)) {
      ToastError({
        title: 'A data final é inválida',
      });

      setLoading(false);

      return;
    }

    setInitialDate(initialDateSelected);
    setFinalDate(finalDateSelected);
  }

  async function handleUpdate(id) {
    dispatch(updateScaleMediaRequest(id));
  }

  return (
    <Container>
      <Aside>
        <Menu scale="scale" />
      </Aside>

      <Main>
        <Title title="Escalas de mídia" count={`${scales.length}`} />

        <Section>
          <Nav>
            {scale_media_new && (
              <div>
                <SubMenuButton
                  type="button"
                  onClick={() => history.push('/media/scale/scales-new')}
                >
                  Adicionar
                </SubMenuButton>
              </div>
            )}
          </Nav>

          <NavTwo>
            <Form initialData={initial} onSubmit={handleSubmit}>
              <div>
                <InputText
                  name="initialDateSelected"
                  type="date"
                  min="2021-01-01"
                  max="9999-12-31"
                  lang="pt-BR"
                  required
                />

                <InputText
                  name="finalDateSelected"
                  type="date"
                  min="2021-01-01"
                  max="9999-12-31"
                  lang="pt-BR"
                  required
                />
              </div>

              <SubmitButton type="submit">
                {loading ? (
                  <div className="loading">
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  </div>
                ) : (
                  'Buscar'
                )}
              </SubmitButton>
            </Form>
          </NavTwo>

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {scales.map(scale => (
                <Scale
                  key={scale.id}
                  scale={scale}
                  onUpdate={() => handleUpdate(scale.id)}
                />
              ))}
              {scales.length === 0 && (
                <p className="empty">Nenhuma escala encontrada</p>
              )}
            </ul>
          )}
        </Section>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
