import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Aside, Container, Main } from '~/components/Layout/Body';
import SubMenuButton from '~/components/Layout/Buttons/SubMenuButton';
import Title from '~/components/Layout/Title';
import Post from '~/components/Pages/Praise/Post';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import {
  pagePostRequest,
  updatePostRequest,
} from '~/store/modules/praise/post/actions';
import { ToastError } from '~/utils/toast';

import Menu from '../../Menu';
import { Scroll } from './styles';

export default function PostList() {
  const {
    churchPermissions: { post_praise_new },
  } = useSelector(state => state.user.profile);
  const pageNumber = useSelector(state => state.post.postPage);

  const dispatch = useDispatch();

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [posts, setPosts] = useState([]);

  const [comment, setComment] = useState(false);

  const [page, setPage] = useState(pageNumber === undefined ? 1 : pageNumber);

  dispatch(pagePostRequest(page));

  try {
    useEffect(() => {
      async function loadPosts() {
        const response = await api
          .get('/church/praise/posts-list', { params: { page } })
          .catch(async res => {
            try {
              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Pro disabled') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setPosts(response.data);

        setLoading(false);
      }

      loadPosts();
    }, [page, comment]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleUpdate(id) {
    dispatch(updatePostRequest(id));
  }

  return (
    <Container>
      <Aside>
        <Menu post="post" />
      </Aside>

      <Main>
        <Title title="Postagem" />

        <Scroll>
          {post_praise_new && (
            <div className="buttons">
              <SubMenuButton
                type="button"
                onClick={() => history.push('/post/post-new')}
              >
                Adicionar
              </SubMenuButton>
            </div>
          )}

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {posts.post.map(post => (
                <Post
                  key={post.id}
                  post={post}
                  postComments={posts.comment}
                  onUpdate={() => handleUpdate(post.id)}
                  handleComment={() => setComment(!comment)}
                  comment={comment}
                />
              ))}

              {posts.post.length === 0 && (
                <p className="empty">Nenhuma postagem encontrada</p>
              )}

              <div className="div-button-page">
                <button
                  type="button"
                  onClick={() => setPage(page - 1)}
                  disabled={page === 1}
                >
                  Anterior
                </button>

                <span>[ {page} ]</span>

                <button
                  type="button"
                  onClick={() => setPage(page + 1)}
                  disabled={posts.post.length === 0}
                >
                  Próximo
                </button>
              </div>
            </ul>
          )}
        </Scroll>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
