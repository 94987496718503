import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Switch from 'react-switch';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format, parseISO } from 'date-fns';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Layout/Body';
import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Media/Menu';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content, Label } from '../styles';

const schema = Yup.object().shape({
  user_new: Yup.boolean(),
  user_update: Yup.boolean(),
  user_delete: Yup.boolean(),
  user_list: Yup.boolean(),

  scale_media_new: Yup.boolean(),
  scale_media_update: Yup.boolean(),
  scale_media_delete: Yup.boolean(),
  scale_media_list: Yup.boolean(),
});

export default function UsersPermissionMedia() {
  const id = useSelector(state => state.user.permissionId);
  const { supervisor } = useSelector(state => state.user.profile);

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [churchPermissions, setChurchPermissions] = useState([]);

  const [user_new, setUser_new] = useState(false);
  const [user_update, setUser_update] = useState(false);
  const [user_delete, setUser_delete] = useState(false);
  const [user_list, setUser_list] = useState(false);

  const [scale_media_new, setScale_media_new] = useState(false);
  const [scale_media_update, setScale_media_update] = useState(false);
  const [scale_media_delete, setScale_media_delete] = useState(false);
  const [scale_media_list, setScale_media_list] = useState(false);

  try {
    useEffect(() => {
      async function loadChurchPermissionsData() {
        const { data } = await api.get(`/church/admin/users-permission/${id}`);

        const { created_at, updated_at } = data;

        const parsedCreate = parseISO(created_at);
        const parsedUpdate = parseISO(updated_at);

        const formattedCreate = format(parsedCreate, 'yyyy-MM-dd');
        const formattedUpdate = format(parsedUpdate, 'yyyy-MM-dd');

        const response = {
          id: data.id,
          name: data.user.name,
          nameuser: data.user.nameuser,
          email: data.user.email,
          created_at: formattedCreate,
          updated_at: formattedUpdate,
        };

        setChurchPermissions(response);

        setUser_new(data.user_new);
        setUser_update(data.user_update);
        setUser_delete(data.user_delete);
        setUser_list(data.user_list);

        setScale_media_new(data.scale_media_new);
        setScale_media_update(data.scale_media_update);
        setScale_media_delete(data.scale_media_delete);
        setScale_media_list(data.scale_media_list);
      }

      loadChurchPermissionsData();
    }, [id]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  function handleUserNew() {
    if (user_new) {
      setUser_new(false);
    } else {
      setUser_new(true);
      setUser_list(true);
    }
  }

  function handleUserUpdate() {
    if (user_update) {
      setUser_update(false);
      setUser_delete(false);
    } else {
      setUser_update(true);
      setUser_list(true);
    }
  }

  function handleUserDelete() {
    if (user_delete) {
      setUser_delete(false);
    } else {
      setUser_delete(true);
      setUser_update(true);
      setUser_list(true);
    }
  }

  function handleUserList() {
    if (user_list) {
      setUser_list(false);
      setUser_new(false);
      setUser_update(false);
      setUser_delete(false);
    } else {
      setUser_list(true);
    }
  }

  function handleScaleMediaNew() {
    if (scale_media_new) {
      setScale_media_new(false);
    } else {
      setScale_media_new(true);
      setScale_media_list(true);
    }
  }

  function handleScaleMediaUpdate() {
    if (scale_media_update) {
      setScale_media_update(false);
      setScale_media_delete(false);
    } else {
      setScale_media_update(true);
      setScale_media_list(true);
    }
  }

  function handleScaleMediaDelete() {
    if (scale_media_delete) {
      setScale_media_delete(false);
    } else {
      setScale_media_delete(true);
      setScale_media_update(true);
      setScale_media_list(true);
    }
  }

  function handleScaleMediaList() {
    if (scale_media_list) {
      setScale_media_list(false);
      setScale_media_new(false);
      setScale_media_update(false);
      setScale_media_delete(false);
    } else {
      setScale_media_list(true);
    }
  }

  async function handleSubmit() {
    try {
      setLoading(true);

      const data = {
        user_new,
        user_update,
        user_delete,
        user_list,

        scale_media_new,
        scale_media_update,
        scale_media_delete,
        scale_media_list,
      };

      const dataResponse = await api
        .put(`/church/admin/users-permission/${churchPermissions.id}`, data)
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      ToastSuccess({ title: 'Permissão alterada' });

      window.history.back();
    } catch (error) {
      ToastError({ title: 'Erro ao alterar, verifique os dados' });

      setLoading(false);
    }
  }

  return (
    <Container>
      <Aside>
        <Menu user="user" />
      </Aside>

      <Main>
        <Title title="Alterar permissão" />

        <Content>
          <Form
            schema={schema}
            initialData={churchPermissions}
            onSubmit={handleSubmit}
          >
            <div className="group">
              <div className="name">
                <InputText
                  label="Nome completo"
                  title="Nome completo"
                  name="name"
                  disabled
                />
              </div>

              <div className="nameuser">
                <InputText
                  label="Nome de usuário"
                  title="Nome de usuário"
                  name="nameuser"
                  disabled
                />
              </div>
            </div>

            <div className="group">
              <div className="email">
                <InputText
                  label="E-mail"
                  title="E-mail"
                  name="email"
                  type="email"
                  disabled
                />
              </div>
            </div>

            <div className="group">
              <div className="created">
                <InputText
                  label="Cadastrado"
                  title="Data que foi cadastro"
                  name="created_at"
                  type="date"
                  disabled
                />
              </div>

              <div className="updated">
                <InputText
                  label="Alterado"
                  title="Data que foi alterado"
                  name="updated_at"
                  type="date"
                  disabled
                />
              </div>
            </div>

            <hr />

            <div className="title">
              <strong>Membro</strong>
            </div>

            <div className="group-switch">
              <div>
                <Label>Cadastrar</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={handleUserNew}
                    checked={user_new}
                  />
                </div>
              </div>

              <div>
                <Label>Editar</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={handleUserUpdate}
                    checked={user_update}
                  />
                </div>
              </div>

              {supervisor && (
                <div>
                  <Label>Deletar</Label>
                  <div className="permission">
                    <Switch
                      height={15}
                      width={40}
                      handleDiameter={20}
                      onColor="#0195F6"
                      offColor="#121214"
                      onChange={handleUserDelete}
                      checked={user_delete}
                    />
                  </div>
                </div>
              )}

              <div>
                <Label>Listar</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={handleUserList}
                    checked={user_list}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="title">
              <strong>Escala de mídia</strong>
            </div>

            <div className="group-switch">
              <div>
                <Label>Cadastrar</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={handleScaleMediaNew}
                    checked={scale_media_new}
                  />
                </div>
              </div>

              <div>
                <Label>Editar</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={handleScaleMediaUpdate}
                    checked={scale_media_update}
                  />
                </div>
              </div>

              <div>
                <Label>Deletar</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={handleScaleMediaDelete}
                    checked={scale_media_delete}
                  />
                </div>
              </div>

              <div>
                <Label>Listar</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={handleScaleMediaList}
                    checked={scale_media_list}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="buttons">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <CancelButton
                  type="button"
                  onClick={() => window.history.back()}
                >
                  Cancelar
                </CancelButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
