import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Layout/Body';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import Menu from '../../Menu';
import { Content } from './styles';

const schema = Yup.object().shape({
  title: Yup.string()
    .required()
    .min(3, 'Título deve ter no mínimo 3 caracteres')
    .max(100, 'Título deve ter no máximo 100 caracteres'),
  description: Yup.string()
    .min(0, 'Descrição deve ter no mínimo 0 caracteres')
    .max(10000, 'Descrição deve ter no máximo 10000 caracteres'),
  link: Yup.string().max(254, 'URL YouTube deve ter no máximo 254 caracteres'),
});

export default function PostUpdate() {
  const {
    churchPermissions: { post_praise_delete },
  } = useSelector(state => state.user.profile);

  const id = useSelector(state => state.post.postId);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [posts, setPosts] = useState([]);
  const [description, setDescription] = useState();

  try {
    useEffect(() => {
      async function loadPostData() {
        const { data } = await api.get(`/church/praise/posts-show/${id}`);

        setPosts(data);
        setDescription(data.description);
      }

      loadPostData();
    }, [id]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleSubmit({ title, link }) {
    try {
      setLoading(true);

      const dataResponse = await api
        .put(`/church/praise/posts-update/${id}`, {
          title,
          description,
          link,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Title already exists') {
              ToastError({
                title: 'Título já existe ',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      ToastSuccess({ title: 'Postagem alterada' });

      history.push('/post/post-list');
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao alterar, verifique os dados' });
    }
  }

  async function handleDelete() {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          await api.delete(`/church/praise/posts-delete/${id}`);

          Swal.fire('Excluída!', 'Postagem excluída', 'success');

          history.push('/post/post-list');
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Container>
      <Aside>
        <Menu post="post" />
      </Aside>

      <Main>
        <Title title="Alterar postagem" />

        <Content>
          <Form schema={schema} initialData={posts} onSubmit={handleSubmit}>
            <div className="group">
              <div className="title">
                <InputText label="Título" name="title" />
              </div>
            </div>

            <div className="group">
              <div className="link">
                <InputText label="URL YouTube" name="link" />
              </div>
            </div>

            <div className="group">
              <div>
                <InputText
                  label="Descrição"
                  name="description"
                  value={description}
                  onChange={event => setDescription(event.target.value)}
                  multiline
                />
              </div>
            </div>

            {post_praise_delete && (
              <button
                type="button"
                className="delete"
                onClick={() => handleDelete()}
              >
                Excluir postagem
              </button>
            )}

            <div className="group">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>
              <div className="cancel">
                <SubmitButton
                  type="button"
                  onClick={() => history.push('/post/post-list')}
                >
                  Cancelar
                </SubmitButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
