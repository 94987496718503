import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import Dialog from '~/components/Layout/Dialog';
import InputText from '~/components/Layout/Inputs/InputText';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content } from './styles';

const schema = Yup.object().shape({
  description: Yup.string()
    .required('Descrição é obrigatória')
    .max(254, 'Motivo deve ter no máximo 254 caracteres'),
  date: Yup.date().required(),
  hour: Yup.string().required('Selecione o horário'),
  adults: Yup.number()
    .typeError('Adultos é obrigatório')
    .min(0, 'Valor mínimo de adultos 0'),
  teenagers: Yup.number()
    .typeError('Adolecentes é obrigatório')
    .min(0, 'Valor mínimo de adolecentes 0'),
  visitors: Yup.number()
    .typeError('Visitantes é obrigatório')
    .min(0, 'Valor mínimo de visitantes 0'),
  new_converts: Yup.number()
    .typeError('Convertidos é obrigatório')
    .min(0, 'Valor mínimo de convertidos 0'),
  cars: Yup.number()
    .typeError('Carros é obrigatório')
    .min(0, 'Valor mínimo de carros 0'),
  motorcycles: Yup.number()
    .typeError('Motos é obrigatório')
    .min(0, 'Valor mínimo de motos 0'),
});

export default function PublicsUpdate({
  open,
  handleClose,
  handleDataUpdate,
  presenceUpdate,
  handleDataDelete,
}) {
  const {
    churchPermissions: { presence_delete },
  } = useSelector(state => state.user.profile);

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [presences, setPresences] = useState([]);

  try {
    useEffect(() => {
      async function loadPublicData() {
        const parsedDate = parseISO(presenceUpdate.available);
        const formattedDate = format(parsedDate, 'yyyy-MM-dd');

        const data = await presenceUpdate;

        setPresences({
          description: data.description,
          date: formattedDate,
          hour: data.hour,
          adults: data.adults,
          teenagers: data.teenagers,
          visitors: data.visitors,
          new_converts: data.new_converts,
          cars: data.cars,
          motorcycles: data.motorcycles,
        });
      }

      loadPublicData();
    }, [presenceUpdate]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleSubmit({
    description,
    date,
    hour,
    adults,
    teenagers,
    visitors,
    new_converts,
    cars,
    motorcycles,
  }) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = utcToZonedTime(date, timezone);

    try {
      setLoading(true);

      const data = {
        description,
        available: timezoneDate,
        hour,
        adults,
        teenagers,
        visitors,
        new_converts,
        cars,
        motorcycles,
      };

      const dataResponse = await api
        .put(
          `/church/admin/presences/publics-update/${presenceUpdate.id}`,
          data
        )
        .catch(async res => {
          try {
            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      handleDataUpdate({
        id: presenceUpdate.id,
        description,
        available: timezoneDate.toISOString(),
        hour,
        adults,
        teenagers,
        visitors,
        new_converts,
        cars,
        motorcycles,
        created_at: presenceUpdate.created_at,
      });
      handleClose();

      ToastSuccess({ title: 'Presença alterada' });
    } catch (error) {
      ToastError({ title: 'Erro ao alterar, verifique os dados' });

      setLoading(false);
    }
  }

  async function handleDelete() {
    handleClose();

    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          await api.delete(
            `/church/admin/presences/publics-delete/${presenceUpdate.id}`
          );

          Swal.fire('Excluída!', 'Presença excluída', 'success');

          handleDataDelete({ id: presenceUpdate.id });
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      width={60}
      title="Alterar presença"
      handleClose={handleClose}
    >
      <Content>
        <Form schema={schema} initialData={presences} onSubmit={handleSubmit}>
          <div className="group">
            <div className="description">
              <InputText label="Descrição" name="description" />
            </div>

            <div className="available">
              <InputText
                label="Data"
                name="date"
                type="date"
                min="2021-01-01"
                max="9999-12-31"
                lang="pt-BR"
                required
              />
            </div>

            <div className="hour">
              <InputText label="Hora" name="hour" type="time" />
            </div>
          </div>

          <div className="group">
            <div className="adults">
              <InputText label="Adultos" name="adults" type="number" />
            </div>

            <div className="teenagers">
              <InputText label="Adolecentes" name="teenagers" type="number" />
            </div>

            <div className="visitors">
              <InputText label="Visitantes" name="visitors" type="number" />
            </div>

            <div className="new_converts">
              <InputText
                label="Convertidos"
                name="new_converts"
                type="number"
              />
            </div>
          </div>

          <div className="group">
            <div className="cars">
              <InputText label="Carros" name="cars" type="number" />
            </div>

            <div className="motorcycles">
              <InputText label="Motos" name="motorcycles" type="number" />
            </div>
          </div>

          {presence_delete && (
            <button
              type="button"
              className="delete"
              onClick={() => handleDelete()}
            >
              Excluir presença
            </button>
          )}

          <hr />

          <div className="buttons">
            <div>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress
                    size={24}
                    style={{ color: 'var(--color-white)' }}
                  />
                ) : (
                  'Salvar'
                )}
              </SubmitButton>
            </div>

            <div className="cancel">
              <CancelButton type="button" onClick={handleClose}>
                Cancelar
              </CancelButton>
            </div>
          </div>
        </Form>
      </Content>

      {logout && <UserLogout message={message} />}
    </Dialog>
  );
}
