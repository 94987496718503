import { combineReducers } from 'redux';

import user from './admin/user/reducer';
import auth from './auth/reducer';
import trainingLesson from './course/trainingLesson/reducer';
import scaleMedia from './media/scale/reducer';
import scaleMinister from './minister/scale/reducer';
import block from './praise/block/reducer';
import post from './praise/post/reducer';
import scale from './praise/scale/reducer';
import song from './praise/song/reducer';
import prayer from './prayer/reducer';

export default combineReducers({
  auth,
  user,
  song,
  block,
  scale,
  post,
  scaleMinister,
  scaleMedia,
  trainingLesson,
  prayer,
});
