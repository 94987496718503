/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Aside, Container, Main } from '~/components/Layout/Body';
import Title from '~/components/Layout/Title';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError } from '~/utils/toast';

import Menu from '../../Menu';
import { Content, OneYear } from './styles';

export default function LastSixMonths() {
  const { tenant } = useSelector(state => state.user.profile);

  const [loadingChart, setLoadingChart] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [data, setData] = useState([]);

  const [dataPieChart, setDataPieChart] = useState([]);

  const [dataComboChartAdults, setDataComboChartAdults] = useState([]);
  const [dataComboChartTeenagers, setDataComboChartTeenagers] = useState([]);
  const [dataComboChartVisitors, setDataComboChartVisitors] = useState([]);
  const [dataComboChartNew_converts, setDataComboChartNew_converts] = useState(
    []
  );

  const [timeCourseResult, setTimeCourseResult] = useState([0]);
  const [adultsResult, setAdultsResult] = useState([0]);
  const [teenagersResult, setTeenagersResult] = useState([0]);
  const [visitorsResult, setVisitorsResult] = useState([0]);
  const [new_convertsResult, setNew_convertsResult] = useState([0]);

  const [adultsTotal, setAdultsTotal] = useState([0]);
  const [teenagersTotal, setTeenagersTotal] = useState([0]);
  const [visitorsTotal, setVisitorsTotal] = useState([0]);
  const [new_convertsTotal, setNew_convertsTotal] = useState([0]);

  try {
    useEffect(() => {
      async function loadPresencesGrafic() {
        const response = await api
          .get('/dashboard-public/one-year')
          .catch(async res => {
            try {
              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Pro disabled') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoadingChart(false);
            }
          });

        setData(response.data);

        setLoadingChart(false);
      }

      loadPresencesGrafic();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  useEffect(() => {
    setTimeCourseResult(data.length === 0 ? [0] : data[0]);
    setAdultsResult(data.length === 0 ? [0] : data[1]);
    setTeenagersResult(data.length === 0 ? [0] : data[2]);
    setVisitorsResult(data.length === 0 ? [0] : data[3]);
    setNew_convertsResult(data.length === 0 ? [0] : data[4]);

    setAdultsTotal(data.length === 0 ? [0] : data[5]);
    setTeenagersTotal(data.length === 0 ? [0] : data[6]);
    setVisitorsTotal(data.length === 0 ? [0] : data[7]);
    setNew_convertsTotal(data.length === 0 ? [0] : data[8]);

    setDataPieChart([
      ['Member', 'Number of members'],
      ['Adultos', adultsTotal],
      ['Adolescentes', teenagersTotal],
      ['Visitantes', visitorsTotal],
      ['Novos convertidos', new_convertsTotal],
    ]);

    const countAdultsMax = Math.max.apply(
      null,
      adultsResult.map(adult => adult)
    );

    const countAdultsMin = Math.min.apply(
      null,
      adultsResult.map(adult => adult)
    );

    const colorAdult = adultsResult.map(
      adult =>
        (adult <= countAdultsMin && '#EB5545') ||
        (adult < countAdultsMax - Math.round(countAdultsMax * 0.1) &&
          '#F4F482') ||
        (adult >= countAdultsMax - Math.round(countAdultsMax * 0.1) &&
          '#0195F6')
    );

    setDataComboChartAdults([
      ['Month', 'Adultos', { role: 'style' }, { role: 'annotation' }],
      [timeCourseResult[0], adultsResult[0], colorAdult[0], adultsResult[0]],
      [timeCourseResult[1], adultsResult[1], colorAdult[1], adultsResult[1]],
      [timeCourseResult[2], adultsResult[2], colorAdult[2], adultsResult[2]],
      [timeCourseResult[3], adultsResult[3], colorAdult[3], adultsResult[3]],
      [timeCourseResult[4], adultsResult[4], colorAdult[4], adultsResult[4]],
      [timeCourseResult[5], adultsResult[5], colorAdult[5], adultsResult[5]],
      [timeCourseResult[6], adultsResult[6], colorAdult[6], adultsResult[6]],
      [timeCourseResult[7], adultsResult[7], colorAdult[7], adultsResult[7]],
      [timeCourseResult[8], adultsResult[8], colorAdult[8], adultsResult[8]],
      [timeCourseResult[9], adultsResult[9], colorAdult[9], adultsResult[9]],
      [
        timeCourseResult[10],
        adultsResult[10],
        colorAdult[10],
        adultsResult[10],
      ],
      [
        timeCourseResult[11],
        adultsResult[11],
        colorAdult[11],
        adultsResult[11],
      ],
    ]);

    const countTeenagersMax = Math.max.apply(
      null,
      teenagersResult.map(teenager => teenager)
    );

    const countTeenagersMin = Math.min.apply(
      null,
      teenagersResult.map(teenager => teenager)
    );

    const colorTeenager = teenagersResult.map(
      teenager =>
        (teenager <= countTeenagersMin && '#EB5545') ||
        (teenager < countTeenagersMax - Math.round(countTeenagersMax * 0.1) &&
          '#F4F482') ||
        (teenager >= countTeenagersMax - Math.round(countTeenagersMax * 0.1) &&
          '#0195F6')
    );

    setDataComboChartTeenagers([
      ['Month', 'Adolescentes', { role: 'style' }, { role: 'annotation' }],
      [
        timeCourseResult[0],
        teenagersResult[0],
        colorTeenager[0],
        teenagersResult[0],
      ],
      [
        timeCourseResult[1],
        teenagersResult[1],
        colorTeenager[1],
        teenagersResult[1],
      ],
      [
        timeCourseResult[2],
        teenagersResult[2],
        colorTeenager[2],
        teenagersResult[2],
      ],
      [
        timeCourseResult[3],
        teenagersResult[3],
        colorTeenager[3],
        teenagersResult[3],
      ],
      [
        timeCourseResult[4],
        teenagersResult[4],
        colorTeenager[4],
        teenagersResult[4],
      ],
      [
        timeCourseResult[5],
        teenagersResult[5],
        colorTeenager[5],
        teenagersResult[5],
      ],
      [
        timeCourseResult[6],
        teenagersResult[6],
        colorTeenager[6],
        teenagersResult[6],
      ],
      [
        timeCourseResult[7],
        teenagersResult[7],
        colorTeenager[7],
        teenagersResult[7],
      ],
      [
        timeCourseResult[8],
        teenagersResult[8],
        colorTeenager[8],
        teenagersResult[8],
      ],
      [
        timeCourseResult[9],
        teenagersResult[9],
        colorTeenager[9],
        teenagersResult[9],
      ],
      [
        timeCourseResult[10],
        teenagersResult[10],
        colorTeenager[10],
        teenagersResult[10],
      ],
      [
        timeCourseResult[11],
        teenagersResult[11],
        colorTeenager[11],
        teenagersResult[11],
      ],
    ]);

    const countVisitorsMax = Math.max.apply(
      null,
      visitorsResult.map(visitor => visitor)
    );

    const countVisitorsMin = Math.min.apply(
      null,
      visitorsResult.map(visitor => visitor)
    );

    const colorVisitor = visitorsResult.map(
      visitor =>
        (visitor <= countVisitorsMin && '#EB5545') ||
        (visitor < countVisitorsMax - Math.round(countVisitorsMax * 0.1) &&
          '#F4F482') ||
        (visitor >= countVisitorsMax - Math.round(countVisitorsMax * 0.1) &&
          '#0195F6')
    );

    setDataComboChartVisitors([
      ['Month', 'Visitantes', { role: 'style' }, { role: 'annotation' }],
      [
        timeCourseResult[0],
        visitorsResult[0],
        colorVisitor[0],
        visitorsResult[0],
      ],
      [
        timeCourseResult[1],
        visitorsResult[1],
        colorVisitor[1],
        visitorsResult[1],
      ],
      [
        timeCourseResult[2],
        visitorsResult[2],
        colorVisitor[2],
        visitorsResult[2],
      ],
      [
        timeCourseResult[3],
        visitorsResult[3],
        colorVisitor[3],
        visitorsResult[3],
      ],
      [
        timeCourseResult[4],
        visitorsResult[4],
        colorVisitor[4],
        visitorsResult[4],
      ],
      [
        timeCourseResult[5],
        visitorsResult[5],
        colorVisitor[5],
        visitorsResult[5],
      ],
      [
        timeCourseResult[6],
        visitorsResult[6],
        colorVisitor[6],
        visitorsResult[6],
      ],
      [
        timeCourseResult[7],
        visitorsResult[7],
        colorVisitor[7],
        visitorsResult[7],
      ],
      [
        timeCourseResult[8],
        visitorsResult[8],
        colorVisitor[8],
        visitorsResult[8],
      ],
      [
        timeCourseResult[9],
        visitorsResult[9],
        colorVisitor[9],
        visitorsResult[9],
      ],
      [
        timeCourseResult[10],
        visitorsResult[10],
        colorVisitor[10],
        visitorsResult[10],
      ],
      [
        timeCourseResult[11],
        visitorsResult[11],
        colorVisitor[11],
        visitorsResult[11],
      ],
    ]);

    const countNew_convertsMax = Math.max.apply(
      null,
      new_convertsResult.map(new_convert => new_convert)
    );

    const countNew_convertsMin = Math.min.apply(
      null,
      new_convertsResult.map(new_convert => new_convert)
    );

    const colorNew_convert = new_convertsResult.map(
      new_convert =>
        (new_convert <= countNew_convertsMin && '#EB5545') ||
        (new_convert <
          countNew_convertsMax - Math.round(countNew_convertsMax * 0.1) &&
          '#F4F482') ||
        (new_convert >=
          countNew_convertsMax - Math.round(countNew_convertsMax * 0.1) &&
          '#0195F6')
    );

    setDataComboChartNew_converts([
      ['Month', 'Novos convertidos', { role: 'style' }, { role: 'annotation' }],
      [
        timeCourseResult[0],
        new_convertsResult[0],
        colorNew_convert[0],
        new_convertsResult[0],
      ],
      [
        timeCourseResult[1],
        new_convertsResult[1],
        colorNew_convert[1],
        new_convertsResult[1],
      ],
      [
        timeCourseResult[2],
        new_convertsResult[2],
        colorNew_convert[2],
        new_convertsResult[2],
      ],
      [
        timeCourseResult[3],
        new_convertsResult[3],
        colorNew_convert[3],
        new_convertsResult[3],
      ],
      [
        timeCourseResult[4],
        new_convertsResult[4],
        colorNew_convert[4],
        new_convertsResult[4],
      ],
      [
        timeCourseResult[5],
        new_convertsResult[5],
        colorNew_convert[5],
        new_convertsResult[5],
      ],
      [
        timeCourseResult[6],
        new_convertsResult[6],
        colorNew_convert[6],
        new_convertsResult[6],
      ],
      [
        timeCourseResult[7],
        new_convertsResult[7],
        colorNew_convert[7],
        new_convertsResult[7],
      ],
      [
        timeCourseResult[8],
        new_convertsResult[8],
        colorNew_convert[8],
        new_convertsResult[8],
      ],
      [
        timeCourseResult[9],
        new_convertsResult[9],
        colorNew_convert[9],
        new_convertsResult[9],
      ],
      [
        timeCourseResult[10],
        new_convertsResult[10],
        colorNew_convert[10],
        new_convertsResult[10],
      ],
      [
        timeCourseResult[11],
        new_convertsResult[11],
        colorNew_convert[11],
        new_convertsResult[11],
      ],
    ]);
  }, [
    adultsResult,
    adultsTotal,
    data,
    new_convertsResult,
    new_convertsTotal,
    teenagersResult,
    teenagersTotal,
    timeCourseResult,
    visitorsResult,
    visitorsTotal,
  ]);

  const groupWidth =
    data.length === 1
      ? '10%'
      : data.length === 2
      ? '20%'
      : data.length === 3
      ? '30%'
      : data.length === 4
      ? '40$'
      : data.length === 5
      ? '50%'
      : data.length === 6
      ? '60%'
      : data.length === 7
      ? '70%'
      : '75%';

  return (
    <Container>
      <Aside>
        <Menu oneYear="oneYear" />
      </Aside>

      <Main>
        <Title title="Últimos 12 meses" />

        <OneYear>
          {loadingChart ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <>
              <Content>
                <Chart
                  chartType="PieChart"
                  data={dataPieChart}
                  options={{
                    title: tenant,
                    titleTextStyle: {
                      color: '#FFFFFF',
                      fontSize: 16,
                      bold: true,
                      italic: false,
                    },
                    height: '232px',
                    curveType: 'function',
                    backgroundColor: '#202024',
                    chartArea: { right: 0, left: 0, bottom: 27 },
                    legend: {
                      textStyle: { color: '#FFFFFF', fontSize: 12 },
                    },
                    colors: ['#0195F6', '#F4F482', '#7159C1', '#06BC0A'],
                    is3D: true,
                    pieSliceTextStyle: {
                      color: '#121214',
                    },
                    animation: {
                      duration: 1000,
                      easing: 'in',
                      startup: true,
                    },
                  }}
                />

                <div className="legent">
                  <div>
                    <span style={{ color: 'var(--color-blue)' }}>
                      Adultos:{' '}
                    </span>

                    <p>{adultsTotal}</p>
                  </div>

                  <hr />

                  <div>
                    <span style={{ color: 'var(--color-yellow)' }}>
                      Adolescentes:{' '}
                    </span>

                    <p>{teenagersTotal}</p>
                  </div>

                  <hr />

                  <div>
                    <span style={{ color: 'var(--color-purple)' }}>
                      Visitantes:{' '}
                    </span>

                    <p>{visitorsTotal}</p>
                  </div>

                  <hr />

                  <div>
                    <span style={{ color: 'var(--color-green)' }}>
                      Novos convertidos:{' '}
                    </span>

                    <p>{new_convertsTotal}</p>
                  </div>
                </div>
              </Content>

              <div className="chart">
                {dataComboChartAdults.length !== 0 && (
                  <Chart
                    chartType="ComboChart"
                    data={dataComboChartAdults}
                    options={{
                      title: tenant,
                      titleTextStyle: {
                        color: '#FFFFFF',
                        fontSize: 16,
                        bold: true,
                        italic: false,
                      },
                      height: '350px',
                      curveType: 'function',
                      backgroundColor: '#202024',
                      chartArea: { right: 50, left: 50 },
                      legend: {
                        position: 'bottom',
                        textStyle: { color: '#FFFFFF', fontSize: 12 },
                      },
                      colors: ['#0195F6'],
                      reverseCategories: true,
                      fontSize: 12,
                      fontName: 'Roboto',
                      hAxis: {
                        viewWindow: {
                          // max: 12,
                        },
                        textStyle: {
                          fontSize: 10,
                          color: '#FFFFFF',
                          opacity: 0.5,
                        },
                      },
                      vAxis: {
                        viewWindow: {
                          min: 0,
                        },
                        baselineColor: '#FFFFFF',
                      },
                      seriesType: 'bars',
                      bar: { groupWidth },
                      animation: {
                        duration: 1000,
                        easing: 'in',
                        startup: true,
                      },
                    }}
                  />
                )}

                {dataComboChartTeenagers.length !== 0 && (
                  <Chart
                    chartType="ComboChart"
                    data={dataComboChartTeenagers}
                    style={{ paddingTop: 0.5 }}
                    options={{
                      title: tenant,
                      titleTextStyle: {
                        color: '#FFFFFF',
                        fontSize: 16,
                        bold: true,
                        italic: false,
                      },
                      height: '350px',
                      curveType: 'function',
                      backgroundColor: '#202024',
                      chartArea: { right: 50, left: 50 },
                      legend: {
                        position: 'bottom',
                        textStyle: { color: '#FFFFFF', fontSize: 12 },
                      },
                      colors: ['#0195F6'],
                      reverseCategories: true,
                      fontSize: 12,
                      fontName: 'Roboto',
                      hAxis: {
                        viewWindow: {
                          // max: 12,
                        },
                        textStyle: {
                          fontSize: 10,
                          color: '#FFFFFF',
                          opacity: 0.5,
                        },
                      },
                      vAxis: {
                        viewWindow: {
                          min: 0,
                        },
                        baselineColor: '#FFFFFF',
                      },
                      seriesType: 'bars',
                      bar: { groupWidth },
                      animation: {
                        duration: 1000,
                        easing: 'in',
                        startup: true,
                      },
                    }}
                  />
                )}

                {dataComboChartVisitors.length !== 0 && (
                  <Chart
                    chartType="ComboChart"
                    data={dataComboChartVisitors}
                    style={{ paddingTop: 1 }}
                    options={{
                      title: tenant,
                      titleTextStyle: {
                        color: '#FFFFFF',
                        fontSize: 16,
                        bold: true,
                        italic: false,
                      },
                      height: '350px',
                      curveType: 'function',
                      backgroundColor: '#202024',
                      chartArea: { right: 50, left: 50 },
                      legend: {
                        position: 'bottom',
                        textStyle: { color: '#FFFFFF', fontSize: 12 },
                      },
                      colors: ['#0195F6'],
                      reverseCategories: true,
                      fontSize: 12,
                      fontName: 'Roboto',
                      hAxis: {
                        viewWindow: {
                          // max: 12,
                        },
                        textStyle: {
                          fontSize: 10,
                          color: '#FFFFFF',
                          opacity: 0.5,
                        },
                      },
                      vAxis: {
                        viewWindow: {
                          min: 0,
                        },
                        baselineColor: '#FFFFFF',
                      },
                      seriesType: 'bars',
                      bar: { groupWidth },
                      animation: {
                        duration: 1000,
                        easing: 'in',
                        startup: true,
                      },
                    }}
                  />
                )}

                {dataComboChartNew_converts.length !== 0 && (
                  <Chart
                    chartType="ComboChart"
                    data={dataComboChartNew_converts}
                    style={{ paddingTop: 1 }}
                    options={{
                      title: tenant,
                      titleTextStyle: {
                        color: '#FFFFFF',
                        fontSize: 16,
                        bold: true,
                        italic: false,
                      },
                      height: '350px',
                      curveType: 'function',
                      backgroundColor: '#202024',
                      chartArea: { right: 50, left: 50 },
                      legend: {
                        position: 'bottom',
                        textStyle: { color: '#FFFFFF', fontSize: 12 },
                      },
                      colors: ['#0195F6'],
                      reverseCategories: true,
                      fontSize: 12,
                      fontName: 'Roboto',
                      hAxis: {
                        viewWindow: {
                          // max: 12,
                        },
                        textStyle: {
                          fontSize: 10,
                          color: '#FFFFFF',
                          opacity: 0.5,
                        },
                      },
                      vAxis: {
                        viewWindow: {
                          min: 0,
                        },
                        baselineColor: '#FFFFFF',
                      },
                      seriesType: 'bars',
                      bar: { groupWidth },
                      animation: {
                        duration: 1000,
                        easing: 'in',
                        startup: true,
                      },
                    }}
                  />
                )}
              </div>
            </>
          )}

          {logout && <UserLogout message={message} />}
        </OneYear>
      </Main>
    </Container>
  );
}
