import styled from 'styled-components';

export const Content = styled.div`
  padding: 2rem;
  border-radius: 0.5rem;
  background: var(--color-primary);

  div {
    display: flex;
    align-items: center;

    h1 {
      font-size: var(--fontSizes-4xl);
      color: var(--color-blue);
    }

    strong {
      color: var(--color-yellow);
      white-space: nowrap;
      margin-left: 1rem;
    }
  }

  div.letter {
    margin: 1rem 0;

    div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      pre {
        font-size: var(--fontSizes-4xl);
        font-family: 'Roboto', sans-serif;
        line-height: 3rem;
        opacity: var(--opacity-semiOpaque);

        white-space: pre-wrap;
        word-break: break-word;
      }

      strong,
      span,
      p,
      a,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      pre,
      code,
      small {
        -webkit-touch-callout: inherit;
        -webkit-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
      }
    }
  }

  div.div-player {
    div {
      width: 100% !important;
      height: 18rem !important;

      border-radius: 0.5rem;

      div.react-player__play-icon {
        width: 1.4rem !important;
        height: 1.4rem !important;
      }
    }
  }

  @media (min-width: 720px) {
    div.letter {
      pre {
        margin: 2rem 0;
      }
    }

    div.div-player {
      div {
        height: 37rem !important;
      }
    }
  }
`;
