import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { updateProfileSuccess } from './actions';

export function* updateProfile({ payload }) {
  try {
    const {
      name,
      nameuser,
      email,
      document,
      phone,
      birthday,
      photo,
    } = payload.data;

    const profile = {
      name,
      nameuser,
      email,
      document,
      phone,
      birthday,
      photo,
    };

    const response = yield call(api.put, '/church/user/profile', profile);

    ToastSuccess({ title: 'Seu perfil foi alterado' });

    yield put(updateProfileSuccess(response.data));
  } catch (error) {
    if (error.response.data.error === 'Validation fails') {
      ToastError({
        title: 'Seus dados foram recusados. Verifique-os e tente novamente',
      });
    }

    if (error.response.data.error === 'User name already exists') {
      ToastError({
        title: 'Nome de usuário já existe',
      });
    }

    if (error.response.data.error === 'Email already exists') {
      ToastError({
        title: 'E-mail já existe',
      });
    }

    if (error.response.data.error === 'Document already exists') {
      ToastError({
        title: 'CPF já existe',
      });
    }

    if (error.response.data.error === 'CPF is invalid') {
      ToastError({
        title: 'CPF é inválido',
      });
    }
  }
}
export default all([takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile)]);
