import React from 'react';
import { MdModeEdit } from 'react-icons/md';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';

import { formatDistance, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import Markdown from '~/components/Layout/Markdown';

import { Container } from './styles';

export default function TrainingLesson({ trainingLesson, onUpdate }) {
  const {
    churchPermissions: { training_update },
  } = useSelector(state => state.user.profile);

  const formattedDate = formatDistance(
    parseISO(trainingLesson.updated_at),
    new Date(),
    {
      addSuffix: true,
      locale: pt,
    }
  );

  return (
    <Container>
      <div className="title">
        <strong>{trainingLesson.title}</strong>

        {training_update && (
          <button type="button" title="Alterar aula" onClick={onUpdate}>
            <MdModeEdit size={20} />
          </button>
        )}
      </div>

      {trainingLesson.link && (
        <div className="div-player">
          <ReactPlayer url={trainingLesson.link} playing controls light pip />
        </div>
      )}

      <div>
        <pre>
          <Markdown data={trainingLesson.description} />

          <base target="_blank" />
        </pre>
      </div>

      <div>
        <span>{formattedDate}</span>
      </div>
    </Container>
  );
}
