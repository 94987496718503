import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import DashboardPublicLastSixMonths from '~/pages/Dashboard/DashboardPublic/LastSixMonths';
import DashboardPublicMonth from '~/pages/Dashboard/DashboardPublic/Month';
import DashboardPublicOneYear from '~/pages/Dashboard/DashboardPublic/OneYear';
import Pro from '~/pages/Dashboard/Pro';

import Route from './Route';

export default function Auth() {
  const version = useSelector(state => state.user.profile);

  const pro = version === null ? false : version.pro;

  return (
    <Switch>
      <Route
        path="/dashboard-public/month"
        component={DashboardPublicMonth}
        isPrivate
      />

      <Route
        path="/dashboard-public/last-six-months"
        component={pro ? DashboardPublicLastSixMonths : Pro}
        isPrivate
      />

      <Route
        path="/dashboard-public/one-year"
        component={pro ? DashboardPublicOneYear : Pro}
        isPrivate
      />
    </Switch>
  );
}
