import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { CircularProgress } from '@material-ui/core';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';

import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import Dialog from '~/components/Layout/Dialog';
import InputText from '~/components/Layout/Inputs/InputText';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content } from './styles';

const schema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(4, 'Senha atual deve ter no mínimo 4 caracteres')
    .max(20, 'Senha atual deve ter no máximo 20 caracteres'),
  password: Yup.string()
    .min(4, 'Nova senha deve ter no mínimo 4 caracteres')
    .max(20, 'Nova senha deve ter no máximo 20 caracteres'),
  confirmPassword: Yup.string()
    .min(4, 'Confirme sua senha deve ter no mínimo 4 caracteres')
    .max(20, 'Confirme sua senha deve ter no máximo 20 caracteres')
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),
});

export default function Password({ open, handleClose }) {
  const profile = useSelector(state => state.user.profile);

  const [loading, setLoading] = useState(false);

  const [errorInput, setErrorInput] = useState(false);

  async function handleSubmit({ oldPassword, password, confirmPassword }) {
    setLoading(true);

    try {
      const data = await api
        .put('/church/user/password/', {
          oldPassword,
          password,
          confirmPassword,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Password does not match') {
              ToastError({
                title: 'Senha atual incorreta',
              });

              setLoading(false);

              setErrorInput(true);

              setTimeout(() => setErrorInput(false), 5000);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) return;

      handleClose();

      setLoading(false);

      ToastSuccess({ title: 'Sua senha foi alterada' });
    } catch (error) {
      ToastError({ title: 'Erro ao adicionar, verifique os dados' });

      setLoading(false);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      width={40}
      title="Senha secreta"
      handleClose={handleClose}
    >
      <Content>
        <Form schema={schema} initialData={profile} onSubmit={handleSubmit}>
          <div className="group">
            <div>
              <InputText
                label="Senha atual"
                name="oldPassword"
                type="password"
                placeholder="****"
                autoComplete="cc-csc"
              />

              {errorInput && <p>Senha atual incorreta</p>}
            </div>

            <div>
              <InputText
                label="Nova senha"
                name="password"
                type="password"
                placeholder="****"
                autoComplete="cc-csc"
              />
            </div>

            <div>
              <InputText
                label="Confirme sua senha"
                name="confirmPassword"
                type="password"
                placeholder="****"
                autoComplete="cc-csc"
              />
            </div>
          </div>

          <hr />

          <div className="buttons">
            <div>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress
                    size={24}
                    style={{ color: 'var(--color-white)' }}
                  />
                ) : (
                  'Salvar'
                )}
              </SubmitButton>
            </div>

            <div className="cancel">
              <CancelButton type="button" onClick={handleClose}>
                Cancelar
              </CancelButton>
            </div>
          </div>
        </Form>
      </Content>
    </Dialog>
  );
}
