import React from 'react';
import { useSelector } from 'react-redux';

import versionFunctions from '~/utils/versionFunctions';

import { Content, Table } from './styles';

export default function Pro() {
  const { pro } = useSelector(state => state.user.profile);

  return (
    <Content pro={pro}>
      <strong>Informações do plano</strong>

      <div>
        {pro ? (
          <p>
            Atualmente você possui o plano <strong>Pro</strong> do{' '}
            <span> Church Praise</span>. Confira abaixo algumas diferenças entre
            os planos.
          </p>
        ) : (
          <>
            <p>
              Atualmente você possui o plano <strong>Basic</strong> do{' '}
              <span> Church Praise</span>. Evolua seu plano e tenha mais
              vantagens.
            </p>

            <p>
              O plano <strong className="pro">Pro</strong> possuem
              funcionalidades exclusivas, tornando o controle da sua igreja
              ainda mais completo. Confira abaixo algumas diferenças entre os
              planos.
            </p>
          </>
        )}
      </div>

      <hr />

      <Table>
        <thead>
          <tr>
            <th> </th>
            <th>Basic</th>
            <th className="pro">Pro</th>
          </tr>
        </thead>

        <tbody>
          {versionFunctions.map(item => (
            <tr key={item.id}>
              <td>
                <span>{item.description}</span>
              </td>

              <td>{item.basic}</td>
              <td>{item.pro}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <span>
        Para mais informações, entre em contato conosco pelo{' '}
        <a
          href="https://www.instagram.com/church.praise/"
          target="_blank"
          rel="noreferrer"
        >
          Instagram
        </a>
      </span>
    </Content>
  );
}
