import styled from 'styled-components';

export const Modal = styled.div`
  padding: 1rem 1rem 3rem 1rem;
  border-radius: 0.5rem;
  border: none;
`;

export const DialogTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 2rem 1.5rem 3rem 2rem;

  strong {
    font-size: var(--fontSizes-5xl);
    color: var(--color-white);
  }

  div.divClose {
    button {
      display: flex;
      align-items: center;

      padding: 0.5rem;
      border-radius: 0.5rem;
      background: transparent;
      border: none;

      color: var(--color-white);
      opacity: var(--opacity-intense);

      transition: all 0.2s ease 0s;

      &:hover {
        background: var(--color-secondary);
        opacity: var(--opacity-default);
      }
    }
  }
`;

export const DialogBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;

  margin: 0rem 2rem;

  label {
    color: var(--color-white);
  }
`;
