import styled from 'styled-components';

export const Container = styled.div`
  div {
    div.group {
      display: flex;

      p {
        font-weight: bold;
        color: ${props =>
          props.today ? 'var(--color-green)' : 'var(--color-blue)'};
      }

      a {
        margin: 0 1rem;
        font-size: var(--fontSizes-2xl);
        color: var(--color-white);
        &:hover {
          opacity: var(--opacity-medium);
        }
      }

      strong {
        margin-right: 1rem;
        color: var(--color-yellow);
      }
    }

    span {
      font-size: var(--fontSizes-lg);
      color: var(--color-white);
      opacity: var(--opacity-medium);
    }
  }
`;
