import React from 'react';
import { Switch } from 'react-router-dom';

import Forgot from '~/pages/Auth/Forgot';
import Helpdesk from '~/pages/Auth/Helpdesk';
import SignIn from '~/pages/Auth/SignIn';
import SignUp from '~/pages/Auth/SignUp';
import Dashboard from '~/pages/Dashboard';
import { store } from '~/store';

import Route from './Route';

export default function Auth() {
  const { signed } = store.getState().auth;

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />

      <Route path="/dashboard" component={Dashboard} isPrivate />

      <Route path="/help" component={Helpdesk} isPrivate={signed} />

      <Route path="/signup" component={SignUp} />
      <Route path="/forgot" component={Forgot} />

      {/* <Route
        path=""
        component={() => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <strong>Erro: 404</strong>
            <span>Ooooops, essa página não existe.</span>

            <a href="/">Voltar</a>
          </div>
        )}
      /> */}
    </Switch>
  );
}
