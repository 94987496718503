import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Aside, Container, Main, Nav, Section } from '~/components/Layout/Body';
import SubMenuButton from '~/components/Layout/Buttons/SubMenuButton';
import Title from '~/components/Layout/Title';
import TrainingLesson from '~/components/Pages/Course/TrainingLesson';
import TrainingLessonAllList from '~/components/Pages/Course/TrainingLessonAllList';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { selectedTrainingLessonRequest } from '~/store/modules/course/trainingLesson/actions';
import { ToastError } from '~/utils/toast';

import TrainingUser from '../../Training/TrainingUser';
import TrainingLessonNew from '../TrainingLessonNew';
import TrainingLessonUpdate from '../TrainingLessonUpdate';
import { Menu } from './styles';

export default function TrainingLessonList() {
  const {
    churchPermissions: { training_update },
  } = useSelector(state => state.user.profile);

  const id = useSelector(state => state.trainingLesson.trainingLessonId);
  const idSelected = useSelector(
    state => state.trainingLesson.trainingLessonSelectedId
  );

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [openNew, setOpenNew] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openUser, setOpenUser] = useState(false);

  const [trainingLessons, setTrainingLessons] = useState([]);
  const [trainingLessonOpen, setTrainingLessonOpen] = useState([]);

  const [trainingLessonUpdate, setTrainingLessonUpdate] = useState([]);
  const [trainingUser, setTrainingUser] = useState([]);

  const [countLessonCurrent, setCountLessonCurrent] = useState(0);
  const [countLesson, setCountLesson] = useState(0);
  const [countLessonUpdate, setCountLessonUpdate] = useState('');

  useEffect(() => {
    const lessonCompleteds = trainingLessons.filter(
      lessonCompleted => lessonCompleted.lesson[0] !== undefined
    );

    const countLessons = lessonCompleteds.filter(lesson => lesson.id).length;

    if (countLessonUpdate !== 'countUpdate') {
      setCountLessonCurrent(
        Math.floor((countLessons * 100) / trainingLessons.length)
      );

      setCountLesson(countLessons);
    }
  }, [countLessonUpdate, trainingLessons]);

  function handleLessonCount(lesson) {
    if (lesson === 'removeLesson') {
      setCountLessonCurrent(
        Math.floor(((countLesson - 1) * 100) / trainingLessons.length)
      );

      setCountLesson(countLesson - 1);
    } else if (lesson === 'addLesson') {
      setCountLessonCurrent(
        Math.floor(((countLesson + 1) * 100) / trainingLessons.length)
      );

      setCountLesson(countLesson + 1);
    }
  }

  try {
    useEffect(() => {
      async function loadTrainingLessons() {
        const response = await api
          .get(`/church/course/trainings-lesson-list/${id}`)
          .catch(async res => {
            try {
              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Pro disabled') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setTrainingLessons(response.data);

        setLoading(false);
      }

      loadTrainingLessons();
    }, [id]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  useEffect(() => {
    if (idSelected === undefined) {
      setTrainingLessonOpen(
        trainingLessons[0] === undefined ? [] : [trainingLessons[0]]
      );
    } else if (idSelected !== undefined) {
      const lessonNew =
        trainingLessons[0] === undefined ? [] : [trainingLessons[0]];

      const lessonOld = trainingLessons.filter(
        trainingLesson => trainingLesson.id === idSelected
      )[0];

      setTrainingLessonOpen(lessonOld === undefined ? lessonNew : [lessonOld]);
    }
  }, [idSelected, trainingLessons]);

  function handleNew() {
    setOpenNew(true);
  }

  function handleDataNew(data) {
    const addTrainingLesson = [...trainingLessons, data];
    // eslint-disable-next-line func-names
    const trainingLessonSort = addTrainingLesson.sort(function(
      initialOrden,
      finalOrden
    ) {
      // eslint-disable-next-line no-nested-ternary
      return finalOrden.created_at > initialOrden.created_at
        ? 1
        : initialOrden.created_at > finalOrden.created_at
        ? -1
        : 0;
    });

    setTrainingLessons(trainingLessonSort);
    setTrainingLessonOpen([data]);

    setCountLessonUpdate('countUpdate');
    setCountLessonCurrent(
      Math.floor((countLesson * 100) / trainingLessonSort.length)
    );
    setCountLesson(countLesson);
  }

  async function handleUpdate(idLesson) {
    const { data } = await api.get(
      `/church/course/trainings-lesson-show/${idLesson}`
    );

    setTrainingLessonUpdate(data);

    setOpenUpdate(true);
  }

  function handleDataUpdate(data) {
    const removeTrainingLessonUpdate = trainingLessons.filter(
      lesson => lesson.id !== data.id
    );

    const addTrainingLessonUpdate = [...removeTrainingLessonUpdate, data];

    // eslint-disable-next-line func-names
    const trainingSort = addTrainingLessonUpdate.sort(function(
      initialOrden,
      finalOrden
    ) {
      // eslint-disable-next-line no-nested-ternary
      return finalOrden.created_at > initialOrden.created_at
        ? 1
        : initialOrden.created_at > finalOrden.created_at
        ? -1
        : 0;
    });

    setTrainingLessons(trainingSort);
    setTrainingLessonOpen([data]);

    setCountLessonUpdate('countUpdate');
    setCountLessonCurrent(countLessonCurrent);
    setCountLesson(countLesson);
  }

  function handleDataDelete(data) {
    const trainingLessonSort = trainingLessons.filter(
      lesson => lesson.id !== data.id
    );

    setTrainingLessons(trainingLessonSort);

    setTrainingLessonOpen(
      trainingLessons.filter(lesson => lesson.id !== data.id)
    );

    setCountLessonUpdate('countUpdate');

    if (data.lesson[0] !== undefined) {
      setCountLessonCurrent(
        Math.floor(((countLesson - 1) * 100) / trainingLessonSort.length)
      );

      setCountLesson(countLesson - 1);
    } else {
      setCountLessonCurrent(
        Math.floor((countLesson * 100) / trainingLessonSort.length)
      );
      setCountLesson(countLesson);
    }
  }

  function handleRead(idLesson) {
    dispatch(selectedTrainingLessonRequest(idLesson));

    setTrainingLessonOpen([
      trainingLessons.filter(
        trainingLesson => trainingLesson.id === idLesson
      )[0],
    ]);
  }

  async function handleUser() {
    const { data } = await api.get(`/church/course/trainings-user-show/${id}`);

    setTrainingUser(data);

    setOpenUser(true);
  }

  function handleUserDelete(data) {
    setTrainingUser(data);

    setOpenUser(true);
  }

  function handleClose() {
    setOpenNew(false);
    setOpenUpdate(false);
    setOpenUser(false);
  }

  return (
    <Container>
      <Aside>
        <Menu>
          <div className="title">
            <strong>Aulas</strong>
          </div>

          <div className="progress">
            <span title="Aula(s) concluída(s)">{countLesson}</span>

            <div>
              <div style={{ width: `${countLessonCurrent || 0}%` }} />

              <span
                style={{ left: `${countLessonCurrent || 0}%` }}
              >{`${countLessonCurrent || 0}%`}</span>
            </div>

            <span title="Aulas totais">{`${trainingLessons.length}`}</span>
          </div>

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {trainingLessons.map(trainingLesson => (
                <TrainingLessonAllList
                  key={trainingLesson.id}
                  trainingLesson={trainingLesson}
                  onRead={() => handleRead(trainingLesson.id)}
                  focus={
                    trainingLesson.id ===
                    trainingLessonOpen.map(lesson => lesson.id)[0]
                  }
                  handleLessonCount={handleLessonCount}
                />
              ))}
              {trainingLessons.length === 0 && (
                <p className="empty">Nenhuma aula encontrada</p>
              )}
            </ul>
          )}

          <div className="button-link">
            <button type="button" onClick={handleUser}>
              Alunos
            </button>
          </div>

          <div className="button-link">
            <Link
              title="Voltar aos treinamentos"
              to="/course/training/training-list"
            >
              Treinamentos
            </Link>
          </div>
        </Menu>
      </Aside>

      <Main>
        <Title title={trainingLessonOpen.map(title => title.title)} />

        <Section>
          <Nav>
            {training_update && (
              <div>
                <SubMenuButton type="button" onClick={handleNew}>
                  Adicionar
                </SubMenuButton>
              </div>
            )}
          </Nav>

          {openNew && (
            <TrainingLessonNew
              open={openNew}
              handleClose={handleClose}
              handleDataNew={handleDataNew}
            />
          )}

          {openUpdate && (
            <TrainingLessonUpdate
              open={openUpdate}
              handleClose={handleClose}
              handleDataUpdate={handleDataUpdate}
              trainingLessonUpdate={trainingLessonUpdate}
              handleDataDelete={handleDataDelete}
            />
          )}

          {openUser && (
            <TrainingUser
              open={openUser}
              handleClose={handleClose}
              trainingUser={trainingUser}
              handleUserDelete={handleUserDelete}
            />
          )}

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {trainingLessonOpen.map(lesson => (
                <TrainingLesson
                  key={lesson.id}
                  trainingLesson={lesson}
                  onUpdate={() => handleUpdate(lesson.id)}
                />
              ))}
              {trainingLessonOpen.length === 0 && (
                <p className="empty">Nenhuma aula selecionada</p>
              )}
            </ul>
          )}
        </Section>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
