import React from 'react';
import { MdAssignment, MdGroup } from 'react-icons/md';
import { useSelector } from 'react-redux';

import MenuButton from '~/components/Layout/Buttons/MenuButton';

import { Content } from './styles';

export default function Menu({ scale, user }) {
  const {
    churchPermissions: { scale_media_list, user_list },
  } = useSelector(state => state.user.profile);

  return (
    <Content>
      <div>
        {scale_media_list && (
          <MenuButton to="/media/scale/scales-list" focus={scale}>
            <MdAssignment size={18} /> Escalas
          </MenuButton>
        )}

        {user_list && (
          <MenuButton to="/admin/media/users-active" focus={user}>
            <MdGroup size={18} /> Membros
          </MenuButton>
        )}
      </div>
    </Content>
  );
}
