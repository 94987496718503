import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';

import {
  Aside,
  Container,
  Main,
  Nav,
  NavTwo,
  Section,
} from '~/components/Layout/Body';
import SubMenuButton from '~/components/Layout/Buttons/SubMenuButton';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import Song from '~/components/Pages/Praise/Song';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Praise/Menu';
import api from '~/services/api';
import { readSongRequest } from '~/store/modules/praise/song/actions';
import { ToastError } from '~/utils/toast';

import SongNew from '../SongNew';
import SongUpdate from '../SongUpdate';

export default function SongActive() {
  const {
    churchPermissions: { song_list, song_update, song_new },
  } = useSelector(state => state.user.profile);

  const dispatch = useDispatch();

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [openNew, setOpenNew] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const [songs, setSongs] = useState([]);
  const [songUpdate, setSongUpdate] = useState([]);

  const [search, setSearch] = useState('');

  const filteredSongs = search
    ? songs.filter(
        song =>
          song.name.toLowerCase().includes(search.toLowerCase()) ||
          song.author.toLowerCase().includes(search.toLowerCase()) ||
          song.letter.toLowerCase().includes(search.toLowerCase())
      )
    : songs;

  try {
    useEffect(() => {
      async function loadSongs() {
        const response = await api
          .get('/church/praise/songs-active')
          .catch(async res => {
            try {
              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Praise department disabled') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Pro disabled') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setSongs(response.data);
        setLoading(false);
      }

      loadSongs();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleRead(id) {
    dispatch(readSongRequest(id));
  }

  function handleNew() {
    setOpenNew(true);
  }

  function handleDataNew(data) {
    if (!data.active) {
      setSongs(songs);
    } else {
      setSongs([data, ...songs]);
    }
  }

  async function handleUpdate(id) {
    const { data } = await api.get(`/church/praise/songs-show/${id}`);

    setSongUpdate(data);

    setOpenUpdate(true);
  }

  function handleDataUpdate(data) {
    const removeSongUpdate = songs.filter(song => song.id !== data.id);

    if (!data.active) {
      setSongs(removeSongUpdate);
    } else {
      setSongs([data, ...removeSongUpdate]);
    }
  }

  function handleDataDuplicate(data) {
    if (!data.active) {
      setSongs(songs);
    } else {
      setSongs([data, ...songs]);
    }
  }

  function handleDataDelete(data) {
    setSongs(songs.filter(song => song.id !== data.id));
  }

  function handleClose() {
    setOpenNew(false);
    setOpenUpdate(false);
  }

  return (
    <Container>
      <Aside>
        <Menu song="song" />
      </Aside>

      <Main>
        <Title title="Músicas ativas" count={`${filteredSongs.length}`} />

        <Section>
          <Nav>
            <div>
              {song_list && (
                <SubMenuButton
                  focus
                  type="button"
                  onClick={() => history.push('/song/song-active')}
                >
                  Ativas
                </SubMenuButton>
              )}

              {song_update && (
                <SubMenuButton
                  type="button"
                  onClick={() => history.push('/song/song-disabled')}
                >
                  Desativadas
                </SubMenuButton>
              )}

              {song_new && (
                <SubMenuButton type="button" onClick={handleNew}>
                  Adicionar
                </SubMenuButton>
              )}
            </div>
          </Nav>

          <NavTwo>
            <Form>
              <div style={{ width: '100%' }}>
                <InputText
                  name="searchSong"
                  placeholder="Encontre por Nome, Autor ou Letra"
                  value={search}
                  onChange={event => setSearch(event.target.value)}
                />
              </div>
            </Form>
          </NavTwo>

          {openNew && (
            <SongNew
              open={openNew}
              handleClose={handleClose}
              handleDataNew={handleDataNew}
            />
          )}

          {openUpdate && (
            <SongUpdate
              open={openUpdate}
              handleClose={handleClose}
              handleDataUpdate={handleDataUpdate}
              songUpdate={songUpdate}
              handleDataDelete={handleDataDelete}
              handleDataDuplicate={handleDataDuplicate}
            />
          )}

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {filteredSongs.map(song => (
                <Song
                  key={song.id}
                  song={song}
                  onRead={() => handleRead(song.id)}
                  onUpdate={() => handleUpdate(song.id)}
                />
              ))}
              {filteredSongs.length === 0 && (
                <p className="empty">Nenhuma música encontrada</p>
              )}
            </ul>
          )}
        </Section>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
