import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

export default function BlockFive({
  today,
  scale,
  blockFiveReadOne,
  blockFiveReadTwo,
  blockFiveReadThree,
  blockFiveReadFour,
  blockFiveReadFive,
  blockFiveReadSix,
  blockFiveReadSeven,
  blockFiveReadEight,
}) {
  return (
    <Container today={today}>
      {scale.blockFive.firstSong && (
        <div>
          <div className="group">
            <p>1.</p>

            <Link to="/song/song-read" onClick={blockFiveReadOne}>
              {scale.blockFive.firstSong ? scale.blockFive.firstSong.name : ''}
            </Link>

            <strong>
              {scale.blockFive.firstSong ? scale.blockFive.firstSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockFive.firstSong ? scale.blockFive.firstSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFive.secondSong && (
        <div>
          <div className="group">
            <p>2.</p>

            <Link to="/song/song-read" onClick={blockFiveReadTwo}>
              {scale.blockFive.secondSong
                ? scale.blockFive.secondSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockFive.secondSong
                ? scale.blockFive.secondSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockFive.secondSong
              ? scale.blockFive.secondSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFive.thirdSong && (
        <div>
          <div className="group">
            <p>3.</p>

            <Link to="/song/song-read" onClick={blockFiveReadThree}>
              {scale.blockFive.thirdSong ? scale.blockFive.thirdSong.name : ''}
            </Link>

            <strong>
              {scale.blockFive.thirdSong ? scale.blockFive.thirdSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockFive.thirdSong ? scale.blockFive.thirdSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFive.fourthtSong && (
        <div>
          <div className="group">
            <p>4.</p>

            <Link to="/song/song-read" onClick={blockFiveReadFour}>
              {scale.blockFive.fourthtSong
                ? scale.blockFive.fourthtSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockFive.fourthtSong
                ? scale.blockFive.fourthtSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockFive.fourthtSong
              ? scale.blockFive.fourthtSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFive.fifthSong && (
        <div>
          <div className="group">
            <p>5.</p>

            <Link to="/song/song-read" onClick={blockFiveReadFive}>
              {scale.blockFive.fifthSong ? scale.blockFive.fifthSong.name : ''}
            </Link>

            <strong>
              {scale.blockFive.fifthSong ? scale.blockFive.fifthSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockFive.fifthSong ? scale.blockFive.fifthSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFive.sixthSong && (
        <div>
          <div className="group">
            <p>6.</p>

            <Link to="/song/song-read" onClick={blockFiveReadSix}>
              {scale.blockFive.sixthSong ? scale.blockFive.sixthSong.name : ''}
            </Link>

            <strong>
              {scale.blockFive.sixthSong ? scale.blockFive.sixthSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockFive.sixthSong ? scale.blockFive.sixthSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFive.seventhSong && (
        <div>
          <div className="group">
            <p>7.</p>

            <Link to="/song/song-read" onClick={blockFiveReadSeven}>
              {scale.blockFive.seventhSong
                ? scale.blockFive.seventhSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockFive.seventhSong
                ? scale.blockFive.seventhSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockFive.seventhSong
              ? scale.blockFive.seventhSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockFive.eighthSong && (
        <div>
          <div className="group">
            <p>8.</p>

            <Link to="/song/song-read" onClick={blockFiveReadEight}>
              {scale.blockFive.eighthSong
                ? scale.blockFive.eighthSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockFive.eighthSong
                ? scale.blockFive.eighthSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockFive.eighthSong
              ? scale.blockFive.eighthSong.author
              : ''}
          </span>
        </div>
      )}
    </Container>
  );
}
