import React from 'react';
import { MdControlPointDuplicate, MdModeEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { endOfDay, format, isBefore, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import BlockFive from './BlockFive';
import BlockFour from './BlockFour';
import BlockOne from './BlockOne';
import BlockThree from './BlockThree';
import BlockTwo from './BlockTwo';
import {
  Backing,
  Block,
  Container,
  Header,
  Instrument,
  Minister,
  Song,
} from './styles';

export default function Scale({
  scale,

  blockOneReadOne,
  blockOneReadTwo,
  blockOneReadThree,
  blockOneReadFour,
  blockOneReadFive,
  blockOneReadSix,
  blockOneReadSeven,
  blockOneReadEight,

  blockTwoReadOne,
  blockTwoReadTwo,
  blockTwoReadThree,
  blockTwoReadFour,
  blockTwoReadFive,
  blockTwoReadSix,
  blockTwoReadSeven,
  blockTwoReadEight,

  blockThreeReadOne,
  blockThreeReadTwo,
  blockThreeReadThree,
  blockThreeReadFour,
  blockThreeReadFive,
  blockThreeReadSix,
  blockThreeReadSeven,
  blockThreeReadEight,

  blockFourReadOne,
  blockFourReadTwo,
  blockFourReadThree,
  blockFourReadFour,
  blockFourReadFive,
  blockFourReadSix,
  blockFourReadSeven,
  blockFourReadEight,

  blockFiveReadOne,
  blockFiveReadTwo,
  blockFiveReadThree,
  blockFiveReadFour,
  blockFiveReadFive,
  blockFiveReadSix,
  blockFiveReadSeven,
  blockFiveReadEight,

  songReadOne,
  songReadTwo,
  songReadThree,
  songReadFour,
  songReadFive,
  songReadSix,
  songReadSeven,
  songReadEight,

  onUpdate,

  onDuplicate,
}) {
  const {
    id,
    churchPermissions: { scale_praise_new, scale_praise_update },
  } = useSelector(state => state.user.profile);

  const ministerOne =
    scale.ministerOne !== null || '' ? id === scale.ministerOne.id : false;

  const ministerTwo =
    scale.ministerTwo !== null || '' ? id === scale.ministerTwo.id : false;

  const backingOne =
    scale.backingOne !== null || '' ? id === scale.backingOne.id : false;

  const backingTwo =
    scale.backingTwo !== null || '' ? id === scale.backingTwo.id : false;

  const backingThree =
    scale.backingThree !== null || '' ? id === scale.backingThree.id : false;

  const drumsId =
    scale.drumsId !== null || '' ? id === scale.drumsId.id : false;

  const violaId =
    scale.violaId !== null || '' ? id === scale.violaId.id : false;

  const contrabassId =
    scale.contrabassId !== null || '' ? id === scale.contrabassId.id : false;

  const guitarId =
    scale.guitarId !== null || '' ? id === scale.guitarId.id : false;

  const keyboardId =
    scale.keyboardId !== null || '' ? id === scale.keyboardId.id : false;

  const percussionId =
    scale.percussionId !== null || '' ? id === scale.percussionId.id : false;

  const tableOne =
    scale.tableOne !== null || '' ? id === scale.tableOne.id : false;

  const tableTwo =
    scale.tableTwo !== null || '' ? id === scale.tableTwo.id : false;

  const parsedDate = parseISO(scale.available);

  const past = isBefore(new Date(endOfDay(parsedDate)), new Date(new Date()));

  const dateFormatted = format(parsedDate, "dd 'de' MMMM", { locale: pt });

  return (
    <Container
      today={
        ministerOne ||
        ministerTwo ||
        backingOne ||
        backingTwo ||
        backingThree ||
        drumsId ||
        violaId ||
        contrabassId ||
        guitarId ||
        keyboardId ||
        percussionId ||
        tableOne ||
        tableTwo
      }
      past={!past}
    >
      <Header
        today={
          ministerOne ||
          ministerTwo ||
          backingOne ||
          backingTwo ||
          backingThree ||
          drumsId ||
          violaId ||
          contrabassId ||
          guitarId ||
          keyboardId ||
          percussionId ||
          tableOne ||
          tableTwo
        }
      >
        <div className="description">
          <strong>{scale.description}</strong>
          {scale_praise_new && (
            <Link
              title="Duplicar escala"
              to="/scale/scale-duplicate"
              onClick={onDuplicate}
            >
              <MdControlPointDuplicate size={20} />
            </Link>
          )}
        </div>

        <div className="hour-date">
          <span>{scale.hour}</span>

          <div>
            <strong>{dateFormatted}</strong>

            {!past && scale_praise_update && (
              <Link
                title="Alterar escala"
                to="/scale/scale-update"
                onClick={onUpdate}
              >
                <MdModeEdit size={20} />
              </Link>
            )}
          </div>
        </div>
      </Header>

      <hr />

      <Minister
        today={
          ministerOne ||
          ministerTwo ||
          backingOne ||
          backingTwo ||
          backingThree ||
          drumsId ||
          violaId ||
          contrabassId ||
          guitarId ||
          keyboardId ||
          percussionId ||
          tableOne ||
          tableTwo
        }
      >
        <strong>Voz principal</strong>
        <div>
          <span>
            <p>1.</p> {scale.ministerOne.name}
          </span>

          {scale.ministerTwo && (
            <span>
              <p>2.</p> {scale.ministerTwo ? scale.ministerTwo.name : ''}
            </span>
          )}
        </div>
      </Minister>

      <hr />

      <Backing
        today={
          ministerOne ||
          ministerTwo ||
          backingOne ||
          backingTwo ||
          backingThree ||
          drumsId ||
          violaId ||
          contrabassId ||
          guitarId ||
          keyboardId ||
          percussionId ||
          tableOne ||
          tableTwo
        }
      >
        <strong>Backing</strong>
        <div>
          {scale.backingOne && (
            <span>
              <p>1.</p> {scale.backingOne ? scale.backingOne.name : ''}
            </span>
          )}

          {scale.backingTwo && (
            <span>
              <p>2.</p> {scale.backingTwo ? scale.backingTwo.name : ''}
            </span>
          )}

          {scale.backingThree && (
            <span>
              <p>3.</p> {scale.backingThree ? scale.backingThree.name : ''}
            </span>
          )}
        </div>
      </Backing>

      <hr />

      <Instrument
        today={
          ministerOne ||
          ministerTwo ||
          backingOne ||
          backingTwo ||
          backingThree ||
          drumsId ||
          violaId ||
          contrabassId ||
          guitarId ||
          keyboardId ||
          percussionId ||
          tableOne ||
          tableTwo
        }
      >
        {scale.drumsId && (
          <div>
            <strong>Bateria:</strong>
            <span>{scale.drumsId ? scale.drumsId.name : ''}</span>

            <hr />
          </div>
        )}

        {scale.violaId && (
          <div>
            <strong>Violão:</strong>
            <span>{scale.violaId ? scale.violaId.name : ''}</span>

            <hr />
          </div>
        )}

        {scale.contrabassId && (
          <div>
            <strong>Contrabaixo:</strong>
            <span>{scale.contrabassId ? scale.contrabassId.name : ''}</span>

            <hr />
          </div>
        )}

        {scale.guitarId && (
          <div>
            <strong>Guitarra:</strong>
            <span>{scale.guitarId ? scale.guitarId.name : ''}</span>

            <hr />
          </div>
        )}

        {scale.keyboardId && (
          <div>
            <strong>Teclado:</strong>
            <span>{scale.keyboardId ? scale.keyboardId.name : ''}</span>

            <hr />
          </div>
        )}

        {scale.percussionId && (
          <div>
            <strong>Percussão:</strong>
            <span>{scale.percussionId ? scale.percussionId.name : ''}</span>

            <hr />
          </div>
        )}

        {scale.tableOne && (
          <div>
            <strong>Mesa:</strong>
            <span>{scale.tableOne ? scale.tableOne.name : ''}</span>

            <hr />
          </div>
        )}

        {scale.tableTwo && (
          <div>
            <strong>Mesa:</strong>
            <span>{scale.tableTwo ? scale.tableTwo.name : ''}</span>

            <hr />
          </div>
        )}
      </Instrument>

      <Block
        today={
          ministerOne ||
          ministerTwo ||
          backingOne ||
          backingTwo ||
          backingThree ||
          drumsId ||
          violaId ||
          contrabassId ||
          guitarId ||
          keyboardId ||
          percussionId ||
          tableOne ||
          tableTwo
        }
      >
        <div className="description">
          {scale.blockOne && (
            <strong className="description">
              {scale.blockOne.description}
            </strong>
          )}

          {scale.blockTwo && (
            <strong className="description">
              {scale.blockTwo.description}
            </strong>
          )}

          {scale.blockThree && (
            <strong className="description">
              {scale.blockThree.description}
            </strong>
          )}

          {scale.blockFour && (
            <strong className="description">
              {scale.blockFour.description}
            </strong>
          )}

          {scale.blockFive && (
            <strong className="description">
              {scale.blockFive.description}
            </strong>
          )}

          <strong className="description">
            {scale.block === '' ? 'Músicas' : ''}
            {scale.block === null ? 'Músicas' : ''}
          </strong>
        </div>

        <div>
          {scale.blockOne && (
            <BlockOne
              today={
                ministerOne ||
                ministerTwo ||
                backingOne ||
                backingTwo ||
                backingThree ||
                drumsId ||
                violaId ||
                contrabassId ||
                guitarId ||
                keyboardId ||
                percussionId ||
                tableOne ||
                tableTwo
              }
              scale={scale}
              blockOneReadOne={blockOneReadOne}
              blockOneReadTwo={blockOneReadTwo}
              blockOneReadThree={blockOneReadThree}
              blockOneReadFour={blockOneReadFour}
              blockOneReadFive={blockOneReadFive}
              blockOneReadSix={blockOneReadSix}
              blockOneReadSeven={blockOneReadSeven}
              blockOneReadEight={blockOneReadEight}
            />
          )}

          {scale.blockTwo && (
            <BlockTwo
              today={
                ministerOne ||
                ministerTwo ||
                backingOne ||
                backingTwo ||
                backingThree ||
                drumsId ||
                violaId ||
                contrabassId ||
                guitarId ||
                keyboardId ||
                percussionId ||
                tableOne ||
                tableTwo
              }
              scale={scale}
              blockTwoReadOne={blockTwoReadOne}
              blockTwoReadTwo={blockTwoReadTwo}
              blockTwoReadThree={blockTwoReadThree}
              blockTwoReadFour={blockTwoReadFour}
              blockTwoReadFive={blockTwoReadFive}
              blockTwoReadSix={blockTwoReadSix}
              blockTwoReadSeven={blockTwoReadSeven}
              blockTwoReadEight={blockTwoReadEight}
            />
          )}

          {scale.blockThree && (
            <BlockThree
              today={
                ministerOne ||
                ministerTwo ||
                backingOne ||
                backingTwo ||
                backingThree ||
                drumsId ||
                violaId ||
                contrabassId ||
                guitarId ||
                keyboardId ||
                percussionId ||
                tableOne ||
                tableTwo
              }
              scale={scale}
              blockThreeReadOne={blockThreeReadOne}
              blockThreeReadTwo={blockThreeReadTwo}
              blockThreeReadThree={blockThreeReadThree}
              blockThreeReadFour={blockThreeReadFour}
              blockThreeReadFive={blockThreeReadFive}
              blockThreeReadSix={blockThreeReadSix}
              blockThreeReadSeven={blockThreeReadSeven}
              blockThreeReadEight={blockThreeReadEight}
            />
          )}

          {scale.blockFour && (
            <BlockFour
              today={
                ministerOne ||
                ministerTwo ||
                backingOne ||
                backingTwo ||
                backingThree ||
                drumsId ||
                violaId ||
                contrabassId ||
                guitarId ||
                keyboardId ||
                percussionId ||
                tableOne ||
                tableTwo
              }
              scale={scale}
              blockFourReadOne={blockFourReadOne}
              blockFourReadTwo={blockFourReadTwo}
              blockFourReadThree={blockFourReadThree}
              blockFourReadFour={blockFourReadFour}
              blockFourReadFive={blockFourReadFive}
              blockFourReadSix={blockFourReadSix}
              blockFourReadSeven={blockFourReadSeven}
              blockFourReadEight={blockFourReadEight}
            />
          )}

          {scale.blockFive && (
            <BlockFive
              today={
                ministerOne ||
                ministerTwo ||
                backingOne ||
                backingTwo ||
                backingThree ||
                drumsId ||
                violaId ||
                contrabassId ||
                guitarId ||
                keyboardId ||
                percussionId ||
                tableOne ||
                tableTwo
              }
              scale={scale}
              blockFiveReadOne={blockFiveReadOne}
              blockFiveReadTwo={blockFiveReadTwo}
              blockFiveReadThree={blockFiveReadThree}
              blockFiveReadFour={blockFiveReadFour}
              blockFiveReadFive={blockFiveReadFive}
              blockFiveReadSix={blockFiveReadSix}
              blockFiveReadSeven={blockFiveReadSeven}
              blockFiveReadEight={blockFiveReadEight}
            />
          )}
        </div>
      </Block>

      <Song
        today={
          ministerOne ||
          ministerTwo ||
          backingOne ||
          backingTwo ||
          backingThree ||
          drumsId ||
          violaId ||
          contrabassId ||
          guitarId ||
          keyboardId ||
          percussionId ||
          tableOne ||
          tableTwo
        }
      >
        {scale.firstSong && (
          <div>
            <div className="group">
              <p>1.</p>

              <Link to="/song/song-read" onClick={songReadOne}>
                {scale.firstSong ? scale.firstSong.name : ''}
              </Link>

              <strong>{scale.firstSong ? scale.firstSong.tone : ''}</strong>
            </div>

            <span>{scale.firstSong ? scale.firstSong.author : ''}</span>

            <hr />
          </div>
        )}

        {scale.secondSong && (
          <div>
            <div className="group">
              <p>2.</p>

              <Link to="/song/song-read" onClick={songReadTwo}>
                {scale.secondSong ? scale.secondSong.name : ''}
              </Link>

              <strong>{scale.secondSong ? scale.secondSong.tone : ''}</strong>
            </div>

            <span>{scale.secondSong ? scale.secondSong.author : ''}</span>

            <hr />
          </div>
        )}

        {scale.thirdSong && (
          <div>
            <div className="group">
              <p>3.</p>

              <Link to="/song/song-read" onClick={songReadThree}>
                {scale.thirdSong ? scale.thirdSong.name : ''}
              </Link>

              <strong>{scale.thirdSong ? scale.thirdSong.tone : ''}</strong>
            </div>

            <span>{scale.thirdSong ? scale.thirdSong.author : ''}</span>

            <hr />
          </div>
        )}

        {scale.fourthtSong && (
          <div>
            <div className="group">
              <p>4.</p>

              <Link to="/song/song-read" onClick={songReadFour}>
                {scale.fourthtSong ? scale.fourthtSong.name : ''}
              </Link>

              <strong>{scale.fourthtSong ? scale.fourthtSong.tone : ''}</strong>
            </div>

            <span>{scale.fourthtSong ? scale.fourthtSong.author : ''}</span>

            <hr />
          </div>
        )}

        {scale.fifthSong && (
          <div>
            <div className="group">
              <p>5.</p>

              <Link to="/song/song-read" onClick={songReadFive}>
                {scale.fifthSong ? scale.fifthSong.name : ''}
              </Link>

              <strong>{scale.fifthSong ? scale.fifthSong.tone : ''}</strong>
            </div>

            <span>{scale.fifthSong ? scale.fifthSong.author : ''}</span>

            <hr />
          </div>
        )}

        {scale.sixthSong && (
          <div>
            <div className="group">
              <p>6.</p>

              <Link to="/song/song-read" onClick={songReadSix}>
                {scale.sixthSong ? scale.sixthSong.name : ''}
              </Link>

              <strong>{scale.sixthSong ? scale.sixthSong.tone : ''}</strong>
            </div>

            <span>{scale.sixthSong ? scale.sixthSong.author : ''}</span>

            <hr />
          </div>
        )}

        {scale.seventhSong && (
          <div>
            <div className="group">
              <p>7.</p>

              <Link to="/song/song-read" onClick={songReadSeven}>
                {scale.seventhSong ? scale.seventhSong.name : ''}
              </Link>

              <strong>{scale.seventhSong ? scale.seventhSong.tone : ''}</strong>
            </div>

            <span>{scale.seventhSong ? scale.seventhSong.author : ''}</span>

            <hr />
          </div>
        )}

        {scale.eighthSong && (
          <div>
            <div className="group">
              <p>8.</p>

              <Link to="/song/song-read" onClick={songReadEight}>
                {scale.eighthSong ? scale.eighthSong.name : ''}
              </Link>

              <strong>{scale.eighthSong ? scale.eighthSong.tone : ''}</strong>
            </div>

            <span>{scale.eighthSong ? scale.eighthSong.author : ''}</span>
          </div>
        )}
      </Song>
      <hr />

      <div className="note">
        <strong>Ensaio:</strong>
        <span>{scale.note ? scale.note : ''}</span>
      </div>
    </Container>
  );
}
