import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

export default function BlockTwo({
  today,
  scale,
  blockTwoReadOne,
  blockTwoReadTwo,
  blockTwoReadThree,
  blockTwoReadFour,
  blockTwoReadFive,
  blockTwoReadSix,
  blockTwoReadSeven,
  blockTwoReadEight,
}) {
  return (
    <Container today={today}>
      {scale.blockTwo.firstSong && (
        <div>
          <div className="group">
            <p>1.</p>

            <Link to="/song/song-read" onClick={blockTwoReadOne}>
              {scale.blockTwo.firstSong ? scale.blockTwo.firstSong.name : ''}
            </Link>

            <strong>
              {scale.blockTwo.firstSong ? scale.blockTwo.firstSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockTwo.firstSong ? scale.blockTwo.firstSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockTwo.secondSong && (
        <div>
          <div className="group">
            <p>2.</p>

            <Link to="/song/song-read" onClick={blockTwoReadTwo}>
              {scale.blockTwo.secondSong ? scale.blockTwo.secondSong.name : ''}
            </Link>

            <strong>
              {scale.blockTwo.secondSong ? scale.blockTwo.secondSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockTwo.secondSong ? scale.blockTwo.secondSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockTwo.thirdSong && (
        <div>
          <div className="group">
            <p>3.</p>

            <Link to="/song/song-read" onClick={blockTwoReadThree}>
              {scale.blockTwo.thirdSong ? scale.blockTwo.thirdSong.name : ''}
            </Link>

            <strong>
              {scale.blockTwo.thirdSong ? scale.blockTwo.thirdSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockTwo.thirdSong ? scale.blockTwo.thirdSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockTwo.fourthtSong && (
        <div>
          <div className="group">
            <p>4.</p>

            <Link to="/song/song-read" onClick={blockTwoReadFour}>
              {scale.blockTwo.fourthtSong
                ? scale.blockTwo.fourthtSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockTwo.fourthtSong
                ? scale.blockTwo.fourthtSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockTwo.fourthtSong
              ? scale.blockTwo.fourthtSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockTwo.fifthSong && (
        <div>
          <div className="group">
            <p>5.</p>

            <Link to="/song/song-read" onClick={blockTwoReadFive}>
              {scale.blockTwo.fifthSong ? scale.blockTwo.fifthSong.name : ''}
            </Link>

            <strong>
              {scale.blockTwo.fifthSong ? scale.blockTwo.fifthSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockTwo.fifthSong ? scale.blockTwo.fifthSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockTwo.sixthSong && (
        <div>
          <div className="group">
            <p>6.</p>

            <Link to="/song/song-read" onClick={blockTwoReadSix}>
              {scale.blockTwo.sixthSong ? scale.blockTwo.sixthSong.name : ''}
            </Link>

            <strong>
              {scale.blockTwo.sixthSong ? scale.blockTwo.sixthSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockTwo.sixthSong ? scale.blockTwo.sixthSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockTwo.seventhSong && (
        <div>
          <div className="group">
            <p>7.</p>

            <Link to="/song/song-read" onClick={blockTwoReadSeven}>
              {scale.blockTwo.seventhSong
                ? scale.blockTwo.seventhSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockTwo.seventhSong
                ? scale.blockTwo.seventhSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockTwo.seventhSong
              ? scale.blockTwo.seventhSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockTwo.eighthSong && (
        <div>
          <div className="group">
            <p>8.</p>

            <Link to="/song/song-read" onClick={blockTwoReadEight}>
              {scale.blockTwo.eighthSong ? scale.blockTwo.eighthSong.name : ''}
            </Link>

            <strong>
              {scale.blockTwo.eighthSong ? scale.blockTwo.eighthSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockTwo.eighthSong ? scale.blockTwo.eighthSong.author : ''}
          </span>
        </div>
      )}
    </Container>
  );
}
