import styled from 'styled-components';

export const Content = styled.div`
  form {
    div.group {
      display: block;
      padding-bottom: 1rem;

      div {
        width: 100%;
      }

      textarea {
        height: 50rem;
      }
    }

    div.cancel {
      margin-left: 0;

      button {
        background: var(--color-red);
        &:hover {
          opacity: var(--opacity-semiOpaque);
        }
      }
    }
  }

  @media (min-width: 1180px) {
    form {
      div.group {
        display: flex;
        padding-bottom: 0;

        div.title,
        div.link {
          margin-bottom: 1rem;
        }
      }

      button {
        margin-top: 3rem;
      }

      div.cancel {
        display: flex;
        justify-content: flex-end;

        button {
          width: 50%;
        }
      }
    }
  }
`;
