import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border-left: 0.1rem solid var(--color-red);
  background: var(--color-primary);

  &:hover {
    opacity: var(--opacity-semiOpaque);
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div.alignHorizontally {
    display: flex;
    align-items: center;

    div {
      span {
        margin-right: 1rem;
        color: var(--color-red);
      }
    }

    div.edit {
      button {
        border: none;
        background: transparent;
        color: var(--color-white);

        &:hover {
          opacity: var(--opacity-medium);
        }
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  div img {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    border: 0.2rem solid var(--color-white);
    background: var(--color-secondary);

    margin-right: 1rem;

    object-fit: cover;
  }

  div.alignVertically {
    div strong {
      margin-right: 1rem;
    }

    div span {
      font-size: var(--fontSizes-xl);
      color: var(--color-white);
      opacity: var(--opacity-medium);
    }
  }
`;
