import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import UsersActive from '~/pages/Admin/User/UsersActive';
import UsersDisabled from '~/pages/Admin/User/UsersDisabled';
import UsersNew from '~/pages/Admin/User/UsersNew/UsersNewMedia';
import UsersPermission from '~/pages/Admin/User/UsersPermission/UsersPermissionMedia';
import UsersUpdate from '~/pages/Admin/User/UsersUpdate/UsersUpdateMedia';
import Pro from '~/pages/Media/Pro';
import ScalesList from '~/pages/Media/Scale/ScalesList';
import ScalesNew from '~/pages/Media/Scale/ScalesNew';
import ScalesUpdate from '~/pages/Media/Scale/ScalesUpdate';

import Route from './Route';

export default function Media() {
  const version = useSelector(state => state.user.profile);

  const pro = version === null ? false : version.pro;

  return (
    <Switch>
      <Route
        path="/media/scale/scales-new"
        component={pro ? ScalesNew : Pro}
        isPrivate
      />
      <Route
        path="/media/scale/scales-list"
        component={pro ? ScalesList : Pro}
        isPrivate
      />
      <Route
        path="/media/scale/scales-update"
        component={pro ? ScalesUpdate : Pro}
        isPrivate
      />

      <Route
        path="/admin/media/users-active"
        component={pro ? UsersActive : Pro}
        isPrivate
      />
      <Route
        path="/admin/media/users-disabled"
        component={pro ? UsersDisabled : Pro}
        isPrivate
      />
      <Route
        path="/admin/media/users-new"
        component={pro ? UsersNew : Pro}
        isPrivate
      />
      <Route
        path="/admin/media/users-update"
        component={pro ? UsersUpdate : Pro}
        isPrivate
      />
      <Route
        path="/admin/media/users-permission"
        component={pro ? UsersPermission : Pro}
        isPrivate
      />
    </Switch>
  );
}
