export function updatePostRequest(id) {
  return {
    type: '@post/UPDATE_POST_REQUEST',
    payload: { id },
  };
}

export function pagePostRequest(page) {
  return {
    type: '@post/PAGE_POST_REQUEST',
    payload: { page },
  };
}
