import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import UsersActive from '~/pages/Admin/User/UsersActive';
import UsersDisabled from '~/pages/Admin/User/UsersDisabled';
import UsersNew from '~/pages/Admin/User/UsersNew/UsersNewPraise';
import UsersPermission from '~/pages/Admin/User/UsersPermission/UsersPermissionPraise';
import UsersUpdate from '~/pages/Admin/User/UsersUpdate/UsersUpdatePraise';
import BlockListFive from '~/pages/Praise/Block/BlockList/BlockListFive';
import BlockListFour from '~/pages/Praise/Block/BlockList/BlockListFour';
import BlockListOne from '~/pages/Praise/Block/BlockList/BlockListOne';
import BlockListThree from '~/pages/Praise/Block/BlockList/BlockListThree';
import BlockListTwo from '~/pages/Praise/Block/BlockList/BlockListTwo';
import BlockNewFive from '~/pages/Praise/Block/BlockNew/BlockNewFive';
import BlockNewFour from '~/pages/Praise/Block/BlockNew/BlockNewFour';
import BlockNewOne from '~/pages/Praise/Block/BlockNew/BlockNewOne';
import BlockNewThree from '~/pages/Praise/Block/BlockNew/BlockNewThree';
import BlockNewTwo from '~/pages/Praise/Block/BlockNew/BlockNewTwo';
import BlockUpdateFive from '~/pages/Praise/Block/BlockUpdate/BlockUpdateFive';
import BlockUpdateFour from '~/pages/Praise/Block/BlockUpdate/BlockUpdateFour';
import BlockUpdateOne from '~/pages/Praise/Block/BlockUpdate/BlockUpdateOne';
import BlockUpdateThree from '~/pages/Praise/Block/BlockUpdate/BlockUpdateThree';
import BlockUpdateTwo from '~/pages/Praise/Block/BlockUpdate/BlockUpdateTwo';
import PostList from '~/pages/Praise/Post/PostList';
import PostNew from '~/pages/Praise/Post/PostNew';
import PostUpdate from '~/pages/Praise/Post/PostUpdate';
import Pro from '~/pages/Praise/Pro';
import ScaleDuplicate from '~/pages/Praise/Scale/ScaleDuplicate';
import ScaleList from '~/pages/Praise/Scale/ScaleList';
import ScaleNew from '~/pages/Praise/Scale/ScaleNew';
import ScaleUpdate from '~/pages/Praise/Scale/ScaleUpdate';
import SongActive from '~/pages/Praise/Song/SongActive';
import SongDisabled from '~/pages/Praise/Song/SongDisabled';
import SongDuplicate from '~/pages/Praise/Song/SongDuplicate';
import SongNew from '~/pages/Praise/Song/SongNew';
import SongRead from '~/pages/Praise/Song/SongRead';
import SongUpdate from '~/pages/Praise/Song/SongUpdate';

import Route from './Route';

export default function Praise() {
  const version = useSelector(state => state.user.profile);

  const pro = version === null ? false : version.pro;

  return (
    <Switch>
      <Route
        path="/admin/praise/users-active"
        component={pro ? UsersActive : Pro}
        isPrivate
      />
      <Route
        path="/admin/praise/users-disabled"
        component={pro ? UsersDisabled : Pro}
        isPrivate
      />
      <Route
        path="/admin/praise/users-new"
        component={pro ? UsersNew : Pro}
        isPrivate
      />
      <Route
        path="/admin/praise/users-update"
        component={pro ? UsersUpdate : Pro}
        isPrivate
      />
      <Route
        path="/admin/praise/users-permission"
        component={pro ? UsersPermission : Pro}
        isPrivate
      />

      <Route
        path="/scale/scale-list"
        component={pro ? ScaleList : Pro}
        isPrivate
      />
      <Route
        path="/scale/scale-new"
        component={pro ? ScaleNew : Pro}
        isPrivate
      />
      <Route
        path="/scale/scale-update"
        component={pro ? ScaleUpdate : Pro}
        isPrivate
      />
      <Route
        path="/scale/scale-duplicate"
        component={pro ? ScaleDuplicate : Pro}
        isPrivate
      />

      <Route
        path="/song/song-active"
        component={pro ? SongActive : Pro}
        isPrivate
      />
      <Route
        path="/song/song-disabled"
        component={pro ? SongDisabled : Pro}
        isPrivate
      />
      <Route path="/song/song-new" component={pro ? SongNew : Pro} isPrivate />
      <Route
        path="/song/song-read"
        component={pro ? SongRead : Pro}
        isPrivate
      />
      <Route
        path="/song/song-update"
        component={pro ? SongUpdate : Pro}
        isPrivate
      />
      <Route
        path="/song/song-duplicate"
        component={pro ? SongDuplicate : Pro}
        isPrivate
      />

      <Route
        path="/block/block-list-one"
        component={pro ? BlockListOne : Pro}
        isPrivate
      />
      <Route
        path="/block/block-list-two"
        component={pro ? BlockListTwo : Pro}
        isPrivate
      />
      <Route
        path="/block/block-list-three"
        component={pro ? BlockListThree : Pro}
        isPrivate
      />
      <Route
        path="/block/block-list-four"
        component={pro ? BlockListFour : Pro}
        isPrivate
      />
      <Route
        path="/block/block-list-five"
        component={pro ? BlockListFive : Pro}
        isPrivate
      />

      <Route
        path="/block/block-new-one"
        component={pro ? BlockNewOne : Pro}
        isPrivate
      />
      <Route
        path="/block/block-new-two"
        component={pro ? BlockNewTwo : Pro}
        isPrivate
      />
      <Route
        path="/block/block-new-three"
        component={pro ? BlockNewThree : Pro}
        isPrivate
      />
      <Route
        path="/block/block-new-four"
        component={pro ? BlockNewFour : Pro}
        isPrivate
      />
      <Route
        path="/block/block-new-five"
        component={pro ? BlockNewFive : Pro}
        isPrivate
      />

      <Route
        path="/block/block-update-one"
        component={pro ? BlockUpdateOne : Pro}
        isPrivate
      />
      <Route
        path="/block/block-update-two"
        component={pro ? BlockUpdateTwo : Pro}
        isPrivate
      />
      <Route
        path="/block/block-update-three"
        component={pro ? BlockUpdateThree : Pro}
        isPrivate
      />
      <Route
        path="/block/block-update-four"
        component={pro ? BlockUpdateFour : Pro}
        isPrivate
      />
      <Route
        path="/block/block-update-five"
        component={pro ? BlockUpdateFive : Pro}
        isPrivate
      />

      <Route path="/post/post-new" component={pro ? PostNew : Pro} isPrivate />
      <Route
        path="/post/post-list"
        component={pro ? PostList : Pro}
        isPrivate
      />
      <Route
        path="/post/post-update"
        component={pro ? PostUpdate : Pro}
        isPrivate
      />
    </Switch>
  );
}
