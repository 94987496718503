import produce from 'immer';

export default function scaleMinister(state = {}, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@scale-minister/UPDATE_SCALE_REQUEST': {
        draft.scaleMinisterId = action.payload.id;
        break;
      }
      default:
    }
  });
}
