import styled from 'styled-components';

export const Content = styled.div`
  div.title {
    margin-bottom: 2rem;

    display: flex;
    align-items: center;

    white-space: nowrap;

    strong {
      font-size: var(--fontSizes-6xl);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      margin-left: 0.8rem;
      padding: 0.5rem;

      font-weight: bold;
      background: ${props =>
        props.pro ? 'var(--color-orange)' : 'var(--color-blue)'};
      border-radius: 0.5rem;
      color: var(--color-white);
    }
  }

  form {
    padding: 2rem;
    border-radius: 0.5rem;
    background: var(--color-primary);
  }

  div.dice {
    padding-bottom: 0.5rem;

    strong {
      font-size: var(--fontSizes-4xl);
      font-weight: 400;
      color: var(--color-blue);
    }

    span {
      font-size: var(--fontSizes-2xl);
      color: var(--color-white);
      opacity: var(--opacity-semiOpaque);
    }

    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);
    }
  }

  div.controlled {
    padding-bottom: 0.5rem;

    strong {
      font-size: var(--fontSizes-4xl);
      font-weight: 400;
      color: var(--color-blue);
    }

    span {
      font-size: var(--fontSizes-2xl);
      color: var(--color-white);
      opacity: var(--opacity-semiOpaque);
    }

    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);
    }
  }

  div.submit {
    button {
      width: 30%;
    }
  }

  @media (min-width: 1180px) {
    div.title {
      margin-bottom: 4rem;

      strong {
        margin-top: 0;
      }
    }

    form {
      div.dice,
      div.controlled {
        margin-bottom: 1rem;
      }
    }
  }
`;
