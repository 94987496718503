import React, { useEffect, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { Link, useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import axios from 'axios';
import { addMonths, format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputText from '~/components/Layout/Inputs/InputText';
import api from '~/services/api';
import { cep, cnpjMask, cpfMask, phoneMask } from '~/utils/inputMask';
import { ToastError } from '~/utils/toast';

import { Container } from './styles';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(10, 'Razão social deve ter no mínimo 10 caracteres')
    .max(100, 'Razão social deve ter no máximo 100 caracteres'),
  document: Yup.string().length(18, 'CNPJ dever ser preenchido apenas números'),
  nickname: Yup.string()
    .min(3, 'Nome fantasia deve ter no mínimo 3 caracteres')
    .max(20, 'Nome fantasia ter no máximo 20 caracteres'),
  people: Yup.number()
    .min(10, 'Limite de pessoas deve ter no mínimo 10')
    .typeError('Limite de pessoas é obrigatório'),
  responsible: Yup.string()
    .min(10, 'Nome completo deve ter no mínimo 10 caracteres')
    .max(50, 'Nome completo deve ter no máximo 50 caracteres'),
  zip_code: Yup.string(),
  number: Yup.string(),
  complement: Yup.string(),
  nameuser: Yup.string()
    .min(3, 'Nome de usuário deve ter no mínimo 3 caracteres')
    .max(20, 'Nome de usuário deve ter no máximo 20 caracteres'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .min(7, 'E-mail deve ter no mínimo 7 caracteres')
    .max(50, 'E-mail deve ter no máximo 50 caracteres'),
  password: Yup.string()
    .min(4, 'Senha deve ter no mínimo 4 caracteres')
    .max(20, 'Senha deve ter no máximo 20 caracteres'),
  confirmPassword: Yup.string()
    .min(4, 'Confirme a senha deve ter no mínimo 4 caracteres')
    .max(20, 'Confirme a senha deve ter no máximo 20 caracteres')
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),
  responsible_document: Yup.string(),
  phone: Yup.string().length(16, 'Celular está incompleto'),
});

export default function SignUp() {
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const year = format(new Date(), 'yyyy');

  const available = format(addMonths(new Date(), 1), 'yyyy-MM-dd');

  const [name, setName] = useState('');
  const [document, setDocument] = useState('');
  const [nickname, setNickname] = useState('');
  const [people, setPeople] = useState('');
  const [zip_code, setZip_code] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  const [responsible, setResponsible] = useState('');
  const [nameuser, setNameuser] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [responsibleDocument, setResponsibleDocument] = useState('');
  const [phone, setPhone] = useState('');

  useEffect(() => {
    async function cepUser() {
      if (zip_code.length === 9) {
        const response = await axios.get(
          `https://viacep.com.br/ws/${zip_code.replace(/\D/g, '')}/json/`
        );

        const { logradouro, bairro, localidade, uf } = response.data;

        setStreet(logradouro);
        setDistrict(bairro);
        setCity(localidade);
        setState(uf);
      }
    }

    cepUser();
  }, [zip_code]);

  async function handleSubmit() {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = zonedTimeToUtc(available, timezone);
    const birthday = zonedTimeToUtc(format(new Date(), 'yyyy-MM-dd'), timezone);

    const nicknameTrim = nickname.trim();

    try {
      setLoading(true);

      const data = await api
        .post('/church/account-new', {
          name,
          document,
          nickname: nicknameTrim,
          people,
          available: timezoneDate,
          responsible,
          zip_code,
          number,
          complement,
          nameuser,
          email,
          password,
          responsible_document: responsibleDocument,
          phone,
          birthday,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'CNPJ is invalid') {
              ToastError({ title: 'CNPJ é inválido' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Nickname already exists') {
              ToastError({ title: 'Nome fantasia já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User name already exists') {
              ToastError({ title: 'Nome de usuário já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Email already exists') {
              ToastError({ title: 'E-mail já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'CPF is invalid') {
              ToastError({ title: 'CPF é inválido' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdUser already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) {
        setLoading(false);

        return;
      }

      await Swal.fire({
        title: 'Parabéns! <br /> Conta criada com êxito',
        html: `<br /> Seja bem-vindo!<br /><br /> Você será redirecionado para a tela de 'login' 😃`,
        timer: 20000,
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      history.push('/');
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  return (
    <Container>
      <div className="signup">
        <Form schema={schema} onSubmit={handleSubmit}>
          <h2>Dados da conta</h2>

          <div className="group">
            <div className="name">
              <InputText
                label="Razão social"
                name="name"
                value={name}
                onChange={event => setName(event.target.value)}
              />
            </div>

            <div className="document">
              <InputText
                label="CNPJ"
                name="document"
                value={document}
                onChange={event => setDocument(cnpjMask(event.target.value))}
              />
            </div>
          </div>

          <div className="group">
            <div className="people">
              <InputText
                label="Limite de pessoas"
                name="people"
                type="number"
                value={people}
                onChange={event => setPeople(event.target.value)}
              />
            </div>

            <div className="nickname">
              <InputText
                label="Nome fantasia"
                name="nickname"
                value={nickname}
                onChange={event => setNickname(event.target.value)}
              />
            </div>
          </div>

          <div className="group">
            <div className="zip_code">
              <InputText
                label="CEP"
                name="zip_code"
                value={zip_code}
                onChange={event => setZip_code(cep(event.target.value))}
              />
            </div>

            <div className="street">
              <InputText label="Rua" name="street" value={street} disabled />
            </div>
          </div>

          <div className="group">
            <div className="number">
              <InputText
                label="Número"
                name="number"
                type="number"
                value={number}
                onChange={event => setNumber(event.target.value)}
              />
            </div>

            <div className="complement">
              <InputText
                label="Complemento"
                name="complement"
                value={complement}
                onChange={event => setComplement(event.target.value)}
              />
            </div>
          </div>

          <div className="group">
            <div className="district">
              <InputText
                label="Bairro"
                name="district"
                value={district}
                disabled
              />
            </div>

            <div className="city">
              <InputText label="Cidade" name="city" value={city} disabled />
            </div>

            <div className="state">
              <InputText label="UF" name="state" value={state} disabled />
            </div>
          </div>

          <h2>Dados do responsável</h2>

          <div className="group">
            <div className="responsible">
              <InputText
                label="Nome completo"
                name="responsible"
                value={responsible}
                onChange={event => setResponsible(event.target.value)}
              />
            </div>

            <div className="nameuser">
              <InputText
                label="Nome de usuário"
                name="nameuser"
                value={nameuser}
                onChange={event =>
                  setNameuser(
                    event.target.value.toLowerCase().replace(/\s+/g, '')
                  )
                }
              />
            </div>
          </div>

          <div className="group">
            <div className="email">
              <InputText
                label="E-mail"
                name="email"
                type="email"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            </div>

            <div className="password">
              <InputText
                label="Senha"
                name="password"
                type="password"
                autoComplete="cc-csc"
                value={password}
                onChange={event => setPassword(event.target.value)}
              />
            </div>

            <div className="confirm-password">
              <InputText
                label="Confirme a senha"
                name="confirmPassword"
                type="password"
                autoComplete="cc-csc"
              />
            </div>
          </div>

          <div className="group">
            <div className="responsible_document">
              <InputText
                label="CPF"
                name="responsible_document"
                value={responsibleDocument}
                onChange={event =>
                  setResponsibleDocument(cpfMask(event.target.value))
                }
              />
            </div>

            <div className="phone">
              <InputText
                label="Celular"
                name="phone"
                value={phone}
                onChange={event => setPhone(phoneMask(event.target.value))}
              />
            </div>
          </div>

          <div className="group">
            <div>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress
                    size={24}
                    style={{ color: 'var(--color-white)' }}
                  />
                ) : (
                  'Criar conta'
                )}
              </SubmitButton>
            </div>

            <div className="cancel">
              <Link to="/">
                <MdArrowBack />
                Já tenho conta
              </Link>
            </div>
          </div>

          <div className="year">
            <p>@{year}</p>
          </div>
        </Form>
      </div>
    </Container>
  );
}
