import produce from 'immer';

export default function scaleMedia(state = {}, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@scale-media/UPDATE_SCALE_REQUEST': {
        draft.scaleMediaId = action.payload.id;
        break;
      }
      default:
    }
  });
}
