import React from 'react';
import { MdModeEdit, MdMusicNote } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Container, Content, Header } from './styles';

export default function Song({ song, onRead, onUpdate }) {
  const {
    churchPermissions: { song_update },
  } = useSelector(state => state.user.profile);

  return (
    <Container>
      <Content>
        <Header shepherd={song.shepherd}>
          <MdMusicNote size={20} color="var(--color-blue)" />

          <div>
            <Link to="/song/song-read" title="Ver música" onClick={onRead}>
              {song.name}
            </Link>
            <strong>{song.tone}</strong>
            <span>{song.author}</span>
          </div>
        </Header>

        <div className="edit">
          {song_update && (
            <button type="button" title="Alterar música" onClick={onUpdate}>
              <MdModeEdit size={20} />
            </button>
          )}
        </div>
      </Content>
    </Container>
  );
}
