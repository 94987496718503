import React from 'react';
import { MdModeEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { endOfDay, format, isBefore, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import { Container, Escalated, Header } from './styles';

export default function Scale({ scale, onUpdate }) {
  const {
    id,
    churchPermissions: { scale_media_update },
  } = useSelector(state => state.user.profile);

  const projectionId =
    scale.projectionId !== null || '' ? id === scale.projectionId.id : false;

  const streamingId =
    scale.streamingId !== null || '' ? id === scale.streamingId.id : false;

  const footageId =
    scale.footageId !== null || '' ? id === scale.footageId.id : false;

  const photographyId =
    scale.photographyId !== null || '' ? id === scale.photographyId.id : false;

  const storiesId =
    scale.storiesId !== null || '' ? id === scale.storiesId.id : false;

  const parsedDate = parseISO(scale.available);

  const past = isBefore(new Date(endOfDay(parsedDate)), new Date(new Date()));

  const dateFormatted = format(parsedDate, "dd 'de' MMMM", { locale: pt });

  return (
    <Container
      past={!past}
      today={
        projectionId || streamingId || footageId || photographyId || storiesId
      }
    >
      <Header
        today={
          projectionId || streamingId || footageId || photographyId || storiesId
        }
      >
        <div className="description">
          <strong>{scale.description}</strong>
          {!past && scale_media_update && (
            <Link
              title="Alterar escala"
              to="/media/scale/scales-update"
              onClick={onUpdate}
            >
              <MdModeEdit size={20} />
            </Link>
          )}
        </div>

        <div className="hour-date">
          <span>{scale.hour}</span>

          <div>
            <strong>{dateFormatted}</strong>
          </div>
        </div>
      </Header>

      <hr />

      <Escalated
        today={
          projectionId || streamingId || footageId || photographyId || storiesId
        }
      >
        {scale.projectionId && (
          <div>
            <strong>Projeção:</strong>
            <span>{scale.projectionId ? scale.projectionId.name : ''}</span>

            <hr />
          </div>
        )}

        {scale.streamingId && (
          <div>
            <strong>Transmissão: </strong>
            <span>{scale.streamingId ? scale.streamingId.name : ''}</span>

            <hr />
          </div>
        )}

        {scale.footageId && (
          <div>
            <strong>Filmagem: </strong>
            <span>{scale.footageId ? scale.footageId.name : ''}</span>

            <hr />
          </div>
        )}

        {scale.photographyId && (
          <div>
            <strong>Fotografia</strong>
            <span>{scale.photographyId ? scale.photographyId.name : ''}</span>

            <hr />
          </div>
        )}

        {scale.storiesId && (
          <div>
            <strong>Stories</strong>
            <span>{scale.storiesId ? scale.storiesId.name : ''}</span>

            <hr />
          </div>
        )}
      </Escalated>

      {scale.note && (
        <div className="note">
          <strong>Observação:</strong>
          <span>{scale.note}</span>
        </div>
      )}
    </Container>
  );
}
