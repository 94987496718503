import React, { useState } from 'react';
import { MdCheckCircleOutline, MdOutlineCircle } from 'react-icons/md';

import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Container } from './styles';

export default function TrainingLessonAllList({
  trainingLesson,
  onRead,
  focus,
  handleLessonCount,
}) {
  const [check, setCheck] = useState(trainingLesson.lesson[0]);

  async function handleCompleted(id) {
    try {
      const data = await api
        .post(`/church/course/lessons-user-completed/${id}`)
        .catch(async res => {
          try {
            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });
          }
        });

      if (data === undefined) return;

      setCheck(check ? '' : id);

      handleLessonCount(check ? 'removeLesson' : 'addLesson');

      ToastSuccess({ title: check ? 'Aula não concluída' : 'Aula concluída' });
    } catch (error) {
      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  return (
    <Container>
      <button type="button" title="Abrir" className="open" onClick={onRead}>
        <p
          style={
            focus !== false
              ? {
                  opacity: 'var(--opacity-default)',
                  fontWeight: 'bold',
                }
              : {}
          }
        >
          {trainingLesson.title}
        </p>
      </button>

      <button
        type="button"
        title={check ? 'Não concluída' : 'Concluída'}
        className="check"
        onClick={() => handleCompleted(trainingLesson.id)}
        style={
          focus !== false
            ? {
                border: '0.1rem solid var(--color-green)',
                borderRadius: '0.5rem',
              }
            : {}
        }
      >
        {check ? (
          <MdCheckCircleOutline color="var(--color-green)" />
        ) : (
          <MdOutlineCircle />
        )}
      </button>
    </Container>
  );
}
