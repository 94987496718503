import React from 'react';
import { useDispatch } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';

import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import { signOut } from '~/store/modules/auth/actions';

import { Content, DialogButton, DialogMessages, DialogTitle } from './styles';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UserLogout({ message }) {
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Dialog
      open
      TransitionComponent={Transition}
      keepMounted
      onClose={handleSignOut}
      PaperProps={{
        style: {
          backgroundColor: 'var(--color-primary)',
        },
      }}
    >
      <Content>
        <DialogTitle>
          <strong>Oops...</strong>
        </DialogTitle>

        <DialogMessages>
          <div>
            <p>{message}</p>
          </div>
        </DialogMessages>

        <DialogButton>
          <SubmitButton type="button" onClick={handleSignOut}>
            Ok
          </SubmitButton>
        </DialogButton>
      </Content>
    </Dialog>
  );
}
