import React from 'react';
import { Switch } from 'react-router-dom';

import Logins from '~/pages/Profile/Logins';
import Profile from '~/pages/Profile/Me';

import Route from './Route';

export default function Profiles() {
  return (
    <Switch>
      <Route path="/profile/me" component={Profile} isPrivate />

      <Route path="/profile/logins" component={Logins} isPrivate />
    </Switch>
  );
}
