import React from 'react';
import { MdModeEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { format, isBefore, parseISO } from 'date-fns';

import photoDefault from '~/assets/photo-default.svg';

import { Container, Content, Header } from './styles';

export default function Block({
  block,
  onReadOne,
  onReadTwo,
  onReadThree,
  onReadFour,
  onReadFive,
  onReadSix,
  onReadSeven,
  onReadEight,
  onUpdate,
  to,
}) {
  const {
    churchPermissions: { block_update },
  } = useSelector(state => state.user.profile);

  const parsedDate = parseISO(block.available);
  const formattedAvailable = format(parsedDate, 'dd/MM/yyyy');

  const edit = isBefore(new Date(parsedDate), new Date(new Date()));

  return (
    <Container edit={!edit}>
      <div className="minister-name">
        <strong>{block.minister.name}</strong>
      </div>

      <strong>{formattedAvailable}</strong>

      <hr />

      <Content>
        <Header>
          <img
            src={block.minister.photo ? block.minister.url : photoDefault}
            alt=""
          />

          <div>
            {block.firstSong && (
              <div>
                <div className="group">
                  <p>1.</p>

                  <Link to="/song/song-read" onClick={onReadOne}>
                    {block.firstSong ? block.firstSong.name : ''}
                  </Link>

                  <strong>{block.firstSong ? block.firstSong.tone : ''}</strong>
                </div>

                <span>{block.firstSong ? block.firstSong.author : ''}</span>
              </div>
            )}

            {block.secondSong && (
              <div>
                <div className="group">
                  <p>2.</p>

                  <Link to="/song/song-read" onClick={onReadTwo}>
                    {block.secondSong ? block.secondSong.name : ''}
                  </Link>

                  <strong>
                    {block.secondSong ? block.secondSong.tone : ''}
                  </strong>
                </div>

                <span>{block.secondSong ? block.secondSong.author : ''}</span>
              </div>
            )}

            {block.thirdSong && (
              <div>
                <div className="group">
                  <p>3.</p>

                  <Link to="/song/song-read" onClick={onReadThree}>
                    {block.thirdSong ? block.thirdSong.name : ''}
                  </Link>

                  <strong>{block.thirdSong ? block.thirdSong.tone : ''}</strong>
                </div>

                <span>{block.thirdSong ? block.thirdSong.author : ''}</span>
              </div>
            )}

            {block.fourthtSong && (
              <div>
                <div className="group">
                  <p>4.</p>

                  <Link to="/song/song-read" onClick={onReadFour}>
                    {block.fourthtSong ? block.fourthtSong.name : ''}
                  </Link>

                  <strong>
                    {block.fourthtSong ? block.fourthtSong.tone : ''}
                  </strong>
                </div>

                <span>{block.fourthtSong ? block.fourthtSong.author : ''}</span>
              </div>
            )}

            {block.fifthSong && (
              <div>
                <div className="group">
                  <p>5.</p>

                  <Link to="/song/song-read" onClick={onReadFive}>
                    {block.fifthSong ? block.fifthSong.name : ''}
                  </Link>

                  <strong>{block.fifthSong ? block.fifthSong.tone : ''}</strong>
                </div>

                <span>{block.fifthSong ? block.fifthSong.author : ''}</span>
              </div>
            )}

            {block.sixthSong && (
              <div>
                <div className="group">
                  <p>6.</p>

                  <Link to="/song/song-read" onClick={onReadSix}>
                    {block.sixthSong ? block.sixthSong.name : ''}
                  </Link>

                  <strong>{block.sixthSong ? block.sixthSong.tone : ''}</strong>
                </div>

                <span>{block.sixthSong ? block.sixthSong.author : ''}</span>
              </div>
            )}

            {block.seventhSong && (
              <div>
                <div className="group">
                  <p>7.</p>

                  <Link to="/song/song-read" onClick={onReadSeven}>
                    {block.seventhSong ? block.seventhSong.name : ''}
                  </Link>

                  <strong>
                    {block.seventhSong ? block.seventhSong.tone : ''}
                  </strong>
                </div>

                <span>{block.seventhSong ? block.seventhSong.author : ''}</span>
              </div>
            )}

            {block.eighthSong && (
              <div>
                <div className="group">
                  <p>8.</p>

                  <Link to="/song/song-read" onClick={onReadEight}>
                    {block.eighthSong ? block.eighthSong.name : ''}
                  </Link>

                  <strong>
                    {block.eighthSong ? block.eighthSong.tone : ''}
                  </strong>
                </div>

                <span>{block.eighthSong ? block.eighthSong.author : ''}</span>
              </div>
            )}
          </div>
        </Header>

        <div>
          {!edit && block_update && (
            <Link
              className="permission"
              title="Alterar bloco"
              to={to}
              onClick={onUpdate}
            >
              <MdModeEdit size={20} />
            </Link>
          )}
        </div>
      </Content>

      <hr />
    </Container>
  );
}
