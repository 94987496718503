import produce from 'immer';

export default function song(state = {}, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@song/READ_SONG_REQUEST': {
        draft.songId = action.payload.id;
        break;
      }
      default:
    }
  });
}
