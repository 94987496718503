import styled from 'styled-components';

export const Div = styled.div`
  margin-bottom: 1rem;

  input {
    width: 100%;
    height: 4.4rem;
    padding: 0 1.5rem;
    border-radius: 0.5rem;
    border: 0.2rem solid var(--color-background);
    background: var(--color-background);
    color: var(--color-white);

    margin: 1rem 0;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      border: 0.2rem solid var(--color-blue);
    }
  }

  input:disabled {
    opacity: var(--opacity-medium);
    cursor: not-allowed;
  }

  textarea {
    width: 100%;
    min-height: 12rem;
    max-height: 30rem;
    padding: 1.5rem;
    border-radius: 0.5rem;
    border: 0.2rem solid var(--color-background);
    background: var(--color-background);
    color: var(--color-white);

    resize: vertical;
    font-size: var(--fontSizes-2xl);
    line-height: 3rem;

    margin: 1rem 0 1rem;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      border: 0.2rem solid var(--color-blue);
    }

    textarea:disabled {
      opacity: var(--opacity-medium);
      cursor: not-allowed;
    }
  }

  span {
    margin: 0 0 1rem;
    align-self: flex-start;
    color: var(--color-mandatory);
  }
`;
