import styled from 'styled-components';

export const Content = styled.div`
  form {
    div.group {
      display: block;

      div.date,
      div.hour {
        margin-bottom: 1rem;
      }

      div.hour,
      div.user {
        margin-left: 0;
      }

      div {
        width: 100%;
      }
    }

    button.delete {
      margin-top: 0;

      border: none;
      background: none;
      color: var(--color-red);

      &:hover {
        opacity: var(--opacity-semiOpaque);
      }
    }

    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);

      margin: 0.5rem 0 2rem 0;
    }
  }

  @media (min-width: 720px) {
    form {
      div.group {
        display: flex;
        padding-bottom: 0;

        div.date,
        div.hour {
          margin-right: 1rem;
        }

        div.date {
          width: 18rem;
        }

        div.hour {
          width: 16rem;
        }
      }

      div.buttons {
        display: flex;
        flex-direction: row-reverse;

        width: 100%;

        button {
          margin-top: 2rem;
          width: 15rem;
        }

        div.cancel {
          margin-right: 1rem;

          button {
            width: 12rem;
          }
        }
      }
    }
  }
`;
