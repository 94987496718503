import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import {
  differenceInYears,
  endOfMonth,
  format,
  isAfter,
  isValid,
} from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import {
  Aside,
  Container,
  Main,
  Nav,
  NavTwo,
  Section,
} from '~/components/Layout/Body';
import SubMenuButton from '~/components/Layout/Buttons/SubMenuButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import Scale from '~/components/Pages/Praise/Scale';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Praise/Menu';
import api from '~/services/api';
import {
  duplicateScaleRequest,
  updateScaleRequest,
} from '~/store/modules/praise/scale/actions';
import { readSongRequest } from '~/store/modules/praise/song/actions';
import { ToastError } from '~/utils/toast';

export default function ScaleList() {
  const {
    churchPermissions: { scale_praise_new, scale_praise_list },
  } = useSelector(state => state.user.profile);

  const dispatch = useDispatch();

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [scales, setScales] = useState([]);

  const currentDate = format(new Date(), 'yyyy-MM-dd');

  const [initialDate, setInitialDate] = useState(currentDate);

  const [finalDate, setFinalDate] = useState(
    format(endOfMonth(new Date()), 'yyyy-MM-dd')
  );

  const initial = {
    initialDateSelected: initialDate,
    finalDateSelected: finalDate,
  };

  try {
    useEffect(() => {
      async function loadScales() {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timezoneDateInitial = utcToZonedTime(initialDate, timezone);
        const timezoneDateFinal = utcToZonedTime(finalDate, timezone);

        setLoading(true);

        const response = await api
          .get('/church/praise/scales-list', {
            params: {
              initialDate: timezoneDateInitial,
              finalDate: timezoneDateFinal,
            },
          })
          .catch(async res => {
            try {
              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Praise department disabled') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Pro disabled') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setScales(response.data);

        setLoading(false);
      }
      loadScales();
    }, [finalDate, initialDate]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleRead(id) {
    dispatch(readSongRequest(id));
  }

  function handleSubmit({ initialDateSelected, finalDateSelected }) {
    if (isAfter(new Date(initialDateSelected), new Date(finalDateSelected))) {
      ToastError({
        title: 'A data inicial não pode ser maior que a data final',
      });

      setLoading(false);

      return;
    }

    if (
      differenceInYears(
        new Date(initialDateSelected),
        new Date(finalDateSelected)
      ) !== 0
    ) {
      ToastError({
        title: 'A busca máxima é de um ano',
      });

      setLoading(false);

      return;
    }

    if (isValid(initialDateSelected)) {
      ToastError({
        title: 'A data inicial é inválida',
      });

      setLoading(false);

      return;
    }

    if (isValid(finalDateSelected)) {
      ToastError({
        title: 'A data final é inválida',
      });

      setLoading(false);

      return;
    }

    setInitialDate(initialDateSelected);
    setFinalDate(finalDateSelected);
  }

  async function handleUpdate(id) {
    dispatch(updateScaleRequest(id));
  }

  async function handleDuplicate(id) {
    dispatch(duplicateScaleRequest(id));
  }

  return (
    <Container>
      <Aside>
        <Menu scale="scale" />
      </Aside>

      <Main>
        <Title title="Escalas de louvores" count={`${scales.length}`} />

        <Section>
          <Nav>
            {scale_praise_new && (
              <div>
                <SubMenuButton
                  type="button"
                  onClick={() => history.push('/scale/scale-new')}
                >
                  Adicionar
                </SubMenuButton>
              </div>
            )}
          </Nav>

          <NavTwo>
            <Form initialData={initial} onSubmit={handleSubmit}>
              <div>
                {scale_praise_list && (
                  <>
                    <InputText
                      name="initialDateSelected"
                      type="date"
                      lang="pt-BR"
                      min="2021-01-01"
                      max="9999-12-31"
                      required
                      disabled={!scale_praise_new}
                    />

                    <InputText
                      name="finalDateSelected"
                      type="date"
                      lang="pt-BR"
                      min="2021-01-01"
                      max="9999-12-31"
                      required
                    />
                  </>
                )}
              </div>

              <SubmitButton type="submit">
                {loading ? (
                  <div className="loading">
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  </div>
                ) : (
                  'Buscar'
                )}
              </SubmitButton>
            </Form>
          </NavTwo>

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {scales.map(scale => (
                <Scale
                  key={scale.id}
                  scale={scale}
                  // block One
                  blockOneReadOne={() =>
                    handleRead(scale.blockOne.firstSong.id)
                  }
                  blockOneReadTwo={() =>
                    handleRead(scale.blockOne.secondSong.id)
                  }
                  blockOneReadThree={() =>
                    handleRead(scale.blockOne.thirdSong.id)
                  }
                  blockOneReadFour={() =>
                    handleRead(scale.blockOne.fourthtSong.id)
                  }
                  blockOneReadFive={() =>
                    handleRead(scale.blockOne.fifthSong.id)
                  }
                  blockOneReadSix={() =>
                    handleRead(scale.blockOne.sixthSong.id)
                  }
                  blockOneReadSeven={() =>
                    handleRead(scale.blockOne.seventhSong.id)
                  }
                  blockOneReadEight={() =>
                    handleRead(scale.blockOne.eighthSong.id)
                  }
                  // block Two
                  blockTwoReadOne={() =>
                    handleRead(scale.blockTwo.firstSong.id)
                  }
                  blockTwoReadTwo={() =>
                    handleRead(scale.blockTwo.secondSong.id)
                  }
                  blockTwoReadThree={() =>
                    handleRead(scale.blockTwo.thirdSong.id)
                  }
                  blockTwoReadFour={() =>
                    handleRead(scale.blockTwo.fourthtSong.id)
                  }
                  blockTwoReadFive={() =>
                    handleRead(scale.blockTwo.fifthSong.id)
                  }
                  blockTwoReadSix={() =>
                    handleRead(scale.blockTwo.sixthSong.id)
                  }
                  blockTwoReadSeven={() =>
                    handleRead(scale.blockTwo.seventhSong.id)
                  }
                  blockTwoReadEight={() =>
                    handleRead(scale.blockTwo.eighthSong.id)
                  }
                  // block Three
                  blockThreeReadOne={() =>
                    handleRead(scale.blockThree.firstSong.id)
                  }
                  blockThreeReadTwo={() =>
                    handleRead(scale.blockThree.secondSong.id)
                  }
                  blockThreeReadThree={() =>
                    handleRead(scale.blockThree.thirdSong.id)
                  }
                  blockThreeReadFour={() =>
                    handleRead(scale.blockThree.fourthtSong.id)
                  }
                  blockThreeReadFive={() =>
                    handleRead(scale.blockThree.fifthSong.id)
                  }
                  blockThreeReadSix={() =>
                    handleRead(scale.blockThree.sixthSong.id)
                  }
                  blockThreeReadSeven={() =>
                    handleRead(scale.blockThree.seventhSong.id)
                  }
                  blockThreeReadEight={() =>
                    handleRead(scale.blockThree.eighthSong.id)
                  }
                  // block Four
                  blockFourReadOne={() =>
                    handleRead(scale.blockFour.firstSong.id)
                  }
                  blockFourReadTwo={() =>
                    handleRead(scale.blockFour.secondSong.id)
                  }
                  blockFourReadThree={() =>
                    handleRead(scale.blockFour.thirdSong.id)
                  }
                  blockFourReadFour={() =>
                    handleRead(scale.blockFour.fourthtSong.id)
                  }
                  blockFourReadFive={() =>
                    handleRead(scale.blockFour.fifthSong.id)
                  }
                  blockFourReadSix={() =>
                    handleRead(scale.blockFour.sixthSong.id)
                  }
                  blockFourReadSeven={() =>
                    handleRead(scale.blockFour.seventhSong.id)
                  }
                  blockFourReadEight={() =>
                    handleRead(scale.blockFour.eighthSong.id)
                  }
                  // block Five
                  blockFiveReadOne={() =>
                    handleRead(scale.blockFive.firstSong.id)
                  }
                  blockFiveReadTwo={() =>
                    handleRead(scale.blockFive.secondSong.id)
                  }
                  blockFiveReadThree={() =>
                    handleRead(scale.blockFive.thirdSong.id)
                  }
                  blockFiveReadFour={() =>
                    handleRead(scale.blockFive.fourthtSong.id)
                  }
                  blockFiveReadFive={() =>
                    handleRead(scale.blockFive.fifthSong.id)
                  }
                  blockFiveReadSix={() =>
                    handleRead(scale.blockFive.sixthSong.id)
                  }
                  blockFiveReadSeven={() =>
                    handleRead(scale.blockFive.seventhSong.id)
                  }
                  blockFiveReadEight={() =>
                    handleRead(scale.blockFive.eighthSong.id)
                  }
                  // Song
                  songReadOne={() => handleRead(scale.firstSong.id)}
                  songReadTwo={() => handleRead(scale.secondSong.id)}
                  songReadThree={() => handleRead(scale.thirdSong.id)}
                  songReadFour={() => handleRead(scale.fourthtSong.id)}
                  songReadFive={() => handleRead(scale.fifthSong.id)}
                  songReadSix={() => handleRead(scale.sixthSong.id)}
                  songReadSeven={() => handleRead(scale.seventhSong.id)}
                  songReadEight={() => handleRead(scale.eighthSong.id)}
                  // Edit
                  onUpdate={() => handleUpdate(scale.id)}
                  // Duplicate
                  onDuplicate={() => handleDuplicate(scale.id)}
                />
              ))}
              {scales.length === 0 && (
                <p className="empty">Nenhuma escala encontrada</p>
              )}
            </ul>
          )}
        </Section>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
