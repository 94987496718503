import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Layout/Body';
import ButtonSelectRemove from '~/components/Layout/Buttons/ButtonSelectRemove';
import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputSelect from '~/components/Layout/Inputs/InputSelect';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Praise/Menu';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content, Label } from '../styles';

const schema = Yup.object().shape({
  description: Yup.string()
    .required('Descrição é obrigatória')
    .max(254, 'Descrição deve ter no máximo 254 caracteres'),
  available: Yup.date().required(),
  hour: Yup.string().required('Selecione o horário'),
  minister_one: Yup.string().required('Selecione um ministro'),
  minister_two: Yup.string(),
  backing_one: Yup.string(),
  backing_two: Yup.string(),
  backing_three: Yup.string(),
  drums: Yup.string(),
  viola: Yup.string(),
  contrabass: Yup.string(),
  guitar: Yup.string(),
  keyboard: Yup.string(),
  percussion: Yup.string(),
  table_one: Yup.string(),
  table_two: Yup.string(),
  block: Yup.string(),
  first_song: Yup.string(),
  second_song: Yup.string(),
  third_song: Yup.string(),
  fourth_song: Yup.string(),
  fifth_song: Yup.string(),
  sixth_song: Yup.string(),
  seventh_song: Yup.string(),
  eighth_song: Yup.string(),
  note: Yup.string(),
});

export default function ScaleNew() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [withBlock, setWithBlock] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const dateNew = { available: format(new Date(), 'yyyy-MM-dd') };

  const [description, setDescription] = useState('');
  const [ministerOne, setMinisterOne] = useState();
  const [minister_two, setMinisterTwo] = useState();
  const [backing_one, setBackingOne] = useState();
  const [backing_two, setBackingTwo] = useState();
  const [backing_three, setBackingThree] = useState();
  const [drums, setDrums] = useState();
  const [viola, setViola] = useState();
  const [contrabass, setContrabass] = useState();
  const [guitar, setGuitar] = useState();
  const [keyboard, setKeyboard] = useState();
  const [percussion, setPercussion] = useState();
  const [table_one, setTableOne] = useState();
  const [table_two, setTableTwo] = useState();
  const [note, setNote] = useState('');

  const [firstSong, setFirstSong] = useState();
  const [secondSong, setSecondSong] = useState();
  const [thirdSong, setThirdSong] = useState();
  const [fourthSong, setFourthSong] = useState();
  const [fifthSong, setFifthSong] = useState();
  const [fixthSong, setFixthSong] = useState();
  const [seventhSong, setSeventhSong] = useState();
  const [eighthSong, setEighthSong] = useState();

  const [ministers, setMinisters] = useState([]);
  const [backings, setBackings] = useState([]);
  const [drum, setDrum] = useState([]);
  const [violas, setViolas] = useState([]);
  const [contrabas, setContrabas] = useState([]);
  const [guitars, setGuitars] = useState([]);
  const [keyboards, setKeyboards] = useState([]);
  const [percussions, setPercussions] = useState([]);
  const [tables, setTables] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [songs, setSongs] = useState([]);

  try {
    useEffect(() => {
      async function loadMinisters() {
        const { data } = await api.get('/church/admin/users-minister');

        setMinisters(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadMinisters();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os ministros' });
  }

  try {
    useEffect(() => {
      async function loadBackings() {
        const { data } = await api.get('/church/admin/users-backing');

        setBackings(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadBackings();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os backings' });
  }

  try {
    useEffect(() => {
      async function loadDrums() {
        const { data } = await api.get('/church/admin/users-drum');

        setDrum(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadDrums();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os bateras' });
  }

  try {
    useEffect(() => {
      async function loadViolas() {
        const { data } = await api.get('/church/admin/users-viola');

        setViolas(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadViolas();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os violonistas' });
  }

  try {
    useEffect(() => {
      async function loadContrabass() {
        const { data } = await api.get('/church/admin/users-contrabas');

        setContrabas(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadContrabass();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os baixistas' });
  }

  try {
    useEffect(() => {
      async function loadGuitars() {
        const { data } = await api.get('/church/admin/users-guitar');

        setGuitars(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadGuitars();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os guitaristas' });
  }

  try {
    useEffect(() => {
      async function loadKeyboards() {
        const { data } = await api.get('/church/admin/users-keyboard');

        setKeyboards(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadKeyboards();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os tecladistas' });
  }

  try {
    useEffect(() => {
      async function loadPercussions() {
        const { data } = await api.get('/church/admin/users-percussion');

        setPercussions(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadPercussions();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os tecladistas' });
  }

  try {
    useEffect(() => {
      async function loadTables() {
        const { data } = await api.get('/church/admin/users-table');

        setTables(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadTables();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os sonoplastas' });
  }

  try {
    useEffect(() => {
      async function loadBlocks() {
        const { data } = await api.get('/church/praise/blocks-list', {
          params: { minister_id: ministerOne },
        });

        setBlocks(
          data.map(block => ({
            id: block.id,
            title: block.description,
          }))
        );
      }

      loadBlocks();
    }, [ministerOne]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os blocos' });
  }

  try {
    useEffect(() => {
      async function loadSongs() {
        const { data } = await api.get('/church/praise/songs-active');

        setSongs(data.map(song => ({ id: song.id, title: song.name })));
      }

      loadSongs();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar as músicas' });
  }

  function handleWithBlock() {
    if (withBlock === false) {
      setWithBlock(true);
      setFirstSong();
      setSecondSong();
      setThirdSong();
      setFourthSong();
      setFifthSong();
      setFixthSong();
      setSeventhSong();
      setEighthSong();
    }

    if (withBlock === true) {
      setWithBlock(false);
    }
  }

  async function handleSubmit({
    available,
    hour,
    minister_one,
    block,
    first_song,
    second_song,
    third_song,
    fourth_song,
    fifth_song,
    sixth_song,
    seventh_song,
    eighth_song,
  }) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = utcToZonedTime(available, timezone);

    try {
      setLoading(true);

      const data = await api
        .post('/church/praise/scales-new', {
          description,
          available: timezoneDate,
          hour,
          minister_one,
          minister_two,
          backing_one,
          backing_two,
          backing_three,
          drums,
          viola,
          contrabass,
          guitar,
          keyboard,
          percussion,
          table_one,
          table_two,
          block,
          first_song,
          second_song,
          third_song,
          fourth_song,
          fifth_song,
          sixth_song,
          seventh_song,
          eighth_song,
          note,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              setMessage('Autenticação falhou. Entre em contato com seu líder');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Praise department disabled') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Pro disabled') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant disabled') {
              setMessage('Conta expirou. Entre em contato com o administrador');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant expired') {
              setMessage(
                'Plano Pro expirou. Entre em contato com o administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Past dates are not permitted') {
              ToastError({
                title: 'Datas passadas não são permitidas',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) return;

      ToastSuccess({ title: 'Escala adicionada' });

      setDescription('');
      setMinisterTwo('');
      setBackingOne('');
      setBackingTwo('');
      setBackingThree('');
      setDrums('');
      setViola('');
      setContrabass('');
      setGuitar('');
      setKeyboard('');
      setPercussion('');
      setTableOne('');
      setTableTwo('');
      setFirstSong('');
      setSecondSong('');
      setThirdSong('');
      setFourthSong('');
      setFifthSong('');
      setFixthSong('');
      setSeventhSong('');
      setEighthSong('');
      setNote('');

      setLoading(false);
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  return (
    <Container>
      <Aside>
        <Menu scale="scale" />
      </Aside>

      <Main>
        <Title title="Adicionar escala" />

        <Content>
          <Form schema={schema} initialData={dateNew} onSubmit={handleSubmit}>
            <div className="group">
              <div className="description">
                <InputText
                  label="Descrição"
                  name="description"
                  value={description}
                  onChange={event => setDescription(event.target.value)}
                />
              </div>

              <div className="date">
                <InputText
                  label="Data"
                  name="available"
                  type="date"
                  min="2021-01-01"
                  max="9999-12-31"
                  lang="pt-BR"
                  required
                />

                <InputText label="Hora" name="hour" type="time" multiple />
              </div>
            </div>

            <div className="group">
              <div className="minister-one">
                <InputSelect
                  label="Voz principal 1"
                  name="minister_one"
                  options={ministers}
                  onChange={event => setMinisterOne(event.target.value)}
                />
              </div>

              <div className="minister-two">
                <InputSelect
                  label="Voz principal 2"
                  name="minister-two"
                  value={minister_two}
                  options={ministers}
                  onChange={event => setMinisterTwo(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setMinisterTwo('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="backing-one">
                <InputSelect
                  label="Backing 1"
                  name="backing_one"
                  value={backing_one}
                  options={backings}
                  onChange={event => setBackingOne(event.target.value)}
                />

                <ButtonSelectRemove
                  style={{ marginRight: 10 }}
                  className="remove"
                  type="button"
                  onClick={() => setBackingOne('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="backing-two">
                <InputSelect
                  label="Backing 2"
                  name="backing_two"
                  value={backing_two}
                  options={backings}
                  onChange={event => setBackingTwo(event.target.value)}
                />

                <ButtonSelectRemove
                  style={{ marginRight: 10 }}
                  className="remove"
                  type="button"
                  onClick={() => setBackingTwo('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="backing-three">
                <InputSelect
                  label="Backing 3"
                  name="backing_three"
                  value={backing_three}
                  options={backings}
                  onChange={event => setBackingThree(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setBackingThree('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="drums">
                <InputSelect
                  label="Bateria"
                  name="drums"
                  value={drums}
                  options={drum}
                  onChange={event => setDrums(event.target.value)}
                />

                <ButtonSelectRemove
                  style={{ marginRight: 10 }}
                  className="remove"
                  type="button"
                  onClick={() => setDrums('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="viola">
                <InputSelect
                  label="Violão"
                  name="viola"
                  value={viola}
                  options={violas}
                  onChange={event => setViola(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setViola('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="contrabass">
                <InputSelect
                  label="Contrabaixo"
                  name="contrabass"
                  value={contrabass}
                  options={contrabas}
                  onChange={event => setContrabass(event.target.value)}
                />

                <ButtonSelectRemove
                  style={{ marginRight: 10 }}
                  className="remove"
                  type="button"
                  onClick={() => setContrabass('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="guitar">
                <InputSelect
                  label="Guitarra"
                  name="guitar"
                  value={guitar}
                  options={guitars}
                  onChange={event => setGuitar(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setGuitar('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="keyboard">
                <InputSelect
                  label="Teclado"
                  name="keyboard"
                  value={keyboard}
                  options={keyboards}
                  onChange={event => setKeyboard(event.target.value)}
                />

                <ButtonSelectRemove
                  style={{ marginRight: 10 }}
                  className="remove"
                  type="button"
                  onClick={() => setKeyboard('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="percussion">
                <InputSelect
                  label="Percussão"
                  name="percussion"
                  value={percussion}
                  options={percussions}
                  onChange={event => setPercussion(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setPercussion('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="table-one">
                <InputSelect
                  label="Mesa 1"
                  name="table_one"
                  value={table_one}
                  options={tables}
                  onChange={event => setTableOne(event.target.value)}
                />

                <ButtonSelectRemove
                  style={{ marginRight: 10 }}
                  className="remove"
                  type="button"
                  onClick={() => setTableOne('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="table-two">
                <InputSelect
                  label="Mesa 2"
                  name="table_two"
                  value={table_two}
                  options={tables}
                  onChange={event => setTableTwo(event.target.value)}
                />

                <ButtonSelectRemove
                  style={{ marginRight: 10 }}
                  className="remove"
                  type="button"
                  onClick={() => setTableTwo('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="with-block">
              <div className="label">
                <Label>Com bloco</Label>
              </div>
              <div>
                <Switch
                  height={15}
                  width={40}
                  handleDiameter={20}
                  onColor="#0195F6"
                  offColor="#121214"
                  onChange={handleWithBlock}
                  checked={withBlock}
                />
              </div>
            </div>

            <div className="group">
              {withBlock === true && (
                <div className="block">
                  <InputSelect
                    label="Bloco"
                    name="block"
                    options={blocks.filter(block => block.id !== undefined)}
                  />
                </div>
              )}
            </div>

            {withBlock === false && (
              <div className="group">
                <div className="first-song">
                  <InputSelect
                    label="Música 1"
                    name="first_song"
                    value={firstSong}
                    options={songs}
                    onChange={event => setFirstSong(event.target.value)}
                  />
                </div>

                <div className="second-song">
                  <InputSelect
                    label="Música 2"
                    name="second_song"
                    value={secondSong}
                    options={songs}
                    onChange={event => setSecondSong(event.target.value)}
                  />

                  <ButtonSelectRemove
                    className="remove"
                    type="button"
                    onClick={() => setSecondSong('')}
                  >
                    X
                  </ButtonSelectRemove>
                </div>

                <div className="third-song">
                  <InputSelect
                    label="Música 3"
                    name="third_song"
                    value={thirdSong}
                    options={songs}
                    onChange={event => setThirdSong(event.target.value)}
                  />

                  <ButtonSelectRemove
                    className="remove"
                    type="button"
                    onClick={() => setThirdSong('')}
                  >
                    X
                  </ButtonSelectRemove>
                </div>

                <div className="fourth-song">
                  <InputSelect
                    label="Música 4"
                    name="fourth_song"
                    value={fourthSong}
                    options={songs}
                    onChange={event => setFourthSong(event.target.value)}
                  />

                  <ButtonSelectRemove
                    className="remove"
                    type="button"
                    onClick={() => setFourthSong('')}
                  >
                    X
                  </ButtonSelectRemove>
                </div>

                <div className="fifth-song">
                  <InputSelect
                    label="Música 5"
                    name="fifth_song"
                    value={fifthSong}
                    options={songs}
                    onChange={event => setFifthSong(event.target.value)}
                  />

                  <ButtonSelectRemove
                    className="remove"
                    type="button"
                    onClick={() => setFifthSong('')}
                  >
                    X
                  </ButtonSelectRemove>
                </div>

                <div className="sixth_song">
                  <InputSelect
                    label="Música 6"
                    name="sixth_song"
                    value={fixthSong}
                    options={songs}
                    onChange={event => setFixthSong(event.target.value)}
                  />

                  <ButtonSelectRemove
                    className="remove"
                    type="button"
                    onClick={() => setFixthSong('')}
                  >
                    X
                  </ButtonSelectRemove>
                </div>

                <div className="seventh_song">
                  <InputSelect
                    label="Música 7"
                    name="seventh_song"
                    value={seventhSong}
                    options={songs}
                    onChange={event => setSeventhSong(event.target.value)}
                  />

                  <ButtonSelectRemove
                    className="remove"
                    type="button"
                    onClick={() => setSeventhSong('')}
                  >
                    X
                  </ButtonSelectRemove>
                </div>

                <div className="eighth_song">
                  <InputSelect
                    label="Música 8"
                    name="eighth_song"
                    value={eighthSong}
                    options={songs}
                    onChange={event => setEighthSong(event.target.value)}
                  />

                  <ButtonSelectRemove
                    className="remove"
                    type="button"
                    onClick={() => setEighthSong('')}
                  >
                    X
                  </ButtonSelectRemove>
                </div>
              </div>
            )}

            <div className="group">
              <div className="note">
                <InputText
                  label="Ensaio"
                  name="note"
                  value={note}
                  onChange={event => setNote(event.target.value)}
                  multiline
                />
              </div>
            </div>

            <hr />

            <div className="buttons">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <CancelButton
                  type="button"
                  onClick={() => history.push('/scale/scale-list')}
                >
                  Cancelar
                </CancelButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
