import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 4rem;
  padding: 2rem;

  @media (min-width: 1180px) {
    margin-top: 7rem;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  max-width: 100rem;
  gap: 2rem;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const Button = styled.div`
  flex: 1 1 20rem;

  border-radius: 0.5rem;
  background: linear-gradient(
    145deg,
    var(--color-primary) 50%,
    var(--color-blue) 100%
  );
  box-shadow: rgb(12 12 14 / 60%) 0 0.5rem 2rem;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 4rem 0;

    strong {
      display: flex;
      justify-content: center;
      margin-bottom: 4rem;
      min-width: 20rem;

      font-size: var(--fontSizes-5xl);
    }

    span {
      margin-top: 2rem;
      opacity: var(--opacity-intense);

      font-size: var(--fontSizes-2xl);
    }
  }

  button {
    width: 75%;
  }
`;

export const Informative = styled.div`
  margin: 0 auto;
  max-width: 100rem;

  margin-bottom: 2rem;

  hr {
    border: 0;
    height: 0.1rem;
    background-color: var(--color-tertiary);

    margin-bottom: 2rem;
  }
`;

export const Notification = styled.div``;

export const Notice = styled.div`
  margin-bottom: 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  padding: 2rem;
  border: none;
  border-radius: 0.5rem;
  background: linear-gradient(
    145deg,
    var(--color-primary) 50%,
    ${props => (props.alert ? 'var(--color-orange)' : 'var(--color-blue)')} 100%
  );
  box-shadow: rgb(12 12 14 / 60%) 0 0.5rem 2rem;

  strong {
    color: var(--color-white);
    font-size: var(--fontSizes-5xl);
    font-style: italic;
  }

  span {
    color: var(--color-white);
    font-size: var(--fontSizes-5xl);
    font-weight: bold;
    font-style: italic;
  }
`;

export const ButtonInformative = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  padding: 2rem;
  border: 0.1rem solid var(--color-secondary);
  border-radius: 0.5rem;
  background: var(--color-primary);
  box-shadow: rgb(12 12 14 / 60%) 0 0.5rem 2rem;

  color: var(--color-width);
  font-size: var(--fontSizes-5xl);
  text-decoration: underline;

  &:hover {
    opacity: var(--opacity-semiOpaque);
  }
`;

export const Feedback = styled.div`
  form {
    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);

      margin: 0 0 2rem 0;
    }
  }

  @media (min-width: 720px) {
    form {
      div.buttons {
        display: flex;
        flex-direction: row-reverse;

        width: 100%;

        button {
          margin-top: 2rem;
          width: 15rem;
        }

        div.cancel {
          margin-right: 1rem;

          button {
            width: 12rem;
          }
        }
      }
    }
  }
`;

export const Description = styled.div`
  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: var(--fontSizes-2xl);

      color: var(--color-white);
      opacity: var(--opacity-intense);
    }

    strong {
      margin: 3rem 0;

      font-size: var(--fontSizes-5xl);
      text-align: center;

      color: var(--color-white);
      opacity: var(--opacity-semiOpaque);
    }

    span {
      padding: 0.5rem 0;

      font-size: var(--fontSizes-2xl);
      text-align: center;

      color: var(--color-white);
      opacity: var(--opacity-intense);
    }
  }

  hr {
    border: 0;
    height: 0.1rem;
    background-color: var(--color-tertiary);

    margin-bottom: 2rem;
  }
`;
