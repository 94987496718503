import produce from 'immer';

export default function trainingLesson(state = {}, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@training-lesson/READ_LESSON_REQUEST': {
        draft.trainingLessonId = action.payload.id;
        break;
      }
      case '@training-lesson/SELECTED_LESSON_REQUEST': {
        draft.trainingLessonSelectedId = action.payload.id;
        break;
      }
      default:
    }
  });
}
