import styled from 'styled-components';

export const Menu = styled.div`
  margin: 0.5rem 0.5rem 3rem 0.5rem;
  padding: 2rem;
  border-radius: 0.5rem;
  background: var(--color-primary);
  width: 100%;

  div.title {
    margin-bottom: 2rem;

    strong {
      color: var(--color-white);
      font-size: var(--fontSizes-4xl);
    }
  }

  div.progress {
    display: flex;
    align-items: center;

    margin-bottom: 3.9rem;

    span {
      font-size: var(--fontSizes-xl);
      font-weight: bold;
      opacity: var(--opacity-medium);
    }

    > div {
      flex: 1;
      margin: 0 0.8rem;
      border-radius: 0.5rem;
      background: var(--color-tertiary);
      height: 0.4rem;

      position: relative;

      div {
        border-radius: 0.5rem;
        background: var(--color-green);
        height: 0.4rem;
        transition: 500ms linear;
      }

      span {
        position: absolute;
        margin-top: 1rem;

        transition: 500ms linear;
        transform: translateX(-50%);

        font-size: var(--fontSizes-lg);
        font-weight: lighter;

        &::before {
          content: '';
          position: absolute;
          left: calc(50% - 0.375rem);
          top: -0.3rem;
          width: 0;
          height: 0;
          border-left: 0.3rem solid transparent;
          border-right: 0.3rem solid transparent;
          border-bottom: 0.3rem solid var(--color-white);
        }
      }
    }
  }

  ul {
    display: grid;
    gap: 1rem;

    p.empty {
      display: flex;
      justify-content: center;

      font-size: var(--fontSizes-xl);
      color: var(--color-white);
      opacity: var(--opacity-intense);
    }
  }

  div.button-link {
    display: flex;

    margin-top: 2rem;

    button {
      border: none;
      background: transparent;
    }

    button,
    a {
      text-decoration: underline;
      font-size: var(--fontSizes-2xl);

      color: var(--color-white);
      opacity: var(--opacity-medium);
    }

    &:hover {
      button,
      a {
        opacity: var(--opacity-default);
        color: var(--color-blue);
      }
    }
  }

  div.loading {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 1180px) {
    margin: 0 4.5rem 0 0;
    width: 23rem;
  }
`;
