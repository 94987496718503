import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import logo from '~/assets/logo.png';
import { signFailure, signInRequest } from '~/store/modules/auth/actions';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .required('O e-mail é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.auth.loading);

  const [secondsAmount, setSecondsAmount] = useState(5);

  useEffect(() => {
    function handleSignFailure() {
      dispatch(signFailure());
    }

    if (loading && secondsAmount > 0) {
      setTimeout(() => {
        setSecondsAmount(state => state - 1);
      }, 1000);
    } else {
      handleSignFailure();

      setSecondsAmount(5);
    }
  }, [dispatch, loading, secondsAmount]);

  let sBrowser;
  const sUsrAg = navigator.userAgent;

  if (sUsrAg.indexOf('MSIE') > -1) {
    sBrowser = 'Microsoft Internet Explorer';
  } else if (sUsrAg.indexOf('Edg') > -1) {
    sBrowser = 'Microsoft Edge';
  } else if (sUsrAg.indexOf('OPR') > -1) {
    sBrowser = 'Opera';
  } else if (sUsrAg.indexOf('Chrome') > -1) {
    sBrowser = 'Google Chrome';
  } else if (sUsrAg.indexOf('Safari') > -1) {
    sBrowser = 'Apple Safari';
  } else if (sUsrAg.indexOf('Firefox') > -1) {
    sBrowser = 'Mozilla Firefox';
  }

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password, sBrowser));
  }

  return (
    <>
      <div className="logo">
        <img src={logo} alt="Praise" />
      </div>

      <div className="signin">
        <div className="title">
          <img src={logo} alt="Praise" />
          <h1>PRAISE</h1>
        </div>

        <div className="form">
          <Form schema={schema} onSubmit={handleSubmit}>
            <h2>Acesse sua conta</h2>

            <Input name="email" type="email" placeholder="E-mail" />

            <Input name="password" type="password" placeholder="Senha" />

            <Link to="/forgot" target="_blank">
              Esqueci minha senha
            </Link>

            <button type="submit" disabled={loading}>
              {loading ? (
                <div>
                  <div>
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />

                    <p>{secondsAmount}</p>
                  </div>
                </div>
              ) : (
                'Entrar'
              )}
            </button>
          </Form>

          <Link to="/signup">Criar conta teste</Link>

          <span className="info">Recomendamos o Google Chrome</span>
        </div>
      </div>
    </>
  );
}
