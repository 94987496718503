import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'praise-church',
      storage,
      whitelist: [
        'auth',
        'user',
        'song',
        'block',
        'scale',
        'post',
        'scaleMinister',
        'scaleMedia',
        'trainingLesson',
        'prayer',
      ],
    },
    reducers
  );

  return persistedReducer;
};
