import React, { useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Section } from '~/components/Layout/Body';
import Dialog from '~/components/Layout/Dialog';
import Title from '~/components/Layout/Title';
import Birthday from '~/components/Pages/Admin/Birthday';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError } from '~/utils/toast';

export default function Birthdays({ open, handleClose }) {
  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [birthdays, setBirthdays] = useState([]);

  try {
    useEffect(() => {
      async function loadBirthdays() {
        const response = await api
          .get('/church/admin/birthdays')
          .catch(async res => {
            try {
              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setBirthdays(response.data);

        setLoading(false);
      }

      loadBirthdays();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      handleClose={handleClose}
      width={60}
    >
      <Title
        title="Aniversariantes"
        count={`${birthdays.length}`}
        style={{ marginTop: '-2.5rem' }}
      />

      <Section>
        {loading ? (
          <div className="loading">
            <CircularProgress style={{ color: 'var(--color-blue)' }} />
          </div>
        ) : (
          <ul>
            {birthdays.map(birthday => (
              <Birthday key={birthday.id} birthday={birthday} />
            ))}
            {birthdays.length === 0 && (
              <p className="empty">Nenhum aniversariante encontrado</p>
            )}
          </ul>
        )}
      </Section>

      {logout && <UserLogout message={message} />}
    </Dialog>
  );
}
