export function updateScaleRequest(id) {
  return {
    type: '@scale/UPDATE_SCALE_REQUEST',
    payload: { id },
  };
}

export function duplicateScaleRequest(id) {
  return {
    type: '@scale/DUPLICATE_SCALE_REQUEST',
    payload: { id },
  };
}
