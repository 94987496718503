import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import { ContainerTwo, Main, Nav, Section } from '~/components/Layout/Body';
import SubMenuButton from '~/components/Layout/Buttons/SubMenuButton';
import Title from '~/components/Layout/Title';
import Training from '~/components/Pages/Course/Training';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { openTrainingLessonRequest } from '~/store/modules/course/trainingLesson/actions';
import { ToastError } from '~/utils/toast';

import TrainingNew from '../TrainingNew';
import TrainingUpdate from '../TrainingUpdate';

export default function TrainingList() {
  const {
    churchPermissions: { training_new },
  } = useSelector(state => state.user.profile);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [openNew, setOpenNew] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const [trainings, setTrainings] = useState([]);
  const [trainingUpdate, setTrainingUpdate] = useState([]);

  try {
    useEffect(() => {
      async function loadTrainings() {
        const response = await api
          .get('/church/course/trainings-list')
          .catch(async res => {
            try {
              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Pro disabled') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setTrainings(response.data);

        setLoading(false);
      }

      loadTrainings();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  function handleNew() {
    setOpenNew(true);
  }

  function handleDataNew(data) {
    const addTraining = [...trainings, data];
    // eslint-disable-next-line func-names
    const trainingSort = addTraining.sort(function(initialOrden, finalOrden) {
      // eslint-disable-next-line no-nested-ternary
      return finalOrden.created_at > initialOrden.created_at
        ? 1
        : initialOrden.created_at > finalOrden.created_at
        ? -1
        : 0;
    });

    setTrainings(trainingSort);
  }

  async function handleUpdate(id) {
    const { data } = await api.get(`/church/course/trainings-show/${id}`);

    setTrainingUpdate(data);

    setOpenUpdate(true);
  }

  function handleDataUpdate(data) {
    const removeTrainingUpdate = trainings.filter(
      training => training.id !== data.id
    );

    const addTrainingUpdate = [...removeTrainingUpdate, data];

    // eslint-disable-next-line func-names
    const trainingSort = addTrainingUpdate.sort(function(
      initialOrden,
      finalOrden
    ) {
      // eslint-disable-next-line no-nested-ternary
      return finalOrden.created_at > initialOrden.created_at
        ? 1
        : initialOrden.created_at > finalOrden.created_at
        ? -1
        : 0;
    });

    setTrainings(trainingSort);
  }

  function handleDataDelete(data) {
    setTrainings(trainings.filter(training => training.id !== data.id));
  }

  function handleOpen(id) {
    dispatch(openTrainingLessonRequest(id));
  }

  function handleClose() {
    setOpenNew(false);
    setOpenUpdate(false);
  }

  return (
    <ContainerTwo>
      <Main>
        <Title title="Treinamentos" count={`${trainings.length}`} />

        <Section>
          <Nav>
            {training_new && (
              <div>
                <SubMenuButton type="button" onClick={handleNew}>
                  Adicionar
                </SubMenuButton>
              </div>
            )}
          </Nav>

          {openNew && (
            <TrainingNew
              open={openNew}
              handleClose={handleClose}
              handleDataNew={handleDataNew}
            />
          )}

          {openUpdate && (
            <TrainingUpdate
              open={openUpdate}
              handleClose={handleClose}
              handleDataUpdate={handleDataUpdate}
              trainingUpdate={trainingUpdate}
              handleDataDelete={handleDataDelete}
            />
          )}

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {trainings.map(training => (
                <Training
                  key={training.id}
                  training={training}
                  onOpen={() => handleOpen(training.id)}
                  onUpdate={() => handleUpdate(training.id)}
                />
              ))}
              {trainings.length === 0 && (
                <p className="empty">Nenhum treinamento encontrado</p>
              )}
            </ul>
          )}
        </Section>
      </Main>

      {logout && <UserLogout message={message} />}
    </ContainerTwo>
  );
}
