import styled from 'styled-components';

export const Content = styled.div`
  padding: 2rem;
  border-radius: 0.5rem;
  background: var(--color-primary);

  form {
    div.group {
      display: block;

      div.nameuser,
      div.updated {
        margin-left: 0;
      }

      div {
        width: 100%;
      }
    }

    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);

      margin: 0.5rem 0 2rem 0;
    }

    div.title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 2.4rem 0;

      height: 4.4rem;
      border-radius: 0.5rem;
      background: var(--color-secondary);
      color: var(--color-white);

      padding: 0 1rem;

      strong {
        font-size: var(--fontSizes-2xl);
      }
    }

    div.group-switch {
      display: flex;
      align-items: center;

      div {
        width: 100%;
      }

      div.permission {
        padding: 0.5rem 0 0 0;
      }
    }
  }

  @media (min-width: 720px) {
    form {
      div.group {
        display: flex;
        padding-bottom: 0;

        div.email {
          margin-bottom: 1rem;
        }

        div.name,
        div.created {
          margin-right: 1rem;
        }

        div.nameuser {
          width: 40%;
        }
      }

      div.buttons {
        display: flex;
        flex-direction: row-reverse;

        width: 100%;

        button {
          margin-top: 2rem;
          width: 15rem;
        }

        div.cancel {
          margin-right: 1rem;

          button {
            width: 12rem;
          }
        }
      }
    }
  }
`;

export const Label = styled.label``;
