import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Layout/Body';
import ButtonSelectRemove from '~/components/Layout/Buttons/ButtonSelectRemove';
import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputSelect from '~/components/Layout/Inputs/InputSelect';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Minister/Menu';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content } from './styles';

const schema = Yup.object().shape({
  description: Yup.string()
    .required('Descrição é obrigatória')
    .max(254, 'Descrição deve ter no máximo 254 caracteres'),
  available: Yup.date().required(),
  hour: Yup.string().required('Selecione o horário'),
  minister_one: Yup.string().required('Selecione um ministro'),
  minister_two: Yup.string(),
  theme: Yup.string().max(254, 'Tema deve ter no máximo 254 caracteres'),
  note: Yup.string().max(1200, 'Observação deve ter no máximo 1200 caracteres'),
});

export default function ScalesUpdate() {
  const {
    churchPermissions: { scale_minister_delete },
  } = useSelector(state => state.user.profile);

  const id = useSelector(state => state.scaleMinister.scaleMinisterId);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [scales, setScales] = useState([]);

  const [ministerList, setMinisterList] = useState([]);

  const [ministerOne, setMinisterOne] = useState();
  const [ministerTwo, setMinisterTwo] = useState();

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  try {
    useEffect(() => {
      async function loadScaleData() {
        const response = await api.get(`/church/admin/scales-show/${id}`);

        const {
          description,
          available,
          hour,
          minister_one,
          minister_two,
          theme,
          note,
        } = response.data;

        const parsedDate = parseISO(available);
        const formattedDate = format(parsedDate, 'yyyy-MM-dd');

        setScales({ description, available: formattedDate, hour, theme, note });

        setMinisterOne(minister_one);
        setMinisterTwo(minister_two || undefined);
      }

      loadScaleData();
    }, [id, timezone]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  try {
    useEffect(() => {
      async function loadMinisterList() {
        const { data } = await api.get('/church/admin/users-minister-word');

        setMinisterList(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadMinisterList();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os ministros' });
  }

  async function handleSubmit({
    description,
    available,
    hour,
    minister_one,
    minister_two,
    theme,
    note,
  }) {
    const timezoneDate = utcToZonedTime(available, timezone);

    try {
      setLoading(true);

      const data = {
        description,
        available: timezoneDate,
        hour,
        minister_one,
        minister_two,
        theme,
        note,
      };

      const dataResponse = await api
        .put(`/church/admin/scales-update/${id}`, data)
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Past dates are not permitted') {
              ToastError({
                title: 'Datas passadas não são permitidas',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      ToastSuccess({ title: 'Escala alterada' });

      history.push('/minister/scale/scales-list');
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao alterar, verifique os dados' });
    }
  }

  async function handleDelete() {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          await api.delete(`/church/admin/scales-delete/${id}`);

          Swal.fire('Excluída!', 'Escala excluída', 'success');

          history.push('/minister/scale/scales-list');
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Container>
      <Aside>
        <Menu scale="scale" />
      </Aside>

      <Main>
        <Title title="Alterar escala" />

        <Content>
          <Form schema={schema} initialData={scales} onSubmit={handleSubmit}>
            <div className="group">
              <div className="description">
                <InputText label="Descrição" name="description" />
              </div>

              <div className="date">
                <InputText
                  label="Data"
                  name="available"
                  type="date"
                  min="2021-01-01"
                  max="9999-12-31"
                  lang="pt-BR"
                  required
                />

                <InputText label="Hora" name="hour" type="time" />
              </div>
            </div>

            <div className="group">
              <div className="minister-one">
                <InputSelect
                  label="Voz principal 1"
                  name="minister_one"
                  value={ministerOne}
                  options={ministerList}
                  onChange={event => setMinisterOne(event.target.value)}
                />
              </div>

              <div className="minister-two">
                <InputSelect
                  label="Voz principal 2"
                  name="minister_two"
                  value={ministerTwo}
                  options={ministerList}
                  onChange={event => setMinisterTwo(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setMinisterTwo('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="theme">
                <InputText label="Tema" name="theme" />
              </div>
            </div>

            <div className="group">
              <div className="note">
                <InputText label="Obseverção" name="note" multiline />
              </div>
            </div>

            {scale_minister_delete && (
              <button
                type="button"
                className="delete"
                onClick={() => handleDelete()}
              >
                Excluir escala
              </button>
            )}

            <hr />

            <div className="buttons">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <CancelButton
                  type="button"
                  onClick={() => history.push('/minister/scale/scales-list')}
                >
                  Cancelar
                </CancelButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
