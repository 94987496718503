import React from 'react';
import { MdEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { formatDistance, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import photoDefault from '~/assets/photo-default.svg';

import { Container, Content, Header } from './styles';

export default function Prayer({
  prayer,
  onUpdate,
  onPrayer,
  onExpand,
  onHide,
}) {
  const { id } = useSelector(state => state.user.profile);

  const formattedDate = formatDistance(
    parseISO(prayer.created_at),
    new Date(),
    {
      addSuffix: true,
      locale: pt,
    }
  );

  return (
    <Container>
      <Header>
        <img src={prayer.user.photo ? prayer.user.url : photoDefault} alt="" />
      </Header>

      <Content>
        <div className="title">
          <strong>{prayer.title}</strong>

          <span>{prayer.user.nameuser ? prayer.user.nameuser : ''}</span>
        </div>

        {onPrayer === prayer.id && (
          <div>
            <pre>{prayer.description}</pre>
          </div>
        )}

        <div className="footer">
          <button
            type="button"
            className="expand"
            onClick={onPrayer === prayer.id ? onHide : onExpand}
          >
            {onPrayer === prayer.id ? '-- Ocultar --' : '-- Expandir --'}
          </button>

          <span>{formattedDate}</span>
        </div>
      </Content>

      <div className="button">
        {onPrayer === prayer.id && id === prayer.user_id && (
          <button type="button" onClick={onUpdate}>
            <MdEdit size={20} />
          </button>
        )}
      </div>
    </Container>
  );
}
