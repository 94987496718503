import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Form } from '@rocketseat/unform';
import { format, isBefore, parseISO } from 'date-fns';

import { Aside, Container, Main, Nav } from '~/components/Layout/Body';
import SubMenuButton from '~/components/Layout/Buttons/SubMenuButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import UserLogout from '~/components/UserLogout';
import Pro from '~/pages/Admin/Account/Church/Pro';
import Menu from '~/pages/Admin/Menu';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';
import { ToastError } from '~/utils/toast';

import ChurchDiceDialog from '../ChurchUpdate';
import { Content } from './styles';

export default function ChurchDice() {
  const {
    praise_department,
    pro,
    churchPermissions: {
      account_update,
      account_list,
      user_update,
      song_update,
    },
  } = useSelector(state => state.user.profile);

  const history = useHistory();

  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [openUpdate, setOpenUpdate] = useState(false);

  const [church, setChurch] = useState([]);
  const [churchUpdate, setChurchUpdate] = useState([]);

  const [date, setDate] = useState();

  const [availables, setAvailables] = useState();
  const [updatedDate, setUpdatedDate] = useState();

  const price = formatPrice(church.people * church.price);

  const formattedNewDate = format(new Date(), 'yyyy-MM-dd');

  const result = isBefore(new Date(date), new Date(formattedNewDate));

  try {
    useEffect(() => {
      async function loadChurchs() {
        const response = await api
          .get('/church/admin/account')
          .catch(async res => {
            try {
              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });
            }
          });

        if (response === undefined) {
          return;
        }

        setChurch(response.data);

        const available = parseISO(response.data.available);
        const updated = parseISO(response.data.updated_at);

        const formattedDate = format(available, 'yyyy-MM-dd');

        const formattedDateAvailable = format(available, 'dd/MM/yyyy');
        const formattedDateUpdate = format(updated, 'dd/MM/yyyy');

        setDate(formattedDate);
        setAvailables(formattedDateAvailable);
        setUpdatedDate(formattedDateUpdate);
      }

      loadChurchs();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleUpdate(id) {
    const { data } = await api.get(`/church/admin/account/${id}`);

    setChurchUpdate(data);

    setOpenUpdate(true);
  }

  function handleDataUpdate(data) {
    setChurch(data);
  }

  function handleClose() {
    setOpenUpdate(false);
  }

  return (
    <Container>
      <Aside>
        <Menu church="church" />
      </Aside>

      <Main>
        <Content pro={pro}>
          <div className="title">
            <strong>Conta da igreja</strong>
            <span>{pro ? 'Pro' : 'Basic'}</span>
          </div>

          <Nav>
            <div>
              {account_list && (
                <SubMenuButton
                  focus
                  type="button"
                  onClick={() =>
                    history.push('/admin/account/church/church-dice')
                  }
                >
                  Conta
                </SubMenuButton>
              )}

              {user_update && (
                <SubMenuButton
                  type="button"
                  onClick={() =>
                    history.push('/admin/account/archiveds/users-archived')
                  }
                >
                  Membros
                </SubMenuButton>
              )}

              {pro && praise_department && song_update && (
                <SubMenuButton
                  type="button"
                  onClick={() =>
                    history.push('/admin/account/archiveds/songs-archived')
                  }
                >
                  Músicas
                </SubMenuButton>
              )}
            </div>
          </Nav>

          {openUpdate && (
            <ChurchDiceDialog
              open={openUpdate}
              handleClose={handleClose}
              handleDataUpdate={handleDataUpdate}
              churchUpdate={churchUpdate}
            />
          )}

          <Form initialData={church}>
            <div>
              <div className="dice">
                <strong>Nome: </strong>
                <span>{church.name}</span>
                <hr />
              </div>

              <div className="dice">
                <strong>CNPJ: </strong>
                <span>{church.document}</span>
                <hr />
              </div>

              <div className="dice">
                <strong>Conta: </strong>
                <span>{church.nickname}</span>
                <hr />
              </div>

              <div className="dice">
                <strong>Responsável: </strong>
                <span>{church.user && church.user.name}</span>
                <hr />
              </div>

              <div className="controlled">
                <strong>Limite de pessoas: </strong>
                <span>{church.people}</span>
                <hr />
              </div>

              <div className="controlled">
                <strong>Valor: </strong>
                <span>{price}</span>
                <hr />
              </div>

              <div className="controlled">
                <strong>Vencimento: </strong>
                {!result ? (
                  <span>{availables}</span>
                ) : (
                  <span style={{ color: 'var(--color-red)' }}>
                    {availables}
                  </span>
                )}

                <hr />
              </div>

              <div className="controlled">
                <strong>Última alteração: </strong>
                <span>{updatedDate}</span>
                <hr />
              </div>

              {account_update && (
                <div className="submit">
                  <SubmitButton
                    type="button"
                    onClick={() => handleUpdate(church.id)}
                  >
                    Alterar
                  </SubmitButton>
                </div>
              )}
            </div>
          </Form>
        </Content>

        <Pro />
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
