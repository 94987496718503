import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import axios from 'axios';
import * as Yup from 'yup';

import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import Dialog from '~/components/Layout/Dialog';
import InputSelect from '~/components/Layout/Inputs/InputSelect';
import InputText from '~/components/Layout/Inputs/InputText';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { cep } from '~/utils/inputMask';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content } from './styles';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(10, 'Nome deve ter no mínimo 10 caracteres')
    .max(100, 'Nome deve ter no máximo 100 caracteres'),
  nickname: Yup.string()
    .min(3, 'Nome deve ter no mínimo 3 caracteres')
    .max(20, 'Nome deve ter no máximo 20 caracteres'),
  zip_code: Yup.string(),
  number: Yup.string(),
  complement: Yup.string(),
  responsible: Yup.string().required('Selecione um responsável'),
});

export default function ChurchUpdate({
  open,
  handleClose,
  handleDataUpdate,
  churchUpdate,
}) {
  const { pro } = useSelector(state => state.user.profile);

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [nameChurch, setNameChurch] = useState([]);
  const [zip_code, setZip_code] = useState([]);
  const [street, setStreet] = useState([]);
  const [district, setDistrict] = useState([]);
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);

  const [users, setUsers] = useState([]);
  const [id_user, setId_user] = useState();

  try {
    useEffect(() => {
      async function loadChurchs() {
        const response = await churchUpdate;

        const { name, nickname, number, complement } = response;

        setNameChurch({ name, nickname, number, complement });
        setZip_code(response.zip_code === null ? '' : response.zip_code);
        setId_user(response.responsible);

        setLoading(false);
      }

      loadChurchs();
    }, [churchUpdate]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  useEffect(() => {
    async function cepUser() {
      if (zip_code.length === 9) {
        const response = await axios.get(
          `https://viacep.com.br/ws/${zip_code.replace(/\D/g, '')}/json/`
        );

        const { logradouro, bairro, localidade, uf } = response.data;

        setStreet(logradouro);
        setDistrict(bairro);
        setCity(localidade);
        setState(uf);
      }
    }

    cepUser();
  }, [zip_code]);

  try {
    useEffect(() => {
      async function loadUsers() {
        const { data } = await api.get('/church/admin/users-active');

        setUsers(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadUsers();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os membros' });
  }

  async function handleSubmit({
    name,
    nickname,
    number,
    complement,
    responsible,
  }) {
    const nicknameTrim = nickname.trim();

    try {
      setLoading(true);

      const dataResponse = await api
        .put(`/church/admin/account/${churchUpdate.id}`, {
          name,
          nickname: nicknameTrim,
          zip_code,
          number,
          complement,
          responsible,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Nickname already exists') {
              ToastError('Conta já existe');

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      handleDataUpdate({
        id: churchUpdate.id,
        name,
        nickname: nicknameTrim,
        document: churchUpdate.document,
        people: churchUpdate.people,
        price: churchUpdate.price,
        available: churchUpdate.available,
        updated_at: churchUpdate.updated_at,
        zip_code,
        number,
        complement,
        responsible,
      });
      handleClose();

      ToastSuccess({ title: 'Conta alterada' });
    } catch (error) {
      ToastError({ title: 'Erro ao alterar, verifique os dados' });
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      width={60}
      handleClose={handleClose}
    >
      <Content pro={pro}>
        <div className="title">
          <strong>Alterar conta da igreja</strong>
          <span>{pro ? 'Pro' : 'Basic'}</span>
        </div>

        <Form schema={schema} initialData={nameChurch} onSubmit={handleSubmit}>
          <div className="group">
            <div className="name">
              <InputText label="Razão social" name="name" />
            </div>

            <div className="nickname">
              <InputText label="Conta" name="nickname" />
            </div>
          </div>

          <div className="group">
            <div className="zip_code">
              <InputText
                label="CEP"
                name="zip_code"
                value={zip_code}
                onChange={event => setZip_code(cep(event.target.value))}
              />
            </div>

            <div className="street">
              <InputText
                label="Rua"
                title="Rua preenchida automaticamente de acordo com o CEP"
                name="street"
                value={street}
                disabled
              />
            </div>
          </div>

          <div className="group">
            <div className="number">
              <InputText label="Número" name="number" type="number" />
            </div>

            <div className="complement">
              <InputText label="Complemento" name="complement" />
            </div>
          </div>

          <div className="group">
            <div className="district">
              <InputText
                label="Bairro"
                title="Bairro preenchido automaticamente de acordo com o CEP"
                name="district"
                value={district}
                disabled
              />
            </div>

            <div className="city">
              <InputText
                label="Cidade"
                title="Cidade preenchida automaticamente de acordo com o CEP"
                name="city"
                value={city}
                disabled
              />
            </div>

            <div className="state">
              <InputText
                label="UF"
                title="UF preenchido automaticamente de acordo com o CEP"
                name="state"
                value={state}
                disabled
              />
            </div>
          </div>

          <div className="group">
            <div className="responsible">
              <InputSelect
                label="Responsável"
                name="responsible"
                value={id_user}
                options={users}
                onChange={event => setId_user(event.target.value)}
              />
            </div>
          </div>

          <hr />

          <div className="buttons">
            <div>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress
                    size={24}
                    style={{ color: 'var(--color-white)' }}
                  />
                ) : (
                  'Salvar'
                )}
              </SubmitButton>
            </div>

            <div className="cancel">
              <CancelButton type="button" onClick={handleClose}>
                Cancelar
              </CancelButton>
            </div>
          </div>
        </Form>
      </Content>
      {logout && <UserLogout message={message} />}
    </Dialog>
  );
}
