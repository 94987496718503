import React from 'react';
import { MdArchive, MdMusicNote } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { Container, Content, Header } from './styles';

export default function SongArchived({ song, onUnarchive }) {
  const {
    churchPermissions: { song_update },
  } = useSelector(state => state.user.profile);

  return (
    <Container>
      <Content>
        <Header shepherd={song.shepherd}>
          <MdMusicNote size={20} color="var(--color-red)" />

          <div>
            <strong>{song.name}</strong>
            <strong className="tone">{song.tone}</strong>
            <span>{song.author}</span>
          </div>
        </Header>

        <div>
          {song_update && (
            <button
              title="Desarquivar música"
              type="button"
              onClick={onUnarchive}
            >
              <MdArchive size={20} color="var(--color-blue)" />
            </button>
          )}
        </div>
      </Content>
    </Container>
  );
}
