import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Layout/Body';
import ButtonSelectRemove from '~/components/Layout/Buttons/ButtonSelectRemove';
import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputSelect from '~/components/Layout/Inputs/InputSelect';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Media/Menu';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content } from './styles';

const schema = Yup.object().shape({
  description: Yup.string()
    .required('Descrição é obrigatória')
    .max(254, 'Descrição deve ter no máximo 254 caracteres'),
  available: Yup.date().required(),
  hour: Yup.string(),
  projection: Yup.string(),
  streaming: Yup.string(),
  footage: Yup.string(),
  photography: Yup.string(),
  stories: Yup.string(),
  note: Yup.string().max(254, 'Tema deve ter no máximo 254 caracteres'),
});

export default function ScalesUpdate() {
  const {
    churchPermissions: { scale_media_delete },
  } = useSelector(state => state.user.profile);

  const id = useSelector(state => state.scaleMedia.scaleMediaId);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [scales, setScales] = useState([]);

  const [projections, setProjections] = useState([]);
  const [streamings, setStreamings] = useState([]);
  const [footages, setFootages] = useState([]);
  const [photographys, setPhotographys] = useState([]);
  const [storiess, setStoriess] = useState([]);

  const [projectionId, setProjectionId] = useState();
  const [streamingId, setStreamingId] = useState();
  const [footageId, setFootageId] = useState();
  const [photographyId, setPhotographyId] = useState();
  const [storiesId, setStoriesId] = useState();

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  try {
    useEffect(() => {
      async function loadScaleData() {
        const response = await api.get(`/church/media/scales-show/${id}`);

        const {
          description,
          available,
          hour,
          projection,
          streaming,
          footage,
          photography,
          stories,
          note,
        } = response.data;

        const parsedDate = parseISO(available);
        const formattedDate = format(parsedDate, 'yyyy-MM-dd');

        setScales({
          description,
          available: formattedDate,
          hour,
          note,
        });

        setProjectionId(projection || undefined);
        setStreamingId(streaming || undefined);
        setFootageId(footage || undefined);
        setPhotographyId(photography || undefined);
        setStoriesId(stories || undefined);
      }

      loadScaleData();
    }, [id, timezone]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  try {
    useEffect(() => {
      async function loadProjections() {
        const { data } = await api.get('/church/admin/users-projection');

        setProjections(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadProjections();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os usuários da projeção' });
  }

  try {
    useEffect(() => {
      async function loadStreamings() {
        const { data } = await api.get('/church/admin/users-streaming');

        setStreamings(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadStreamings();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os usuários da transmissão' });
  }

  try {
    useEffect(() => {
      async function loadFootages() {
        const { data } = await api.get('/church/admin/users-footage');

        setFootages(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadFootages();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os usuários da filmagem' });
  }

  try {
    useEffect(() => {
      async function loadPhotographys() {
        const { data } = await api.get('/church/admin/users-photography');

        setPhotographys(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadPhotographys();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os usuários da fotografia' });
  }

  try {
    useEffect(() => {
      async function loadStoriess() {
        const { data } = await api.get('/church/admin/users-storie');

        setStoriess(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadStoriess();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os usuários do stories' });
  }

  async function handleSubmit({
    description,
    available,
    hour,
    projection,
    streaming,
    footage,
    photography,
    stories,
    note,
  }) {
    const timezoneDate = utcToZonedTime(available, timezone);

    try {
      setLoading(true);

      const data = {
        description,
        available: timezoneDate,
        hour,
        projection,
        streaming,
        footage,
        photography,
        stories,
        note,
      };

      const dataResponse = await api
        .put(`/church/media/scales-update/${id}`, data)
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Past dates are not permitted') {
              ToastError({
                title: 'Datas passadas não são permitidas',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      ToastSuccess({ title: 'Escala alterada' });

      history.push('/media/scale/scales-list');
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao alterar, verifique os dados' });
    }
  }

  async function handleDelete() {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          await api.delete(`/church/media/scales-delete/${id}`);

          Swal.fire('Excluída!', 'Escala excluída', 'success');

          history.push('/media/scale/scales-list');
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Container>
      <Aside>
        <Menu scale="scale" />
      </Aside>

      <Main>
        <Title title="Alterar escala" />

        <Content>
          <Form schema={schema} initialData={scales} onSubmit={handleSubmit}>
            <div className="group">
              <div className="description">
                <InputText label="Descrição" name="description" />
              </div>

              <div className="date">
                <InputText
                  label="Data"
                  name="available"
                  type="date"
                  min="2021-01-01"
                  max="9999-12-31"
                  lang="pt-BR"
                  required
                />

                <InputText label="Hora" name="hour" type="time" />
              </div>
            </div>

            <div className="group">
              <div className="projection">
                <InputSelect
                  label="Projeção"
                  name="projection"
                  value={projectionId}
                  options={projections}
                  onChange={event => setProjectionId(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setProjectionId('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="streaming">
                <InputSelect
                  label="Transmissão"
                  name="streaming"
                  value={streamingId}
                  options={streamings}
                  onChange={event => setStreamingId(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setStreamingId('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="footage">
                <InputSelect
                  label="Filmagem"
                  name="footage"
                  value={footageId}
                  options={footages}
                  onChange={event => setFootageId(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setFootageId('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="photography">
                <InputSelect
                  label="Fotografia"
                  name="photography"
                  value={photographyId}
                  options={photographys}
                  onChange={event => setPhotographyId(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setPhotographyId('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="stories">
                <InputSelect
                  label="Stories"
                  name="stories"
                  value={storiesId}
                  options={storiess}
                  onChange={event => setStoriesId(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setStoriesId('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="note">
                <InputText label="Obseverção" name="note" multiline />
              </div>
            </div>

            {scale_media_delete && (
              <button
                type="button"
                className="delete"
                onClick={() => handleDelete()}
              >
                Excluir escala
              </button>
            )}

            <hr />

            <div className="buttons">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <CancelButton
                  type="button"
                  onClick={() => history.push('/media/scale/scales-list')}
                >
                  Cancelar
                </CancelButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
