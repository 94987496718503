import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.5rem;
  background: var(--color-primary);
  border-left: 0.1rem solid var(--color-white);

  div.button {
    display: flex;
    align-items: center;

    width: 3.5rem;

    button {
      border: none;
      background: transparent;
      color: var(--color-white);

      &:hover {
        opacity: var(--opacity-medium);
      }
    }
  }
`;

export const Content = styled.div`
  padding: 1.5rem 1rem 1.5rem 0;

  display: flex;
  flex-direction: column;

  width: 100%;

  div.title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: var(--fontSizes-5xl);
    color: var(--color-white);

    span {
      padding: 0.5rem;

      border-radius: 1rem;
      background: var(--color-yellow);

      font-size: var(--fontSizes-xl);
      color: var(--color-primary);
    }
  }

  div {
    pre {
      margin-top: 2rem;

      font-size: var(--fontSizes-2xl);
      font-family: 'Roboto', sans-serif;
      text-align: justify;
      line-height: 3rem;
      opacity: var(--opacity-semiOpaque);

      white-space: pre-wrap;
      word-break: break-word;
    }

    strong,
    span,
    p,
    a,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    pre,
    code,
    small {
      -webkit-touch-callout: inherit;
      -webkit-user-select: text;
      -moz-user-select: text;
      -ms-user-select: text;
      user-select: text;
    }
  }

  div.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: 2rem;

    button.expand {
      font-size: var(--fontSizes-lg);

      border: none;
      background: transparent;
      color: var(--color-white);

      &:hover {
        opacity: var(--opacity-medium);
      }
    }

    span {
      font-size: var(--fontSizes-lg);
      color: var(--color-white);
      opacity: var(--opacity-medium);
    }
  }
`;

export const Header = styled.div`
  padding: 1.5rem;

  img {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    border: 0.2rem solid var(--color-white);
    background: var(--color-secondary);

    object-fit: cover;
  }
`;
