import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border-left: 0.1rem solid var(--color-green);
  background: var(--color-primary);

  &:hover {
    opacity: var(--opacity-semiOpaque);
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    span {
      font-size: var(--fontSizes-lg);
      opacity: var(--opacity-medium);
      color: var(--color-white);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-right: 1rem;

    img {
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 50%;
      border: 0.2rem solid var(--color-white);
      background: var(--color-secondary);

      object-fit: cover;
    }
  }

  div.group {
    display: block;

    div {
      display: flex;
    }

    strong {
      max-width: 10ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    p {
      display: none;
    }
  }

  span.browser {
    margin: 0 1rem 0 0;
    padding: 0 1rem;
    border-left: 0.1rem solid var(--color-tertiary);
    border-right: 0.1rem solid var(--color-tertiary);

    font-size: var(--fontSizes-2xl);
    opacity: var(--opacity-default);
  }

  @media (min-width: 720px) {
    div.group {
      width: 30rem;

      strong,
      p {
        max-width: 50ch;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      p {
        display: unset;

        font-size: var(--fontSizes-lg);
        color: var(--color-white);
        opacity: var(--opacity-medium);
      }
    }

    span.browser {
      margin-left: 1rem;
      padding: 0 2rem;
    }
  }
`;
