import React from 'react';
import { MdAnalytics, MdLooks6, MdLooksOne } from 'react-icons/md';
import { useSelector } from 'react-redux';

import MenuButton from '~/components/Layout/Buttons/MenuButton';

import { Content } from './styles';

export default function Menu({ month, lastSixMonths, oneYear }) {
  const {
    churchPermissions: { presence_list },
  } = useSelector(state => state.user.profile);

  return (
    <Content>
      <div>
        {presence_list && (
          <MenuButton to="/dashboard-public/month" focus={month}>
            <MdLooksOne size={18} /> 1 mês
          </MenuButton>
        )}

        {presence_list && (
          <MenuButton
            to="/dashboard-public/last-six-months"
            focus={lastSixMonths}
          >
            <MdLooks6 size={18} /> 6 meses
          </MenuButton>
        )}

        {presence_list && (
          <MenuButton to="/dashboard-public/one-year" focus={oneYear}>
            <MdAnalytics size={18} /> 12 meses
          </MenuButton>
        )}
      </div>
    </Content>
  );
}
