import { all, call, put, takeLatest } from 'redux-saga/effects';

import api from '~/services/api';
import history from '~/services/history';
import { ToastError, ToastInfo } from '~/utils/toast';

import { signFailure, signInSuccess } from './actions';

export function* signIn({ payload }) {
  try {
    const { email, password, browser } = payload;

    const response = yield call(api.post, '/church/sessions', {
      email,
      password,
      browser,
    });

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));

    history.push('/dashboard');
  } catch (error) {
    if (error.response.data.error === 'Invalid credentials') {
      ToastError({
        title: 'E-mail ou senha inválidos',
      });

      yield put(signFailure());
    }
  }
}

export function* signInAccount({ payload }) {
  try {
    const { nickname: nicknameTrim } = payload;
    const nickname = nicknameTrim.trim();

    const response = yield call(api.post, '/church/session-account', {
      nickname,
    });

    const { token, user } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user));

    history.push('/dashboard');

    ToastInfo({ title: `Mudou para ${nickname}` });
  } catch (error) {
    if (error.response.data.error === 'Validation fails') {
      ToastError({
        title: 'Erro na autenticação, confira seus dados',
      });

      yield put(signFailure());
    }

    if (
      error.response.data.error ===
      'User does not have permission to access another account'
    ) {
      ToastError({
        title: 'Você não tem permissão para acessar outra conta',
      });

      yield put(signFailure());
    }

    if (error.response.data.error === 'Tenant does not exist') {
      ToastError({
        title: 'Conta não encontrada',
      });

      yield put(signFailure());
    }

    if (error.response.data.error === 'Tenant disabled') {
      ToastError({
        title: 'Conta expirou',
      });

      yield put(signFailure());
    }
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_REQUEST_ACCOUNT', signInAccount),
  takeLatest('@auth/SIGN_OUT', signOut),
]);
