import styled from 'styled-components';

export const Button = styled.button`
  width: 10%;
  height: 4.4rem;
  padding: 0 1rem;
  border-radius: 0.5rem;
  border: 0.2rem solid var(--color-background);
  background: var(--color-background);
  color: var(--color-red);

  margin-top: 0.8rem;
  margin-left: 1rem;
  &:hover {
    opacity: var(--opacity-intense);
  }
`;
