import styled from 'styled-components';

export const Content = styled.div`
  padding: 2rem;
  border-radius: 0.5rem;

  background: var(--color-primary);

  strong {
    font-size: var(--fontSizes-6xl);
  }

  div {
    margin-top: 4rem;

    p {
      color: var(--color-white);
      font-size: var(--fontSizes-2xl);

      margin-bottom: 2rem;

      text-align: justify;
      text-indent: 0.7cm;
      line-height: 150%;
    }

    strong {
      font-size: var(--fontSizes-2xl);
      text-decoration: underline;

      color: var(--color-blue);
    }

    strong.pro {
      color: var(--color-orange);
    }

    span {
      font-size: var(--fontSizes-2xl);
      font-weight: bold;
    }
  }

  hr {
    border: 0;
    height: 0.1rem;
    background-color: var(--color-tertiary);
  }

  span {
    font-size: var(--fontSizes-2xl);

    a {
      color: var(--color-orange);
      font-size: var(--fontSizes-2xl);

      font-style: italic;
      font-weight: bold;
      text-decoration: underline;

      &:hover {
        opacity: var(--opacity-semiOpaque);
      }
    }
  }
`;

export const Table = styled.table`
  margin-bottom: 2rem;

  width: 100%;

  thead {
    th {
      padding: 1.2rem;

      font-size: var(--fontSizes-2xl);
      text-align: left;
      color: var(--color-blue);
    }

    th.pro {
      color: var(--color-orange);
    }
  }

  tbody td {
    padding: 1.2rem;
    border-bottom: 0.1rem solid var(--color-tertiary);

    font-size: var(--fontSizes-2xl);
    opacity: var(--opacity-intense);
  }
`;
