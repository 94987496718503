import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Title = styled.div`
  margin: 0.5rem 1.5rem;
  padding-bottom: 1rem;

  strong {
    font-size: var(--fontSizes-4xl);
  }
`;

export const Badge = styled.button`
  background: none;
  border: 0;
  position: relative;
  color: var(--color-white);
  opacity: var(--opacity-semiOpaque);
  &:hover {
    opacity: var(--opacity-default);
    color: var(--color-blue);

    span {
      color: var(--color-white);
    }
  }

  margin-right: 1rem;

  ${props =>
    props.press &&
    css`
      content: '';
      color: var(--color-blue);

      span {
        color: var(--color-white);
        font-weight: bold;
      }
    `}

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;
    left: 1.75rem;
    bottom: 1.75rem;

    padding: 0.1rem 0.2rem;

    border-radius: 0.5rem;
    background: var(--color-red);

    span {
      font-size: var(--fontSizes-lg);
    }
  }
`;

export const NotificationList = styled.div`
  position: absolute;
  width: 30rem;
  left: calc(50% - 27rem);
  top: calc(100% + 2.8rem);
  background: var(--color-primary);
  box-shadow: 0 0 1em 0.1rem black;
  border-radius: 0.5rem;
  padding: 1.5rem 0.5rem;
  z-index: 2;
  display: ${props => (props.visible ? 'block' : 'none')};

  @media (max-width: 720px) {
    left: calc(50% - 22.3rem);
  }

  &::before {
    content: '';
    position: absolute;
    left: calc(88.7% - 1.5rem);
    top: -1rem;
    width: 0;
    height: 0;
    border-left: 1.5rem solid transparent;
    border-right: 1.5rem solid transparent;
    border-bottom: 1.5rem solid var(--color-primary);

    @media (max-width: 720px) {
      left: calc(73% - 1.5rem);
    }
  }
`;

export const Scroll = styled.div`
  flex-wrap: nowrap;
  overflow-x: scroll;
  max-height: 30rem;
  padding: 0 1.5rem;
`;

export const Notification = styled.div`
  color: var(--color-white);

  & + div {
    margin-top: 0.5rem;
    padding-top: 0.5rem;
    border-top: 0.1rem solid var(--color-tertiary);
  }

  strong {
    font-size: var(--fontSizes-xl);
  }

  p {
    font-size: var(--fontSizes-xl);
    line-height: 1.8rem;

    span {
      font-size: var(--fontSizes-xl);
      font-weight: bold;
      text-decoration: underline;
    }
  }

  time {
    display: block;
    font-size: var(--fontSizes-lg);
    opacity: var(--opacity-intense);
    margin: 0.5rem 0;
  }

  button {
    font-size: var(--fontSizes-lg);
    border: 0;
    background: none;
    color: var(--color-blue);

    &:hover {
      opacity: var(--opacity-intense);
    }
  }

  ${props =>
    props.unread &&
    css`
      &::after {
        content: '';
        display: inline-block;
        width: 0.6rem;
        height: 0.6rem;
        background: var(--color-red);
        border-radius: 50%;
        margin-left: 1rem;
      }
    `}
`;

export const Empty = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  strong {
    font-size: var(--fontSizes-2xl);
    margin-bottom: 0.5rem;
  }

  p {
    font-size: var(--fontSizes-xl);
    line-height: 1.8rem;
    opacity: var(--opacity-intense);
    color: var(--color-white);
  }
`;

export const Overlay = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
