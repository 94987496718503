import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Switch from 'react-switch';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import Dialog from '~/components/Layout/Dialog';
import InputText from '~/components/Layout/Inputs/InputText';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import SongDuplicate from '../SongDuplicate';
import { Content, Label } from '../styles';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Nome deve ter no mínimo 3 caracteres')
    .max(100, 'Nome deve ter no máximo 100 caracteres'),
  author: Yup.string()
    .min(3, 'Autor deve ter no mínimo 3 caracteres')
    .max(100, 'Autor deve ter no máximo 100 caracteres'),
  letter: Yup.string()
    .min(10, 'Letra deve ter no mínimo 10 caracteres')
    .max(10000, 'Letra deve ter no máximo 10000 caracteres'),
  tone: Yup.string()
    .min(1, 'Tom deve ter no mínimo 1 caracteres')
    .max(20, 'Tom deve ter no máximo 20 caracteres'),
  link: Yup.string().max(254, 'URL YouTube deve ter no máximo 254 caracteres'),
  active: Yup.boolean(),
  archived: Yup.boolean(),
});

export default function SongUpdate({
  open,
  handleClose,
  handleDataUpdate,
  songUpdate,
  handleDataDelete,
  handleDataDuplicate,
}) {
  const {
    churchPermissions: { song_delete, song_new },
  } = useSelector(state => state.user.profile);

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [openDuplicate, setOpenDuplicate] = useState(false);

  const [songs, setSongs] = useState([]);
  const [letter, setLetter] = useState();
  const [shepherd, setShepherd] = useState(false);
  const [active, setActive] = useState(false);
  const [archived, setArchived] = useState(false);

  const [errorInput, setErrorInput] = useState(false);

  try {
    useEffect(() => {
      async function loadSongData() {
        const data = await songUpdate;

        setSongs(data);
        setLetter(data.letter);
        setShepherd(data.shepherd);
        setActive(data.active);
        setArchived(data.archived);
      }

      loadSongData();
    }, [songUpdate]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  function handleActive() {
    if (active) {
      setActive(false);
    } else {
      setActive(true);
      setArchived(false);
    }
  }

  function handleArchived() {
    if (archived === true) {
      setArchived(false);
    } else {
      setArchived(true);
      setActive(false);
    }
  }

  async function handleSubmit({ name, author, tone, link }) {
    try {
      setLoading(true);

      const dataResponse = await api
        .put(`/church/praise/songs-update/${songUpdate.id}`, {
          name,
          author,
          letter,
          tone,
          link,
          shepherd,
          active,
          archived,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Name already exists') {
              ToastError({ title: 'Nome de musíca já existe' });

              setLoading(false);
              setErrorInput(true);

              setTimeout(() => setErrorInput(false), 5000);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      handleDataUpdate({
        id: songUpdate.id,
        name,
        author,
        letter,
        tone,
        link,
        shepherd,
        active,
        archived,
      });
      handleClose();

      ToastSuccess({ title: 'Música alterada' });
    } catch (error) {
      ToastError({ title: 'Erro ao alterar, verifique os dados' });

      setLoading(false);
    }
  }

  async function handleDelete() {
    handleClose();

    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          await api.delete(`/church/praise/songs-delete/${songUpdate.id}`);

          Swal.fire('Excluída!', 'Musíca excluída', 'success');

          handleDataDelete({ id: songUpdate.id });
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  async function handleDuplicate() {
    setOpenDuplicate(true);
  }

  async function handleSongDuplicate(data) {
    handleDataDuplicate(data);
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        width={60}
        title="Alterar música"
        handleClose={handleClose}
      >
        <Content>
          <Form schema={schema} initialData={songs} onSubmit={handleSubmit}>
            <div className="group">
              <div className="name">
                <InputText label="Nome" name="name" />

                {errorInput && <p>Nome já existe</p>}
              </div>

              <div className="author">
                <InputText label="Autor" name="author" />
              </div>
            </div>

            <div className="group">
              <div className="tone">
                <InputText label="Tom" name="tone" />
              </div>

              <div>
                <InputText label="URL YouTube" name="link" />
              </div>
            </div>

            <div className="group">
              <div>
                <InputText
                  label="Letra"
                  name="letter"
                  value={letter}
                  onChange={event => setLetter(event.target.value)}
                  multiline
                />
              </div>
            </div>

            <div className="group-switch">
              <div>
                <Label>Pastor</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={() => setShepherd(!shepherd)}
                    checked={shepherd}
                  />
                </div>
              </div>

              <div>
                <Label>Ativa</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={handleActive}
                    checked={active}
                  />
                </div>
              </div>

              <div>
                <Label>Arquivada</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={handleArchived}
                    checked={archived}
                  />
                </div>
              </div>
            </div>

            <div className="group-buttons">
              {song_delete && (
                <button
                  type="button"
                  className="delete"
                  onClick={() => handleDelete()}
                >
                  Excluir música
                </button>
              )}

              {song_new && (
                <button
                  type="button"
                  className="duplicate"
                  onClick={handleDuplicate}
                >
                  Duplicar música
                </button>
              )}
            </div>

            <hr />

            <div className="buttons">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <CancelButton type="button" onClick={handleClose}>
                  Cancelar
                </CancelButton>
              </div>
            </div>
          </Form>
        </Content>

        {logout && <UserLogout message={message} />}
      </Dialog>

      {openDuplicate && (
        <SongDuplicate
          open={openDuplicate}
          handleClose={handleClose}
          songUpdate={songUpdate}
          handleSongDuplicate={handleSongDuplicate}
        />
      )}
    </>
  );
}
