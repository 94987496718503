import React, { useState } from 'react';
import {
  MdCheckCircleOutline,
  MdOutlineCircle,
  MdRemove,
} from 'react-icons/md';
import { useSelector } from 'react-redux';

import { format, parseISO } from 'date-fns';

import photoDefault from '~/assets/photo-default.svg';
import api from '~/services/api';

import { Container, Content, Header } from './styles';

export default function TrainingUser({ user, onDelete }) {
  const {
    id,
    churchPermissions: { training_delete, training_update },
  } = useSelector(state => state.user.profile);

  const formattedDate = format(parseISO(user.created_at), 'dd/MM/yyyy');

  const [completedLessonUser, setCompletedLessonUser] = useState([]);
  const [userLists, setUserLists] = useState([]);

  async function handleLessonUser(idUser) {
    setUserLists(idUser.user.id);

    if (id === idUser.user.id) return;

    if (userLists === idUser.user.id) {
      setUserLists([]);
      setCompletedLessonUser([]);

      return;
    }

    const { data } = await api.get(
      `/church/course/lessons-users-completed/${idUser.user.id}`,
      {
        params: { training_id: idUser.training_id },
      }
    );

    setCompletedLessonUser(data);
  }

  return (
    <Container
      style={{
        opacity: user.user.id === id && 'var(--opacity-medium)',
      }}
    >
      <Content>
        <Header
          title={
            // eslint-disable-next-line no-nested-ternary
            training_update && user.user.id !== id
              ? userLists !== user.user.id
                ? 'Expandir aulas concluídas'
                : 'Recolher aulas concluídas'
              : ''
          }
          style={{
            cursor: training_update && user.user.id !== id && 'pointer',
          }}
          onClick={() => training_update && handleLessonUser(user)}
        >
          <div>
            <img src={user.user.photo ? user.user.url : photoDefault} alt="" />
          </div>

          <div className="name">
            <span>{user.user.name}</span>
          </div>
        </Header>

        <div>
          <span>{formattedDate}</span>

          {training_delete && (
            <button
              type="button"
              title={user.user.id !== id ? 'Remover aluno' : ''}
              style={{ cursor: user.user.id === id && 'default' }}
              onClick={onDelete}
              disabled={user.user.id === id}
            >
              <MdRemove size={20} />
            </button>
          )}
        </div>
      </Content>

      {user.user.id !== id &&
      userLists === user.user.id &&
      completedLessonUser.length === 0 ? (
        <div className="completed">
          <MdOutlineCircle />
          <p>Nenhuma aula concluída</p>
        </div>
      ) : (
        completedLessonUser.map(lessonUser => (
          <div key={lessonUser.id} className="completed">
            <MdCheckCircleOutline size={18} />
            <p>
              {lessonUser.lesson.title} -{' '}
              {format(parseISO(lessonUser.created_at), 'dd/MM/yyyy')}
            </p>
          </div>
        ))
      )}
    </Container>
  );
}
