import React, { useEffect, useState } from 'react';
import {
  MdAddComment,
  MdGroup,
  MdManageAccounts,
  MdMovie,
  MdMusicNote,
  MdOutlineCake,
  MdOutlineVolunteerActivism,
  MdPerson,
  MdSchool,
} from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { CircularProgress } from '@material-ui/core';
import { Form } from '@rocketseat/unform';
import { format, getMonth, isBefore, parseISO } from 'date-fns';
import * as Yup from 'yup';

import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import Dialog from '~/components/Layout/Dialog';
import InputText from '~/components/Layout/Inputs/InputText';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import Birthdays from '../Admin/Birthdays';
import {
  Button,
  ButtonInformative,
  Container,
  Content,
  Description,
  Feedback,
  Informative,
  Notice,
  Notification,
} from './styles';

const schema = Yup.object().shape({
  description: Yup.string()
    .min(5, 'Descrição deve ter no mínimo 5 caracteres')
    .max(1200, 'Descrição deve ter no máximo 1200 caracteres'),
});

export default function Home() {
  const {
    permission,
    minister_word,
    praise_department,
    media_department,
    nameuser,
    tenant,
    available,
    churchPermissions: {
      user_list,
      scale_praise_list,
      scale_minister_list,
      scale_media_list,
      presence_list,
      presence_leader_list,
      account_list,
      login_list,
      song_list,
      block_list,
      post_praise_list,
      training_list,
    },
  } = useSelector(state => state.user.profile);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState('');

  const [openBirthday, setOpenBirthday] = useState(false);

  const [members, setMembers] = useState();
  const [membersPraise_department, setMembersPraise_department] = useState();
  const [membersMedia_department, setMembersMedia_department] = useState();
  const [membersMinister, setMembersMinister] = useState();
  const [birthday, setBirthday] = useState();
  const [training, setTraining] = useState();
  const [prayer, setPrayers] = useState();

  const formattedNewDate = format(new Date(), 'yyyy-MM-dd');
  const availableParseISO = parseISO(available);
  const formattedAvailable = format(availableParseISO, 'yyyy-MM-dd');
  const monthCurrent = getMonth(new Date());

  const expired = isBefore(
    new Date(formattedNewDate),
    new Date(formattedAvailable)
  );

  const availableExpired = format(new Date(available), 'dd/MM/yyyy');

  try {
    useEffect(() => {
      async function loadCountMembers() {
        const response = await api
          .get('/church/count-members')
          .catch(async res => {
            try {
              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });
            }
          });

        if (response === undefined) {
          return;
        }

        setMembers(response.data[0]);
        setMembersPraise_department(response.data[1]);
        setMembersMedia_department(response.data[2]);
        setMembersMinister(response.data[3]);
        setBirthday(response.data[4]);
        setTraining(response.data[5]);
        setPrayers(response.data[6]);
      }

      loadCountMembers();
    }, [tenant]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleSubmitInformative() {
    setLoading(true);

    try {
      const data = await api
        .post('/admin/feedbacks', {
          description: feedback,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) return;

      setFeedback('');
      setOpen(false);

      setLoading(false);

      ToastSuccess({ title: 'Obrigado por seu feedback' });
    } catch (error) {
      ToastError({ title: 'Erro ao adicionar, verifique os dados' });

      setLoading(false);
    }
  }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleOpenBirthday() {
    setOpenBirthday(true);
  }

  function handleClose() {
    setOpen(false);
    setOpenBirthday(false);
  }

  return (
    <Container>
      <Informative>
        <Notification>
          {!expired && (
            <Notice alert>
              <strong>A versão Pro expirou</strong>

              <span>{availableExpired}</span>
            </Notice>
          )}

          {monthCurrent === 0 && (
            <Notice>
              <strong>Feliz ano novo!</strong>

              <span>2024</span>
            </Notice>
          )}
        </Notification>

        {monthCurrent === 0 || !expired ? <hr /> : ''}

        <ButtonInformative onClick={handleClickOpen}>
          Deixe seu feedback
          <MdAddComment size={22} />
        </ButtonInformative>
      </Informative>

      <Dialog
        open={open}
        width={50}
        title="Deixe seu feedback"
        handleClose={handleClose}
      >
        <Feedback>
          <Description>
            <div>
              <p>Sua opinião gera evolução!</p>

              <strong>
                Como está sendo a sua experiência com a plataforma?
              </strong>
            </div>

            <hr />

            <div>
              <span>Quer nos contar detalhes?</span>

              <span>Quanto mais informações, mais conseguimos evoluir.</span>
            </div>
          </Description>

          <Form schema={schema} onSubmit={handleSubmitInformative}>
            <InputText
              name="description"
              value={feedback}
              onChange={event => setFeedback(event.target.value)}
              multiline
            />

            <hr />

            <div className="buttons">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Enviar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <CancelButton type="button" onClick={handleClose}>
                  Cancelar
                </CancelButton>
              </div>
            </div>
          </Form>
        </Feedback>
      </Dialog>

      {openBirthday && (
        <Birthdays open={openBirthday} handleClose={handleClose} />
      )}

      <Content>
        {permission && (
          <Button>
            <div>
              <strong>Administrativo</strong>

              <MdManageAccounts size={50} />

              <span>{`Membros: ${members || 0}`}</span>

              {user_list ||
              presence_list ||
              presence_leader_list ||
              account_list ? (
                <SubmitButton
                  type="button"
                  onClick={() =>
                    history.push(
                      // eslint-disable-next-line no-nested-ternary
                      user_list
                        ? '/admin/admin/users-active'
                        : // eslint-disable-next-line no-nested-ternary
                        presence_list
                        ? '/admin/presences/publics/publics-list'
                        : // eslint-disable-next-line no-nested-ternary
                        presence_leader_list
                        ? '/admin/presences/leaders/leaders-list'
                        : // eslint-disable-next-line no-nested-ternary
                        account_list
                        ? '/admin/account/church/church-dice'
                        : // eslint-disable-next-line no-constant-condition
                        '/admin/changelogs'
                        ? login_list
                        : '/admin/logins'
                    )
                  }
                >
                  ACESSAR
                </SubmitButton>
              ) : (
                <SubmitButton
                  type="button"
                  onClick={() => history.push('/admin/changelogs')}
                >
                  ACESSAR
                </SubmitButton>
              )}
            </div>
          </Button>
        )}

        {minister_word && (
          <Button>
            <div>
              <strong>Ministros</strong>

              <MdGroup size={50} />

              <span>{`Membros: ${membersMinister || 0}`}</span>

              {scale_minister_list || user_list ? (
                <SubmitButton
                  type="button"
                  onClick={() =>
                    history.push(
                      // eslint-disable-next-line no-nested-ternary
                      scale_minister_list
                        ? '/minister/scale/scales-list'
                        : user_list
                        ? '/admin/minister/users-active'
                        : '/'
                    )
                  }
                >
                  ACESSAR
                </SubmitButton>
              ) : (
                <SubmitButton
                  type="button"
                  onClick={() => history.push('/')}
                  disabled
                >
                  ACESSAR
                </SubmitButton>
              )}
            </div>
          </Button>
        )}

        {praise_department && (
          <Button>
            <div>
              <strong>Louvor</strong>

              <MdMusicNote size={50} />

              <span>{`Membros: ${membersPraise_department || 0}`}</span>

              {scale_praise_list ||
              user_list ||
              song_list ||
              block_list ||
              post_praise_list ? (
                <SubmitButton
                  type="button"
                  onClick={() =>
                    history.push(
                      // eslint-disable-next-line no-nested-ternary
                      scale_praise_list
                        ? '/scale/scale-list'
                        : // eslint-disable-next-line no-nested-ternary
                        user_list
                        ? '/admin/praise/users-active'
                        : // eslint-disable-next-line no-nested-ternary
                        song_list
                        ? '/song/song-active'
                        : // eslint-disable-next-line no-nested-ternary
                        block_list
                        ? '/block/block-list-one'
                        : post_praise_list
                        ? '/post/post-list'
                        : '/'
                    )
                  }
                >
                  ACESSAR
                </SubmitButton>
              ) : (
                <SubmitButton
                  type="button"
                  onClick={() => history.push('/')}
                  disabled
                >
                  ACESSAR
                </SubmitButton>
              )}
            </div>
          </Button>
        )}

        {media_department && (
          <Button>
            <div>
              <strong>Mídia</strong>

              <MdMovie size={50} />

              <span>{`Membros: ${membersMedia_department || 0}`}</span>

              {scale_media_list || user_list ? (
                <SubmitButton
                  type="button"
                  onClick={() =>
                    history.push(
                      // eslint-disable-next-line no-nested-ternary
                      scale_media_list
                        ? '/media/scale/scales-list'
                        : user_list
                        ? '/admin/media/users-active'
                        : '/'
                    )
                  }
                >
                  ACESSAR
                </SubmitButton>
              ) : (
                <SubmitButton
                  type="button"
                  onClick={() => history.push('/')}
                  disabled
                >
                  ACESSAR
                </SubmitButton>
              )}
            </div>
          </Button>
        )}

        <Button>
          <div>
            <strong>Aniversariantes</strong>

            <MdOutlineCake size={50} />

            <span>{`Membros: ${birthday || 0}`}</span>

            <SubmitButton type="button" onClick={handleOpenBirthday}>
              VISUALIZAR
            </SubmitButton>
          </div>
        </Button>

        <Button>
          <div>
            <strong>Treinamentos</strong>

            <MdSchool size={50} />

            <span>{`Treinamentos: ${training || 0}`}</span>

            {training_list ? (
              <SubmitButton
                type="button"
                onClick={() => history.push('/course/training/training-list')}
              >
                ACESSAR
              </SubmitButton>
            ) : (
              <SubmitButton type="button" onClick={() => history.push('/')}>
                ACESSAR
              </SubmitButton>
            )}
          </div>
        </Button>

        <Button>
          <div>
            <strong>Orações</strong>

            <MdOutlineVolunteerActivism size={50} />

            <span>{`Orações: ${prayer || 0}`}</span>

            <SubmitButton
              type="button"
              onClick={() => history.push('/prayer/prayer-list')}
            >
              ACESSAR
            </SubmitButton>
          </div>
        </Button>

        <Button>
          <div>
            <strong>Meu perfil</strong>

            <MdPerson size={50} />

            <span>{nameuser}</span>

            <SubmitButton
              type="button"
              onClick={() => history.push('/profile/me')}
            >
              VISUALIZAR
            </SubmitButton>
          </div>
        </Button>
      </Content>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
