import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh;
  width: 90vw;

  div.signup {
    padding: 2rem;
    border-radius: 0.5rem;
    background: var(--color-primary);

    form {
      h2,
      input,
      label,
      span {
        display: flex;
        align-items: flex-start;
      }

      h2 {
        padding: 4rem 0;
      }

      div.group {
        display: flex;
        flex-direction: column;

        label {
          margin-top: 1rem;
        }

        div {
          width: 100%;
        }

        div.cancel {
          display: flex;
          justify-content: center;

          padding: 2rem 0;

          a {
            display: flex;
            align-items: center;

            margin-top: 1rem;

            font-size: var(--fontSizes-4xl);
            color: var(--color-white);
            opacity: var(--opacity-semiOpaque);

            &:hover {
              opacity: var(--opacity-intense);
            }

            svg {
              font-size: var(--fontSizes-4xl);
              margin-right: 1rem;
            }
          }
        }
      }

      div.year {
        display: flex;
        justify-content: center;

        p {
          padding-bottom: 2rem;
          opacity: var(--opacity-light);
        }
      }
    }
  }

  @media (min-width: 720px) {
    div.signup {
      form {
        div.group {
          display: flex;
          flex-direction: row;

          div.name,
          div.people,
          div.price,
          div.responsible,
          div.email,
          div.password,
          div.responsible_document,
          div.zip_code,
          div.number,
          div.district,
          div.city {
            margin-right: 1rem;
          }

          div.document {
            width: 45%;
          }

          div.people,
          div.nameuser,
          div.password,
          div.confirm-password,
          div.zip_code,
          div.number,
          div.state {
            width: 40%;
          }

          div.cancel {
            display: flex;
            justify-content: flex-end;
          }
        }

        div.year {
          margin-top: 2rem;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    width: 55vw;
  }

  @media (min-width: 1180px) {
    width: 60vw;
  }
`;
