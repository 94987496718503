import produce from 'immer';

export default function prayer(state = {}, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@prayer/PAGE_PRAYER_REQUEST': {
        draft.prayerPage = action.payload.page;
        break;
      }
      default:
    }
  });
}
