import styled from 'styled-components';

export const Button = styled.button`
  width: 100%;
  height: 4.4rem;
  border-radius: 0.5rem;
  border: 0.2rem solid var(--color-blue);
  background: none;

  font-size: var(--fontSizes-2xl);
  font-weight: bold;
  color: var(--color-white);

  margin-top: 2rem;
  &:hover {
    background: var(--color-blue);
    opacity: var(--opacity-intense);
  }
`;
