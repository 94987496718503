import React from 'react';

import versionFunctions from '~/utils/versionFunctions';

import { Content, Table } from './styles';

export default function Pro() {
  return (
    <Content>
      <strong>Informações do plano</strong>

      <div>
        <p>
          Atualmente você possui o plano <strong>Basic</strong> do Church
          Praise. Evolua seu plano e tenha mais vantagens.
        </p>

        <p>
          Sempre que você encontrar está página em algumas funcionalidades do
          Church Praise, significa que o seu plano atual não contempla este
          recurso. O plano <strong className="pro">Pro</strong> possuem
          funcionalidades exclusivas, tornando o controle da sua igreja ainda
          mais completo. Confira abaixo algumas diferenças entre os planos do
          <span> Church Praise</span>.
        </p>
      </div>

      <hr />

      <Table>
        <thead>
          <tr>
            <th> </th>
            <th>Basic</th>
            <th className="pro">Pro</th>
          </tr>
        </thead>

        <tbody>
          {versionFunctions.map(item => (
            <tr key={item.id}>
              <td>
                <span>{item.description}</span>
              </td>

              <td>{item.basic}</td>
              <td>{item.pro}</td>
            </tr>
          ))}
        </tbody>
      </Table>

      <span>
        Para mais informações, entre em contato conosco pelo{' '}
        <a
          href="https://www.instagram.com/church.praise/"
          target="_blank"
          rel="noreferrer"
        >
          Instagram
        </a>
      </span>
    </Content>
  );
}
