import produce from 'immer';

export default function block(state = {}, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@block/UPDATE_BLOCK_REQUEST': {
        draft.blockId = action.payload.id;
        break;
      }
      default:
    }
  });
}
