import styled from 'styled-components';

export const Titles = styled.div`
  margin-bottom: 2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  white-space: nowrap;

  strong {
    font-size: var(--fontSizes-6xl);
    color: var(--color-white);

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  span {
    text-decoration: underline;
    font-size: var(--fontSizes-5xl);
    color: var(--color-white);
    opacity: var(--opacity-light);
  }

  @media (min-width: 1180px) {
    margin-bottom: 4rem;

    strong {
      margin-top: 0;
      max-width: 35ch;
    }
  }
`;
