import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import Dialog from '~/components/Layout/Dialog';
import InputText from '~/components/Layout/Inputs/InputText';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content } from './styles';

const schema = Yup.object().shape({
  date: Yup.date().required('Data é obrigatória'),
  hour: Yup.string(),
  reason: Yup.string().max(254, 'Motivo deve ter no máximo 254 caracteres'),
});

export default function LeadersUpdate({
  open,
  handleClose,
  handleDataUpdate,
  presenceLeaderUpdate,
  handleDataDelete,
}) {
  const {
    churchPermissions: { presence_leader_delete },
  } = useSelector(state => state.user.profile);

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [presenceLeaders, setPresenceLeaders] = useState([]);
  const [reasonNew, setReasonNew] = useState('');

  try {
    useEffect(() => {
      async function loadPresenceLeaderData() {
        const data = await presenceLeaderUpdate;

        const { date, hour, reason, user } = data;

        const parsedDate = parseISO(date);
        const formattedDate = format(parsedDate, 'yyyy-MM-dd');

        setPresenceLeaders({ date: formattedDate, hour, name: user.name });
        setReasonNew(reason || '');
      }

      loadPresenceLeaderData();
    }, [presenceLeaderUpdate]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleSubmit({ date, hour, reason }) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = utcToZonedTime(date, timezone);

    try {
      setLoading(true);

      const data = { date: timezoneDate, hour, reason };

      const dataResponse = await api
        .put(
          `/church/admin/presences/leaders-update/${presenceLeaderUpdate.id}`,
          data
        )
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      handleDataUpdate({
        id: presenceLeaderUpdate.id,
        date: timezoneDate.toISOString(),
        hour,
        reason,
        user_id: presenceLeaderUpdate.user_id,
        user: {
          name: presenceLeaderUpdate.user.name,
          photo: presenceLeaderUpdate.user.photo,
          url: presenceLeaderUpdate.user.url,
        },
      });
      handleClose();

      ToastSuccess({ title: 'Falta alterada' });
    } catch (error) {
      ToastError({ title: 'Erro ao alterar, verifique os dados' });

      setLoading(false);
    }
  }

  async function handleDelete() {
    handleClose();

    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          await api.delete(
            `/church/admin/presences/leaders-delete/${presenceLeaderUpdate.id}`
          );

          Swal.fire('Excluída!', 'Falta excluída', 'success');

          handleDataDelete({ id: presenceLeaderUpdate.id });
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      width={60}
      title="Alterar falta"
      handleClose={handleClose}
    >
      <Content>
        <Form
          schema={schema}
          initialData={presenceLeaders}
          onSubmit={handleSubmit}
        >
          <div className="group">
            <div className="date">
              <InputText
                label="Data"
                name="date"
                type="date"
                min="2021-01-01"
                max="9999-12-31"
                lang="pt-BR"
                required
              />
            </div>

            <div className="hour">
              <InputText label="Hora" name="hour" type="time" />
            </div>

            <div>
              <InputText
                label="Membro"
                name="name"
                disabled
                style={{ border: 0 }}
              />
            </div>
          </div>

          <div className="group">
            <div>
              <InputText
                label="Motivo"
                name="reason"
                value={reasonNew}
                onChange={event => setReasonNew(event.target.value)}
                multiline
              />
            </div>
          </div>

          {presence_leader_delete && (
            <button
              type="button"
              className="delete"
              onClick={() => handleDelete()}
            >
              Excluir falta
            </button>
          )}

          <hr />

          <div className="buttons">
            <div>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress
                    size={24}
                    style={{ color: 'var(--color-white)' }}
                  />
                ) : (
                  'Salvar'
                )}
              </SubmitButton>
            </div>

            <div className="cancel">
              <CancelButton type="button" onClick={handleClose}>
                Cancelar
              </CancelButton>
            </div>
          </div>
        </Form>
      </Content>

      {logout && <UserLogout message={message} />}
    </Dialog>
  );
}
