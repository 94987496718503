import styled from 'styled-components';

export const Content = styled.div`
  div.title {
    margin-top: -2rem;
    margin-bottom: 2rem;

    display: flex;
    align-items: center;

    white-space: nowrap;

    strong {
      font-size: var(--fontSizes-6xl);
      color: var(--color-white);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      margin-left: 0.8rem;
      padding: 0.5rem;

      font-weight: bold;
      background: ${props =>
        props.pro ? 'var(--color-orange)' : 'var(--color-blue)'};
      border-radius: 0.5rem;
      color: var(--color-white);
    }
  }

  form {
    div.group {
      display: block;

      div {
        width: 100%;
      }
    }

    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);

      margin: 0 0 2rem 0;
    }
  }

  @media (min-width: 720px) {
    div.title {
      margin-bottom: 4rem;

      strong {
        margin-top: 0;
      }
    }

    form {
      div.group {
        display: flex;
        padding-bottom: 0;

        div.responsible {
          margin-bottom: 1rem;
        }

        div.nickname {
          width: 60%;
          margin-left: 1rem;
        }

        div.zip_code,
        div.number,
        div.district,
        div.city {
          margin-right: 1rem;
        }

        div.zip_code,
        div.number,
        div.state {
          width: 30%;
        }
      }

      div.buttons {
        display: flex;
        flex-direction: row-reverse;

        width: 100%;

        button {
          margin-top: 2rem;
          width: 15rem;
        }

        div.cancel {
          margin-right: 1rem;

          button {
            width: 12rem;
          }
        }
      }
    }
  }
`;
