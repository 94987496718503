import styled from 'styled-components';

export const Content = styled.div`
  padding: 2rem;
  border-radius: 0.5rem;
  background: var(--color-primary);

  form {
    div.group {
      display: flex;
      flex-direction: column;

      div.date,
      div.minister-two,
      div.backing-one,
      div.backing-two,
      div.backing-three,
      div.drums,
      div.viola,
      div.contrabass,
      div.guitar,
      div.keyboard,
      div.percussion,
      div.table-one,
      div.table-two,
      div.block,
      div.second-song,
      div.third-song,
      div.fourth-song,
      div.fifth-song,
      div.sixth_song,
      div.seventh_song,
      div.eighth_song {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: 0;
      }

      div {
        width: 100%;
      }

      div.block,
      div.first-song {
        margin-top: 1rem;
      }
    }

    button.delete {
      margin-top: 0;
      margin-bottom: 0.5rem;

      border: none;
      background: none;
      color: var(--color-red);

      &:hover {
        opacity: var(--opacity-semiOpaque);
      }
    }

    div.with-block {
      margin: 0;

      div.label {
        margin-bottom: 0.5rem;
      }
    }

    div.group div + div {
      margin-left: 1rem;
    }

    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);

      margin: 0 0 2rem 0;
    }
  }

  @media (min-width: 720px) {
    form {
      div.buttons {
        display: flex;
        flex-direction: row-reverse;

        width: 100%;

        button {
          margin-top: 2rem;
          width: 15rem;
        }

        div.cancel {
          margin-right: 1rem;

          button {
            width: 12rem;
          }
        }
      }
    }
  }
`;

export const Label = styled.label``;
