import styled from 'styled-components';

export const Scroll = styled.div`
  div.buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin-bottom: 2rem;
    margin-right: -1rem;

    button {
      width: 30%;
    }
  }

  div.loading {
    display: flex;
    justify-content: center;
  }

  ul {
    display: grid;
    gap: 1rem;

    p.empty {
      display: flex;
      justify-content: center;

      font-size: var(--fontSizes-2xl);
      color: var(--color-white);
      opacity: var(--opacity-intense);
    }

    div.div-button-page {
      margin-top: 3rem;
      margin-bottom: 0;

      button {
        border: 0;
        background: transparent;
        color: var(--color-blue);
        margin-right: 1rem;
      }

      span {
        margin-right: 1rem;
        opacity: var(--opacity-medium);
      }
    }
  }

  @media (min-width: 1180px) {
    div.buttons {
      margin-bottom: 4rem;
    }
  }
`;
