import { createGlobalStyle } from 'styled-components';

import '@sweetalert2/theme-dark/dark.css';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

  :root {
    font-size: 60%;

    --color-primary: #202024;
    --color-secondary: #29292E;
    --color-tertiary: #323238;
    --color-background: #121214;
    --color-mandatory: #F75A68;
    --color-red: #EB5545;
    --color-yellow: #F4F482;
    --color-white: #FFFFFF;
    --color-blue: #0195F6;
    --color-green: #06BC0A;
    --color-purple: #7159C1;
    --color-orange: #FE8330;

    --opacity-default: 100%;
    --opacity-semiOpaque: 90%;
    --opacity-intense: 75%;
    --opacity-medium: 50%;
    --opacity-light: 25%;
    --opacity-semiTransparent: 10%;

    --fontSizes-xxs: 0.625rem;
    --fontSizes-xs: 0.75rem;
    --fontSizes-sm: 0.875rem;
    --fontSizes-md: 1rem;
    --fontSizes-lg: 1.125rem;
    --fontSizes-xl: 1.25rem;
    --fontSizes-2xl: 1.5rem;
    --fontSizes-4xl: 2rem;
    --fontSizes-5xl: 2.25rem;
    --fontSizes-6xl: 3rem;
    --fontSizes-7xl: 4rem;
    --fontSizes-8xl: 4.5rem;
    --fontSizes-9xl: 6rem;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  *:focus {
    outline: 0;
  }

  html, body, #root {
    height: 100%;
    width: 100%;

    background: var(--color-background) repeat;
  }

  body {
    color: var(--color-white);
    font-family: 'Roboto', sans-serif;
    text-rendering: optimizelegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
  }

  body,
  input,
  button,
  textarea {
    font: 1.6rem 'Roboto', sans-serif;
  }

  a {
    text-decoration: none;
    transition: all 0.2s ease 0s;
  }

  ul {
    list-style: none;
  }

  button {
    cursor: pointer;
    transition: all 0.2s ease 0s;
  }

  strong,
  span,
  p,
  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  code,
  small {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  code {
    font-size: var(--fontSizes-sm);
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
    background: var(--color-secondary);
    padding: 0.2rem 0.25rem;
    border-radius: 0.3rem;
  }

  .swal2-popup {
    font-size: var(--fontSizes-2xl) !important;
  }

  ::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 1rem;
  }

  ::-webkit-scrollbar-corner {
    border: none;
    background: none;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--color-primary);
    border-radius: 3rem;
    cursor: move;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
    border: none;
  }

  @media (min-width: 720px) {
    :root {
      font-size: 61%;
    }
  }

  @media (min-width: 1180px) {
    :root {
      font-size: 62.5%;
    }
  }
`;
