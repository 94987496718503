import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 2.4rem 0.4rem 0.4rem 2.4rem;
  background: var(--color-tertiary);

  div.completed {
    display: flex;
    align-items: center;

    margin: 0 0 0.5rem 3.5rem;

    svg {
      min-width: 1.5rem;
      max-width: 1.5rem;

      opacity: var(--opacity-medium);
      color: var(--color-white);
    }

    p {
      margin: 0 0.5rem;

      font-size: var(--fontSizes-xl);
      opacity: var(--opacity-medium);
      color: var(--color-white);
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;

    span {
      margin-right: 0.5rem;

      font-size: var(--fontSizes-xl);
      opacity: var(--opacity-medium);
      color: var(--color-white);
    }

    button {
      display: flex;
      border: none;
      background: var(--color-secondary);
      padding: 0.5rem;

      color: var(--color-white);

      &:hover {
        opacity: var(--opacity-medium);
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  transition: all 0.2s ease 0s;

  div {
    margin-right: 1rem;

    img {
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 50%;
      border: 0.2rem solid var(--color-white);
      background: var(--color-secondary);

      object-fit: cover;
    }
  }

  div.name {
    width: 8rem;

    span {
      font-size: var(--fontSizes-2xl);
      opacity: var(--opacity-default);
      color: var(--color-white);

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        opacity: var(--opacity-semiOpaque);
      }
    }
  }

  @media (min-width: 370px) {
    div.name {
      width: 14rem;
    }
  }

  @media (min-width: 420px) {
    div.name {
      width: 18rem;
    }
  }

  @media (min-width: 720px) {
    div.name {
      width: 33rem;
    }
  }
`;
