import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Layout/Body';
import ButtonSelectRemove from '~/components/Layout/Buttons/ButtonSelectRemove';
import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputSelect from '~/components/Layout/Inputs/InputSelect';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Praise/Menu';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content } from '../styles';

const schema = Yup.object().shape({
  minister_id: Yup.string().required('Selecione um ministro'),
  first_song: Yup.string().required('Selecione uma música'),
  second_song: Yup.string(),
  third_song: Yup.string(),
  fourth_song: Yup.string(),
  fifth_song: Yup.string(),
  sixth_song: Yup.string(),
  seventh_song: Yup.string(),
  eighth_song: Yup.string(),
  available: Yup.date().required(),
});

export default function BlockNewThree() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const dateNew = { available: format(new Date(), 'yyyy-MM-dd') };

  const [ministers, setMinisters] = useState([]);

  const [songs, setSongs] = useState([]);

  const [secondSong, setSecondSong] = useState();
  const [thirdSong, setThirdSong] = useState();
  const [fourthSong, setFourthSong] = useState();
  const [fifthSong, setFifthSong] = useState();
  const [fixthSong, setFixthSong] = useState();
  const [seventhSong, setSeventhSong] = useState();
  const [eighthSong, setEighthSong] = useState();

  try {
    useEffect(() => {
      async function loadMinisters() {
        const { data } = await api.get('/church/admin/users-minister');

        setMinisters(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadMinisters();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os ministros' });
  }

  try {
    useEffect(() => {
      async function loadSongs() {
        const { data } = await api.get('/church/praise/songs-active');

        setSongs(data.map(song => ({ id: song.id, title: song.name })));
      }

      loadSongs();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar as músicas' });
  }

  async function handleSubmit({
    minister_id,
    first_song,
    second_song,
    third_song,
    fourth_song,
    fifth_song,
    sixth_song,
    seventh_song,
    eighth_song,
    available,
  }) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = utcToZonedTime(available, timezone);

    try {
      setLoading(true);

      const data = await api
        .post('/church/praise/blocks-three-new', {
          minister_id,
          first_song,
          second_song,
          third_song,
          fourth_song,
          fifth_song,
          sixth_song,
          seventh_song,
          eighth_song,
          available: timezoneDate,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              setMessage('Autenticação falhou. Entre em contato com seu líder');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Praise department disabled') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Pro disabled') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant disabled') {
              setMessage('Conta expirou. Entre em contato com o administrador');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant expired') {
              setMessage(
                'Plano Pro expirou. Entre em contato com o administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Past dates are not permitted') {
              ToastError({
                title: 'Datas passadas não são permitidas',
              });

              setLoading(false);

              return;
            }

            if (
              res.response.data.error ===
              'There is already a block with a more recent date'
            ) {
              ToastError({
                title: 'Já existe um bloco com uma data mais recente',
              });

              setLoading(false);

              return;
            }

            if (
              res.response.data.error ===
              'Block one already registered in this in the selected period'
            ) {
              ToastError({
                title: 'Já existe um bloco cadastrado  no período selecionado',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) return;

      ToastSuccess({ title: 'Bloco 3, adicionado' });

      history.push('/block/block-new-four');
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  return (
    <Container>
      <Aside>
        <Menu block="block" />
      </Aside>

      <Main>
        <Title title="Adicionar bloco 3" />

        <Content>
          <Form schema={schema} initialData={dateNew} onSubmit={handleSubmit}>
            <div className="group">
              <div className="minister-date">
                <div className="minister">
                  <InputSelect
                    label="Voz principal"
                    name="minister_id"
                    options={ministers}
                  />
                </div>

                <div className="date">
                  <InputText
                    label="Data"
                    name="available"
                    type="date"
                    min="2021-01-01"
                    max="9999-12-31"
                    lang="pt-BR"
                    required
                  />
                </div>
              </div>
            </div>

            <div className="group">
              <div className="first-song">
                <InputSelect
                  label="Música 1"
                  name="first_song"
                  options={songs}
                />
              </div>

              <div className="second-song">
                <InputSelect
                  label="Música 2"
                  name="second_song"
                  value={secondSong}
                  options={songs}
                  onChange={event => setSecondSong(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setSecondSong('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="third-song">
                <InputSelect
                  label="Música 3"
                  name="third_song"
                  value={thirdSong}
                  options={songs}
                  onChange={event => setThirdSong(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setThirdSong('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="fourth-song">
                <InputSelect
                  label="Música 4"
                  name="fourth_song"
                  value={fourthSong}
                  options={songs}
                  onChange={event => setFourthSong(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setFourthSong('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="fifth-song">
                <InputSelect
                  label="Música 5"
                  name="fifth_song"
                  value={fifthSong}
                  options={songs}
                  onChange={event => setFifthSong(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setFifthSong('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="sixth_song">
                <InputSelect
                  label="Música 6"
                  name="sixth_song"
                  value={fixthSong}
                  options={songs}
                  onChange={event => setFixthSong(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setFixthSong('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="seventh_song">
                <InputSelect
                  label="Música 7"
                  name="seventh_song"
                  value={seventhSong}
                  options={songs}
                  onChange={event => setSeventhSong(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setSeventhSong('')}
                >
                  X
                </ButtonSelectRemove>
              </div>

              <div className="eighth_song">
                <InputSelect
                  label="Música 8"
                  name="eighth_song"
                  value={eighthSong}
                  options={songs}
                  onChange={event => setEighthSong(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setEighthSong('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <hr />

            <div className="buttons">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <CancelButton
                  type="button"
                  onClick={() => history.push('/block/block-list-three')}
                >
                  Cancelar
                </CancelButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
