import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';

import { ContainerTwo, Main, Nav, Section } from '~/components/Layout/Body';
import SubMenuButton from '~/components/Layout/Buttons/SubMenuButton';
import Title from '~/components/Layout/Title';
import Prayer from '~/components/Pages/Prayer';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { pagePrayerRequest } from '~/store/modules/prayer/actions';
import { ToastError } from '~/utils/toast';

import PrayerNew from '../PrayerNew';
import PrayerUpdate from '../PrayerUpdate';

export default function PrayerList() {
  const pageShare = useSelector(state => state.prayer.prayerPage);

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [openNew, setOpenNew] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);

  const [prayers, setPrayers] = useState([]);
  const [prayerUpdate, setPrayerUpdate] = useState([]);

  const [onPrayer, setOnPrayer] = useState();

  const [share, setShare] = useState(
    pageShare === undefined ? 'Private' : pageShare
  );

  dispatch(pagePrayerRequest(share));

  try {
    useEffect(() => {
      async function loadPrayers() {
        const response = await api
          .get('/church/prayers-list', { params: { share } })
          .catch(async res => {
            try {
              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setPrayers(response.data);

        setLoading(false);
      }

      loadPrayers();
    }, [share]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  function handleNew() {
    setOpenNew(true);
  }

  function handleDataNew(data) {
    const addPrayer = data.page === share ? [...prayers, data] : prayers;

    // eslint-disable-next-line func-names
    const prayerSort = addPrayer.sort(function(initialOrden, finalOrden) {
      // eslint-disable-next-line no-nested-ternary
      return finalOrden.created_at > initialOrden.created_at
        ? 1
        : initialOrden.created_at > finalOrden.created_at
        ? -1
        : 0;
    });

    setPrayers(prayerSort);
  }

  async function handleUpdate(id) {
    const { data } = await api.get(`/church/prayers-show/${id}`);

    setPrayerUpdate(data);

    setOpenUpdate(true);
  }

  function handleDataUpdate(data) {
    const removePrayerUpdate = prayers.filter(prayer => prayer.id !== data.id);

    const addPrayerUpdate =
      data.share === data.share_old
        ? [...removePrayerUpdate, data]
        : removePrayerUpdate;

    // eslint-disable-next-line func-names
    const prayerSort = addPrayerUpdate.sort(function(initialOrden, finalOrden) {
      // eslint-disable-next-line no-nested-ternary
      return finalOrden.created_at > initialOrden.created_at
        ? 1
        : initialOrden.created_at > finalOrden.created_at
        ? -1
        : 0;
    });

    setPrayers(prayerSort);
  }

  function handleDataDelete(data) {
    setPrayers(prayers.filter(prayer => prayer.id !== data.id));
  }

  function handleClose() {
    setOpenNew(false);
    setOpenUpdate(false);
  }

  return (
    <ContainerTwo>
      <Main>
        <Title title="Orações" count={`${prayers.length}`} />

        <Section>
          <Nav>
            <div>
              <SubMenuButton
                type="button"
                focus={share === 'Private' ? 'Private' : undefined}
                onClick={() => setShare('Private')}
              >
                Privadas
              </SubMenuButton>

              <SubMenuButton
                type="button"
                focus={share === 'Public' ? 'Public' : undefined}
                onClick={() => setShare('Public')}
              >
                Públicas
              </SubMenuButton>

              <SubMenuButton type="button" onClick={handleNew}>
                Adicionar
              </SubMenuButton>
            </div>
          </Nav>

          {openNew && (
            <PrayerNew
              open={openNew}
              handleClose={handleClose}
              handleDataNew={handleDataNew}
            />
          )}

          {openUpdate && (
            <PrayerUpdate
              open={openUpdate}
              handleClose={handleClose}
              handleDataUpdate={handleDataUpdate}
              prayerUpdate={prayerUpdate}
              handleDataDelete={handleDataDelete}
            />
          )}

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {prayers.map(prayer => (
                <Prayer
                  key={prayer.id}
                  prayer={prayer}
                  onUpdate={() => handleUpdate(prayer.id)}
                  onPrayer={onPrayer}
                  onExpand={() => setOnPrayer(prayer.id)}
                  onHide={() => setOnPrayer()}
                />
              ))}
              {prayers.length === 0 && (
                <p className="empty">Nenhuma oração encontrada</p>
              )}
            </ul>
          )}
        </Section>
      </Main>

      {logout && <UserLogout message={message} />}
    </ContainerTwo>
  );
}
