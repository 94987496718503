import styled from 'styled-components';

export const Comments = styled.div`
  margin-top: 1rem;

  div.comments {
    display: flex;
    margin: 2rem 0;
    border-top: 0.1rem solid var(--color-tertiary);

    img {
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 50%;
      border: 0.2rem solid var(--color-white);
      background: var(--color-secondary);

      margin: 1rem 1rem 0 0;

      object-fit: cover;
    }

    div.boby-comment {
      display: flex;
      margin-top: 1rem;

      p {
        font-size: var(--fontSizes-xl);

        strong.user-name {
          color: var(--color-white);
        }

        strong.user-name-answer {
          margin: 0 0.5rem;
          color: var(--color-blue);
          font-size: var(--fontSizes-2xl);
        }
      }
    }
  }

  div.button-comment {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div.group-buttons {
      display: flex;
      align-items: center;

      button {
        border: 0;
        width: 100%;
        background: transparent;
        color: var(--color-blue);
        opacity: var(--opacity-semiOpaque);
        font-size: var(--fontSizes-xl);

        margin-right: 1rem;
      }

      button.delete {
        color: var(--color-red);
      }
    }

    div span {
      margin-top: 1rem;
    }
  }

  div.comment {
    div span {
      margin-top: 0.2rem;
    }
  }
`;
