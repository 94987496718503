import React from 'react';
import { MdModeEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { Container, Content, Header } from './styles';

export default function PresencePublic({
  presence,
  onUpdate,
  countAdultsMax,
  countAdultsMin,
  countTeenagersMax,
  countTeenagersMin,
  countVisitorsMax,
  countVisitorsMin,
  countNew_convertsMax,
  countNew_convertsMin,
}) {
  const {
    churchPermissions: { presence_update },
  } = useSelector(state => state.user.profile);

  const parsedAvailable = parseISO(presence.available);

  const formattedAvailable = format(parsedAvailable, 'dd/MM/yyyy', {
    locale: pt,
  });

  const colorAdultsResult =
    presence.adults <= countAdultsMin
      ? 'var(--color-red)'
      : 'var(--color-yellow)';
  const colorAdults =
    presence.adults >= countAdultsMax ? 'var(--color-blue)' : colorAdultsResult;

  const colorTeenagersResult =
    presence.teenagers <= countTeenagersMin
      ? 'var(--color-red)'
      : 'var(--color-yellow)';
  const colorTeenagers =
    presence.teenagers >= countTeenagersMax
      ? 'var(--color-blue)'
      : colorTeenagersResult;

  const colorVisitorsResult =
    presence.visitors <= countVisitorsMin
      ? 'var(--color-red)'
      : 'var(--color-yellow)';
  const colorVisitors =
    presence.visitors >= countVisitorsMax
      ? 'var(--color-blue)'
      : colorVisitorsResult;

  const colorNew_convertsResult =
    presence.new_converts <= countNew_convertsMin
      ? 'var(--color-red)'
      : 'var(--color-yellow)';
  const colorNew_converts =
    presence.new_converts >= countNew_convertsMax
      ? 'var(--color-blue)'
      : colorNew_convertsResult;

  return (
    <Container>
      <Header>
        <div>
          <div>
            <strong>{presence.description}</strong>
          </div>

          <div>
            <span>{`${presence.hour} - ${formattedAvailable}`}</span>
          </div>
        </div>

        <div className="edit">
          {presence_update && (
            <button type="button" title="Alterar presença" onClick={onUpdate}>
              <MdModeEdit size={20} />
            </button>
          )}
        </div>
      </Header>

      <Content>
        <div>
          <span style={{ color: `${colorAdults}` }}>Adultos: </span>

          <p style={{ color: `${colorAdults}` }}>{presence.adults}</p>
        </div>

        <hr />

        <div>
          <span style={{ color: `${colorTeenagers}` }}>Adolescentes: </span>

          <p style={{ color: `${colorTeenagers}` }}>{presence.teenagers}</p>
        </div>

        <hr />

        <div>
          <span style={{ color: `${colorVisitors}` }}>Visitantes: </span>

          <p style={{ color: `${colorVisitors}` }}>{presence.visitors}</p>
        </div>

        <hr />

        <div>
          <span style={{ color: `${colorNew_converts}` }}>
            Novos convertidos:{' '}
          </span>

          <p style={{ color: `${colorNew_converts}` }}>
            {presence.new_converts}
          </p>
        </div>

        <hr />

        <div style={{ marginTop: '3rem' }}>
          <span>Carros: </span>

          <p>{presence.cars}</p>
        </div>

        <hr />

        <div>
          <span>Motos: </span>

          <p>{presence.motorcycles}</p>
        </div>
      </Content>
    </Container>
  );
}
