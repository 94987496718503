import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import Pro from '~/pages/Course/Pro';
import Training from '~/pages/Course/Training/TrainingList';
import TrainingLesson from '~/pages/Course/TrainingLesson/TrainingLessonList';

import Route from './Route';

export default function Course() {
  const version = useSelector(state => state.user.profile);

  const pro = version === null ? false : version.pro;

  return (
    <Switch>
      <Route
        path="/course/training/training-list"
        component={pro ? Training : Pro}
        isPrivate
      />

      <Route
        path="/course/training-lesson/training-list"
        component={pro ? TrainingLesson : Pro}
        isPrivate
      />
    </Switch>
  );
}
