import React from 'react';

import { differenceInYears, format, getMonth, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import photoDefault from '~/assets/photo-default.svg';

import { Container, Content, Header } from './styles';

export default function Birthday({ birthday }) {
  const parsedDate = parseISO(birthday.birthday);

  const monthAtual = getMonth(new Date());
  const monthBirthday = getMonth(new Date(parsedDate));

  const years = differenceInYears(new Date(), parsedDate);

  const formattedDate = format(parsedDate, 'dd/MM/yyyy', { locale: pt });

  return (
    <Container birthday={monthAtual === monthBirthday}>
      <Header>
        <img src={birthday.photo ? birthday.url : photoDefault} alt="" />

        <div className="name">
          <strong>{birthday.name}</strong>

          <span>{`${years} anos`}</span>
        </div>
      </Header>

      <Content birthday={monthAtual === monthBirthday}>
        <span>{formattedDate}</span>
      </Content>
    </Container>
  );
}
