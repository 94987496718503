import styled from 'styled-components';

export const Scroll = styled.div`
  div.form {
    display: flex;

    margin-bottom: 2rem;

    div {
      width: 100%;
    }

    button {
      display: flex;

      margin: 4rem 0 0 1rem;

      border: none;
      background: transparent;

      color: var(--color-white);

      &:hover {
        color: var(--color-blue);
      }
    }
  }

  div.count {
    margin-bottom: 2rem;

    display: flex;
    align-items: center;
    justify-content: space-between;

    strong {
      font-size: var(--fontSizes-4xl);
      color: var(--color-white);
    }

    span {
      text-decoration: underline;
      font-size: var(--fontSizes-2xl);
      color: var(--color-white);
      opacity: var(--opacity-light);
    }
  }

  ul {
    display: grid;
    gap: 0.5rem;
  }
`;
