import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import axios from 'axios';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Layout/Body';
import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Minister/Menu';
import api from '~/services/api';
import { cep, cpfMask, phoneMask } from '~/utils/inputMask';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content, Label } from '../styles';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(10, 'Nome completo deve ter no mínimo 10 caracteres')
    .max(50, 'Nome completo deve ter no máximo 50 caracteres'),
  nameuser: Yup.string()
    .min(3, 'Nome de usuário deve ter no mínimo 3 caracteres')
    .max(20, 'Nome de usuário deve ter no máximo 20 caracteres'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .min(7, 'E-mail deve ter no mínimo 7 caracteres')
    .max(50, 'E-mail deve ter no máximo 50 caracteres'),
  password: Yup.string()
    .min(4, 'Senha deve ter no mínimo 4 caracteres')
    .max(20, 'Senha deve ter no máximo 20 caracteres'),
  confirmPassword: Yup.string()
    .min(4, 'Confirme a senha deve ter no mínimo 4 caracteres')
    .max(20, 'Confirme a senha deve ter no máximo 20 caracteres')
    .oneOf([Yup.ref('password'), null], 'Senhas não conferem'),
  document: Yup.string().max(14, 'CPF deve ser preenchido apenas números'),
  phone: Yup.string().length(16, 'Celular está incompleto'),
  birthday: Yup.date().required('Data de aniversário é obrigatória'),
  zip_code: Yup.string(),
  number: Yup.string(),
  complement: Yup.string(),

  active: Yup.boolean(),

  shepherd: Yup.boolean(),
});

export default function UsersNewMinister() {
  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [name, setName] = useState('');
  const [nameuser, setNameuser] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [document, setDocument] = useState('');
  const [phone, setPhone] = useState('');

  const currentDate = { birthday: format(new Date(), 'yyyy-MM-dd') };

  const [zip_code, setZip_code] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');

  const [shepherd, setShepherd] = useState(false);

  useEffect(() => {
    async function cepUser() {
      if (zip_code.length === 9) {
        const response = await axios.get(
          `https://viacep.com.br/ws/${zip_code.replace(/\D/g, '')}/json/`
        );

        const { logradouro, bairro, localidade, uf } = response.data;

        setStreet(logradouro);
        setDistrict(bairro);
        setCity(localidade);
        setState(uf);
      }
    }

    cepUser();
  }, [zip_code]);

  async function handleSubmit({ birthday }) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = utcToZonedTime(birthday, timezone);

    try {
      setLoading(true);

      const data = await api
        .post('/church/admin/users-new', {
          name,
          nameuser,
          email,
          password,
          document,
          phone,
          birthday: timezoneDate,
          zip_code,
          number,
          complement,

          active: true,

          permission: false,
          supervisor: false,

          shepherd,
          minister_word: true,

          praise_department: false,
          minister: false,
          backing: false,
          viola: false,
          contrabass: false,
          guitar: false,
          drums: false,
          percussion: false,
          keyboard: false,
          table: false,

          media_department: false,
          projection: false,
          streaming: false,
          footage: false,
          photography: false,
          stories: false,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              setMessage('Autenticação falhou. Entre em contato com seu líder');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant disabled') {
              setMessage('Conta expirou. Entre em contato com o administrador');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant expired') {
              setMessage(
                'Plano Pro expirou. Entre em contato com o administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Number of peoples reached') {
              ToastError({ title: 'Você atingiu o limite de pessoas' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User name already exists') {
              ToastError({ title: 'Nome de usuário já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Email already exists') {
              ToastError({ title: 'E-mail já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Document already exists') {
              ToastError({ title: 'CPF já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'CPF is invalid') {
              ToastError({ title: 'CPF é inválido' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) return;

      ToastSuccess({ title: 'Membro adicionado' });

      setName('');
      setNameuser('');
      setEmail('');
      setPassword('');
      setConfirmPassword('');
      setDocument('');
      setPhone('');

      setZip_code('');
      setStreet('');
      setNumber('');
      setComplement('');
      setDistrict('');
      setCity('');
      setState('');

      setShepherd(false);

      setLoading(false);
    } catch (error) {
      ToastError({ title: 'Erro ao adicionar, verifique os dados' });

      setLoading(false);
    }
  }

  return (
    <Container>
      <Aside>
        <Menu user="user" />
      </Aside>

      <Main>
        <Title title="Adicionar membro" />

        <Content>
          <Form
            schema={schema}
            initialData={currentDate}
            onSubmit={handleSubmit}
          >
            <div className="group">
              <div className="name">
                <InputText
                  label="Nome completo"
                  name="name"
                  value={name}
                  onChange={event => setName(event.target.value)}
                />
              </div>

              <div className="nameuser">
                <InputText
                  label="Nome de usuário"
                  name="nameuser"
                  value={nameuser}
                  onChange={event =>
                    setNameuser(
                      event.target.value.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                />
              </div>
            </div>

            <div className="group">
              <div className="email">
                <InputText
                  label="E-mail"
                  name="email"
                  type="email"
                  value={email}
                  onChange={event => setEmail(event.target.value)}
                />
              </div>

              <div className="password">
                <InputText
                  label="Senha"
                  name="password"
                  type="password"
                  autoComplete="cc-csc"
                  value={password}
                  onChange={event => setPassword(event.target.value)}
                />
              </div>

              <div className="confirm-password">
                <InputText
                  label="Confirme a senha"
                  name="confirmPassword"
                  type="password"
                  autoComplete="cc-csc"
                  value={confirmPassword}
                  onChange={event => setConfirmPassword(event.target.value)}
                />
              </div>
            </div>

            <div className="group">
              <div className="document">
                <InputText
                  label="CPF"
                  name="document"
                  value={document}
                  onChange={event => setDocument(cpfMask(event.target.value))}
                />
              </div>

              <div className="phone">
                <InputText
                  label="Celular"
                  name="phone"
                  value={phone}
                  onChange={event => setPhone(phoneMask(event.target.value))}
                />
              </div>

              <div className="birthday">
                <InputText
                  label="Data de aniversário"
                  name="birthday"
                  type="date"
                  max="9999-12-31"
                  lang="pt-BR"
                  required
                />
              </div>
            </div>

            <div className="group">
              <div className="zip_code">
                <InputText
                  label="CEP"
                  name="zip_code"
                  value={zip_code}
                  onChange={event => setZip_code(cep(event.target.value))}
                />
              </div>

              <div className="street">
                <InputText
                  label="Rua"
                  title="Rua preenchida automaticamente de acordo com o CEP"
                  name="street"
                  value={street}
                  disabled
                />
              </div>
            </div>

            <div className="group">
              <div className="number">
                <InputText
                  label="Número"
                  name="number"
                  type="number"
                  value={number}
                  onChange={event => setNumber(event.target.value)}
                />
              </div>

              <div className="complement">
                <InputText
                  label="Complemento"
                  name="complement"
                  value={complement}
                  onChange={event => setComplement(event.target.value)}
                />
              </div>
            </div>

            <div className="group">
              <div className="district">
                <InputText
                  label="Bairro"
                  title="Bairro preenchido automaticamente de acordo com o CEP"
                  name="district"
                  value={district}
                  disabled
                />
              </div>

              <div className="city">
                <InputText
                  label="Cidade"
                  title="Cidade preenchida automaticamente de acordo com o CEP"
                  name="city"
                  value={city}
                  disabled
                />
              </div>

              <div className="state">
                <InputText
                  label="UF"
                  title="UF preenchido automaticamente de acordo com o CEP"
                  name="state"
                  value={state}
                  disabled
                />
              </div>
            </div>

            <hr />

            <div className="title">
              <strong>Membro</strong>
            </div>

            <div className="group-switch">
              <div>
                <Label>Pastor</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={() => setShepherd(!shepherd)}
                    checked={shepherd}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="buttons">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <CancelButton
                  type="button"
                  onClick={() => window.history.back()}
                >
                  Cancelar
                </CancelButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
