import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import {
  Aside,
  Container,
  Main,
  Nav,
  NavTwo,
  Section,
} from '~/components/Layout/Body';
import SubMenuButton from '~/components/Layout/Buttons/SubMenuButton';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import Login from '~/components/Pages/Admin/Login';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Admin/Menu';
import api from '~/services/api';
import { ToastError } from '~/utils/toast';

export default function Logins() {
  const {
    churchPermissions: { login_list, history_list },
  } = useSelector(state => state.user.profile);

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [logins, setLogins] = useState([]);

  const currentDate = format(new Date(), 'yyyy-MM');

  const [month, setMonth] = useState(currentDate);

  try {
    useEffect(() => {
      async function loadLogins() {
        if (month.length !== currentDate.length) {
          return;
        }

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const monthResult = utcToZonedTime(month, timezone);

        const response = await api
          .get('/church/admin/logins', {
            params: {
              month: monthResult,
            },
          })
          .catch(async res => {
            try {
              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setLogins(response.data);

        setLoading(false);
      }

      loadLogins();
    }, [currentDate.length, month]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  return (
    <Container>
      <Aside>
        <Menu login="login" />
      </Aside>

      <Main>
        <Title title="Histórico de logins" count={`${logins.length}`} />

        <Section>
          <Nav>
            <div>
              {login_list && (
                <SubMenuButton
                  focus
                  type="button"
                  onClick={() => history.push('/admin/logins')}
                >
                  Logins
                </SubMenuButton>
              )}

              {history_list && (
                <SubMenuButton
                  type="button"
                  onClick={() => history.push('/admin/historys')}
                >
                  Alterações
                </SubMenuButton>
              )}
            </div>
          </Nav>

          <NavTwo>
            <Form>
              <div>
                {login_list && (
                  <InputText
                    name="month"
                    type="month"
                    value={month}
                    onChange={event => setMonth(event.target.value)}
                    lang="pt-BR"
                    required
                  />
                )}
              </div>
            </Form>
          </NavTwo>

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {logins.map(login => (
                <Login key={login.id} login={login} />
              ))}
              {logins.length === 0 && (
                <p className="empty">Nenhum login encontrado</p>
              )}
            </ul>
          )}
        </Section>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
