import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border-left: 0.1rem solid var(--color-red);
  background: var(--color-primary);

  &:hover {
    opacity: var(--opacity-semiOpaque);
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    button {
      border-style: initial;
      border-color: initial;
      border-image: initial;
      margin-left: 1rem;

      background: none;
      &:hover {
        opacity: var(--opacity-medium);
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 4.8rem;
    height: 4.8rem;
    border-radius: 50%;
    border: 0.2rem solid var(--color-white);
    background: var(--color-secondary);

    object-fit: cover;
  }

  div {
    margin-left: 1rem;
    text-align: left;

    strong {
      display: block;
      margin-bottom: 0.2rem;
      font-size: var(--fontSizes-2xl);
    }

    span {
      font-size: var(--fontSizes-2xl);
      color: var(--color-white);
      opacity: var(--opacity-medium);
    }
  }
`;
