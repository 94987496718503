import styled from 'styled-components';

export const Content = styled.div`
  padding: 2rem;
  border-radius: 0.5rem;
  background: var(--color-primary);

  form {
    div.group {
      display: flex;
      flex-direction: column;

      div.second-song,
      div.third-song,
      div.fourth-song,
      div.fifth-song,
      div.sixth_song,
      div.seventh_song,
      div.eighth_song {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: 0;
      }

      div {
        width: 100%;
      }
    }

    button.delete {
      margin-top: 0;

      border: none;
      background: none;
      color: var(--color-red);

      &:hover {
        opacity: var(--opacity-semiOpaque);
      }
    }

    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);

      margin: 0.5rem 0 2rem 0;
    }
  }

  @media (min-width: 720px) {
    form {
      margin-top: 3rem;

      div.group {
        div.minister-date {
          display: flex;
          flex-direction: row;

          div.date {
            width: 50%;
          }
        }

        div.minister-date div + div {
          margin-left: 1rem;
        }
      }

      div.buttons {
        display: flex;
        flex-direction: row-reverse;

        width: 100%;

        button {
          margin-top: 2rem;
          width: 15rem;
        }

        div.cancel {
          margin-right: 1rem;

          button {
            width: 12rem;
          }
        }
      }
    }
  }
`;
