import styled from 'styled-components';

export const Content = styled.div`
  form {
    div.group {
      display: block;
      padding-bottom: 1rem;

      div.name {
        margin-bottom: 1rem;
      }
    }

    div.email {
      margin-bottom: 1rem;
    }

    div {
      width: 100%;
    }
  }

  button {
    width: 30%;
  }

  @media (min-width: 720px) {
    form {
      div.group {
        display: flex;
        padding-bottom: 0;

        div.name {
          margin-right: 1rem;
        }

        div.whatsapp {
          width: 45%;
        }
      }
    }
  }
`;
