import React, { useState } from 'react';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { Form } from '@rocketseat/unform';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import Dialog from '~/components/Layout/Dialog';
import InputText from '~/components/Layout/Inputs/InputText';
import TrainingUsers from '~/components/Pages/Course/TrainingUser';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Scroll } from './styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um e-mail válido')
    .min(7, 'E-mail deve ter no mínimo 7 caracteres')
    .max(50, 'E-mail deve ter no máximo 50 caracteres'),
});

export default function TrainingUser({
  open,
  handleClose,
  trainingUser,
  handleUserDelete,
}) {
  const {
    churchPermissions: { training_update },
  } = useSelector(state => state.user.profile);

  const idTraining = useSelector(
    state => state.trainingLesson.trainingLessonId
  );

  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [trainingUsers, setTrainingUsers] = useState(trainingUser);
  const [email, setEmail] = useState('');

  async function handleSubmit() {
    try {
      const data = await api
        .post('/church/course/trainings-user-new', {
          email,
          training_id: idTraining,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              return;
            }

            if (res.response.data.error === 'User without permission') {
              ToastError({ title: 'Usuário responsável' });

              return;
            }

            if (res.response.data.error === 'User not found') {
              ToastError({ title: 'Usuário não encontrado' });

              return;
            }

            if (res.response.data.error === 'User disabled') {
              ToastError({ title: 'Usuário desativado' });

              return;
            }

            if (res.response.data.error === 'User already has access') {
              ToastError({ title: 'Usuário já tem acesso' });

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });
          }
        });

      if (data === undefined) return;

      const { id, created_at, user } = data.data[0];

      const addTrainingUser = [...trainingUsers, { id, created_at, user }];
      // eslint-disable-next-line func-names
      const trainingUserSort = addTrainingUser.sort(function(
        initialOrden,
        finalOrden
      ) {
        // eslint-disable-next-line no-nested-ternary
        return finalOrden.created_at > initialOrden.created_at
          ? 1
          : initialOrden.created_at > finalOrden.created_at
          ? -1
          : 0;
      });

      setTrainingUsers(trainingUserSort);

      setEmail('');

      ToastSuccess({ title: 'Aluno adicionado' });
    } catch (error) {
      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  async function handleDelete(idUser) {
    handleClose();

    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          await api.delete(`/church/course/trainings-user-delete/${idUser}`);

          handleUserDelete(trainingUsers.filter(user => user.id !== idUser));

          ToastSuccess({ title: 'Aluno removido' });
        } catch (error) {
          if (error.response.data.error === 'User without permission') {
            ToastError({ title: 'Dono do treinamento' });

            handleUserDelete(trainingUsers);

            return;
          }

          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }

      handleUserDelete(trainingUsers);
    });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      width={55}
      title="Alunos"
      handleClose={handleClose}
    >
      <Form schema={schema} onSubmit={handleSubmit}>
        <Scroll>
          {training_update && (
            <div className="form">
              <InputText
                label="Adicionar aluno"
                name="email"
                placeholder="Digite o e-mail"
                value={email}
                onChange={event => setEmail(event.target.value)}
              />

              <button type="submit">
                <MdAdd size={24} />
              </button>
            </div>
          )}

          <div className="count">
            <strong>Alunos</strong>
            <span>{`${trainingUsers.length}`}</span>
          </div>

          <ul>
            {trainingUsers.map(user => (
              <TrainingUsers
                key={user.id}
                user={user}
                onDelete={() => handleDelete(user.id)}
              />
            ))}
          </ul>
        </Scroll>
      </Form>

      {logout && <UserLogout message={message} />}
    </Dialog>
  );
}
