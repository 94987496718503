import React from 'react';
import { MdClose } from 'react-icons/md';

import Dialog from '@material-ui/core/Dialog';

import { DialogBody, DialogTitle, Modal } from './styles';

export default function Dialogs({
  open,
  onClose,
  title,
  handleClose,
  children,
  width,
}) {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      PaperProps={{
        style: {
          margin: '2rem',
          width: `${width}rem`,
          backgroundColor: 'var(--color-primary)',
        },
      }}
    >
      <Modal>
        <DialogTitle>
          <strong>{title}</strong>

          <div className="divClose">
            <button type="button" onClick={handleClose}>
              <MdClose size={22} />
            </button>
          </div>
        </DialogTitle>

        <DialogBody>{children}</DialogBody>
      </Modal>
    </Dialog>
  );
}
