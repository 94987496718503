import React from 'react';
import { MdModeEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { endOfDay, format, isBefore, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';

import { Container, Header, Minister } from './styles';

export default function Scale({ scale, onUpdate }) {
  const {
    churchPermissions: { scale_minister_update },
  } = useSelector(state => state.user.profile);

  const parsedDate = parseISO(scale.available);

  const past = isBefore(new Date(endOfDay(parsedDate)), new Date(new Date()));

  const dateFormatted = format(parsedDate, "dd 'de' MMMM", { locale: pt });

  return (
    <Container past={!past}>
      <Header>
        <div className="description">
          <strong>{scale.description}</strong>
          {!past && scale_minister_update && (
            <Link
              title="Alterar escala"
              to="/minister/scale/scales-update"
              onClick={onUpdate}
            >
              <MdModeEdit size={20} />
            </Link>
          )}
        </div>

        <div className="hour-date">
          <span>{scale.hour}</span>

          <div>
            <strong>{dateFormatted}</strong>
          </div>
        </div>
      </Header>

      <hr />

      <Minister>
        <strong>Ministro da palavra</strong>
        <div>
          <span>
            <p>1.</p> {scale.ministerOne.name}
          </span>

          {scale.ministerTwo && (
            <span>
              <p>2.</p> {scale.ministerTwo ? scale.ministerTwo.name : ''}
            </span>
          )}
        </div>
      </Minister>

      <hr />

      <div className="note">
        <strong>Tema:</strong>
        <span>{scale.theme}</span>
      </div>

      <hr />

      {scale.note && (
        <div className="note">
          <strong>Observação:</strong>
          <span>{scale.note}</span>
        </div>
      )}
    </Container>
  );
}
