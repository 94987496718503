import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border-left: 0.1rem solid var(--color-blue);
  background: var(--color-primary);

  &:hover {
    opacity: var(--opacity-semiOpaque);
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div.edit {
    display: flex;
    align-items: center;

    button {
      border: none;
      background: transparent;
      color: var(--color-white);

      &:hover {
        opacity: var(--opacity-medium);
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;

  div {
    a {
      display: inline-block;
      padding: 0 1rem;
      font-size: var(--fontSizes-2xl);
      font-weight: bold;
      text-align: start;
      color: var(--color-white);
      &:hover {
        opacity: var(--opacity-medium);
      }
      ${props =>
        props.shepherd &&
        css`
          color: var(--color-blue);
          content: '';
        `}
    }

    strong {
      color: var(--color-yellow);
    }

    span {
      margin-left: 1rem;
      font-size: var(--fontSizes-2xl);
      opacity: var(--opacity-medium);
    }
  }
`;
