import React from 'react';
import { MdEdit, MdPlayLesson } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Container, Content } from './styles';

export default function Training({ training, onOpen, onUpdate }) {
  const {
    churchPermissions: { training_update },
  } = useSelector(state => state.user.profile);

  return (
    <Container>
      <Link
        title="Abrir"
        to="/course/training-lesson/training-list"
        onClick={onOpen}
      >
        <Content>
          <span>{training.title}</span>

          <MdPlayLesson />
        </Content>
      </Link>

      {training_update && (
        <button type="button" onClick={onUpdate}>
          <MdEdit size={20} />
        </button>
      )}
    </Container>
  );
}
