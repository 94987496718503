import React from 'react';

import { ContainerTwo, Main } from '~/components/Layout/Body';
import Pro from '~/components/Pro';

export default function ScalesList() {
  return (
    <ContainerTwo>
      <Main>
        <Pro />
      </Main>
    </ContainerTwo>
  );
}
