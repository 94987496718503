import React, { useState } from 'react';
import Switch from 'react-switch';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';

import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import Dialog from '~/components/Layout/Dialog';
import InputText from '~/components/Layout/Inputs/InputText';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content, Label } from '../styles';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Nome deve ter no mínimo 3 caracteres')
    .max(100, 'Nome deve ter no máximo 100 caracteres'),
  author: Yup.string()
    .min(3, 'Autor deve ter no mínimo 3 caracteres')
    .max(100, 'Autor deve ter no máximo 100 caracteres'),
  letter: Yup.string()
    .min(10, 'Letra deve ter no mínimo 10 caracteres')
    .max(10000, 'Letra deve ter no máximo 10000 caracteres'),
  tone: Yup.string()
    .min(1, 'Tom deve ter no mínimo 1 caracteres')
    .max(20, 'Tom deve ter no máximo 20 caracteres'),
  link: Yup.string().max(254, 'URL YouTube deve ter no máximo 254 caracteres'),
  shepherd: Yup.boolean(),
  active: Yup.boolean(),
});

export default function SongNew({ open, handleClose, handleDataNew }) {
  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [name, setName] = useState('');
  const [author, setAuthor] = useState('');
  const [letter, setLetter] = useState('');
  const [tone, setTone] = useState('');
  const [link, setLink] = useState('');

  const [shepherd, setShepherd] = useState(false);
  const [active, setActive] = useState(true);

  const [errorInput, setErrorInput] = useState(false);

  async function handleSubmit() {
    try {
      setLoading(true);

      const data = await api
        .post('/church/praise/songs-new', {
          name,
          author,
          letter,
          tone,
          link,
          shepherd,
          active,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              setMessage('Autenticação falhou. Entre em contato com seu líder');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Praise department disabled') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Pro disabled') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant disabled') {
              setMessage('Conta expirou. Entre em contato com o administrador');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant expired') {
              setMessage(
                'Plano Pro expirou. Entre em contato com o administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Name already exists') {
              ToastError({ title: 'Nome de musíca já existe' });

              setLoading(false);
              setErrorInput(true);

              setTimeout(() => setErrorInput(false), 5000);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) return;

      const { id } = data.data;

      handleDataNew({
        id,
        name,
        author,
        letter,
        tone,
        link,
        shepherd,
        active,
      });

      setName('');
      setAuthor('');
      setLetter('');
      setTone('');
      setLink('');

      setLoading(false);
      handleClose();

      ToastSuccess({ title: 'Música adicionada' });
    } catch (error) {
      ToastError({ title: 'Erro ao adicionar, verifique os dados' });

      setLoading(false);
    }
  }

  return (
    <Dialog
      open={open}
      width={60}
      title="Adicionar música"
      handleClose={handleClose}
    >
      <Content>
        <Form schema={schema} onSubmit={handleSubmit}>
          <div className="group">
            <div className="name">
              <InputText
                label="Nome"
                name="name"
                value={name}
                onChange={event => setName(event.target.value)}
              />

              {errorInput && <p>Nome já existe</p>}
            </div>

            <div className="author">
              <InputText
                label="Autor"
                name="author"
                value={author}
                onChange={event => setAuthor(event.target.value)}
              />
            </div>
          </div>

          <div className="group">
            <div className="tone">
              <InputText
                label="Tom"
                name="tone"
                value={tone}
                onChange={event => setTone(event.target.value)}
              />
            </div>

            <div>
              <InputText
                label="URL YouTube"
                name="link"
                value={link}
                onChange={event => setLink(event.target.value)}
              />
            </div>
          </div>

          <div className="group">
            <div>
              <InputText
                label="Letra"
                name="letter"
                value={letter}
                onChange={event => setLetter(event.target.value)}
                multiline
              />
            </div>
          </div>

          <div className="group-switch">
            <div>
              <Label>Pastor</Label>
              <div className="permission">
                <Switch
                  height={15}
                  width={40}
                  handleDiameter={20}
                  onColor="#0195F6"
                  offColor="#121214"
                  onChange={() => setShepherd(!shepherd)}
                  checked={shepherd}
                />
              </div>
            </div>

            <div>
              <Label>Ativa</Label>
              <div className="permission">
                <Switch
                  height={15}
                  width={40}
                  handleDiameter={20}
                  onColor="#0195F6"
                  offColor="#121214"
                  onChange={() => setActive(!active)}
                  checked={active}
                />
              </div>
            </div>
          </div>

          <hr />

          <div className="buttons">
            <div>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress
                    size={24}
                    style={{ color: 'var(--color-white)' }}
                  />
                ) : (
                  'Salvar'
                )}
              </SubmitButton>
            </div>

            <div className="cancel">
              <CancelButton type="button" onClick={handleClose}>
                Cancelar
              </CancelButton>
            </div>
          </div>
        </Form>
      </Content>

      {logout && <UserLogout message={message} />}
    </Dialog>
  );
}
