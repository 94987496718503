import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Profile = styled.div`
  a {
    display: flex;
    align-items: center;

    div.title {
      display: flex;
      align-items: end;

      margin-right: 1rem;
      text-align: right;

      strong,
      span,
      p {
        display: none;
      }

      p {
        font-size: var(--fontSizes-md);
        color: var(--color-orange);
      }
    }
  }

  img {
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 50%;
    border: 0.3rem solid var(--color-secondary);
    background: var(--color-secondary);

    object-fit: cover;
  }

  div.photo {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 4.9rem;
    width: 4.9rem;
    border-radius: 50%;
    box-shadow: 0 0 1em 0.1rem black;
    background: ${props =>
      props.pro
        ? 'linear-gradient(106.93deg, var(--color-orange) 0%, var(--color-white) 100%)'
        : 'linear-gradient(106.93deg, var(--color-blue) 0%, var(--color-white) 100%)'};
  }

  @media (min-width: 1180px) {
    a {
      margin-left: 4rem;
      color: var(--color-white);

      div.title {
        display: flex;
        flex-direction: column;

        strong {
          display: unset;
          font-size: var(--fontSizes-2xl);

          max-width: 20ch;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        span {
          display: unset;
          font-size: var(--fontSizes-2xl);

          font-weight: bold;
          color: ${props =>
            props.pro ? 'var(--color-orange)' : 'var(--color-blue)'};

          &:hover {
            opacity: var(--opacity-semiOpaque);
          }
        }

        p {
          display: unset;
        }
      }
    }
  }
`;

export const AccountList = styled.div`
  position: absolute;
  width: 30rem;
  right: -0.8rem;
  top: calc(100% + 1.8rem);
  background: var(--color-primary);
  box-shadow: 0 0 1em 0.1rem black;
  border-radius: 0.5rem;
  padding: 1.5rem;
  z-index: 2;
  display: ${props => (props.visible ? 'block' : 'none')};

  @media (max-width: 720px) {
    left: calc(50% - 27.3rem);
  }

  &::before {
    content: '';
    position: absolute;
    left: calc(89.5% - 1.5rem);
    top: -1rem;
    width: 0;
    height: 0;
    border-left: 1.5rem solid transparent;
    border-right: 1.5rem solid transparent;
    border-bottom: 1.5rem solid var(--color-primary);

    @media (max-width: 720px) {
      left: calc(91% - 1rem);
    }
  }

  button.account {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border: 0;

    background: transparent;
    color: var(--color-white);

    &:hover {
      opacity: var(--opacity-medium);
    }

    svg {
      margin-right: 1rem;
    }
  }

  a {
    display: flex;
    margin: 1rem 0 1.5rem 0.5rem;

    color: var(--color-white);

    svg {
      margin-right: 1rem;
    }

    &:hover {
      opacity: var(--opacity-medium);
    }
  }

  button.help {
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 1rem 0 1.5rem 0.5rem;

    border: none;
    background: transparent;
    color: var(--color-white);

    svg {
      margin-right: 1rem;
    }

    &:hover {
      opacity: var(--opacity-medium);
    }
  }

  @media (min-width: 1180px) {
    top: calc(100% + 1.3rem);

    margin: 0.5rem 0 1.5rem 0.5rem;
  }
`;

export const Scroll = styled.div`
  flex-wrap: nowrap;
  overflow-x: scroll;
  max-height: 30rem;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 4.4rem;
  border-radius: 0.5rem;
  border: 0;
  background: var(--color-red);

  font-size: var(--fontSizes-2xl);
  font-weight: bold;
  color: var(--color-white);

  svg {
    margin: 0 1rem;
  }
`;

export const Overlay = styled.div`
  display: ${props => (props.visible ? 'block' : 'none')};
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;
