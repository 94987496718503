import React from 'react';
import { MdModeEdit } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import photoDefault from '~/assets/photo-default.svg';

import { Container, Content, Header } from './styles';

export default function PresenceLeader({ presenceLeader, onUpdate }) {
  const {
    churchPermissions: { presence_leader_update },
  } = useSelector(state => state.user.profile);

  const parsedDate = parseISO(presenceLeader.date);

  const formattedDate = format(parsedDate, 'dd/MM/yyyy', { locale: pt });

  return (
    <Container>
      <Content>
        <Header>
          <div>
            <img
              src={
                presenceLeader.user.photo
                  ? presenceLeader.user.url
                  : photoDefault
              }
              alt=""
            />
          </div>

          <div className="alignVertically">
            <div>
              <strong>{presenceLeader.user.name}</strong>
            </div>

            <div>
              <span>{presenceLeader.reason}</span>
            </div>
          </div>
        </Header>

        <div className="alignHorizontally">
          <div>
            <span>{presenceLeader.hour}</span>
            <span>{formattedDate}</span>
          </div>

          <div className="edit">
            {presence_leader_update && (
              <button type="button" title="Alterar falta" onClick={onUpdate}>
                <MdModeEdit size={20} />
              </button>
            )}
          </div>
        </div>
      </Content>
    </Container>
  );
}
