import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Aside, Container, Main, Nav, Section } from '~/components/Layout/Body';
import SubMenuButton from '~/components/Layout/Buttons/SubMenuButton';
import Title from '~/components/Layout/Title';
import User from '~/components/Pages/Admin/User';
import UserLogout from '~/components/UserLogout';
import AdminMenu from '~/pages/Admin/Menu';
import MediaMenu from '~/pages/Media/Menu';
import MinisterMenu from '~/pages/Minister/Menu';
import PraiseMenu from '~/pages/Praise/Menu';
import api from '~/services/api';
import { updateUserRequest } from '~/store/modules/admin/user/actions';
import { ToastError } from '~/utils/toast';

export default function UsersDisabled() {
  const {
    churchPermissions: { user_list, user_update, user_new },
  } = useSelector(state => state.user.profile);

  const location = useLocation();

  const dispatch = useDispatch();

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [users, setUsers] = useState([]);

  try {
    useEffect(() => {
      async function loadUsers() {
        const response = await api
          .get(`/church/${location.pathname.split('/', 3)[2]}/users-disabled`)
          .catch(async res => {
            try {
              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setUsers(response.data);

        setLoading(false);
      }

      loadUsers();
    }, [location.pathname]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleUpdate(id) {
    dispatch(updateUserRequest(id));
  }

  return (
    <Container>
      <Aside>
        {location.pathname === '/admin/admin/users-disabled' && (
          <AdminMenu user="user" />
        )}

        {location.pathname === '/admin/praise/users-disabled' && (
          <PraiseMenu user="user" />
        )}

        {location.pathname === '/admin/media/users-disabled' && (
          <MediaMenu user="user" />
        )}

        {location.pathname === '/admin/minister/users-disabled' && (
          <MinisterMenu user="user" />
        )}
      </Aside>

      <Main>
        <Title title="Membros desativados" count={`${users.length}`} />

        <Section>
          <Nav>
            <div>
              {user_list && (
                <SubMenuButton
                  type="button"
                  onClick={() =>
                    history.push(
                      `/admin/${
                        location.pathname.split('/', 3)[2]
                      }/users-active`
                    )
                  }
                >
                  Ativos
                </SubMenuButton>
              )}

              {user_update && (
                <SubMenuButton
                  focus
                  type="button"
                  onClick={() =>
                    history.push(
                      `/admin/${
                        location.pathname.split('/', 3)[2]
                      }/users-disabled`
                    )
                  }
                >
                  Desativados
                </SubMenuButton>
              )}

              {user_new && (
                <SubMenuButton
                  type="button"
                  onClick={() =>
                    history.push(
                      `/admin/${location.pathname.split('/', 3)[2]}/users-new`
                    )
                  }
                >
                  Adicionar
                </SubMenuButton>
              )}
            </div>
          </Nav>

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {users.map(user => (
                <User
                  key={user.id}
                  user={user}
                  onUpdate={() => handleUpdate(user.id)}
                />
              ))}
              {users.length === 0 && (
                <p className="empty">Nenhum membro encontrado</p>
              )}
            </ul>
          )}
        </Section>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
