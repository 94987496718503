import React, { useState } from 'react';
import {
  MdLiveHelp,
  MdPerson,
  MdPowerSettingsNew,
  MdSettings,
  MdSubdirectoryArrowRight,
} from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  addDays,
  format,
  formatDistanceToNowStrict,
  isAfter,
  isBefore,
  parseISO,
} from 'date-fns';
import { pt } from 'date-fns/locale';

import photoDefault from '~/assets/photo-default.svg';
import Helpdesk from '~/pages/Auth/Helpdesk';
import { signInRequestAccount, signOut } from '~/store/modules/auth/actions';

import {
  AccountList,
  Button,
  Container,
  Overlay,
  Profile,
  Scroll,
} from './styles';

export default function Bill() {
  const profile = useSelector(state => state.user.profile);
  const dispatch = useDispatch();

  const [visible, setVisible] = useState(false);
  const [openHelp, setOpenHelp] = useState(false);

  const expiredIsAfter = isAfter(new Date(), new Date(profile.available));
  const expiredIsBefore = isBefore(
    addDays(new Date(), 6),
    new Date(profile.available)
  );

  const expired = formatDistanceToNowStrict(
    parseISO(format(new Date(profile.available), 'yyyy-MM-dd')),
    {
      unit: 'day',
      locale: pt,
    }
  );

  function handleSubmit(nickname) {
    dispatch(signInRequestAccount(nickname));

    setVisible(false);
  }

  function handleToggleVisible() {
    setVisible(!visible);
  }

  function handleCloseOverlay() {
    setVisible(false);
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  function handleOpenHelp() {
    setOpenHelp(true);
    setVisible(false);
  }

  function handleClose() {
    setVisible(false);
    setOpenHelp(false);
  }

  return (
    <Container>
      <Profile pro={profile.pro}>
        <Link to="##" onClick={handleToggleVisible}>
          <div className="title">
            <strong>{profile.name}</strong>
            <span>{profile.tenant}</span>

            {profile.pro && !expiredIsAfter && !expiredIsBefore && (
              <p>{`${expired} restantes para acesso na versão Pro`}</p>
            )}
          </div>

          <div className="photo">
            <img src={profile.photo ? profile.url : photoDefault} alt="" />
          </div>
        </Link>
      </Profile>

      <AccountList visible={visible}>
        <Scroll>
          {profile.tenants.map(account => (
            <button
              key={account.tenant.id}
              className="account"
              type="button"
              onClick={() => handleSubmit(account.tenant.nickname)}
              style={
                account.tenant.nickname === profile.tenant
                  ? {
                      color: profile.pro
                        ? 'var(--color-orange)'
                        : 'var(--color-blue)',
                    }
                  : {}
              }
              disabled={account.tenant.nickname === profile.tenant}
            >
              <strong>
                <MdSubdirectoryArrowRight size={18} />
                {account.tenant.nickname}
              </strong>
            </button>
          ))}
        </Scroll>

        {profile.churchPermissions.account_list && (
          <Link
            to="/admin/account/church/church-dice"
            onClick={handleToggleVisible}
          >
            <div>
              <MdSettings size={18} />
            </div>
            <strong>Conta</strong>
          </Link>
        )}

        <Link to="/profile/me" onClick={handleToggleVisible}>
          <div>
            <MdPerson size={18} />
          </div>
          <strong>Meu perfil</strong>
        </Link>

        <button type="button" className="help" onClick={handleOpenHelp}>
          <MdLiveHelp size={18} />

          <strong>Central de ajuda</strong>
        </button>

        <Button type="button" onClick={handleSignOut}>
          <MdPowerSettingsNew size={20} />
          Sair da Plataforma
        </Button>
      </AccountList>

      {openHelp && <Helpdesk open={openHelp} handleClose={handleClose} />}

      <Overlay onClick={handleCloseOverlay} visible={visible} />
    </Container>
  );
}
