import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';

import {
  Aside,
  Container,
  Main,
  Nav,
  NavTwo,
  Section,
} from '~/components/Layout/Body';
import SubMenuButton from '~/components/Layout/Buttons/SubMenuButton';
import InputSelect from '~/components/Layout/Inputs/InputSelect';
import Title from '~/components/Layout/Title';
import Block from '~/components/Pages/Praise/Block';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Praise/Menu';
import api from '~/services/api';
import { updateBlockRequest } from '~/store/modules/praise/block/actions';
import { readSongRequest } from '~/store/modules/praise/song/actions';
import { ToastError } from '~/utils/toast';

export default function BlockListFour() {
  const {
    id: idUser,
    churchPermissions: { block_new, block_list },
  } = useSelector(state => state.user.profile);

  const dispatch = useDispatch();

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [blocks, setBlocks] = useState([]);

  const [minister, setMinister] = useState();
  const [ministers, setMinisters] = useState([]);

  const minister_id = minister || idUser;

  try {
    useEffect(() => {
      async function loadMinisters() {
        const { data } = await api.get('/church/admin/users-minister');

        setMinisters(data.map(user => ({ id: user.id, title: user.name })));

        if (data[0] !== undefined) {
          setMinister(data[0].id);
        }
      }

      loadMinisters();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os ministros' });
  }

  try {
    useEffect(() => {
      async function loadBlocks() {
        const response = await api
          .get('/church/praise/blocks-four-list', {
            params: { minister_id },
          })
          .catch(async res => {
            try {
              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Praise department disabled') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Pro disabled') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setBlocks(response.data);

        setLoading(false);
      }
      loadBlocks();
    }, [minister_id]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleRead(id) {
    dispatch(readSongRequest(id));
  }

  async function handleUpdate(id) {
    dispatch(updateBlockRequest(id));
  }

  return (
    <Container>
      <Aside>
        <Menu block="block" />
      </Aside>

      <Main>
        <Title title="Bloco 4" count={`${blocks.length}`} />

        <Section>
          <Nav>
            {block_new && (
              <div>
                <SubMenuButton
                  type="button"
                  onClick={() => history.push('/block/block-new-four')}
                >
                  Adicionar
                </SubMenuButton>
              </div>
            )}
          </Nav>

          <Nav>
            {block_list && (
              <div className="block">
                <SubMenuButton
                  type="button"
                  onClick={() => history.push('/block/block-list-one')}
                >
                  Bloco 1
                </SubMenuButton>

                <SubMenuButton
                  type="button"
                  onClick={() => history.push('/block/block-list-two')}
                >
                  Bloco 2
                </SubMenuButton>

                <SubMenuButton
                  type="button"
                  onClick={() => history.push('/block/block-list-three')}
                >
                  Bloco 3
                </SubMenuButton>

                <SubMenuButton
                  focus
                  type="button"
                  onClick={() => history.push('/block/block-list-four')}
                >
                  Bloco 4
                </SubMenuButton>

                <SubMenuButton
                  type="button"
                  onClick={() => history.push('/block/block-list-five')}
                >
                  Bloco 5
                </SubMenuButton>
              </div>
            )}
          </Nav>

          <NavTwo>
            <Form>
              <div>
                <InputSelect
                  name="minister"
                  value={minister}
                  options={ministers}
                  onChange={event => setMinister(event.target.value)}
                />
              </div>
            </Form>
          </NavTwo>

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {blocks.map(block => (
                <Block
                  key={block.id}
                  block={block}
                  onReadOne={() => handleRead(block.firstSong.id)}
                  onReadTwo={() => handleRead(block.secondSong.id)}
                  onReadThree={() => handleRead(block.thirdSong.id)}
                  onReadFour={() => handleRead(block.fourthtSong.id)}
                  onReadFive={() => handleRead(block.fifthSong.id)}
                  onReadSix={() => handleRead(block.sixthSong.id)}
                  onReadSeven={() => handleRead(block.seventhSong.id)}
                  onReadEight={() => handleRead(block.eighthSong.id)}
                  onUpdate={() => handleUpdate(block.id)}
                  to="/block/block-update-four"
                />
              ))}
              {blocks.length === 0 && (
                <p className="empty">Nenhum bloco encontrado</p>
              )}
            </ul>
          )}
        </Section>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
