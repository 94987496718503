import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background: ${props =>
    props.birthday
      ? 'linear-gradient(145deg, var(--color-secondary) 50%, var(--color-blue) 100%)'
      : 'var(--color-secondary)'};

  &:hover {
    opacity: var(--opacity-semiOpaque);
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  margin-right: 1rem;

  img {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
    border: 0.2rem solid var(--color-white);
    background: var(--color-primary);

    margin-right: 1rem;

    object-fit: cover;
  }

  div.name {
    display: flex;
    flex-direction: column;

    strong {
      color: var(--color-white);

      max-width: 10ch;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    span {
      font-size: var(--fontSizes-xl);
      color: var(--color-white);
      opacity: var(--opacity-medium);
    }
  }

  @media (min-width: 370px) {
    div.name strong {
      max-width: 15ch;
    }
  }

  @media (min-width: 420px) {
    div.name strong {
      max-width: 20ch;
    }
  }

  @media (min-width: 720px) {
    div.name strong {
      max-width: 38.5ch;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;

  span {
    color: var(--color-white);
  }
`;
