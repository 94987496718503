import React, { useEffect, useState } from 'react';

import CircularProgress from '@material-ui/core/CircularProgress';

import { Aside, Container, Main, Section } from '~/components/Layout/Body';
import Title from '~/components/Layout/Title';
import Changelog from '~/components/Pages/Admin/Account/Changelog';
import Menu from '~/pages/Admin/Menu';
import api from '~/services/api';
import { ToastError } from '~/utils/toast';

export default function Changelogs() {
  const [loading, setLoading] = useState(true);

  const [changelogs, setChangelogs] = useState([]);

  try {
    useEffect(() => {
      async function loadChangelogs() {
        const response = await api
          .get('/church/admin/changelogs')
          .catch(async res => {
            try {
              if (res.response.data.error) {
                ToastError({ title: 'Erro ao carregar os dados' });

                setLoading(false);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setChangelogs(response.data);

        setLoading(false);
      }

      loadChangelogs();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  return (
    <Container>
      <Aside>
        <Menu changelogs="changelogs" />
      </Aside>

      <Main>
        <Title title="Changelog" count={`${changelogs.length}`} />

        <Section>
          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {changelogs.map(changelog => (
                <Changelog key={changelog.id} changelogs={changelog} />
              ))}
              {changelogs.length === 0 && (
                <p className="empty">Nenhum changelog encontrado</p>
              )}
            </ul>
          )}
        </Section>
      </Main>
    </Container>
  );
}
