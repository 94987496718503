import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FormControlLabel, Radio } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Layout/Body';
import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Admin/Menu';
import api from '~/services/api';
import { ToastError } from '~/utils/toast';

import { Content, Div } from './styles';

const schema = Yup.object().shape({
  date: Yup.date().required('Data é obrigatória'),
  hour: Yup.string(),
});

export default function LeadersNew() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const dateNew = { date: format(new Date(), 'yyyy-MM-dd') };

  const [users, setUsers] = useState([]);
  const [selectedLeadersFaults, setSelectedLeadersFaults] = useState([]);

  try {
    useEffect(() => {
      async function loadUsers() {
        const { data } = await api.get('/church/admin/users-active');

        setUsers(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadUsers();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os membros' });
  }

  async function handleSubmit({ date, hour }) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = utcToZonedTime(date, timezone);

    try {
      setLoading(true);

      selectedLeadersFaults.map(async user =>
        api
          .post('/church/admin/presences/leaders-new', {
            date: timezoneDate,
            hour,
            user_id: user,
          })
          .catch(async res => {
            try {
              if (res.response.data.error === 'Validation fails') {
                ToastError({
                  title:
                    'Seus dados foram recusados. Verifique-os e tente novamente',
                });

                setLoading(false);

                return;
              }

              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (
                res.response.data.error ===
                'There is already a missing user in the selected period'
              ) {
                ToastError({
                  title: 'Já existe um usuário ausente no período selecionado',
                });

                setLoading(false);

                return;
              }

              if (res.response.data.error === 'Id already exists') {
                ToastError({ title: 'Recarregue a pagina e tente novamente' });

                setLoading(false);

                return;
              }

              if (res.response.data.error === 'IdHistory already exists') {
                ToastError({ title: 'Recarregue a pagina e tente novamente' });

                setLoading(false);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao adicionar, verifique os dados' });

              setLoading(false);
            }
          })
      );

      if (selectedLeadersFaults.length === 0) {
        ToastError({ title: 'Selecione um usuário' });

        setLoading(false);

        return;
      }

      await Swal.fire({
        title: 'Adicionando Falta(s)',
        html: `Por favor, aguarde! :D`,
        timer: selectedLeadersFaults.length * 1000,
        timerProgressBar: true,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      history.push('/admin/presences/leaders/leaders-list');
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  function handleSelectLeaderFault(id) {
    const alreadySelectedFault = selectedLeadersFaults.findIndex(
      leader => leader === id
    );

    if (alreadySelectedFault >= 0) {
      const filteredLeaders = selectedLeadersFaults.filter(
        leader => leader !== id
      );

      setSelectedLeadersFaults(filteredLeaders);
    } else {
      setSelectedLeadersFaults([...selectedLeadersFaults, id]);
    }
  }

  return (
    <Container>
      <Aside>
        <Menu presenceLeader="presenceLeader" />
      </Aside>

      <Main>
        <Title title="Adicionar falta" />

        <Content>
          <Form schema={schema} initialData={dateNew} onSubmit={handleSubmit}>
            <div className="group">
              <div className="date">
                <InputText
                  label="Data"
                  name="date"
                  type="date"
                  min="2021-01-01"
                  max="9999-12-31"
                  lang="pt-BR"
                  required
                />
              </div>

              <div className="hour">
                <InputText label="Hora" name="hour" type="time" />
              </div>
            </div>

            <hr />

            {users.map(user => (
              <div key={user.id} className="group-presence">
                <Div>
                  <span
                    style={
                      selectedLeadersFaults.includes(user.id)
                        ? { color: 'var(--color-red)' }
                        : {}
                    }
                  >
                    {user.title}
                  </span>

                  <div>
                    <FormControlLabel
                      label="Falta"
                      value="falt"
                      control={
                        <Radio
                          checked={!!selectedLeadersFaults.includes(user.id)}
                          onChange={() => handleSelectLeaderFault(user.id)}
                          value={selectedLeadersFaults}
                          name="radio-buttons"
                        />
                      }
                    />

                    <FormControlLabel
                      label="Presente"
                      value="presence"
                      control={
                        <Radio
                          checked={!selectedLeadersFaults.includes(user.id)}
                          onChange={() => handleSelectLeaderFault(user.id)}
                          value={selectedLeadersFaults}
                          name="radio-buttons"
                        />
                      }
                    />
                  </div>

                  <hr />
                </Div>
              </div>
            ))}

            <div className="buttons">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <CancelButton
                  type="button"
                  onClick={() =>
                    history.push('/admin/presences/leaders/leaders-list')
                  }
                >
                  Cancelar
                </CancelButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
