import React from 'react';

import { Button } from './styles';

export default function SubmitButton({ type, onClick, children, disabled }) {
  return (
    <Button type={type} onClick={onClick} disabled={disabled}>
      {children}
    </Button>
  );
}
