import React, { useState } from 'react';

import { CircularProgress } from '@material-ui/core';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';

import { Button } from '~/components/Layout/Buttons/SubmitButton/styles';
import Dialog from '~/components/Layout/Dialog';
import InputText from '~/components/Layout/Inputs/InputText';
import api from '~/services/api';
import { phoneMask } from '~/utils/inputMask';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content } from './styles';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(10, 'Nome completo deve ter no mínimo 10 caracteres')
    .max(50, 'Nome completo deve ter no máximo 50 caracteres'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .min(7, 'E-mail deve ter no mínimo 7 caracteres')
    .max(50, 'E-mail deve ter no máximo 50 caracteres'),
  whatsapp: Yup.string().max(16, 'WhatsApp está incompleto'),
  description: Yup.string()
    .min(5, 'Descrição deve ter no mínimo 5 caracteres')
    .max(1200, 'Descrição deve ter no máximo 1200 caracteres'),
});

export default function Helpdesk({ open, handleClose }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [description, setDescription] = useState('');

  const [loading, setLoading] = useState(false);

  async function handleSubmitHelpdesk() {
    setLoading(true);

    try {
      const data = await api
        .post('/admin/helpdesk', {
          name,
          email,
          whatsapp,
          description,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) return;

      setName('');
      setEmail('');
      setWhatsapp('');
      setDescription('');

      handleClose();

      setLoading(false);

      ToastSuccess({ title: 'Dúvida enviada' });
    } catch (error) {
      ToastError({ title: 'Erro ao adicionar, verifique os dados' });

      setLoading(false);
    }
  }

  return (
    <Dialog
      open={open}
      width={60}
      title="Central de Ajuda"
      handleClose={handleClose}
    >
      <Content>
        <Form schema={schema} onSubmit={handleSubmitHelpdesk}>
          <div className="group">
            <div className="name">
              <InputText
                label="Nome completo"
                name="name"
                value={name}
                onChange={event => setName(event.target.value)}
              />
            </div>

            <div className="whatsapp">
              <InputText
                label="WhatsApp"
                name="whatsapp"
                value={whatsapp}
                onChange={event => setWhatsapp(phoneMask(event.target.value))}
              />
            </div>
          </div>

          <div className="email">
            <InputText
              label="E-mail"
              name="email"
              type="email"
              value={email}
              onChange={event => setEmail(event.target.value)}
            />
          </div>

          <InputText
            label="Descrição"
            name="description"
            placeholder="Como podemos lhe ajudar?"
            value={description}
            onChange={event => setDescription(event.target.value)}
            multiline
          />

          <Button type="submit" disabled={loading}>
            {loading ? (
              <CircularProgress
                size={24}
                style={{ color: 'var(--color-white)' }}
              />
            ) : (
              'Enviar'
            )}
          </Button>
        </Form>
      </Content>
    </Dialog>
  );
}
