import React from 'react';
import { MdHome, MdInput, MdPerson } from 'react-icons/md';

import MenuButton from '~/components/Layout/Buttons/MenuButton';

import { Content } from './styles';

export default function Menu({ profile, login }) {
  return (
    <Content>
      <MenuButton to="/dashboard">
        <MdHome size={18} /> Home
      </MenuButton>

      <MenuButton to="/profile/me" focus={profile}>
        <MdPerson size={18} /> Meu perfil
      </MenuButton>

      <MenuButton to="/profile/logins" focus={login}>
        <MdInput size={18} /> Meus login
      </MenuButton>
    </Content>
  );
}
