import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Switch from 'react-switch';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Layout/Body';
import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Media/Menu';
import api from '~/services/api';
import { updatePermissionRequest } from '~/store/modules/admin/user/actions';
import { cep, cpfMask, phoneMask } from '~/utils/inputMask';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content, Label } from '../styles';

const schema = Yup.object().shape({
  name: Yup.string()
    .min(10, 'Nome completo deve ter no mínimo 10 caracteres')
    .max(50, 'Nome completo deve ter no máximo 50 caracteres'),
  nameuser: Yup.string()
    .min(3, 'Nome de usuário deve ter no mínimo 3 caracteres')
    .max(20, 'Nome de usuário deve ter no máximo 20 caracteres'),
  email: Yup.string()
    .email('Insira um e-mail válido')
    .min(7, 'E-mail deve ter no mínimo 7 caracteres')
    .max(50, 'E-mail deve ter no máximo 50 caracteres'),
  password: Yup.string(),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'Senhas não conferem'
  ),
  document: Yup.string().max(14, 'CPF deve ser preenchido apenas números'),
  phone: Yup.string().length(16, 'Celular está incompleto'),
  birthday: Yup.date().required('Data de aniversário é obrigatória'),
  zip_code: Yup.string(),
  number: Yup.string(),
  complement: Yup.string(),

  active: Yup.boolean(),

  projection: Yup.boolean(),
  streaming: Yup.boolean(),
  footage: Yup.boolean(),
  photography: Yup.boolean(),
  stories: Yup.boolean(),
});

export default function UsersUpdateMedia() {
  const id = useSelector(state => state.user.userId);
  const {
    supervisor,
    churchPermissions: { user_update, user_delete },
  } = useSelector(state => state.user.profile);

  const dispatch = useDispatch();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [users, setUsers] = useState([]);
  const [nameusers, setNameusers] = useState([]);
  const [userDocument, setUserDocument] = useState([]);
  const [userPhone, setUserPhone] = useState([]);

  const [zip_code, setZip_code] = useState([]);
  const [street, setStreet] = useState([]);
  const [district, setDistrict] = useState([]);
  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);

  const [active, setActive] = useState(false);

  const [projection, setProjection] = useState(false);
  const [streaming, setStreaming] = useState(false);
  const [footage, setFootage] = useState(false);
  const [photography, setPhotography] = useState(false);
  const [stories, setStories] = useState(false);

  try {
    useEffect(() => {
      async function loadUserData() {
        const { data } = await api.get(`/church/admin/users-show/${id}`);

        const {
          name,
          nameuser,
          email,
          document,
          phone,
          birthday,
          created_at,
          updated_at,
          number,
          complement,
        } = data;

        const parsedBirthday = parseISO(birthday);
        const parsedCreate = parseISO(created_at);
        const parsedUpdate = parseISO(updated_at);

        const formattedBirthday = format(parsedBirthday, 'yyyy-MM-dd');
        const formattedCreate = format(parsedCreate, 'yyyy-MM-dd');
        const formattedUpdate = format(parsedUpdate, 'yyyy-MM-dd');

        const response = {
          name,
          nameuser,
          email,
          document,
          phone,
          birthday: formattedBirthday,
          created_at: formattedCreate,
          updated_at: formattedUpdate,
          number,
          complement,
        };

        setUsers(response);
        setNameusers(response.nameuser);
        setUserDocument(response.document);
        setUserPhone(response.phone);

        setZip_code(data.zip_code === null ? '' : data.zip_code);

        setActive(data.active);

        setProjection(data.projection);
        setStreaming(data.streaming);
        setFootage(data.footage);
        setPhotography(data.photography);
        setStories(data.stories);
      }

      loadUserData();
    }, [id]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  useEffect(() => {
    async function cepUser() {
      if (zip_code.length === 9) {
        const response = await axios.get(
          `https://viacep.com.br/ws/${zip_code.replace(/\D/g, '')}/json/`
        );

        const { logradouro, bairro, localidade, uf } = response.data;

        setStreet(logradouro);
        setDistrict(bairro);
        setCity(localidade);
        setState(uf);
      }
    }

    cepUser();
  }, [zip_code]);

  function handleActive() {
    if (active) {
      setActive(false);
    } else {
      setActive(true);
    }
  }

  async function handleSubmit({
    name,
    nameuser,
    email,
    document,
    phone,
    birthday,
    password,
    number,
    complement,
  }) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = utcToZonedTime(birthday, timezone);

    try {
      setLoading(true);

      const data = Object.assign(
        {
          name,
          nameuser,
          email,
          document,
          phone,
          birthday: timezoneDate,
          zip_code,
          number,
          complement,

          active,

          projection,
          streaming,
          footage,
          photography,
          stories,
        },
        password ? { password } : {}
      );

      const dataResponse = await api
        .put(`/church/admin/users-update/${id}`, data)
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Number of peoples reached') {
              ToastError({ title: 'Você atingiu o limite de pessoas' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User name already exists') {
              ToastError({ title: 'Nome de usuário já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Email already exists') {
              ToastError({ title: 'E-mail já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Document already exists') {
              ToastError({ title: 'CPF já existe' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'CPF is invalid') {
              ToastError({ title: 'CPF é inválido' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao alterar, verifique os dados' });

            setLoading(false);
          }
        });

      if (dataResponse === undefined) return;

      ToastSuccess({ title: 'Membro alterado' });

      window.history.back();
    } catch (error) {
      ToastError({ title: 'Erro ao alterar, verifique os dados' });

      setLoading(false);
    }
  }

  async function handleDelete() {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          await api.delete(`/church/admin/users-delete/${id}`);

          Swal.fire('Excluído!', 'membro excluído', 'success');

          window.history.back();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  async function handlePermissionUser(user_id) {
    dispatch(updatePermissionRequest(user_id));

    history.push('/admin/media/users-permission');
  }

  return (
    <Container>
      <Aside>
        <Menu user="user" />
      </Aside>

      <Main>
        <Title title="Alterar cadastro" />

        <Content>
          <Form schema={schema} initialData={users} onSubmit={handleSubmit}>
            <div className="group">
              <div className="name">
                <InputText label="Nome completo" name="name" />
              </div>

              <div className="nameuser">
                <InputText
                  label="Nome de usuário"
                  name="nameuser"
                  value={nameusers}
                  onChange={event =>
                    setNameusers(
                      event.target.value.toLowerCase().replace(/\s+/g, '')
                    )
                  }
                />
              </div>
            </div>

            <div className="group">
              <div className="email">
                <InputText label="E-mail" name="email" type="email" />
              </div>

              <div className="password">
                <InputText
                  label="Senha"
                  placeholder="****"
                  name="password"
                  type="password"
                  autoComplete="cc-csc"
                />
              </div>

              <div className="confirm-password">
                <InputText
                  label="Confirme a senha"
                  placeholder="****"
                  name="confirmPassword"
                  type="password"
                  autoComplete="cc-csc"
                />
              </div>
            </div>

            <div className="group">
              <div className="document">
                <InputText
                  label="CPF"
                  placeholder="000.000.000-00"
                  name="document"
                  value={userDocument}
                  onChange={event =>
                    setUserDocument(cpfMask(event.target.value))
                  }
                />
              </div>

              <div className="phone">
                <InputText
                  label="Celular"
                  name="phone"
                  value={userPhone}
                  onChange={event =>
                    setUserPhone(phoneMask(event.target.value))
                  }
                />
              </div>

              <div className="birthday">
                <InputText
                  label="Data de aniversário"
                  name="birthday"
                  type="date"
                  max="9999-12-31"
                  lang="pt-BR"
                  required
                />
              </div>
            </div>

            <div className="group">
              <div className="zip_code">
                <InputText
                  label="CEP"
                  name="zip_code"
                  value={zip_code}
                  onChange={event => setZip_code(cep(event.target.value))}
                />
              </div>

              <div className="street">
                <InputText
                  label="Rua"
                  title="Rua preenchida automaticamente de acordo com o CEP"
                  name="street"
                  value={street}
                  disabled
                />
              </div>
            </div>

            <div className="group">
              <div className="number">
                <InputText label="Número" name="number" type="number" />
              </div>

              <div className="complement">
                <InputText label="Complemento" name="complement" />
              </div>
            </div>

            <div className="group">
              <div className="district">
                <InputText
                  label="Bairro"
                  title="Bairro preenchido automaticamente de acordo com o CEP"
                  name="district"
                  value={district}
                  disabled
                />
              </div>

              <div className="city">
                <InputText
                  label="Cidade"
                  title="Cidade preenchida automaticamente de acordo com o CEP"
                  name="city"
                  value={city}
                  disabled
                />
              </div>

              <div className="state">
                <InputText
                  label="UF"
                  title="UF preenchido automaticamente de acordo com o CEP"
                  name="state"
                  value={state}
                  disabled
                />
              </div>
            </div>

            <div className="group">
              <div className="created">
                <InputText
                  label="Cadastrado"
                  title="Data que foi cadastro"
                  name="created_at"
                  type="date"
                  disabled
                />
              </div>

              <div className="updated">
                <InputText
                  label="Alterado"
                  title="Data que foi alterado"
                  name="updated_at"
                  type="date"
                  disabled
                />
              </div>
            </div>

            <hr />

            <div className="title">
              <strong>Membro</strong>
            </div>

            <div className="group-switch">
              <div>
                <Label>Ativo</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={handleActive}
                    checked={active}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="title">
              <strong>Departamento</strong>
            </div>

            <div className="group-switch">
              <div>
                <Label>Projeção</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={() => setProjection(!projection)}
                    checked={projection}
                  />
                </div>
              </div>

              <div>
                <Label>Transmissão</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={() => setStreaming(!streaming)}
                    checked={streaming}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="group-switch">
              <div>
                <Label>Filmagem</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={() => setFootage(!footage)}
                    checked={footage}
                  />
                </div>
              </div>

              <div>
                <Label>Fotografia</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={() => setPhotography(!photography)}
                    checked={photography}
                  />
                </div>
              </div>

              <div>
                <Label>Stories</Label>
                <div className="permission">
                  <Switch
                    height={15}
                    width={40}
                    handleDiameter={20}
                    onColor="#0195F6"
                    offColor="#121214"
                    onChange={() => setStories(!stories)}
                    checked={stories}
                  />
                </div>
              </div>
            </div>

            <hr />

            <div className="delete">
              {supervisor && user_delete && (
                <button
                  type="button"
                  className="delete"
                  onClick={() => handleDelete()}
                >
                  Excluir membro
                </button>
              )}

              {user_update && (
                <button
                  type="button"
                  className="delete"
                  onClick={() => handlePermissionUser(id)}
                  style={{ color: 'var(--color-blue)' }}
                >
                  Alterar permissão
                </button>
              )}
            </div>

            <hr />

            <div className="buttons">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <CancelButton
                  type="button"
                  onClick={() => window.history.back()}
                >
                  Cancelar
                </CancelButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
