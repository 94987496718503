import React from 'react';
import ReactPlayer from 'react-player';

import Markdown from '~/components/Layout/Markdown';

import { Container } from './styles';

export default function Changelog({ changelogs }) {
  return (
    <Container>
      <div className="version">
        <strong>{changelogs.version}</strong>
      </div>

      {changelogs.link && (
        <div className="div-player">
          <ReactPlayer url={changelogs.link} playing controls light pip />
        </div>
      )}

      <div>
        <pre>
          <Markdown data={changelogs.change} />
        </pre>
      </div>
    </Container>
  );
}
