import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

export default function BlockThree({
  today,
  scale,
  blockThreeReadOne,
  blockThreeReadTwo,
  blockThreeReadThree,
  blockThreeReadFour,
  blockThreeReadFive,
  blockThreeReadSix,
  blockThreeReadSeven,
  blockThreeReadEight,
}) {
  return (
    <Container today={today}>
      {scale.blockThree.firstSong && (
        <div>
          <div className="group">
            <p>1.</p>

            <Link to="/song/song-read" onClick={blockThreeReadOne}>
              {scale.blockThree.firstSong
                ? scale.blockThree.firstSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockThree.firstSong
                ? scale.blockThree.firstSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockThree.firstSong
              ? scale.blockThree.firstSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockThree.secondSong && (
        <div>
          <div className="group">
            <p>2.</p>

            <Link to="/song/song-read" onClick={blockThreeReadTwo}>
              {scale.blockThree.secondSong
                ? scale.blockThree.secondSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockThree.secondSong
                ? scale.blockThree.secondSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockThree.secondSong
              ? scale.blockThree.secondSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockThree.thirdSong && (
        <div>
          <div className="group">
            <p>3.</p>

            <Link to="/song/song-read" onClick={blockThreeReadThree}>
              {scale.blockThree.thirdSong
                ? scale.blockThree.thirdSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockThree.thirdSong
                ? scale.blockThree.thirdSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockThree.thirdSong
              ? scale.blockThree.thirdSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockThree.fourthtSong && (
        <div>
          <div className="group">
            <p>4.</p>

            <Link to="/song/song-read" onClick={blockThreeReadFour}>
              {scale.blockThree.fourthtSong
                ? scale.blockThree.fourthtSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockThree.fourthtSong
                ? scale.blockThree.fourthtSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockThree.fourthtSong
              ? scale.blockThree.fourthtSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockThree.fifthSong && (
        <div>
          <div className="group">
            <p>5.</p>

            <Link to="/song/song-read" onClick={blockThreeReadFive}>
              {scale.blockThree.fifthSong
                ? scale.blockThree.fifthSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockThree.fifthSong
                ? scale.blockThree.fifthSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockThree.fifthSong
              ? scale.blockThree.fifthSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockThree.sixthSong && (
        <div>
          <div className="group">
            <p>6.</p>

            <Link to="/song/song-read" onClick={blockThreeReadSix}>
              {scale.blockThree.sixthSong
                ? scale.blockThree.sixthSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockThree.sixthSong
                ? scale.blockThree.sixthSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockThree.sixthSong
              ? scale.blockThree.sixthSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockThree.seventhSong && (
        <div>
          <div className="group">
            <p>7.</p>

            <Link to="/song/song-read" onClick={blockThreeReadSeven}>
              {scale.blockThree.seventhSong
                ? scale.blockThree.seventhSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockThree.seventhSong
                ? scale.blockThree.seventhSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockThree.seventhSong
              ? scale.blockThree.seventhSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockThree.eighthSong && (
        <div>
          <div className="group">
            <p>8.</p>

            <Link to="/song/song-read" onClick={blockThreeReadEight}>
              {scale.blockThree.eighthSong
                ? scale.blockThree.eighthSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockThree.eighthSong
                ? scale.blockThree.eighthSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockThree.eighthSong
              ? scale.blockThree.eighthSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}
    </Container>
  );
}
