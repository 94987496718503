import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';

export default function BlockOne({
  today,
  scale,
  blockOneReadOne,
  blockOneReadTwo,
  blockOneReadThree,
  blockOneReadFour,
  blockOneReadFive,
  blockOneReadSix,
  blockOneReadSeven,
  blockOneReadEight,
}) {
  return (
    <Container today={today}>
      {scale.blockOne.firstSong && (
        <div>
          <div className="group">
            <p>1.</p>

            <Link to="/song/song-read" onClick={blockOneReadOne}>
              {scale.blockOne.firstSong ? scale.blockOne.firstSong.name : ''}
            </Link>

            <strong>
              {scale.blockOne.firstSong ? scale.blockOne.firstSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockOne.firstSong ? scale.blockOne.firstSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockOne.secondSong && (
        <div>
          <div className="group">
            <p>2.</p>

            <Link to="/song/song-read" onClick={blockOneReadTwo}>
              {scale.blockOne.secondSong ? scale.blockOne.secondSong.name : ''}
            </Link>

            <strong>
              {scale.blockOne.secondSong ? scale.blockOne.secondSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockOne.secondSong ? scale.blockOne.secondSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockOne.thirdSong && (
        <div>
          <div className="group">
            <p>3.</p>

            <Link to="/song/song-read" onClick={blockOneReadThree}>
              {scale.blockOne.thirdSong ? scale.blockOne.thirdSong.name : ''}
            </Link>

            <strong>
              {scale.blockOne.thirdSong ? scale.blockOne.thirdSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockOne.thirdSong ? scale.blockOne.thirdSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockOne.fourthtSong && (
        <div>
          <div className="group">
            <p>4.</p>

            <Link to="/song/song-read" onClick={blockOneReadFour}>
              {scale.blockOne.fourthtSong
                ? scale.blockOne.fourthtSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockOne.fourthtSong
                ? scale.blockOne.fourthtSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockOne.fourthtSong
              ? scale.blockOne.fourthtSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockOne.fifthSong && (
        <div>
          <div className="group">
            <p>5.</p>

            <Link to="/song/song-read" onClick={blockOneReadFive}>
              {scale.blockOne.fifthSong ? scale.blockOne.fifthSong.name : ''}
            </Link>

            <strong>
              {scale.blockOne.fifthSong ? scale.blockOne.fifthSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockOne.fifthSong ? scale.blockOne.fifthSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockOne.sixthSong && (
        <div>
          <div className="group">
            <p>6.</p>

            <Link to="/song/song-read" onClick={blockOneReadSix}>
              {scale.blockOne.sixthSong ? scale.blockOne.sixthSong.name : ''}
            </Link>

            <strong>
              {scale.blockOne.sixthSong ? scale.blockOne.sixthSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockOne.sixthSong ? scale.blockOne.sixthSong.author : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockOne.seventhSong && (
        <div>
          <div className="group">
            <p>7.</p>

            <Link to="/song/song-read" onClick={blockOneReadSeven}>
              {scale.blockOne.seventhSong
                ? scale.blockOne.seventhSong.name
                : ''}
            </Link>

            <strong>
              {scale.blockOne.seventhSong
                ? scale.blockOne.seventhSong.tone
                : ''}
            </strong>
          </div>

          <span>
            {scale.blockOne.seventhSong
              ? scale.blockOne.seventhSong.author
              : ''}
          </span>

          <hr />
        </div>
      )}

      {scale.blockOne.eighthSong && (
        <div>
          <div className="group">
            <p>8.</p>

            <Link to="/song/song-read" onClick={blockOneReadEight}>
              {scale.blockOne.eighthSong ? scale.blockOne.eighthSong.name : ''}
            </Link>

            <strong>
              {scale.blockOne.eighthSong ? scale.blockOne.eighthSong.tone : ''}
            </strong>
          </div>

          <span>
            {scale.blockOne.eighthSong ? scale.blockOne.eighthSong.author : ''}
          </span>
        </div>
      )}
    </Container>
  );
}
