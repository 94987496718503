import React from 'react';

import { Titles } from './styles';

export default function Title({ title, count, style }) {
  return (
    <Titles style={style}>
      <strong>{title}</strong>
      {count && <span>{count}</span>}
    </Titles>
  );
}
