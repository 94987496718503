import styled, { css } from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border-left: 0.1rem solid var(--color-red);
  background: var(--color-primary);

  &:hover {
    opacity: var(--opacity-semiOpaque);
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    button {
      border-style: initial;
      border-color: initial;
      border-image: initial;
      margin-left: 1rem;

      background: none;
      &:hover {
        opacity: var(--opacity-medium);
      }
    }
  }
`;

export const Header = styled.div`
  display: flex;

  div {
    strong {
      display: inline-block;
      padding: 0 1rem;
      font-size: var(--fontSizes-2xl);
      ${props =>
        props.shepherd &&
        css`
          color: var(--color-blue);
          content: '';
        `}
    }

    strong.tone {
      color: var(--color-yellow);
    }

    span {
      margin-left: 1rem;
      font-size: var(--fontSizes-2xl);
      color: white;
      opacity: var(--opacity-medium);
    }
  }
`;
