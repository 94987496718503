import React, { useEffect, useMemo, useState } from 'react';
import { MdNotifications } from 'react-icons/md';
import { useSelector } from 'react-redux';

import { formatDistance, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import socketio from 'socket.io-client';

import api from '~/services/api';
import { ToastInfo } from '~/utils/toast';

import {
  Badge,
  Container,
  Empty,
  Notification,
  NotificationList,
  Overlay,
  Scroll,
  Title,
} from './styles';

export default function Notifications() {
  const profile = useSelector(state => state.user.profile);

  const [visible, setVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);

  const countNotifications = useMemo(
    () => notifications.filter(notification => !notification.read).length,
    [notifications]
  );

  const socket = useMemo(
    () =>
      socketio(process.env.REACT_APP_API_URL, {
        query: {
          connectedUser_id: profile.id,
        },
      }),
    [profile.id]
  );

  useEffect(() => {
    socket.on('church-notification', notification => {
      setNotifications([notification, ...notifications]);

      ToastInfo({ title: 'Nova notificação' });
    });
  }, [notifications, socket]);

  useEffect(() => {
    async function loadNotifications() {
      const response = await api.get('/church/user/scale-notifications');

      const data = response.data.map(notification => ({
        ...notification,
        timeDistance: formatDistance(
          parseISO(notification.createdAt),
          new Date(),
          { addSuffix: true, locale: pt }
        ),
      }));

      setNotifications(data);
    }

    loadNotifications();
  }, []);

  function handleToggleVisible() {
    setVisible(!visible);
  }

  function handleCloseOverlay() {
    setVisible(false);
  }

  async function handleMarkAsRead(id) {
    await api.put(`/church/user/scale-notification/${id}`);

    setNotifications(
      notifications.map(notification =>
        notification._id === id ? { ...notification, read: true } : notification
      )
    );
  }

  return (
    <Container>
      <Badge onClick={handleToggleVisible} title="Notificações" press={visible}>
        {countNotifications > 0 && (
          <div>
            <span>{countNotifications}</span>
          </div>
        )}
        <MdNotifications size={24} />
      </Badge>

      <NotificationList visible={visible}>
        <Title>
          <strong>Notificações</strong>
        </Title>

        <Scroll>
          {notifications.map(notification => (
            <Notification key={notification._id} unread={!notification.read}>
              <p>
                {notification.content} | <span>{notification.to}</span>
              </p>
              <time>{notification.timeDistance}</time>
              {!notification.read && (
                <button
                  type="button"
                  onClick={() => handleMarkAsRead(notification._id)}
                >
                  Marcar como lida
                </button>
              )}
            </Notification>
          ))}
        </Scroll>

        {notifications.length === 0 && (
          <Empty>
            <strong>Oooh!</strong>

            <p>Ainda não tem nada por aqui</p>
          </Empty>
        )}
      </NotificationList>

      <Overlay onClick={handleCloseOverlay} visible={visible} />
    </Container>
  );
}
