import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import * as Yup from 'yup';

import { Aside, Container, Main } from '~/components/Layout/Body';
import ButtonSelectRemove from '~/components/Layout/Buttons/ButtonSelectRemove';
import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputSelect from '~/components/Layout/Inputs/InputSelect';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Minister/Menu';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content } from './styles';

const schema = Yup.object().shape({
  description: Yup.string()
    .required('Descrição é obrigatória')
    .max(254, 'Descrição deve ter no máximo 254 caracteres'),
  available: Yup.date().required(),
  hour: Yup.string().required('Selecione o horário'),
  minister_one: Yup.string().required('Selecione um ministro'),
  minister_two: Yup.string(),
  theme: Yup.string().max(254, 'Tema deve ter no máximo 254 caracteres'),
  note: Yup.string().max(1200, 'Observação deve ter no máximo 1200 caracteres'),
});

export default function ScalesNew() {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const dateNew = { available: format(new Date(), 'yyyy-MM-dd') };

  const [description, setDescription] = useState('');
  const [minister_one, setMinister_one] = useState();
  const [minister_two, setMinister_two] = useState();
  const [theme, setTheme] = useState('');
  const [note, setNote] = useState('');

  const [ministers, setMinisters] = useState([]);

  try {
    useEffect(() => {
      async function loadMinisters() {
        const { data } = await api.get('/church/admin/users-minister-word');

        setMinisters(data.map(user => ({ id: user.id, title: user.name })));
      }

      loadMinisters();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os ministros' });
  }

  async function handleSubmit({ available, hour }) {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = utcToZonedTime(available, timezone);

    try {
      setLoading(true);

      const data = await api
        .post('/church/admin/scales-new', {
          description,
          available: timezoneDate,
          hour,
          minister_one,
          minister_two,
          theme,
          note,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              setMessage('Autenticação falhou. Entre em contato com seu líder');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Pro disabled') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant disabled') {
              setMessage('Conta expirou. Entre em contato com o administrador');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant expired') {
              setMessage(
                'Plano Pro expirou. Entre em contato com o administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Past dates are not permitted') {
              ToastError({
                title: 'Datas passadas não são permitidas',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) return;

      ToastSuccess({ title: 'Escala adicionada' });

      setDescription('');
      setMinister_two('');
      setTheme('');
      setNote('');

      setLoading(false);
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  return (
    <Container>
      <Aside>
        <Menu scale="scale" />
      </Aside>

      <Main>
        <Title title="Adicionar escala" />

        <Content>
          <Form schema={schema} initialData={dateNew} onSubmit={handleSubmit}>
            <div className="group">
              <div className="description">
                <InputText
                  label="Descrição"
                  name="description"
                  value={description}
                  onChange={event => setDescription(event.target.value)}
                />
              </div>

              <div className="date">
                <InputText
                  label="Data"
                  name="available"
                  type="date"
                  min="2021-01-01"
                  max="9999-12-31"
                  lang="pt-BR"
                  required
                />

                <InputText label="Hora" name="hour" type="time" multiple />
              </div>
            </div>

            <div className="group">
              <div className="minister-one">
                <InputSelect
                  label="Ministro da palavra 1"
                  name="minister_one"
                  options={ministers}
                  onChange={event => setMinister_one(event.target.value)}
                />
              </div>

              <div className="minister-two">
                <InputSelect
                  label="Ministro da palavra 2"
                  name="minister-two"
                  value={minister_two}
                  options={ministers}
                  onChange={event => setMinister_two(event.target.value)}
                />

                <ButtonSelectRemove
                  className="remove"
                  type="button"
                  onClick={() => setMinister_two('')}
                >
                  X
                </ButtonSelectRemove>
              </div>
            </div>

            <div className="group">
              <div className="theme">
                <InputText
                  label="Tema"
                  name="theme"
                  value={theme}
                  onChange={event => setTheme(event.target.value)}
                />
              </div>
            </div>

            <div className="group">
              <div className="note">
                <InputText
                  label="Observação"
                  name="note"
                  value={note}
                  onChange={event => setNote(event.target.value)}
                  multiline
                />
              </div>
            </div>

            <hr />

            <div className="buttons">
              <div>
                <SubmitButton type="submit" disabled={loading}>
                  {loading ? (
                    <CircularProgress
                      size={24}
                      style={{ color: 'var(--color-white)' }}
                    />
                  ) : (
                    'Salvar'
                  )}
                </SubmitButton>
              </div>

              <div className="cancel">
                <CancelButton
                  type="button"
                  onClick={() => history.push('/minister/scale/scales-list')}
                >
                  Cancelar
                </CancelButton>
              </div>
            </div>
          </Form>
        </Content>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
