import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@material-ui/core/CircularProgress';
import { useField } from '@rocketseat/unform';
import { utcToZonedTime } from 'date-fns-tz';

import photoDefault from '~/assets/photo-default.svg';
import api from '~/services/api';
import { updateProfileRequest } from '~/store/modules/admin/user/actions';

import { Container } from './styles';

export default function PhotoInput() {
  const profile = useSelector(state => state.user.profile);

  const dispatch = useDispatch();

  const { defaultValue, registerField } = useField('url');

  const [file, setFile] = useState(profile.photo);
  const [preview, setPreview] = useState(profile.photo && defaultValue);

  const [progress, setProgress] = useState(false);

  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: 'photo',
        ref: ref.current,
        path: 'dataset.file',
      });
    }
  }, [ref, registerField]);

  async function handleChange(event) {
    setProgress(true);

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const timezoneDate = utcToZonedTime(profile.birthday, timezone);

    const data = new FormData();

    data.append('file', event.target.files[0]);

    const response = await api.put('/church/user/photo', data);

    const { photo, url } = await response.data;

    setFile(photo);
    setPreview(url);

    dispatch(
      updateProfileRequest({
        name: profile.name,
        nameuser: profile.nameuser,
        email: profile.email,
        document: profile.document,
        phone: profile.phone,
        birthday: timezoneDate,
        photo,
      })
    );

    setProgress(false);
  }

  return (
    <Container>
      <label htmlFor="photo">
        {progress ? (
          <CircularProgress style={{ color: 'var(--color-blue)' }} />
        ) : (
          <div>
            <img title="Alterar foto" src={preview || photoDefault} alt="" />
          </div>
        )}

        <input
          type="file"
          id="photo"
          accept="image/*"
          data-file={file}
          onChange={handleChange}
          ref={ref}
          disabled={progress}
        />
      </label>
    </Container>
  );
}
