import React, { useState } from 'react';
import { MdModeEdit } from 'react-icons/md';
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { formatDistance, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import InputText from '~/components/Layout/Inputs/InputText';
import Markdown from '~/components/Layout/Markdown';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import PostComment from './Comment';
import { Container } from './styles';

export default function Post({
  post,
  postComments,
  onUpdate,
  handleComment,
  comment,
}) {
  const {
    churchPermissions: { post_praise_update },
  } = useSelector(state => state.user.profile);

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [comments, setComments] = useState('');
  const [borderInput, setBorderInput] = useState(false);

  const formattedDate = formatDistance(parseISO(post.updated_at), new Date(), {
    addSuffix: true,
    locale: pt,
  });

  async function handleSubmit() {
    try {
      setLoading(true);

      if (comments.length < 5) {
        setLoading(false);
        setBorderInput(true);

        setTimeout(() => setBorderInput(false), 5000);

        return;
      }

      const data = await api
        .post('/church/praise/post-comments-new', {
          post_id: post.id,
          title: post.title,
          comment: comments,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'User disabled') {
              setMessage('Autenticação falhou. Entre em contato com seu líder');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Pro disabled') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant disabled') {
              setMessage('Conta expirou. Entre em contato com o administrador');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Tenant expired') {
              setMessage(
                'Plano Pro expirou. Entre em contato com o administrador'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Id already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) {
        setLoading(false);

        return;
      }

      ToastSuccess({ title: 'Comentário adicionado' });

      setComments('');
      handleComment(false);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  return (
    <Container>
      <div className="title">
        <strong>{post.title}</strong>

        {post_praise_update && (
          <Link
            title="Alterar postagem"
            to="/post/post-update"
            onClick={onUpdate}
          >
            <MdModeEdit size={20} />
          </Link>
        )}
      </div>

      {post.link && (
        <div className="div-player">
          <ReactPlayer url={post.link} playing controls light pip />
        </div>
      )}

      <div>
        <pre>
          <Markdown data={post.description} />
        </pre>
      </div>

      <div>
        <span>{formattedDate}</span>
      </div>

      <div>
        <SubmitButton type="submit" onClick={handleComment}>
          {loading ? (
            <CircularProgress
              size={24}
              style={{ color: 'var(--color-white)' }}
            />
          ) : (
            'Comentar'
          )}
        </SubmitButton>

        <span>{`${postComments.length} ${'comentários'}`}</span>
      </div>

      {comment && (
        <div className="comment">
          <div className="input-button">
            <div className="div-input">
              <InputText
                name="post"
                placeholder="Comentário ..."
                onChange={event => setComments(event.target.value)}
                style={
                  borderInput ? { border: '0.1rem solid var(--color-red)' } : {}
                }
              />
            </div>

            <SubmitButton type="button" onClick={() => handleSubmit()}>
              {loading ? (
                <CircularProgress
                  size={24}
                  style={{ color: 'var(--color-white)' }}
                />
              ) : (
                'Enviar'
              )}
            </SubmitButton>
          </div>

          {borderInput && (
            <span>Comentário deve ter no mínimo 5 caracteres</span>
          )}
        </div>
      )}

      {postComments.map(postComment => (
        <PostComment
          key={postComment.id}
          post={post}
          postComment={postComment}
          handleComment={handleComment}
        />
      ))}

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
