import React from 'react';
import {
  // MdQuestionAnswer,
  MdAssignment,
  MdGroup,
  MdMusicNote,
  MdViewAgenda,
} from 'react-icons/md';
import { useSelector } from 'react-redux';

import MenuButton from '~/components/Layout/Buttons/MenuButton';

import { Content } from './styles';

export default function Menu({ song, scale, block, user }) {
  const {
    churchPermissions: {
      scale_praise_list,
      user_list,
      song_list,
      block_list,
      // post_praise_list,
    },
  } = useSelector(state => state.user.profile);

  return (
    <Content>
      <div>
        {scale_praise_list && (
          <MenuButton to="/scale/scale-list" focus={scale}>
            <MdAssignment size={18} /> Escalas
          </MenuButton>
        )}

        {user_list && (
          <MenuButton to="/admin/praise/users-active" focus={user}>
            <MdGroup size={18} /> Membros
          </MenuButton>
        )}

        {song_list && (
          <MenuButton to="/song/song-active" focus={song}>
            <MdMusicNote size={18} /> Músicas
          </MenuButton>
        )}

        {block_list && (
          <MenuButton to="/block/block-list-one" focus={block}>
            <MdViewAgenda size={18} /> Blocos
          </MenuButton>
        )}

        {/* {post_praise_list && (
          <MenuButton to="/post/post-list" focus={post}>
            <MdQuestionAnswer size={18} /> Postagem
          </MenuButton>
        )} */}
      </div>
    </Content>
  );
}
