import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import Swal from 'sweetalert2/src/sweetalert2';

import { Aside, Container, Main, Nav, Section } from '~/components/Layout/Body';
import SubMenuButton from '~/components/Layout/Buttons/SubMenuButton';
import Title from '~/components/Layout/Title';
import Song from '~/components/Pages/Admin/Account/SongArchived';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Admin/Menu';
import api from '~/services/api';
import { ToastError } from '~/utils/toast';

export default function SongsArchived() {
  const {
    praise_department,
    pro,
    churchPermissions: { account_list, user_update, song_update },
  } = useSelector(state => state.user.profile);

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [songs, setSongs] = useState([]);

  try {
    useEffect(() => {
      async function loadSongs() {
        const response = await api
          .get('/church/praise/songs-archived')
          .catch(async res => {
            try {
              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Praise department disabled') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Pro disabled') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setSongs(response.data);

        setLoading(false);
      }

      loadSongs();
    }, []);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleUnarchive(id) {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'A música voltará para lista de desativadas',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Desarquivar',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          await api.put(`/church/praise/songs-unarchive/${id}`);

          setSongs(songs.filter(song => song.id !== id));

          Swal.fire('Desarquivada!', 'A música foi desarquivada', 'success');
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Container>
      <Aside>
        <Menu church="church" />
      </Aside>

      <Main>
        <Title title="Músicas arquivadas" count={`${songs.length}`} />

        <Section>
          <Nav>
            <div>
              {account_list && (
                <SubMenuButton
                  type="button"
                  onClick={() =>
                    history.push('/admin/account/church/church-dice')
                  }
                >
                  Conta
                </SubMenuButton>
              )}

              {user_update && (
                <SubMenuButton
                  type="button"
                  onClick={() =>
                    history.push('/admin/account/archiveds/users-archived')
                  }
                >
                  Membros
                </SubMenuButton>
              )}

              {pro && praise_department && song_update && (
                <SubMenuButton
                  focus
                  type="button"
                  onClick={() =>
                    history.push('/admin/account/archiveds/songs-archived')
                  }
                >
                  Músicas
                </SubMenuButton>
              )}
            </div>
          </Nav>

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {songs.map(song => (
                <Song
                  key={song.id}
                  song={song}
                  onUnarchive={() => handleUnarchive(song.id)}
                />
              ))}
              {songs.length === 0 && (
                <p className="empty">Nenhuma música encontrada</p>
              )}
            </ul>
          )}
        </Section>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
