const date = [
  {
    id: 1,
    description: 'Cadastro de membros',
    basic: '√',
    pro: '√',
  },
  {
    id: 2,
    description: 'Controle de presenças',
    basic: '√',
    pro: '√',
  },
  {
    id: 3,
    description: 'Controle de faltas',
    basic: '√',
    pro: '√',
  },
  {
    id: 4,
    description: 'Histórico de login',
    basic: '√',
    pro: '√',
  },
  {
    id: 5,
    description: 'Histórico de alterações',
    basic: '√',
    pro: '√',
  },
  {
    id: 6,
    description: 'Lista de aniversariantes',
    basic: '√',
    pro: '√',
  },
  {
    id: 7,
    description: 'Adicionar orações',
    basic: '√',
    pro: '√',
  },
  {
    id: 8,
    description: 'Dashboard de presenças',
    basic: '√',
    pro: '√',
  },
  {
    id: 9,
    description: '• Presenças mensal',
    basic: '√',
    pro: '√',
  },

  // Pro

  {
    id: 101,
    description: '• Presenças últimos 6 meses',
    basic: '-',
    pro: '√',
  },
  {
    id: 102,
    description: '• Presenças últimos 12 meses',
    basic: '-',
    pro: '√',
  },
  {
    id: 103,
    description: 'Escala de ministros',
    basic: '-',
    pro: '√',
  },
  {
    id: 104,
    description: 'Escala de louvor',
    basic: '-',
    pro: '√',
  },
  {
    id: 105,
    description: '• Cadastro de músicas',
    basic: '-',
    pro: '√',
  },
  {
    id: 106,
    description: '• Cadastro de blocos',
    basic: '-',
    pro: '√',
  },
  {
    id: 107,
    description: 'Escala de mídia',
    basic: '-',
    pro: '√',
  },
  {
    id: 108,
    description: 'Cadastro de treinamentos',
    basic: '-',
    pro: '√',
  },
];

export default date;
