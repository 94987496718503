import styled from 'styled-components';

export const Container = styled.div`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    padding: 1rem 0;
  }

  a,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--color-blue);
  }

  li {
    margin-left: 2rem;
  }

  code {
    font-size: var(--fontSizes-2xl);
  }

  code {
    color: var(--color-yellow);
  }
`;
