import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import CircularProgress from '@material-ui/core/CircularProgress';
import { Form } from '@rocketseat/unform';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

import {
  Aside,
  Container,
  Main,
  Nav,
  NavTwo,
  Section,
} from '~/components/Layout/Body';
import SubMenuButton from '~/components/Layout/Buttons/SubMenuButton';
import InputText from '~/components/Layout/Inputs/InputText';
import Title from '~/components/Layout/Title';
import PresenceLeaders from '~/components/Pages/Admin/PresenceLeader';
import UserLogout from '~/components/UserLogout';
import Menu from '~/pages/Admin/Menu';
import api from '~/services/api';
import { ToastError } from '~/utils/toast';

import LeadersUpdate from '../LeadersUpdate';

export default function LeadersList() {
  const {
    churchPermissions: { presence_leader_list, presence_leader_new },
  } = useSelector(state => state.user.profile);

  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [openUpdate, setOpenUpdate] = useState(false);

  const [presenceLeaders, setPresenceLeaders] = useState([]);
  const [presenceLeaderUpdate, setPresenceLeaderUpdate] = useState([]);

  const currentDate = format(new Date(), 'yyyy-MM');

  const [month, setMonth] = useState(currentDate);

  try {
    useEffect(() => {
      async function loadPresenceLeaders() {
        if (month.length !== currentDate.length) {
          return;
        }

        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const monthResult = utcToZonedTime(month, timezone);

        setLoading(true);
        const response = await api
          .get('/church/admin/presences/leaders-list', {
            params: {
              month: monthResult,
            },
          })
          .catch(async res => {
            try {
              if (res.response.data.error === 'User without permission') {
                setMessage(
                  'Você não possui permissão para este tipo de operação'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'User disabled') {
                setMessage(
                  'Autenticação falhou. Entre em contato com seu líder'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant disabled') {
                setMessage(
                  'Conta expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Tenant expired') {
                setMessage(
                  'Plano Pro expirou. Entre em contato com o administrador'
                );

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token not provided') {
                setMessage('Autenticação falhou, refaça seu login');

                setLogout(true);

                return;
              }

              if (res.response.data.error === 'Token invalid') {
                setMessage('Autenticação inválida, refaça seu login');

                setLogout(true);

                return;
              }
            } catch (error) {
              ToastError({ title: 'Erro ao carregar os dados' });

              setLoading(false);
            }
          });

        if (response === undefined) {
          setLoading(false);

          return;
        }

        setPresenceLeaders(response.data);

        setLoading(false);
      }

      loadPresenceLeaders();
    }, [currentDate.length, month]);
  } catch (error) {
    ToastError({ title: 'Erro ao carregar os dados' });
  }

  async function handleUpdate(id) {
    const { data } = await api.get(
      `/church/admin/presences/leaders-show/${id}`
    );

    setPresenceLeaderUpdate(data);

    setOpenUpdate(true);
  }

  function handleDataUpdate(data) {
    const removePresenceLeaderUpdate = presenceLeaders.filter(
      presence => presence.id !== data.id
    );

    setPresenceLeaders([data, ...removePresenceLeaderUpdate]);
  }

  function handleDataDelete(data) {
    setPresenceLeaders(
      presenceLeaders.filter(presence => presence.id !== data.id)
    );
  }

  function handleClose() {
    setOpenUpdate(false);
  }

  return (
    <Container>
      <Aside>
        <Menu presenceLeader="presenceLeader" />
      </Aside>

      <Main>
        <Title title="Faltas" count={`${presenceLeaders.length}`} />

        <Section>
          <Nav>
            <div>
              {presence_leader_new && (
                <SubMenuButton
                  type="button"
                  onClick={() =>
                    history.push('/admin/presences/leaders/leaders-new')
                  }
                >
                  Adicionar
                </SubMenuButton>
              )}
            </div>
          </Nav>

          <NavTwo>
            <Form>
              <div>
                {presence_leader_list && (
                  <InputText
                    name="month"
                    type="month"
                    value={month}
                    onChange={event => setMonth(event.target.value)}
                    lang="pt-BR"
                    required
                  />
                )}
              </div>
            </Form>
          </NavTwo>

          {openUpdate && (
            <LeadersUpdate
              open={openUpdate}
              handleClose={handleClose}
              handleDataUpdate={handleDataUpdate}
              presenceLeaderUpdate={presenceLeaderUpdate}
              handleDataDelete={handleDataDelete}
            />
          )}

          {loading ? (
            <div className="loading">
              <CircularProgress style={{ color: 'var(--color-blue)' }} />
            </div>
          ) : (
            <ul>
              {presenceLeaders.map(presenceLeader => (
                <PresenceLeaders
                  key={presenceLeader.id}
                  presenceLeader={presenceLeader}
                  onUpdate={() => handleUpdate(presenceLeader.id)}
                />
              ))}
              {presenceLeaders.length === 0 && (
                <p className="empty">Nenhuma falta encontrada</p>
              )}
            </ul>
          )}
        </Section>
      </Main>

      {logout && <UserLogout message={message} />}
    </Container>
  );
}
