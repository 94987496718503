import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 0.5rem;
  background: var(--color-primary);
  border-left: 0.1rem solid var(--color-blue);

  a {
    width: 100%;
  }

  button {
    padding: 3rem 1.5rem;
    height: 98%;

    display: flex;
    align-items: center;

    border: none;
    background: transparent;
    color: var(--color-white);

    &:hover {
      background: var(--color-secondary);
    }
  }
`;

export const Content = styled.div`
  padding: 3rem;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background: var(--color-secondary);
  }

  span {
    font-size: var(--fontSizes-4xl);
    color: var(--color-white);
  }

  svg {
    min-width: 2rem;
    max-width: 2rem;

    color: var(--color-white);
  }
`;
