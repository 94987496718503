import styled from 'styled-components';

export const Container = styled.div`
  padding: 0.5rem 2rem;
  border-radius: 0.5rem;
  border-left: 0.1rem solid var(--color-yellow);
  background: var(--color-primary);

  &:hover {
    opacity: var(--opacity-semiOpaque);
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  div.date {
    white-space: nowrap;

    span {
      font-size: var(--fontSizes-lg);
      opacity: var(--opacity-medium);
      color: var(--color-white);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  div {
    margin-right: 1rem;

    img {
      width: 2.8rem;
      height: 2.8rem;
      border-radius: 50%;
      border: 0.2rem solid var(--color-white);
      background: var(--color-secondary);

      object-fit: cover;
    }
  }

  div.group {
    strong {
      display: block;
    }

    span {
      font-size: var(--fontSizes-lg);
      color: var(--color-white);
      opacity: var(--opacity-semiOpaque);
    }

    span.type {
      font-size: var(--fontSizes-2xl);
      font-weight: bold;
      color: var(--color-red);
    }
  }
`;
