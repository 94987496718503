import React from 'react';
import { Switch } from 'react-router-dom';

import SongsArchived from '~/pages/Admin/Account/Archiveds/SongsArchived';
import UsersArchived from '~/pages/Admin/Account/Archiveds/UsersArchived';
import ChurchDice from '~/pages/Admin/Account/Church/ChurchDice';
import ChurchUpdate from '~/pages/Admin/Account/Church/ChurchUpdate';
import Changelogs from '~/pages/Admin/Changelogs';
import Historys from '~/pages/Admin/Historys';
import Logins from '~/pages/Admin/Logins';
import LeadersList from '~/pages/Admin/Presences/Leaders/LeadersList';
import LeadersNew from '~/pages/Admin/Presences/Leaders/LeadersNew';
import LeadersUpdate from '~/pages/Admin/Presences/Leaders/LeadersUpdate';
import PublicsList from '~/pages/Admin/Presences/Publics/PublicsList';
import PublicsNew from '~/pages/Admin/Presences/Publics/PublicsNew';
import PublicsUpdate from '~/pages/Admin/Presences/Publics/PublicsUpdate';
import UsersActive from '~/pages/Admin/User/UsersActive';
import UsersDisabled from '~/pages/Admin/User/UsersDisabled';
import UsersNew from '~/pages/Admin/User/UsersNew';
import UsersPermission from '~/pages/Admin/User/UsersPermission';
import UsersUpdate from '~/pages/Admin/User/UsersUpdate';
import Prayer from '~/pages/Prayer/PrayerList';

import Route from './Route';

export default function Admin() {
  return (
    <Switch>
      <Route
        path="/admin/account/church/church-dice"
        component={ChurchDice}
        isPrivate
      />
      <Route
        path="/admin/account/church/church-update"
        component={ChurchUpdate}
        isPrivate
      />
      <Route
        path="/admin/account/archiveds/songs-archived"
        component={SongsArchived}
        isPrivate
      />
      <Route
        path="/admin/account/archiveds/users-archived"
        component={UsersArchived}
        isPrivate
      />

      <Route path="/admin/changelogs" component={Changelogs} isPrivate />

      <Route path="/admin/historys" component={Historys} isPrivate />

      <Route path="/admin/logins" component={Logins} isPrivate />

      <Route
        path="/admin/presences/publics/publics-list"
        component={PublicsList}
        isPrivate
      />
      <Route
        path="/admin/presences/publics/publics-new"
        component={PublicsNew}
        isPrivate
      />
      <Route
        path="/admin/presences/publics/publics-update"
        component={PublicsUpdate}
        isPrivate
      />

      <Route
        path="/admin/presences/leaders/leaders-new"
        component={LeadersNew}
        isPrivate
      />
      <Route
        path="/admin/presences/leaders/leaders-list"
        component={LeadersList}
        isPrivate
      />
      <Route
        path="/admin/presences/leaders/leaders-update"
        component={LeadersUpdate}
        isPrivate
      />

      <Route
        path="/admin/admin/users-active"
        component={UsersActive}
        isPrivate
      />
      <Route
        path="/admin/admin/users-disabled"
        component={UsersDisabled}
        isPrivate
      />
      <Route path="/admin/admin/users-new" component={UsersNew} isPrivate />
      <Route
        path="/admin/admin/users-update"
        component={UsersUpdate}
        isPrivate
      />
      <Route
        path="/admin/admin/users-permission"
        component={UsersPermission}
        isPrivate
      />

      <Route path="/prayer/prayer-list" component={Prayer} isPrivate />
    </Switch>
  );
}
