import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 4rem;

  display: flex;
  flex-direction: column;

  @media (min-width: 1180px) {
    max-width: 100rem;
    margin: 4rem auto 0;
    padding: 4rem 0;

    display: flex;
    flex-direction: row;

    flex-wrap: nowrap;
  }
`;

export const ContainerTwo = styled.div`
  margin-top: 4rem;

  display: flex;
  flex-direction: column;

  @media (min-width: 1180px) {
    width: 110vh;
    margin: 4rem auto 0;
    padding: 4rem 0;

    display: flex;
    flex-direction: row;

    flex-wrap: nowrap;
  }
`;

export const Aside = styled.aside`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  margin: 1rem 0.5rem;
  border-bottom: 0.1rem solid var(--color-tertiary);

  @media (min-width: 1180px) {
    border-bottom: none;
    border-right: 0.1rem solid var(--color-tertiary);

    flex-direction: column;
    overflow-y: scroll;
  }
`;

export const Main = styled.main`
  padding: 1rem;

  @media (min-width: 1180px) {
    flex: 1;
    margin-left: 3rem;
  }
`;

export const Nav = styled.div`
  margin-bottom: 2rem;

  div {
    display: flex;
    justify-content: end;

    width: 100%;

    button {
      width: 15rem;
    }

    button + button {
      margin-left: 1rem;
    }
  }

  @media (min-width: 1180px) {
    margin-bottom: 4rem;

    div.block {
      width: 65.8rem;
    }
  }
`;

export const NavTwo = styled.div`
  form {
    padding: 2rem;
    border-radius: 0.5rem;
    background: var(--color-primary);

    margin-bottom: 2rem;

    div {
      display: grid;
      gap: 1rem;

      input,
      select {
        margin: 0;
      }

      div {
        margin-bottom: 0;
      }
    }
  }

  @media (min-width: 420px) {
    form {
      div {
        display: flex;
        align-items: center;

        input + input {
          margin-right: 0;
        }

        div {
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 720px) {
    form {
      display: flex;
      justify-content: space-between;

      button {
        margin: 0 0 0 1rem;

        width: 15rem;
      }

      select {
        width: 30rem;
      }
    }
  }

  @media (min-width: 1180px) {
    form {
      margin-bottom: 4rem;
    }
  }
`;

export const Section = styled.div`
  div.loading {
    display: flex;
    justify-content: center;
  }

  ul {
    display: grid;
    gap: 0.5rem;

    p.empty {
      display: flex;
      justify-content: center;

      font-size: var(--fontSizes-2xl);
      color: var(--color-white);
      opacity: var(--opacity-intense);
    }
  }
`;
