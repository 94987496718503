import styled from 'styled-components';

export const Content = styled.div`
  padding: 2rem;
  border-radius: 0.5rem;
  background: var(--color-primary);

  form {
    div.group {
      display: flex;
      flex-direction: column;

      div.date,
      div.projection,
      div.streaming,
      div.footage,
      div.photography,
      div.stories {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-left: 0;
      }

      div {
        width: 100%;
      }
    }

    div.group div + div {
      margin-left: 1rem;
    }

    hr {
      border: 0;
      height: 0.1rem;
      background-color: var(--color-tertiary);

      margin: 0 0 2rem 0;
    }
  }

  @media (min-width: 720px) {
    form {
      div.buttons {
        display: flex;
        flex-direction: row-reverse;

        width: 100%;

        button {
          margin-top: 2rem;
          width: 15rem;
        }

        div.cancel {
          margin-right: 1rem;

          button {
            width: 12rem;
          }
        }
      }
    }
  }
`;
