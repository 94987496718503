import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Switch from 'react-switch';

import { CircularProgress } from '@material-ui/core';
import { Form } from '@rocketseat/unform';
import Swal from 'sweetalert2/src/sweetalert2';
import * as Yup from 'yup';

import CancelButton from '~/components/Layout/Buttons/CancelButton';
import SubmitButton from '~/components/Layout/Buttons/SubmitButton';
import Dialog from '~/components/Layout/Dialog';
import InputText from '~/components/Layout/Inputs/InputText';
import UserLogout from '~/components/UserLogout';
import api from '~/services/api';
import { ToastError, ToastSuccess } from '~/utils/toast';

import { Content, Label, Share } from './styles';

const schema = Yup.object().shape({
  title: Yup.string()
    .min(2, 'Descrição deve ter no mínimo 2 caracteres')
    .max(100, 'Descrição deve ter no máximo 100 caracteres'),
  description: Yup.string()
    .min(5, 'Descrição deve ter no mínimo 5 caracteres')
    .max(1200, 'Descrição deve ter no máximo 1200 caracteres'),
  share: Yup.boolean(),
});

export default function PrayerUpdate({
  open,
  handleClose,
  handleDataUpdate,
  prayerUpdate,
  handleDataDelete,
}) {
  const { nameuser, photo, url, tenant } = useSelector(
    state => state.user.profile
  );

  const [loading, setLoading] = useState(false);
  const [logout, setLogout] = useState(false);
  const [message, setMessage] = useState('');

  const [share, setShare] = useState(prayerUpdate.share);

  async function handleSubmit({ title, description }) {
    setLoading(true);

    try {
      const data = await api
        .put(`/church/prayers-update/${prayerUpdate.id}`, {
          title,
          description,
          share,
        })
        .catch(async res => {
          try {
            if (res.response.data.error === 'Validation fails') {
              ToastError({
                title:
                  'Seus dados foram recusados. Verifique-os e tente novamente',
              });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'User without permission') {
              setMessage(
                'Você não possui permissão para este tipo de operação'
              );

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'IdHistory already exists') {
              ToastError({ title: 'Recarregue a pagina e tente novamente' });

              setLoading(false);

              return;
            }

            if (res.response.data.error === 'Token not provided') {
              setMessage('Autenticação falhou, refaça seu login');

              setLogout(true);

              return;
            }

            if (res.response.data.error === 'Token invalid') {
              setMessage('Autenticação inválida, refaça seu login');

              setLogout(true);

              return;
            }
          } catch (error) {
            ToastError({ title: 'Erro ao adicionar, verifique os dados' });

            setLoading(false);
          }
        });

      if (data === undefined) return;

      handleDataUpdate({
        id: prayerUpdate.id,
        title,
        description,
        share,
        user_id: prayerUpdate.user_id,
        created_at: prayerUpdate.created_at,
        user: share === false ? { photo, url } : { nameuser, photo, url },
        share_old: prayerUpdate.share,
      });

      handleClose();

      ToastSuccess(
        share === false
          ? { title: 'Oração alterada' }
          : { title: `Oração compartilhada com '${tenant}'` }
      );
    } catch (error) {
      setLoading(false);

      ToastError({ title: 'Erro ao adicionar, verifique os dados' });
    }
  }

  async function handleDelete() {
    handleClose();

    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Você não poderá reverter isso!',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: 'var(--color-blue)',
      cancelButtonColor: 'var(--color-red)',
      confirmButtonText: 'Excluir',
      cancelButtonText: 'Cancelar',
    }).then(async result => {
      if (result.value) {
        try {
          await api.delete(`/church/prayers-delete/${prayerUpdate.id}`);

          Swal.fire('Excluída!', 'Oração excluída', 'success');

          handleDataDelete({ id: prayerUpdate.id });
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Algo deu errado!',
          });
        }
      }
    });
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      width={50}
      title="Alterar oração"
      handleClose={handleClose}
    >
      <Content>
        <Form
          schema={schema}
          initialData={prayerUpdate}
          onSubmit={handleSubmit}
        >
          <InputText label="Título" name="title" />

          <InputText label="Descrição" name="description" multiline />

          <Share>
            <Label>Compartilhar</Label>
            <div className="share">
              <Switch
                height={15}
                width={40}
                handleDiameter={20}
                onColor="#0195F6"
                offColor="#121214"
                onChange={() => setShare(!share)}
                checked={share}
              />
            </div>

            <button
              type="button"
              className="delete"
              onClick={() => handleDelete()}
            >
              Excluir oração
            </button>
          </Share>

          <hr />

          <div className="buttons">
            <div>
              <SubmitButton type="submit" disabled={loading}>
                {loading ? (
                  <CircularProgress
                    size={24}
                    style={{ color: 'var(--color-white)' }}
                  />
                ) : (
                  'Salvar'
                )}
              </SubmitButton>
            </div>

            <div className="cancel">
              <CancelButton type="button" onClick={handleClose}>
                Cancelar
              </CancelButton>
            </div>
          </div>
        </Form>
      </Content>

      {logout && <UserLogout message={message} />}
    </Dialog>
  );
}
